import { makeStyles, Theme } from '@material-ui/core/styles'

const attachmentStyles = makeStyles((theme: Theme) => ({
  input: {
    display: 'none',
  },
  helperText: {
    position: 'absolute',
    top: '100%',
    left: 0,
  },
  button: {
    margin: theme.spacing(1.5, 0),
  },
  error: {
    background: '#ab8989',
    '&:hover': {
      background: '#7f5c5c',
    },
  },
}))

export default attachmentStyles
