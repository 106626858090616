import { useEffect } from 'react'
import {
  VERSION_CONFLICT_KEY,
  clearFormBackupData,
  getFormBackupData,
  storeFormBackupData,
  subscribeConflictEvent,
} from './utils'

type Params = {
  data: unknown
  onRestore?: (restoredData: unknown) => void
  enabled?: boolean
}

function useBackupDataOnVersionConflict({ data, onRestore, enabled = true }: Params) {
  useEffect(() => {
    if (!enabled || !onRestore) return

    function storeData() {
      const isDataFunc = typeof data === 'function'
      const dataToStore = isDataFunc ? data() : data
      const dataToJson = JSON.stringify(dataToStore)
      storeFormBackupData(dataToJson)
    }

    function handler(e: StorageEvent) {
      if (e.key !== VERSION_CONFLICT_KEY) return
      storeData()
    }

    window.addEventListener('storage', handler)

    const conflictEventSubscription = subscribeConflictEvent(() => {
      storeData()
    })

    return () => {
      window.removeEventListener('storage', handler)
      conflictEventSubscription.unsubscribe()
    }
  }, [data, enabled, onRestore])

  useEffect(() => {
    if (!enabled || !onRestore) return

    let dataFromStorage = null

    try {
      dataFromStorage = JSON.parse(getFormBackupData() || '')
    } catch (e) {
      dataFromStorage = null
    }

    if (!dataFromStorage) return

    onRestore(dataFromStorage)
    clearFormBackupData()
  }, [enabled, onRestore])
}

export default useBackupDataOnVersionConflict
