import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const attributeAddButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1001,
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
      position: 'sticky',
      top: '113px',
      background: '#f4f6f8',
    },
  })
)

export default attributeAddButtonStyles
