import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { removeContainer } from '../store'

type UseRemoveContainer = (id: string) => any

export const useRemoveContainer: UseRemoveContainer = (id) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(removeContainer(id))
  }, [dispatch, id])
}
