import React, { useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { IconPicker, TextField } from 'core/form'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

const TypeOptions = () => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext<any>()

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target

      // set options
      setFieldValue(name, value)
    },
    [setFieldValue]
  )

  return (
    <>
      <Grid item xs={12}>
        <TextField onChange={handleChange} name={'options.order'} label={t('labels.order')} />
      </Grid>
      <Grid item xs={12}>
        <IconPicker />
      </Grid>
    </>
  )
}

export default TypeOptions
