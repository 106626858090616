import { useMutation, useQueryClient, UseMutationResult } from 'react-query'
import { httpService } from 'core/data'

type UseCreateOne = (resource: string) => UseMutationResult<unknown, unknown, number>

export const useCreate: UseCreateOne = (resource) => {
  const queryClient = useQueryClient()

  return useMutation(
    (values: any) => {
      return httpService.post(`/${resource}`, values)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(resource)
      },
    }
  )
}
