import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useEntityStatusStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    status: {
      display: 'flex',
      alignItems: 'center',
      '& b': {
        display: 'inline-flex',
        marginLeft: 10,
      },
    },
    editBtn: {
      marginLeft: 5,
    },
    collapse: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 5,
    },
    select: {
      minWidth: 160,
      marginRight: 5,
    },
    setStatusBtn: {
      display: 'inline-flex',
      marginRight: 5,
    },
  })
)

export default useEntityStatusStyles
