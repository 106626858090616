import React, { FC, useCallback, useMemo } from 'react'
// Material Components
import Box from '@material-ui/core/Box'
// Material Icons
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Types
import { Section } from 'core/types/layouts'
// Styles
import layoutContainerStyles from './container.styles'
import layoutGeneralStyles from '../../form-container.styles'
// Custom Hooks
import {
  useActiveElement,
  useRemoveContainer,
  useToggleSidebar,
} from 'modules/entity-layouts/hooks'
//
import clsx from 'clsx'

type TProps = {
  data: Section
  isActiveContainer: boolean
  id: string
  width: number
  columns: number
  isSidebar?: boolean
}

const LayoutContainer: FC<TProps> = (props) => {
  const { data, id, width, columns, isActiveContainer, isSidebar } = props
  const classes = layoutContainerStyles()
  const classesGeneral = layoutGeneralStyles()

  const handleRemoveContainer = useRemoveContainer(id)
  const handleActiveContainer = useActiveElement(data.id, id)
  const handleActiveSection = useActiveElement(data.id, '')
  const handleRemoveSidebar = useToggleSidebar(false)

  // columns render
  const createDomElem = useCallback(
    (num: number) => {
      const itemsColumns = []
      for (let i = 1; i <= num; i++) {
        itemsColumns.push(<span className={classes.span} key={i} />)
      }
      return itemsColumns
    },
    [classes.span]
  )

  const letterCount = useMemo(() => {
    return createDomElem(columns)
  }, [columns, createDomElem])

  return (
    <Box className={classes.root} width={width / 100}>
      <Box
        className={clsx(
          classesGeneral.container,
          isActiveContainer && classesGeneral.activeContainer
        )}
      >
        <Box className={classes.columnsWrapper} onClick={handleActiveContainer}>
          {letterCount}
        </Box>
        {isActiveContainer && (
          <IconButton
            onClick={() => {
              handleRemoveContainer()
              handleActiveSection()
              isSidebar && handleRemoveSidebar()
            }}
            className={clsx(classesGeneral.removeIco, classesGeneral.generalIco)}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default LayoutContainer
