import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // position: 'relative'
    },
    disabled: {
      opacity: 0.7,
      pointerEvents: 'none',
    },
    loader: {
      position: 'absolute',
      top: 16,
      right: 10,
      zIndex: 10,
    },
  })
)

export default styles
