// Core
import React, { useCallback, useState } from 'react'
import { useHistory as useRouterHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
// Components
import History from './history'
// Hooks
import { useConfirmation } from 'core/confirmation'
import useDateUtils from 'core/date/use-date-utils'
import { useNotify } from 'core/hooks'
// Services
import { EntityService } from 'modules/new-entity/utils'
// Types
import { EavResourceType, IEntity, AsideComponent } from 'modules/new-entity/types'
import { EntityType } from 'core/types'

type Params = {
  entity: IEntity
  entityType: EntityType
  isWidgets: boolean
  asideComponents: AsideComponent[]
  toggleAsideComponents: (component: AsideComponent, isShowAction?: boolean) => void
  resourceType?: EavResourceType
}

export default function useHistory({
  entity,
  entityType,
  isWidgets,
  asideComponents,
  toggleAsideComponents,
  resourceType,
}: Params) {
  const history = useRouterHistory()
  const { setConfirmation } = useConfirmation()
  const notify = useNotify()
  const { formateDate } = useDateUtils()

  const [isOpen, setIsOpen] = useState(false)

  const closeHistory = useCallback(() => {
    toggleAsideComponents('history')
    setIsOpen(false)
  }, [toggleAsideComponents])

  const showHistory = useCallback(() => {
    const isActive = isOpen && asideComponents.at(-1) === 'history'

    if (isActive) {
      closeHistory()
    } else {
      toggleAsideComponents('history', true)
      setIsOpen(true)
    }
  }, [asideComponents, closeHistory, isOpen, toggleAsideComponents])

  const entityUrlBase = `/${resourceType}/${entityType?.id}/edit`
  const originalPath = `${entityUrlBase}/${entity?.originalId}`
  const listPath = !entityType?.single ? `/${resourceType}/${entityType?.id}` : null

  const restoreM = useMutation(
    () => {
      return EntityService.restoreEntityVersion(entity.originalId, entity['@id'], isWidgets)
    },
    {
      onSuccess: () => {
        history.push(originalPath)
        notify('Successfully Restored', { type: 'success' })
      },
      onError: () => {
        notify('Something went wrong', { type: 'error' })
      },
    }
  )

  const restoreVersion = useCallback(() => {
    const updatedAt = formateDate(entity?.updatedAt)

    setConfirmation({
      open: true,
      title: 'Confirm restoring',
      content: `Are you sure you want to restore ${updatedAt} version?`,
      onSuccess: () => {
        restoreM.mutate()
      },
    })
  }, [entity, formateDate, restoreM, setConfirmation])

  const isRestoring = restoreM.isLoading

  return {
    isOpen,
    showHistory,
    originalPath,
    listPath,
    restoreVersion,
    isRestoring,
    History: isOpen && (
      <History
        onClose={closeHistory}
        data={entity.originalVersions || []}
        entityTypeId={entityType?.id as number}
        entityId={entity?.id}
        originalId={entity?.originalId}
        entityUrlBase={entityUrlBase}
      />
    ),
  }
}
