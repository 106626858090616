import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'
import { colors } from '@material-ui/core'

const { disableBg } = defaultThemeColors

const globalAttributeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: theme.spacing(17),
      '&.system > button': {
        background: disableBg,
      },
    },
    actionArea: {
      border: '1px solid #cfd8dc',
      transition: 'all .3s ease',
      background: '#fff',
      padding: '5px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      height: '100%',
      borderRadius: theme.spacing(0.5),
      paddingBottom: theme.spacing(1),
      '& p': {
        wordBreak: 'break-word',
      },
      '&:hover': {
        boxShadow: ' 0px 0px 1px rgba(0, 0, 0, 0.31), 0px 10px 20px rgba(0, 0, 0, 0.25)',
      },
    },
    iconWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: theme.spacing(11),
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      backgroundColor: '#E4E2FF', // need change to theme colors
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      '& > span': {
        color: theme.palette.common.white,
      },
    },
    icon: {
      fontSize: '2.5rem',
      color: theme.palette.primary.main,
    },
    line: {
      fontSize: '0.85rem',
      color: colors.blueGrey['900'],
      padding: theme.spacing(0.5),
    },
    name: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    type: {
      color: colors.blueGrey[600],
    },
    notAllowed: {
      cursor: 'not-allowed',
    },
  })
)

export default globalAttributeItemStyles
