// Core
import React, { FC } from 'react'
import { ViewType, Search } from './components'
// Styles
import useStyles from './data-header.styles'

type Props = {
  hasGrid: boolean
}

const DataHeader: FC<Props> = ({ hasGrid }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Search />
      {hasGrid && <ViewType />}
    </div>
  )
}

export default DataHeader
