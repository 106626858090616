import { httpService } from 'core/data'
import { IUser, TUserRole } from 'core/types'
import { getIdFromIri } from 'core/utils'
import { Site } from 'modules/sites'

export class AppService {
  static async getActiveRole(site: Site, user: IUser) {
    const siteIri = site['@id']
    const activeSiteRole = user.siteRoles.find((item) => item.site === siteIri)
    if (!activeSiteRole) throw new Error('User cannot be without role')
    const activeSiteRoleIri = activeSiteRole.role
    const activeRoleData = await httpService
      .get<TUserRole>(`/roles/${getIdFromIri(activeSiteRoleIri)}`, {
        headers: {
          'x-site-id': site.token,
        },
      })
      .then((res) => res.data)
    return activeRoleData
  }
}
