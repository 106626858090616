// Types
import { ValidationRuleMap, RuleTypesList } from '../types'

/**
 * Map of validation rules to validation values
 *
 * @example
 * const ruleValue = validationRulesMap[ruleName]
 * if (ruleValue.type === RuleTypesList.boolean) {
 *   return <Checkbox name={ruleValue.name} />
 * }
 */
export const validationRulesMap: ValidationRuleMap = {
  required: { type: RuleTypesList.boolean, name: 'required' },
  unique: { type: RuleTypesList.boolean, name: 'unique' },
  global_unique: { type: RuleTypesList.boolean, name: 'global_unique' },
  length: {
    type: RuleTypesList.fieldArray,
    name: 'length',
    fields: [
      { type: RuleTypesList.field, name: 'min', inputType: 'number' },
      { type: RuleTypesList.field, name: 'max', inputType: 'number' },
    ],
  },
  regex: { type: RuleTypesList.field, name: 'regex' },
  range: {
    type: RuleTypesList.fieldArray,
    name: 'range',
    fields: [
      { type: RuleTypesList.field, name: 'min', inputType: 'number' },
      { type: RuleTypesList.field, name: 'max', inputType: 'number' },
    ],
  },
  repeats: {
    type: RuleTypesList.fieldArray,
    name: 'count',
    fields: [
      { type: RuleTypesList.field, name: 'min', inputType: 'number' },
      { type: RuleTypesList.field, name: 'max', inputType: 'number' },
    ],
  },
}
