import React, { FC } from 'react'
import { Switch, Route as ReactRouter } from 'react-router-dom'
import { Route } from 'core/router'
import { EditLocalization, LocalizationList } from 'modules/translations/components'
import { NotFound } from 'common/components'

type TProps = {
  children?: never
  path: string
}

const NavigationResource: FC<TProps> = (props) => {
  const { path } = props

  return (
    <ReactRouter {...props} path={`${path}`}>
      <Switch>
        <Route path={`${path}`} exact componentTo={<LocalizationList name={'translations'} />} />
        <Route
          path={`${path}/:code`}
          exact
          componentTo={<EditLocalization name={'translations'} />}
        />
        <Route path="/*" componentTo={<NotFound />} />
      </Switch>
    </ReactRouter>
  )
}

export default NavigationResource
