import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const createFieldsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5, 0, 0),
    },
    rootNested: {
      padding: theme.spacing(5, 5, 0),
    },
  })
)

export default createFieldsStyles
