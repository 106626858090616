import { httpService } from 'core/data/services'
import { QueryOptions, useQuery } from 'react-query'

type Params = {} & QueryOptions<unknown>

export const useGetOne = <Data = any>(resource: string, id: number, params: Params = {}) => {
  const { queryKey, ...queryOptions } = params

  return useQuery<unknown, unknown, Data>(
    [resource, id, queryKey],
    () => {
      return httpService.get<Data>(`/${resource}/${id}`).then((res) => res.data)
    },
    queryOptions
  )
}
