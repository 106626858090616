// Core
import React, { FC } from 'react'
import { Box, Button, FormLabel, Paper } from '@material-ui/core'
import { FieldArray, getIn } from 'formik'
import { v4 as uniqueId } from 'uuid'
// Components
import RuleFields from './rule-fields'
import OperatorDivider from './operator-divider'
import AddButton from './add-button'
// Types
import {
  SegmentGroupCommon,
  SegmentGroupCreate,
  SegmentRuleCommon,
  SegmentRuleCreate,
} from 'modules/segments/types'

type Props = {}

const createSegmentRule = (): SegmentRuleCreate => ({
  uuid: uniqueId(),
  type: 'location',
  operator: 'is',
  data: [],
})

const createSegmentGroup = (): SegmentGroupCreate => ({
  uuid: uniqueId(),
  segmentRules: [createSegmentRule()],
})

const isLastEL = (arr: unknown[], index: number) => arr.length - 1 === index

const RulesField: FC<Props> = () => {
  return (
    <Box>
      <FormLabel>Characteristic rules</FormLabel>
      <Box mt={1}>
        <FieldArray
          name="segmentRuleGroups"
          render={(groupHelpers) => {
            const groups: SegmentGroupCommon[] = groupHelpers.form.values.segmentRuleGroups
            const hasGroups = groups.length > 0

            return (
              <>
                {groups.map((group, groupIndex) => {
                  const isLast = isLastEL(groups, groupIndex)

                  return (
                    <Box key={group.uuid || group.id} mb={2}>
                      <Paper>
                        <FieldArray
                          name={`segmentRuleGroups.${groupIndex}.segmentRules`}
                          render={(rulesHelpers) => {
                            const rules: SegmentRuleCommon[] = getIn(
                              rulesHelpers.form.values,
                              `segmentRuleGroups.${groupIndex}.segmentRules`
                            )

                            const hasRules = rules.length > 0

                            return (
                              <Box p={1}>
                                {rules.map((rule, ruleIndex) => (
                                  <RuleFields
                                    key={rule.uuid || rule.id}
                                    data={rule}
                                    isLast={isLastEL(rules, ruleIndex)}
                                    fieldsPath={`segmentRuleGroups.${groupIndex}.segmentRules.${ruleIndex}`}
                                    onDelete={() => {
                                      if (rules.length === 1) {
                                        groupHelpers.remove(groupIndex)
                                      } else {
                                        rulesHelpers.remove(ruleIndex)
                                      }
                                    }}
                                  />
                                ))}
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  mt={hasRules ? 2 : 0}
                                >
                                  <AddButton
                                    text={hasRules ? 'Or' : 'Add rule'}
                                    onClick={() => {
                                      rulesHelpers.push(createSegmentRule())
                                    }}
                                  />
                                  <Button
                                    size="small"
                                    onClick={() => groupHelpers.remove(groupIndex)}
                                    color="primary"
                                  >
                                    Delete group
                                  </Button>
                                </Box>
                              </Box>
                            )
                          }}
                        />
                      </Paper>
                      {!isLast && <OperatorDivider text="And" spacing={2} />}
                    </Box>
                  )
                })}

                <AddButton
                  text={hasGroups ? 'And' : 'Add rules'}
                  onClick={() => {
                    groupHelpers.push(createSegmentGroup())
                  }}
                />
              </>
            )
          }}
        />
      </Box>
    </Box>
  )
}

export default RulesField
