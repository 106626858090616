import cloneDeep from 'lodash.clonedeep'

export const transformSettingsReq = (data: { [name: string]: string | number }) => {
  data = cloneDeep(data)

  if (data.priority) data.priority = +data.priority
  if (data.importantPeriodPriority) data.importantPeriodPriority = +data.importantPeriodPriority
  if (data.importantPeriodDuration) data.importantPeriodDuration = +data.importantPeriodDuration

  return data
}
