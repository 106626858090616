import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(4),
    },
    control: {
      minWidth: '200px',
      marginRight: theme.spacing(2),
    },
    button: {
      alignSelf: 'center',
    },
  })
)
