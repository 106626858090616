import React, { FC } from 'react'
import { Create } from 'core/data'
import { ResourceProps } from 'core/data'
import CrudForm from '../crud-form'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
})

const CreateNetwork: FC<ResourceProps> = (props) => {
  const initialProps = {
    name: '',
  }

  return (
    <Create
      validationSchema={validationSchema}
      initialData={initialProps}
      {...props}
      crudForm={CrudForm}
    />
  )
}

export default CreateNetwork
