import React, { FC } from 'react'
import { Box, IconButton } from '@material-ui/core'
import { ViewList, ViewModule } from '@material-ui/icons'

export const ViewTypeSwitcher: FC = () => {
  return (
    <Box>
      <IconButton>
        <ViewList />
      </IconButton>
      <IconButton>
        <ViewModule />
      </IconButton>
    </Box>
  )
}
