import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { removeSection } from '../store'

type UseRemoveSection = (id: string) => any

export const useRemoveSection: UseRemoveSection = (id) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(removeSection(id))
  }, [dispatch, id])
}
