import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const rootLoaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export default rootLoaderStyles
