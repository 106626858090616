// Core
import React, { FC } from 'react'
import { Switch, Route as ReactRouter } from 'react-router-dom'
import { Route } from 'core/router'
// Components
import { TypesList, EntitiesList, EntityMutationRoute } from '../../components'
import { NotFound } from 'common'
// Types
import { EavResourceType, MutationType } from '../../types'

type Props = {
  children?: never
  path: string
  resourceType: EavResourceType
}

const Resource: FC<Props> = (props) => {
  const { path, resourceType } = props

  return (
    <ReactRouter {...props} path={`${path}`}>
      <Switch>
        <Route path={`${path}`} exact componentTo={<TypesList resourceType={resourceType} />} />
        <Route
          path={`${path}/:typeId`}
          exact
          componentTo={<EntitiesList resourceType={resourceType} />}
        />
        <Route
          path={`${path}/:typeId/edit/:id`}
          exact
          componentTo={
            <EntityMutationRoute mutationType={MutationType.EDIT} resourceType={resourceType} />
          }
        />
        <Route
          path={`${path}/:typeId/create`}
          exact
          componentTo={
            <EntityMutationRoute mutationType={MutationType.CREATE} resourceType={resourceType} />
          }
        />
        <Route path="/*" componentTo={<NotFound />} />
      </Switch>
    </ReactRouter>
  )
}

export default Resource
