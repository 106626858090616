/**
 * @deprecated - use RelationsSelect and RelationsField
 */
// Core
import React, { FC, useCallback, useRef } from 'react'
import { useField } from 'formik'
// Components
import { SmartSelect } from 'ui'
// Types
import { SmartSelectProps, SmartSelectValue } from 'ui'

type Props = {
  children?: never
  name: string
  entityTypeIri?: string | undefined
} & SmartSelectProps

const SmartSelectField: FC<Props> = (props) => {
  const { name, entityTypeIri, onChange, ...rest } = props
  const [field, meta, { setValue, setTouched }] = useField(name)

  // REF to get useSmartSelect methods
  const methodsRef = useRef<any>()

  const changeHandler = useCallback(
    (item: SmartSelectValue) => {
      setValue(item)
      if (onChange) onChange(item)
    },
    [setValue, onChange]
  )

  const blurHandler = useCallback(() => {
    setTouched(true)
  }, [setTouched])

  return (
    <SmartSelect
      {...rest}
      value={field.value}
      onChange={changeHandler}
      onBlur={blurHandler}
      error={meta.touched ? meta.error : undefined}
      innerRef={methodsRef}
      entityTypeIri={entityTypeIri}
    />
  )
}

export default SmartSelectField
