// Core
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
// Hooks
import { useNotify } from 'core/hooks'
// Services/Utils
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
// Types
import { OnVersionMutation, ResourceWithVersions, VersionCreateFunc } from '../types'

type Params = {
  resource: string
  resourceId: number
  typeId?: number
  basePath?: string
  onVersionMutation: OnVersionMutation
  onVersionMutationSuccess?: () => Promise<unknown>
}

export default function useResourceSwitcherControl(params: Params) {
  const { resource, resourceId, onVersionMutation, onVersionMutationSuccess, typeId, basePath } =
    params

  const history = useHistory()
  const notify = useNotify()

  const [mutationState, setMutationState] = useState<{
    type: 'edit' | 'create'
    id?: number
  } | null>(null)

  const dataQ = useQuery(
    ['versions-data', resource, resourceId],
    async () => {
      let resourceData = await httpService
        .get<ResourceWithVersions>(`/${resource}/${resourceId}`)
        .then((res) => res.data)

      const isOriginal = Boolean(!resourceData.original)
      const currentName = isOriginal ? 'Default' : resourceData.name
      const originalId = isOriginal ? resourceData.id : +getIdFromIri(resourceData.original!)

      if (!isOriginal) {
        resourceData = await httpService
          .get<ResourceWithVersions>(`/${resource}/${originalId}`)
          .then((res) => res.data)

        resourceData.versions.unshift({
          id: originalId,
          name: 'Default',
          originalLabel: 'personalized',
          '@id': `/api/${resource}/${originalId}`,
        })
      }

      return {
        currentName,
        isOriginal,
        originalId,
        versions: resourceData.versions.filter(
          (resourceVersion) => resourceVersion.originalLabel === 'personalized'
        ),
      }
    },
    {
      enabled: Boolean(resourceId),
    }
  )

  const getEditPath = useCallback(
    (id: number) => {
      if (basePath) {
        return `/${basePath}/${id}`
      }
      return `/${resource}/${typeId ? `${typeId}` : ''}/edit/${id}`
    },
    [resource, typeId, basePath]
  )

  const versionCreateHandler = useCallback<VersionCreateFunc>(
    async (values) => {
      if (!dataQ.data?.originalId) {
        throw new Error('Original ID is not exist')
      }

      const isEdit = Boolean(values.id)

      const created = await onVersionMutation({
        originalId: dataQ.data.originalId,
        resourceId: values.id,
        name: values.name,
        segments: [values.segment],
      })

      if (onVersionMutationSuccess) {
        await onVersionMutationSuccess()
      }

      if (!isEdit) {
        history.push(getEditPath(created.id))
      } else {
        dataQ.refetch()
      }

      setMutationState(null)

      notify(`Personalized version is successfully ${isEdit ? 'edited' : 'created'}`, {
        type: 'success',
      })
    },
    [dataQ, getEditPath, history, onVersionMutation, onVersionMutationSuccess, notify]
  )

  return {
    ...dataQ,
    mutationState,
    setMutationState,
    getEditPath,
    versionCreateHandler,
  }
}
