// Core
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import debounce from 'lodash.debounce'
import { useField } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import { httpService } from 'core/data'
// Types
import { ValueFieldProps } from './types'
import { LocationValue } from 'modules/segments/types'

type Prediction = {
  id: string
  type: string
  attributes: {
    type: 'country' | 'region' | 'city'
    city: string | number
    region: string
    country: string
    description: string
  }
}

const LocationField: FC<ValueFieldProps> = ({ fieldPath }) => {
  const [{ value }, meta, helpers] = useField<LocationValue[]>(fieldPath)

  const [term, setTerm] = useState('')
  const [options, setOptions] = useState<Prediction[]>([])

  const fetchPredictions = useMemo(
    () =>
      debounce(async (input: string) => {
        try {
          const placementApiUrl = process.env.REACT_APP_PLACEMENT_SERVICE_API_URL

          const places = await httpService
            .get<{ data: Prediction[] }>(`${placementApiUrl}/locations/search/${input}`, {
              transformRequest: (data, headers) => {
                delete headers.JWTAuthorization
                delete headers['X-SITE-ID']
              },
            })
            .then((res) => res.data.data)
          if (!places) return
          setOptions(places)
        } catch (e) {
          console.log(e)
        }
      }, 400),
    []
  )

  useEffect(() => {
    if (!term) {
      setOptions([])
      return
    }

    fetchPredictions(term)
  }, [term, fetchPredictions])

  const selectHandler = useCallback(
    async (values: LocationValue[] | Prediction[]) => {
      const newValues: LocationValue[] = values.map((value) => {
        if ('attributes' in value) {
          return {
            internalId: value.id,
            description: value.attributes.description,
            type: value.attributes.type,
            city: value.attributes.city,
            region: value.attributes.region,
            country: value.attributes.country,
          }
        }
        return value
      })

      helpers.setValue(newValues)
    },
    [helpers]
  )

  return (
    <Box>
      <Autocomplete
        options={options}
        size="small"
        multiple
        // @ts-expect-error
        getOptionLabel={(option) => option.attributes?.description || option.description}
        // @ts-expect-error
        getOptionSelected={(option, value) => option.attributes.description === value.description}
        inputValue={term}
        onInputChange={(e, value) => setTerm(value)}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            label="Value"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.error}
          />
        )}
        // @ts-expect-error
        value={value}
        onChange={(e, val) => {
          // @ts-expect-error
          selectHandler(val)
        }}
        limitTags={1}
        freeSolo
      />
    </Box>
  )
}

export default LocationField
