// Core
import React, { cloneElement, FC, ReactElement } from 'react'
import { Drawer } from '@material-ui/core'
import { defaultThemeSizes } from 'core/themes/default/default'
// Types
import { ActionsData } from 'core/data/types/actions'
// Styles
import sidebarStyles from './sidebar.styles'

type TProps = {
  children?: ReactElement
  type: 'create' | 'edit'
  actionsData?: ActionsData
}

const SidebarGlobal: FC<TProps> = (props) => {
  const { children } = props
  const classes = sidebarStyles()

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      className={classes.root}
      PaperProps={{
        style: {
          top: defaultThemeSizes.pageHeaderHeight + 64,
          zIndex: 1180,
          height: `calc(100% - ${69 + 64}px)`,
        },
      }}
    >
      {children ? cloneElement(children, { ...props }) : null}
    </Drawer>
  )
}

export default SidebarGlobal
