// Core
import React, { FC, useCallback, useMemo } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Box, Grid, Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
// Hooks
import { useTranslation } from 'react-i18next'
import { usePageBuilderContext } from 'modules/new-entity/context'
import { useActionWithConfirmation } from 'modules/new-entity/hooks'
// Components
import ButtonAdd from 'modules/new-entity/components/page-builder/components/button-add'
import SectionBg from 'modules/new-entity/components/page-builder/components/container/components/section-colorpicker'
import Actions from 'modules/new-entity/components/page-builder/components/actions'
// Types
import { SectionBackground, SectionContainer } from 'core/types/layouts'
import { IEntityWidget, RemoveSectionId } from 'modules/new-entity/types'
import { Actions as ActionsType } from 'modules/new-entity/components/page-builder/types'
// Styles
import useStyles from './container.styles'

type Props = {
  setTypesModalState: (value: any) => void
  container: SectionContainer
  widgets: IEntityWidget[]
  hasWidget: boolean
  sectionId: any
  sectionBg: string | undefined
  containerDragHandleProps: DraggableProvidedDragHandleProps | undefined
  disableMoveDown: boolean
  disableMoveUp: boolean
  index: number
  isDraggingOver: boolean
  isDropping: boolean
  isDragging: boolean
  disabled?: boolean
}

const Container: FC<Props> = (props) => {
  const classes = useStyles()
  const {
    setTypesModalState,
    container,
    widgets,
    hasWidget,
    sectionId,
    sectionBg,
    children,
    containerDragHandleProps,
    disableMoveDown,
    disableMoveUp,
    index,
    isDraggingOver,
    isDropping,
    isDragging,
    disabled,
    ...rest
  } = props

  const { t } = useTranslation()

  const {
    activeWidgets,
    dynamicLayout,
    actions: { toggleContainer, removeSection, reorderSection, selectSectiontBg },
  } = usePageBuilderContext()
  const isOpen = Boolean(activeWidgets[container.id])

  const actionWithConfirmation = useActionWithConfirmation(
    'global.change-layout',
    'notify.delete-container'
  )

  const removeSectionHandler = useCallback(
    (values: RemoveSectionId) => {
      actionWithConfirmation(() => removeSection(values))
    },
    [actionWithConfirmation, removeSection]
  )

  const selectSectiontBgHandler = (color: SectionBackground) => {
    selectSectiontBg(sectionId, color)
  }

  const containerActions = useMemo(
    (): ActionsType => ({
      moveUpAction: {
        action: () => reorderSection(index, index - 1),
        condition: !disableMoveUp,
      },
      moveDownAction: {
        action: () => reorderSection(index, index + 1),
        condition: !disableMoveDown,
      },
      deleteAction: {
        action: () => removeSectionHandler({ containerId: container.id, sectionId }),
        condition: true,
      },
    }),
    [
      container.id,
      disableMoveDown,
      disableMoveUp,
      index,
      removeSectionHandler,
      reorderSection,
      sectionId,
    ]
  )

  return (
    <Grid {...rest} item xs={12} className={classes.root}>
      <Box
        className={clsx(classes.wrap, {
          [classes.isDraggingOver]: isDraggingOver,
          [classes.isDropping]: isDraggingOver && isDropping,
          [classes.isDragging]: isDragging,
        })}
      >
        <div className={classes.topPanel}>
          <div className={classes.info}>
            <div
              className={clsx(classes.dndBtn, {
                [classes.dndBtnDisabled]: disabled,
                [classes.hidden]: !dynamicLayout,
              })}
              {...containerDragHandleProps}
            >
              <Tooltip
                placement="top"
                title={`${t('page-builder.container.drag')}`}
                classes={isDragging ? { popper: classes.hidden } : {}}
              >
                <Icon className="icon-dragindrop" />
              </Tooltip>
            </div>
            {hasWidget && (
              <IconButton
                size="small"
                onClick={() => toggleContainer(container.id, widgets)}
                className={classes.toggleBtn}
                disableRipple
              >
                <Tooltip
                  placement="top"
                  title={`${
                    !isOpen
                      ? t('page-builder.container.expand')
                      : t('page-builder.container.collapse')
                  }`}
                >
                  <Icon className={`icon-chevron-${!isOpen ? 'right' : 'down'}`} />
                </Tooltip>
              </IconButton>
            )}
            <Typography className={classes.title} component="p">
              {container.name}
            </Typography>
          </div>
          {dynamicLayout && (
            <div className={classes.settings}>
              <div className={classes.bg}>
                <SectionBg
                  label={t('page-builder.container.bg')}
                  color={sectionBg!}
                  clickHandler={selectSectiontBgHandler}
                  disabled={disabled}
                />
              </div>
              <Actions actions={containerActions} disabled={disabled} />
            </div>
          )}
        </div>

        {children}

        <ButtonAdd
          disabled={disabled || isDraggingOver}
          onClick={() => {
            setTypesModalState({
              container: container.id,
              type: 'push',
            })
          }}
          fullWidth
        />
      </Box>
    </Grid>
  )
}

export default Container
