// Core
import React, { FC } from 'react'
import { CircularProgress, IconButton } from '@material-ui/core'
import { Delete, Edit, OpenInNew } from '@material-ui/icons'
import clsx from 'clsx'
// Hooks
import { useDeleteEntity } from 'modules/new-entity/hooks'
// Types
import { SelectActions, SelectHandler, SelectOption, SelectValueProp } from './types'

type Props = {
  valueData: SelectOption
  selectHandler?: SelectHandler
  refetch?: () => void
  value?: SelectValueProp
  viewAction?: SelectActions['viewAction']
  editAction?: SelectActions['editAction']
  deleteAction?: SelectActions['deleteAction']
}

const Actions: FC<Props> = (props) => {
  const { valueData, value, selectHandler, refetch, viewAction, editAction, deleteAction } = props

  const id = valueData?.id

  const { deleteEntityM, deleteEntity } = useDeleteEntity(id, {
    onSuccess: () => {
      refetch?.()

      if (deleteAction && value && selectHandler) {
        deleteAction(id, value, selectHandler)
      }
    },
  })

  const editHandler: React.MouseEventHandler = (e) => {
    e.stopPropagation()
    editAction?.(id)
  }

  const viewHandler: React.MouseEventHandler = (e) => {
    e.stopPropagation()
  }

  const deleteHandler: React.MouseEventHandler = (e) => {
    e.stopPropagation()
    deleteEntity()
  }

  return (
    <div className={clsx('crud-actions', { loading: deleteEntityM.isLoading })}>
      {deleteEntityM.isLoading ? (
        <CircularProgress size={15} />
      ) : (
        <>
          {editAction && (
            <IconButton size="small" onClick={editHandler}>
              <Edit />
            </IconButton>
          )}
          {viewAction && (
            <IconButton
              component="a"
              href={viewAction(valueData)}
              target="_blank"
              size="small"
              onClick={viewHandler}
            >
              <OpenInNew />
            </IconButton>
          )}
          {deleteAction && (
            <IconButton size="small" onClick={deleteHandler}>
              <Delete />
            </IconButton>
          )}
        </>
      )}
    </div>
  )
}

export default Actions
