// Core
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik, FormikHelpers } from 'formik'
import { Grid } from '@material-ui/core'
// Components
import FormPageLayout from 'modules/layout/components/form-page-layout'
import { SubmitButton, TextField } from 'core/form'
import ChangePassword from '../components/change-password'
// Hooks
import { useAppContext } from 'core/app'
import { useNotify } from 'core/hooks'
import useGetProfile from '../hooks/use-get-profile'
// Utils/Services
import { ApiErrorsService } from 'core/form/utils/api-errors'
import { httpService } from 'core/data'
// Types
import { IUser } from 'core/types'

const Profile: FC = () => {
  const { t } = useTranslation()
  const notify = useNotify()
  const { user } = useAppContext()
  const { data, isLoading, refresh } = useGetProfile()

  const submitHandler = async (values: IUser, helpers: FormikHelpers<IUser>) => {
    try {
      await httpService.put(`/user_profiles/${user.id}`, values)
      notify('Your profile has been updated', { type: 'success' })
      await refresh()
    } catch (error) {
      if (ApiErrorsService.isApiError(error)) {
        const apiErrorsService = new ApiErrorsService(helpers, error as any)
        apiErrorsService.setFormErrors()
      } else {
        notify('Something went wrong', { type: 'error' })
      }
    }
  }

  return (
    <Formik initialValues={data} onSubmit={submitHandler}>
      <Form>
        <FormPageLayout
          title="Profile"
          sidebar={<SubmitButton title="Save" />}
          isLoading={isLoading}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField name="firstName" type="text" required label={t('labels.first-name')} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="lastName" type="text" required label={t('labels.last-name')} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="alias" type="text" required label={t('labels.alias')} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="email" type="text" required label={t('labels.email')} disabled />
            </Grid>
            <Grid item xs={12}>
              <ChangePassword />
            </Grid>
          </Grid>
        </FormPageLayout>
      </Form>
    </Formik>
  )
}

export default Profile
