// Core
import React, { FC, useMemo } from 'react'
import * as Yup from 'yup'
// Components
import { Edit } from 'core/data'
import CrudForm from '../crud-form'
// Hooks
import { useResetMfa } from 'modules/user/hooks/use-reset-mfa'
// Types
import { ResourceProps } from 'core/data'

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  alias: Yup.string().required(),
  // userRoles: Yup.array().required(),
  // sites: Yup.array().required(),
})

const UserEdit: FC<ResourceProps> = (props) => {
  const { resetMfa } = useResetMfa(props.name)

  const actions = useMemo(() => {
    const actionWrapper = (action: () => void) => (onShowActions?: (value: boolean) => void) => {
      action()
      onShowActions && onShowActions(false)
    }

    return [
      {
        name: 'reset_mfa',
        label: 'Reset 2 factor authentication',
        action: actionWrapper(resetMfa.mutate),
        condition: true,
        icon: 'icon-unlock',
      },
    ]
  }, [resetMfa])

  const actionsData = {
    actions,
    loading: resetMfa.isLoading,
  }

  return (
    <Edit
      validationSchema={validationSchema}
      {...props}
      crudForm={CrudForm}
      actionsData={actionsData}
    />
  )
}

export default UserEdit
