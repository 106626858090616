import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const { purpleLight } = defaultThemeColors

const layoutContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '98px',
      cursor: 'pointer',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(0, 1),
      display: 'flex',
    },

    columnsWrapper: {
      display: 'flex',
      width: '100%',
    },

    span: {
      borderLeft: `1px dashed ${purpleLight}`,
      minHeight: '98px',
      width: '100%',
      pointerEvents: 'none',
      '&:first-child': {
        borderLeft: 'none',
      },
    },
  })
)

export default layoutContainerStyles
