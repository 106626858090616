// Core
import React, { FC } from 'react'
import { useQuery } from 'react-query'
// Components
import RelationsSelect, { RelationsSelectProps } from '../relations-select'
// Hooks
import { useGetActiveSite } from 'modules/sites'
import { useActions } from './hooks/use-actions'
// Services/Utils
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
// Types
import { EntityType } from 'core/types'

type Props = {
  entityTypeIri?: string
  forceNewTabAction?: boolean
} & Omit<RelationsSelectProps, 'source'>

const EntityRelationsSelect: FC<Props> = (props) => {
  const {
    defaultValue = '',
    multiple,
    reqParams,
    valueField,
    entityTypeIri,
    forceNewTabAction,
    ...rest
  } = props

  const entityTypeId = entityTypeIri ? getIdFromIri(entityTypeIri) : null

  const activeSite = useGetActiveSite()

  const entityTypeQuery = useQuery(
    ['entity-type', entityTypeIri],
    () => {
      return httpService.get<EntityType>(`/entity_types/${entityTypeId}`).then((res) => res.data)
    },
    {
      enabled: Boolean(entityTypeIri),
    }
  )

  const { actions, renderModal } = useActions({
    entityType: entityTypeQuery.data,
    valueField,
    defaultValue,
    multiple,
  })

  const canStartFetchData = entityTypeId ? !entityTypeQuery.isLoading : true

  const openInNewTabAction = forceNewTabAction || Boolean(entityTypeId)

  const hasTemplateFilter = Boolean(reqParams?.template)

  const network = activeSite?.network
  const networkId = getIdFromIri(network!)

  const dataQueryParams = {
    original: true,
    ...(!hasTemplateFilter && { 'filter[status][equal]': 'publish' }),
    ...(entityTypeQuery.data?.sharedBetweenNetworkSites && {
      network,
      'filter[_network][equal]': networkId,
    }),
  }

  return (
    <RelationsSelect
      source="entities"
      defaultValue={defaultValue}
      valueField={valueField}
      reqParams={reqParams}
      dataQueryParams={dataQueryParams}
      searchParam="filter._name.contain"
      multiple={multiple}
      canStartFetchData={canStartFetchData}
      openInNewTabAction={openInNewTabAction}
      viewAction={actions.viewAction}
      createAction={actions.createAction}
      editAction={actions.editAction}
      deleteAction={actions.deleteAction}
      renderModal={renderModal}
      {...rest}
    />
  )
}

export default EntityRelationsSelect
