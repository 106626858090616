import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const attributeTypeSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      padding: theme.spacing(1, 2),
    },
  })
)

export default attributeTypeSelectStyles
