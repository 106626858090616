import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const renderValuesStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    count: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '-12px',

      width: theme.spacing(3),
      height: theme.spacing(3),
      padding: theme.spacing(0.8),
      borderRadius: '50%',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${colors.blueGrey['100']}`,
    },
    formControlItem: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',

      padding: theme.spacing(3, 2),
      margin: theme.spacing(3, 0),

      border: '2px dashed transparent',
      borderRadius: theme.spacing(0.5),

      transition: theme.transitions.create(['background-color', 'border-color'], {
        duration: theme.transitions.duration.standard,
      }),
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
        borderColor: colors.blueGrey['100'],
        '& > .action--area': {
          opacity: 1,
        },
        '& > .move--btn': {
          opacity: 1,
        },
      },
    },
  })
)

export default renderValuesStyles
