import React, { FC } from 'react'
// Material Components
import { Container, Icon } from '@material-ui/core'
// Styles
import layoutStartSectionStyles from './start-section.styles'
import layoutGeneralStyles from '../../form-container.styles'
// Custom Hooks
import { useAddSection } from 'modules/entity-layouts/hooks'
import { useTranslation } from 'react-i18next'

type TProps = {}

const LayoutStartSection: FC<TProps> = (props) => {
  const { t } = useTranslation()
  const classes = layoutStartSectionStyles()
  const classesGeneral = layoutGeneralStyles()

  const handleAddSection = useAddSection()

  return (
    <Container maxWidth={false} className={classes.root} onClick={() => handleAddSection(0)}>
      <div className={classesGeneral.hint}>
        <Icon className="icon-plus-square" />
        {t('layout.add-first')}
      </div>
    </Container>
  )
}

export default LayoutStartSection
