import React, { FC, useMemo } from 'react'
import { Typography } from '@material-ui/core'
import { Attribute } from 'core/types/attribute'
import DefaultValueTitle from './default-value-title'
import { useTranslation } from 'react-i18next'

type TProps = {
  attrData: Attribute
}

const DefaultValuePreview: FC<TProps> = (props) => {
  const { attrData } = props
  const { t } = useTranslation()

  if (attrData.defaultValues === []) return null
  const { value } = attrData.defaultValues

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { newValue, defaultValuesLength } = useMemo(() => {
    let newValue: any[] = []
    Array.isArray(value) ? (newValue = value) : newValue.push(value)
    const defaultValuesLength: number = newValue?.length

    return { newValue, defaultValuesLength }
  }, [value])

  if (value) {
    return (
      <>
        <Typography variant={'caption'}>
          <strong>{t('labels.def-value')}: </strong>
          {newValue.map((value: string, index: number) => {
            return (
              <span key={value}>
                <DefaultValueTitle defaultValueIri={value} />
                {defaultValuesLength - 1 !== index && ', '}
              </span>
            )
          })}
        </Typography>
      </>
    )
  }

  return null
}

export default DefaultValuePreview
