// Core
import { useCallback, useState } from 'react'
import { FormikProps, useFormik } from 'formik'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
// Hooks
import { useTranslationContext } from './use-translation-context'
import { useRevalidate } from 'core/hooks/use-revalidate'
// Services
import { httpService } from 'core/data'
// Utils
import { transformResponseValidationData } from 'core/utils'
// Types
import { Translation } from '../types'

type MutationData = {
  type: 'delete' | 'update'
  data: Translation
}

type FormikValues = {
  slug: string
  system: boolean
  locale: string
  value: string
}

type TransformedValues = (values: FormikValues) => {
  valuesData: Translation
  type: 'update' | 'create'
}

type UseTranslationTypes = (data?: Translation) => {
  form: FormikProps<FormikValues>
  mutation: UseMutationResult<any, any, any>
  deleteHandler: () => void
  editHandler: () => void
  isEdit: boolean
}

export const useTranslationController: UseTranslationTypes = (data) => {
  const { createMutation, invalidate, code } = useTranslationContext()
  const [isEdit, setIsEdit] = useState(false)
  const localizationIri = `/api/localizations/${code}`
  const queryClient = useQueryClient()
  const { revalidateAll } = useRevalidate()

  const mutation = useMutation<unknown, unknown, MutationData>(
    async (mutationData) => {
      const { type, data } = mutationData
      if (type === 'delete') {
        httpService.delete(`/translations/${data.id}`)
      } else {
        await httpService.put(`/translations/${data.id}`, data)
      }
      await revalidateAll()
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('translation')
      },
      onSettled: async () => {
        await invalidate()
      },
    }
  )

  const activeLocale = data?.localizations.find((item) => item.locale === localizationIri)

  const transformValues: TransformedValues = useCallback(
    (values) => {
      if (data) {
        const valuesData: Translation = { ...data }
        if (activeLocale) {
          const newLocalizations: Translation['localizations'] =
            data?.localizations.map((locale) => {
              if (locale.locale === localizationIri) {
                return {
                  ...locale,
                  value: values.value,
                }
              }
              return locale
            }) || []

          valuesData.localizations = [...newLocalizations]
        } else {
          valuesData.localizations = [
            ...data?.localizations,
            {
              locale: values.locale,
              value: values.value,
            },
          ]
        }
        return { valuesData, type: 'update' }
      }
      const valuesData: Translation = {
        slug: values.slug,
        localizations: [
          {
            locale: values.locale,
            value: values.value,
          },
        ],
        system: false,
      }
      return { valuesData, type: 'create' }
    },
    [activeLocale, data, localizationIri]
  )

  const form = useFormik<FormikValues>({
    initialValues: {
      slug: data?.slug || '',
      locale: localizationIri,
      value: activeLocale?.value || '',
      system: false,
    },
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      values = { ...values }

      try {
        const getTransformed = () => transformValues(values)
        if (getTransformed().type === 'update') {
          await mutation.mutateAsync({
            type: 'update',
            data: getTransformed().valuesData,
          })
          setIsEdit(false)
        } else {
          await createMutation.mutateAsync(getTransformed().valuesData)
          helpers.resetForm()
        }
        await revalidateAll()
      } catch (error: any) {
        const errors = transformResponseValidationData(error.response?.data?.violations)
        if (errors) helpers.setErrors(errors)
      }
    },
  })

  const deleteHandler = useCallback(async () => {
    if (isEdit) {
      setIsEdit(false)
    } else {
      await mutation.mutateAsync({
        type: 'delete',
        data: data!,
      })
    }
  }, [data, isEdit, mutation])

  const editHandler = useCallback(async () => {
    if (!isEdit) {
      setIsEdit(true)
    } else {
      await form.submitForm()
    }
  }, [form, isEdit])

  return {
    form,
    mutation,
    deleteHandler,
    editHandler,
    isEdit,
  }
}
