// Core
import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
// Components
import { SwitcherField, TextField } from 'core/form/components'
// Translation
import { useTranslation } from 'react-i18next'
// Types
import { RuleTypesList, RuleValues } from '../types'

/**
 * Render attribute validation controls
 * by rule values
 * @param rule
 * @param parent
 * @param isPreview
 */

type Props = {
  rule: RuleValues
  isPreview?: boolean
  parent?: RuleValues
}

export const RulesRenderer: FC<Props> = (props) => {
  const { rule, isPreview, parent } = props

  const { t } = useTranslation()

  const name = parent
    ? `validationRules.${parent.name}.${rule.name}`
    : `validationRules.${rule.name}`

  switch (rule.type) {
    case RuleTypesList.boolean:
      return <SwitcherField label={t(name)} key={name} name={name} disabled={isPreview} />
    case RuleTypesList.field:
      return (
        <TextField
          key={name}
          disabled={isPreview}
          name={name}
          label={t(name)}
          type={rule.inputType}
        />
      )
    case RuleTypesList.fieldArray:
      return (
        <div key={`${name}-group`}>
          <Typography style={{ marginBottom: '8px' }} component={'p'}>
            {t(`validationLabel.${rule.name}`)}
          </Typography>
          <div className="validation-group">
            {rule.fields &&
              rule.fields.map((insetRule, ind) => {
                return (
                  <RulesRenderer key={ind} isPreview={isPreview} rule={insetRule} parent={rule} />
                )
              })}
          </div>
        </div>
      )
  }

  return null
}
