import React, { useCallback, useRef } from 'react'
import { MediaFolder, MediaListItem } from '../types'
import { useMutation, useQueryClient } from 'react-query'
import { httpService } from 'core/data'
import { useConfirmation } from 'core/confirmation'
import { useNotify } from 'core/hooks'
import { useFolderSelect } from '../components/folder-select'
import { isAxiosError } from 'axios'
import cloneDeep from 'lodash.clonedeep'

export type HelperStorage = Record<number, MediaListItem>

export type MutationPayload = {
  folderId?: number
  action: 'delete' | 'copy' | 'move'
}

const findDestinationFolder = (folderId: number, folders: MediaFolder[]) => {
  const findFolder = folders.find((folder) => folder.id === folderId)
  if (!findFolder) {
    throw new Error(`Folder should be defined`)
  }
  return findFolder
}

type GetSuccessMessagesParams = {
  count: number
  action: MutationPayload['action']
  destinationFolderId?: number
  folders: MediaFolder[]
}

const getSuccessMessage = ({
  count,
  action,
  destinationFolderId,
  folders,
}: GetSuccessMessagesParams) => {
  const pluralSymbol = count > 1 ? 's' : ''

  const actionNames: Record<MutationPayload['action'], string> = {
    delete: 'Deleted',
    copy: 'Copied',
    move: 'Moved',
  }

  if ((action === 'copy' || action === 'move') && destinationFolderId) {
    const destinationFolderName = findDestinationFolder(destinationFolderId, folders).name

    return (
      <>
        {actionNames[action]} {count} item{pluralSymbol} successfully to
        <b> {destinationFolderName}</b>
      </>
    )
  }

  return `${actionNames[action]} ${count} item${pluralSymbol} successfully`
}

type Params = {
  selected: number[]
  helperStorage: HelperStorage
  selectedFolder: number
  onSuccess?: (action: MutationPayload['action']) => void
  queryKey: string
  folders: MediaFolder[]
}

export const useMediaBulkActions = ({
  selected,
  helperStorage,
  selectedFolder,
  onSuccess,
  queryKey,
  folders,
}: Params) => {
  const { setConfirmation } = useConfirmation()
  const notify = useNotify()
  const queryClient = useQueryClient()

  const { FolderSelectModal, openFolderSelect, closeFolderSelect, setIsLoading } = useFolderSelect()

  const mutation = useMutation(
    (payload: MutationPayload) => {
      const { action, folderId } = payload
      const items = generateBulkIriCollection(selected, helperStorage)
      const destination = `/api/media_folders/${folderId}`

      if (action === 'copy') {
        return httpService.post('/media_advanceds/copying', { items, destination })
      }
      if (action === 'move') {
        return httpService.post('/media_advanceds/moving', { items, destination })
      }

      return httpService.post('/media_advanceds/deletion', { items })
    },
    {
      onMutate: () => {
        setIsLoading(true)
      },
      onSuccess: async (_, payload) => {
        await onSuccess?.(payload.action)
        notify(
          getSuccessMessage({
            count: selected.length,
            action: payload.action,
            destinationFolderId: payload.folderId,
            folders,
          }),
          {
            type: 'success',
          }
        )
        closeFolderSelect()

        // Experimental optimistic
        if (payload.action === 'delete' || payload.action === 'move') {
          queryClient.setQueriesData(queryKey, (data: any) => {
            if (!data) return undefined
            const clonedData = cloneDeep(data)
            clonedData['hydra:member'] = clonedData['hydra:member'].map((item: any) => {
              if (!selected.includes(item.id)) return item
              return { ...item, pending: true }
            })
            return clonedData
          })
        }
      },
      onError: (e) => {
        if (isAxiosError(e) && e.response?.data?.['hydra:description']) {
          notify(e.response?.data?.['hydra:description'], { type: 'error' })
        } else {
          notify('Something went wrong', { type: 'error' })
        }
      },
      onSettled: () => {
        setIsLoading(false)
      },
    }
  )

  const bulkDelete = useCallback(() => {
    setConfirmation({
      open: true,
      title: 'Delete confirmation',
      content: 'Are you sure want to delete this items?',
      onSuccess: () => mutation.mutate({ action: 'delete' }),
    })
  }, [mutation, setConfirmation])

  const bulkCopy = useCallback(() => {
    openFolderSelect({
      title: `Copy ${selected.length} items to`,
      mainActionTitle: 'Copy here',
      selectedFolder,
      onMainAction: (folderId) => {
        setConfirmation({
          open: true,
          title: 'Copy confirmation',
          content: 'Are you sure want to copy this items?',
          onSuccess: () => mutation.mutate({ action: 'copy', folderId }),
        })
      },
    })
  }, [mutation, openFolderSelect, selected.length, selectedFolder, setConfirmation])

  const bulkMove = useCallback(() => {
    openFolderSelect({
      title: `Move ${selected.length} items to`,
      mainActionTitle: 'Move here',
      selectedFolder,
      disabledItems: selected,
      disableCurrentSelection: true,
      onMainAction: (folderId) => {
        setConfirmation({
          open: true,
          title: 'Move confirmation',
          content: 'Are you sure want to move this items?',
          onSuccess: () => mutation.mutate({ action: 'move', folderId }),
        })
      },
    })
  }, [mutation, openFolderSelect, selected, selectedFolder, setConfirmation])

  const dndMoveHandler = useCallback(
    (folderId: number | undefined) => {
      if (!folderId) return
      mutation.mutate({ action: 'move', folderId })
    },
    [mutation]
  )

  return {
    bulkDelete,
    bulkCopy,
    bulkMove,
    dndMoveHandler,
    FolderSelectModal,
  }
}

function generateBulkIriCollection(selected: number[], helperStorage: HelperStorage) {
  return selected.map((id) => {
    const sourceItem = helperStorage[id]

    if (!sourceItem) throw new Error('Error in generation bulk iris')

    const isFolder = sourceItem.type === 'folder'
    const resource = isFolder ? 'media_folders' : 'media'

    return `/api/${resource}/${id}`
  })
}

type SetHelperStorageDataParams = {
  selected: number[]
  rowData?: MediaListItem
  rows?: MediaListItem[]
}

export const useMediaBulkActionsHelperStorage = () => {
  const helperStorage = useRef<HelperStorage>({})

  const setHelperStorageData = useCallback((params: SetHelperStorageDataParams) => {
    const { selected, rowData, rows = [] } = params

    const prevStoredData = Object.keys(helperStorage.current).map(
      (id) => helperStorage.current[+id]
    )

    const combinedSourceData = [...rows, ...prevStoredData]

    if (rowData) {
      combinedSourceData.push(rowData)
    }

    helperStorage.current = selected.reduce<HelperStorage>((acc, id) => {
      const findSourceItem = combinedSourceData.find((item) => item.id === id)

      if (findSourceItem) {
        acc[id] = findSourceItem
      }

      return acc
    }, {})
  }, [])

  return {
    helperStorage: helperStorage.current,
    setHelperStorageData,
  }
}
