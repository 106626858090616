import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const groupModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    wrap: {
      margin: theme.spacing(3, 0),
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      '&>p': {
        whiteSpace: 'nowrap',
        padding: theme.spacing(0, 3),
        '&:first-child': {
          paddingLeft: 0,
        },
      },
    },
  })
)

export default groupModalStyles
