// Core
import React, { FC, useCallback, useMemo, useState } from 'react'
import { Box, Grid, Button, Icon, Tooltip, FormControlLabel, Switch } from '@material-ui/core'
import { DragDropContext, DragDropContextProps, Draggable, Droppable } from 'react-beautiful-dnd'
// Components
import { Container, WidgetTypesModal, Widgets, ButtonInsert } from './components'
// Hooks
import { useTranslation } from 'react-i18next'
import { usePageBuilderContext } from 'modules/new-entity/context'
import { useActionWithConfirmation } from 'modules/new-entity/hooks'
import { useSitesContext } from 'modules/sites'
// Utils
import { dragEndEvent } from 'common/utils/custom-events'
import { needConfirmation } from 'modules/new-entity/utils/static-layout-confirmation'
// Types
import { DndTypes, IEntityWidget, THEME_TWO_COLOR_SCHEME } from '../../types'
// Styles
import useStyles from './page-builder.styles'

type TypesModalState = {
  container: string
  type: 'push' | 'unshift' | number
}

type DroppableState = {
  sourceId: string
  type: string
  destinationId?: string
}

type Props = {
  submittedOnce?: boolean
  disabled?: boolean
}

const PageBuilder: FC<Props> = ({ submittedOnce, disabled }) => {
  const classes = useStyles()
  const {
    widgets: entityWidgets,
    sections,
    dynamicLayout,
    actions: {
      addWidget,
      reorderWidgets,
      moveWidget,
      addSection,
      reorderSection,
      toggleDynamicLayout,
    },
  } = usePageBuilderContext()

  const [typesModalState, setTypesModalState] = useState<TypesModalState | null>(null)
  const [droppable, setDroppable] = useState<DroppableState | null>(null)

  const isDraggingOver = Boolean(droppable)

  const { t } = useTranslation()

  const widgets = useMemo<{ [containerId: string]: IEntityWidget[] }>(() => {
    if (sections.length === 0) return {}

    const containersSchema: { [containerId: string]: IEntityWidget[] } = {}
    sections.forEach((section) => {
      section.containers.forEach((container) => {
        containersSchema[`${container.id}`] = []
      })
    })

    entityWidgets.forEach((item: IEntityWidget, index) => {
      if (containersSchema[`${item.options.container}`]) {
        containersSchema[`${item.options.container}`].push({ ...item, realIndex: index })
      }
    })

    entityWidgets.forEach((item: IEntityWidget) => {
      if (containersSchema[`${item.options.container}`]?.length) {
        containersSchema[`${item.options.container}`].sort((a: IEntityWidget, b: IEntityWidget) => {
          return a.sortOrder > b.sortOrder ? 1 : -1
        })
      }
    })

    return containersSchema
  }, [entityWidgets, sections])

  const addWidgetHandler = useCallback(
    (widgetType: any) => {
      if (!typesModalState) return
      addWidget(typesModalState.container, widgetType, typesModalState.type)
      setTypesModalState(null)
    },
    [addWidget, typesModalState]
  )

  const handleInsertWidget = useCallback((containerId: string, widgetIndex: number) => {
    setTypesModalState({
      container: containerId,
      type: widgetIndex,
    })
  }, [])

  const dragStart: DragDropContextProps['onDragStart'] = useCallback((start) => {
    const { destination, source, type } = start
    setDroppable({
      sourceId: source?.droppableId,
      type,
      destinationId: destination?.droppableId,
    })
  }, [])

  const dragEnd: DragDropContextProps['onDragEnd'] = useCallback(
    (result) => {
      setDroppable(null)

      const { destination, source, type } = result
      if (!destination) return

      if (type === DndTypes.widget) {
        const dropId: any = source.droppableId
        const destId: any = destination?.droppableId

        if (dropId === destId) {
          reorderWidgets(dropId, source.index, destination?.index)
        } else {
          moveWidget(widgets[dropId], widgets[destId], source, destination)
        }
      } else {
        const dropIndex = source.index
        const destIndex = destination.index

        if (dropIndex !== destIndex) {
          reorderSection(dropIndex, destIndex)
        }
      }

      document.dispatchEvent(dragEndEvent)
    },
    [reorderWidgets, moveWidget, widgets, reorderSection]
  )

  const dragUpdate: DragDropContextProps['onDragUpdate'] = useCallback((update) => {
    const { destination, source, type } = update

    setDroppable((prev) => {
      if (
        prev?.sourceId !== source?.droppableId ||
        prev?.type !== type ||
        prev?.destinationId !== destination?.droppableId
      ) {
        return {
          sourceId: source?.droppableId,
          type,
          destinationId: destination?.droppableId,
        }
      }
      return prev
    })
  }, [])

  const actionWithConfirmation = useActionWithConfirmation(
    'global.change-layout',
    'notify.change-layout'
  )

  const toggleDynamicLayoutHandler = useCallback(() => {
    if (dynamicLayout) {
      needConfirmation(sections, entityWidgets)
        ? actionWithConfirmation(toggleDynamicLayout)
        : toggleDynamicLayout()
    } else {
      toggleDynamicLayout()
    }
  }, [actionWithConfirmation, dynamicLayout, entityWidgets, sections, toggleDynamicLayout])

  const {
    siteSettings: {
      colorScheme: { value: colorSchemeValue },
    },
  } = useSitesContext()

  return (
    <Box className={classes.root}>
      {THEME_TWO_COLOR_SCHEME.includes(colorSchemeValue) && (
        <div className={classes.switcherWrap}>
          <FormControlLabel
            disabled={disabled}
            control={
              <Switch
                checked={dynamicLayout}
                onChange={toggleDynamicLayoutHandler}
                color="primary"
              />
            }
            labelPlacement="start"
            label="Dynamic layout"
          />
        </div>
      )}
      <DragDropContext onDragEnd={dragEnd} onDragStart={dragStart} onDragUpdate={dragUpdate}>
        <Droppable droppableId="section" type={DndTypes.section}>
          {(providedDrop) => (
            <Grid container ref={providedDrop.innerRef}>
              {sections.map((section, sectionIndex) => (
                <Draggable
                  draggableId={section.id}
                  index={sectionIndex}
                  key={section.id}
                  isDragDisabled={disabled}
                >
                  {(providedDrag, snapshotDrag) => (
                    <Grid
                      container
                      ref={providedDrag.innerRef}
                      className={classes.section}
                      {...providedDrag.draggableProps}
                    >
                      {dynamicLayout && (
                        <ButtonInsert
                          type="container"
                          title={t('page-builder.container.add')}
                          onClick={() => addSection(sectionIndex)}
                          disabled={disabled || isDraggingOver}
                        />
                      )}
                      <Grid key={section.id} item xs={12}>
                        {section.containers.map((container, containerIndex) => {
                          let isDropping = false
                          const isDraggingWidget = droppable?.type === 'widget'

                          if (isDraggingWidget) {
                            isDropping = droppable?.destinationId
                              ? droppable?.destinationId === container.id
                              : widgets[container.id].some(
                                  (i) => i.options.container === droppable?.sourceId
                                )
                          }

                          return (
                            <Container
                              key={`${container.id}-${containerIndex}`}
                              setTypesModalState={setTypesModalState}
                              container={container}
                              widgets={widgets[container.id]}
                              hasWidget={Boolean(widgets[container.id]?.length)}
                              sectionId={section.id}
                              sectionBg={section.options.background}
                              disabled={disabled}
                              containerDragHandleProps={providedDrag.dragHandleProps}
                              disableMoveDown={sectionIndex >= sections.length - 1}
                              disableMoveUp={sectionIndex === 0}
                              isDraggingOver={isDraggingOver}
                              isDropping={isDropping}
                              isDragging={snapshotDrag.isDragging}
                              index={sectionIndex}
                            >
                              <Widgets
                                widgets={widgets[container.id]}
                                container={container}
                                isDraggingOver={isDraggingOver}
                                handleInsertWidget={handleInsertWidget}
                                submittedOnce={submittedOnce}
                                disabled={disabled}
                              />
                            </Container>
                          )
                        })}
                      </Grid>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {providedDrop.placeholder}
              {dynamicLayout && (
                <Tooltip placement="top" title={`${t('page-builder.container.add')}`}>
                  <Button
                    color="primary"
                    className={classes.addBtn}
                    onClick={() => addSection()}
                    disabled={disabled || isDraggingOver}
                  >
                    <Icon className="icon-plus-circle" />
                  </Button>
                </Tooltip>
              )}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <WidgetTypesModal
        opened={Boolean(typesModalState)}
        onClose={() => setTypesModalState(null)}
        onAddWidget={addWidgetHandler}
      />
    </Box>
  )
}

export default PageBuilder
