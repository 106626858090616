import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory, useLocation, Prompt } from 'react-router-dom'
import { Location } from 'history'
import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core'
import isEqual from 'lodash.isequal'
import { useTranslation } from 'react-i18next'

type UnsavedChanges = () => {
  prompt: JSX.Element
  methods: { setRef: (node: any) => void; savedChanges: () => void }
}

export const useUnsavedChangesWarning: UnsavedChanges = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const formRef = useRef()

  const closeModal = () => {
    setModalVisible(false)
  }

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    /** no trigger when query/hash change */
    if (location.pathname === nextLocation.pathname) return true

    // @ts-ignore
    const { initialValues, values } = formRef.current
    const noDirty = isEqual(initialValues, values)

    if (!confirmedNavigation && !noDirty) {
      setModalVisible(true)
      setLastLocation(nextLocation)

      return false
    }

    return true
  }

  const handleConfirmNavigationClick = () => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }

  const savedChanges = useCallback(() => {
    setConfirmedNavigation(true)
  }, [])

  const setRef = useCallback((node) => {
    formRef.current = node
  }, [])

  useEffect(
    () => {
      if (confirmedNavigation && lastLocation) {
        history.push(lastLocation.pathname)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, lastLocation, history]
  )

  // on refresh page
  const handleBlockedReload = useCallback(
    (e: any) => {
      // @ts-ignore
      const { initialValues, values } = formRef.current

      const noDirty = isEqual(initialValues, values)

      if (!confirmedNavigation && !noDirty) {
        e.preventDefault()
        e.returnValue = t('modals.discard')
      }
    },
    [confirmedNavigation, t]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBlockedReload)
    return () => {
      window.removeEventListener('beforeunload', handleBlockedReload)
    }
  }, [handleBlockedReload])
  // end

  const routerPrompt = (
    <>
      <Prompt message={handleBlockedNavigation} />
      <Dialog
        open={modalVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('modals.discard')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleConfirmNavigationClick()} color="secondary">
            {t('modals.discard-btn')}
          </Button>
          <Button onClick={() => closeModal()} color="primary" autoFocus>
            {t('modals.keep-btn')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )

  return {
    prompt: routerPrompt,
    methods: {
      setRef,
      savedChanges,
    },
  }
}
