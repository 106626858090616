import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const navigationFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    spiner: {
      display: 'flex',
      'justify-content': 'center',
      width: '100%',
    },
  })
)

export default navigationFormStyles
