// Core
import React, { FC, ReactElement } from 'react'
import clsx from 'clsx'
import { Box, CircularProgress, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
// Hooks
import useBlockScroll from 'core/hooks/use-block-scroll'
// Styles
import useStyles from './sidebar.styles'

type Props = {
  onClose: () => void
  isLoading?: boolean
  header?: ReactElement
  actions?: ReactElement
  zIndex?: number
}

const Sidebar: FC<Props> = (props) => {
  const { onClose, isLoading, children, header, actions, zIndex } = props
  const classes = useStyles()
  const { blockScroll, allowScroll } = useBlockScroll()

  const closeHandler = () => {
    onClose()
    allowScroll()
  }

  return (
    <Box
      className={clsx(classes.root, 'fixed-sidebar')}
      onMouseEnter={blockScroll}
      onMouseLeave={allowScroll}
      onMouseOver={blockScroll}
      style={{ zIndex }}
    >
      <Box className={classes.inner}>
        <Box className={classes.bar} pl={2} pt={1}>
          {header}
          <Box flexShrink={0}>
            {actions}
            <IconButton onClick={closeHandler}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        {isLoading && (
          <Box className={classes.loader}>
            <CircularProgress />
          </Box>
        )}
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Box>
  )
}

export default Sidebar
