import React, { FC } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { v4 as uniqueKey } from 'uuid'
// Styles
import useStyles from './radio-button.styles'
import useFieldController from 'core/form/hooks/use-field-controller'

type TProps = {
  options: { value: string | number; label: string | number }[]
  name: string
  label?: string
  disabled?: boolean
  required?: boolean
}

const RadioButton: FC<TProps> = (props) => {
  const classes = useStyles()
  const { options, name, label = '', disabled, required } = props
  const [{ value }, meta, { setValue }] = useFieldController(name)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number((event.target as HTMLInputElement).value))
  }

  return (
    <Box className={classes.root}>
      <FormControl
        disabled={disabled}
        component="fieldset"
        fullWidth
        error={meta.hasError}
        required={required}
      >
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          name={name}
          aria-label={label}
          value={String(value) || ''}
          onChange={handleChange}
          style={{ gap: 8, marginTop: 8 }}
        >
          {options.length &&
            options.map((item: any) => (
              <FormControlLabel
                key={uniqueKey()}
                value={String(item.value)}
                control={<Radio color="primary" style={{ paddingTop: 0, paddingBottom: 0 }} />}
                label={item.label}
              />
            ))}
        </RadioGroup>
        {meta.hasError && (
          <FormHelperText>
            {<span className={classes.helperText}>{meta.errorMsg}</span>}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default RadioButton
