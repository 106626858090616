import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { resetActiveElement } from '../store'

type UseDisactiveElements = () => any

export const useDisactiveElements: UseDisactiveElements = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(resetActiveElement())
  }, [dispatch])
}
