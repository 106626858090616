import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  list: {
    background: theme.palette.background.default,
  },
  actionsRoot: {
    padding: '4px 14px',
    display: 'flex',
    marginBottom: 10,
  },
  bulk: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    '& button': {
      marginLeft: 10,
    },
  },
  searchRoot: {
    display: 'flex',
    padding: '2px 4px',
    width: 250,
    marginLeft: 12,
  },
  searchInput: {
    flex: 1,
    marginLeft: 15,
  },
  rolesSelect: {
    minWidth: 150,
  },
}))
