import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeSizes } from 'core/themes/default/default'

const topOffset = defaultThemeSizes.appBarHeight + defaultThemeSizes.pageHeaderHeight

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: topOffset,
      right: 0,
      bottom: 0,
      width: 360,
      zIndex: 'auto',
      display: 'flex',
      height: `calc(100% - ${topOffset}px)`,
      background: theme.palette.background.paper,
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    inner: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    bar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    content: {
      flex: 1,
      overflow: 'auto',
    },
    loader: {
      position: 'absolute',
      zIndex: 2000,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export default styles
