export enum ValidationRulesList {
  'required',
  'unique',
  'global_unique',
  'length',
  'regex',
  'range',
  'repeats',
}

export enum RuleTypesList {
  'boolean',
  'field',
  'fieldArray',
}

export type RuleValues = {
  type: RuleTypesList
  name: string
  inputType?: string
  fields?: RuleValues[]
}

export type ValidationRuleMap = {
  [ruleName in keyof typeof ValidationRulesList]: RuleValues
}
