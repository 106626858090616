import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { sidebar } from '../store'

type UseToggleSidebar = (checker?: boolean) => any

export const useToggleSidebar: UseToggleSidebar = (checker) => {
  const dispatch = useDispatch()

  return useCallback(
    (event: any) => {
      if (checker === false) {
        dispatch(sidebar(false))
      } else {
        dispatch(sidebar(event.target.checked))
      }
    },
    [checker, dispatch]
  )
}
