import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const { contentBg } = defaultThemeColors

const layoutGeneralStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: contentBg,
      position: 'relative',
      borderRadius: '4px',
      border: '1px solid transparent',
      cursor: 'pointer',
    },
    activeContainer: {
      boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.31), 0px 10px 20px rgba(0, 0, 0, 0.25)',
      background: '#FFFFFF',
      border: '1px solid #5850EC',
    },

    hint: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '& > span': {
        margin: theme.spacing(0, 0, 1),
      },
    },

    layoutBuilderWrap: {
      padding: theme.spacing(3, 0),
    },

    // icons
    generalIco: {
      position: 'absolute',
      '& svg': {
        color: '#3F51B5',
      },
    },
    topIco: {
      bottom: 'calc(100% + 5px)',
    },
    bottomIco: {
      top: 'calc(100% + 5px)',
    },
    removeIco: {
      top: '-10px',
      right: '-10px',
    },
  })
)

export default layoutGeneralStyles
