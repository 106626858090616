import {
  ActionTypes,
  AttributesModalOpenAction,
  CreateAttributeAction,
  ShowNestedFormAction,
} from './types'
import { CreateAttribute } from 'core/types/attribute'

export const attributesModalOpen = (
  isOpen: boolean,
  type?: 'edit' | 'create' | 'preview'
): AttributesModalOpenAction => ({
  type: ActionTypes.ATTRIBUTES_MODAL_OPEN,
  payload: {
    isOpen,
    type,
  },
})

export const createAttribute = (
  data: CreateAttribute,
  type: 'default' | 'repeater' | '',
  formProps?: any,
  formikBag?: any,
  successAction?: any
): CreateAttributeAction => ({
  type: ActionTypes.CREATE_ATTRIBUTE,
  payload: {
    data,
    type,
    formProps,
    formikBag,
    successAction,
  },
})

export const showNestedForm = (isOpen: boolean): ShowNestedFormAction => ({
  type: ActionTypes.SHOW_NESTED_FORM,
  payload: {
    isOpen,
  },
})

export type AttributesModalActions =
  | AttributesModalOpenAction
  | CreateAttributeAction
  | ShowNestedFormAction
