// Core
import React, { FC, useMemo } from 'react'
import { LinearProgress } from '@material-ui/core'
import { useQuery } from 'react-query'
// Utils
import { httpService } from 'core/data'
import { isIri, parseIri } from 'core/utils'

type Props = {
  defaultValueIri: string
}

const DefaultValueTitle: FC<Props> = (props) => {
  const { defaultValueIri } = props

  const isIriValue = useMemo(() => isIri(defaultValueIri), [defaultValueIri])
  const [referenceType, referenceId] = useMemo(
    () => (isIriValue ? parseIri(defaultValueIri) : []),
    [isIriValue, defaultValueIri]
  )

  const { data: referenceName, isLoading } = useQuery(
    [referenceType, referenceId],
    async () => {
      const response = await httpService.get<any>(`/${referenceType}/${referenceId}`)
      return response.data?.name
    },
    {
      enabled: isIriValue,
    }
  )

  if (isLoading) return <LinearProgress color="primary" />
  return isIriValue ? <>{referenceName}</> : <>{defaultValueIri}</>
}

export default DefaultValueTitle
