import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const createFieldsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 0, 0),
    },
  })
)

export default createFieldsStyles
