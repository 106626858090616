import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const paperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    disabled: {},
    label: {
      fontWeight: 500,
      color: theme.palette.text.primary,

      '$disabled &': {
        color: theme.palette.text.disabled,
      },
    },
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1.25),

      '$disabled &': {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
    paper: {
      position: 'relative',
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      border: '1.5px solid rgba(176, 190, 197, 0.4)',
      borderRadius: '50%',
    },
    checked: {
      position: 'absolute',
      top: '-4px',
      right: '-4px',
      bottom: '-4px',
      left: '-4px',
      border: `1.5px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
    },
  })
)

export default paperStyles
