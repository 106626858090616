import { v4 as uuidv4 } from 'uuid'
// types
import { Backgrounds, Section, SectionBackground } from 'core/types/layouts'

type Template = (order: number, containerId?: string, background?: SectionBackground) => Section

export const createSectionTemplate: Template = (order, containerId, background) => ({
  id: uuidv4(),
  containers: [
    {
      id: containerId || uuidv4(),
      sidebar: false,
      options: {
        width: 100,
        columns: 1,
      },
      name: `Container ${order}`,
      slug: `container-${order}`,
    },
  ],
  options: {
    hasSidebar: false,
    sidebarWidth: 25,
    sidebarPosition: 'right',
    background: background || Backgrounds.LIGHT,
  },
})
