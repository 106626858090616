import React, { FC } from 'react'
import { Create, useInitialLocalization } from 'core/data'
import { ResourceProps } from 'core/data'
import CrudForm from '../crud-form'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  width: Yup.number().min(0, 'Value cannot be negative'),
  height: Yup.number().min(0, 'Value cannot be negative'),
  quality: Yup.number()
    .min(0, 'Value must be from 0 to 100')
    .max(100, 'Value must be from 0 to 100'),
})

const CreateMediaSize: FC<ResourceProps> = (props) => {
  const addInitialLocalization = useInitialLocalization(['name'])

  const initialProps = {
    name: '',
    slug: '',
    width: 0,
    height: 0,
    quality: 0,
    crop: false,
  }

  addInitialLocalization(initialProps)

  return (
    <Create
      validationSchema={validationSchema}
      initialData={initialProps}
      crudForm={CrudForm}
      {...props}
    />
  )
}

export default CreateMediaSize
