// Core
import React, { FC } from 'react'
import * as Yup from 'yup'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useQuery } from 'react-query'
// Components
import { TextField } from 'core/form'
import RelationsField from 'core/form/components/relations-field'
// Hooks
import useGetUsedSegments from '../hooks/use-get-used-segments'
// Services
import { httpService } from 'core/data'
// Types
import { ResourceVersion, ResourceWithVersions, VersionValues, VersionCreateFunc } from '../types'

type Props = {
  resource: string
  originalId: number
  onVersionMutation: VersionCreateFunc
  versions: ResourceVersion[]
  editId?: number
} & DialogProps

const VersionDialog: FC<Props> = (props) => {
  const { onVersionMutation, resource, originalId, versions, editId, ...dialogProps } = props

  const isEdit = Boolean(editId)

  const initialDataQ = useQuery(
    ['resource-data', editId],
    () => {
      return httpService.get<ResourceWithVersions>(`/${resource}/${editId}`).then((res) => res.data)
    },
    { enabled: isEdit, cacheTime: 0 }
  )

  const validationRules = Yup.object().shape({
    name: Yup.string()
      .required()
      .max(255, 'This value is too long. It should have 255 characters or less.'),
    segment: Yup.string().required(),
  })

  const { data: disabledSegments } = useGetUsedSegments({
    resource,
    originalId,
    currentId: editId,
    versions: versions || [],
  })

  const title = isEdit ? 'Edit' : 'Create'

  const initialValues: VersionValues = isEdit
    ? {
        id: editId,
        name: initialDataQ.data?.name || '',
        segment: initialDataQ.data?.segments[0] || '',
      }
    : {
        name: '',
        segment: '',
      }

  return (
    <Dialog {...dialogProps} maxWidth="sm" fullWidth>
      <DialogTitle>{title} personalized version</DialogTitle>
      {!initialDataQ.isLoading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationRules}
          onSubmit={onVersionMutation}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <DialogContent>
                <Box mb={3}>
                  <TextField name="name" label="Name" />
                </Box>
                <RelationsField
                  source="segments"
                  name="segment"
                  valueField="@id"
                  label="Segment"
                  disabledOptions={disabledSegments || []}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                >
                  {title}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      ) : (
        <Box display="flex" justifyContent="center" my={5}>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  )
}

export default VersionDialog
