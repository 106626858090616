import { takeEvery, call, put } from 'redux-saga/effects'
import { ActionTypes, CreateAttributeAction } from '../types'
import { httpService } from 'core/data'
import get from 'lodash.get'
import { toast } from 'react-toastify'
import { attributesModalOpen, showNestedForm } from '../actions'
import cloneDeep from 'lodash.clonedeep'

const getFilteredData = (data: any[]) => {
  return data.map((item: any, index: number) => {
    return {
      ...item,
      sortOrder: index,
    }
  })
}

function* createAttributeWorker(action: CreateAttributeAction) {
  try {
    const { data, type, formProps, formikBag, successAction } = action.payload
    const dataCopy = cloneDeep(data)
    let response

    switch (type) {
      case 'default': {
        if (dataCopy.setAttributes && dataCopy.setAttributes.length) {
          dataCopy.setAttributes = getFilteredData(dataCopy.setAttributes)
          response = yield call(httpService.post, '/attributes', dataCopy)
        } else {
          response = yield call(httpService.post, '/attributes', dataCopy)
        }
        yield call(successAction, response.data['@id'])
        yield put(attributesModalOpen(false))
        break
      }

      case 'repeater': {
        if (dataCopy.setAttributes && dataCopy.setAttributes.length) {
          dataCopy.setAttributes = getFilteredData(dataCopy.setAttributes)
          response = yield call(httpService.post, '/attributes', dataCopy)
          const attribute = {
            attribute: response.data['@id'],
          }

          // @ts-ignore
          formProps.setFieldValue('setAttributes', [...formProps.values.setAttributes, attribute])
          formikBag.resetForm()
          yield put(showNestedForm(false))
        } else {
          response = yield call(httpService.post, '/attributes', dataCopy)
          const attribute = {
            attribute: response.data['@id'],
          }

          // @ts-ignore
          formProps.setFieldValue('setAttributes', [...formProps.values.setAttributes, attribute])
          formikBag.resetForm()
          yield put(showNestedForm(false))
        }

        break
      }

      default: {
        // eslint-disable-next-line no-console
        console.log('******createAttributeWorker****** Default case')
        break
      }
    }
  } catch (e) {
    const message = get(e, ['response', 'data', 'hydra:description']) || 'Creation error'

    toast(message, {
      type: 'error',
    })

    console.error(e, 'createAttributeWorker') // eslint-disable-line
  }
}

export function* createAttributeWatcher() {
  yield takeEvery(ActionTypes.CREATE_ATTRIBUTE, createAttributeWorker)
}
