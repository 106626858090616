/**
 * @deprecated - use RelationsSelect and RelationsField
 */
// Core
import React, { FC, useCallback, useEffect } from 'react'
import { Popover, CircularProgress, FormHelperText } from '@material-ui/core'
import debounce from 'lodash.debounce'
import clsx from 'clsx'
// Components
import { Pagination, List, Search, Value } from './components'
import { useTranslation } from 'react-i18next'
// Hooks
import { useActions, useSmartSelect } from './hooks'
// Styles
import useStyles from './smart-select.styles'
// Types
import { SmartSelectProps } from './types'

type Props = {
  children?: never
  innerRef?: any
} & SmartSelectProps

const SmartSelect: FC<Props> = (props) => {
  const {
    titleField = 'name',
    valueField = 'id',
    label,
    multiple,
    onBlur,
    error,
    hasClear = true,
    clearAfterSetValue = false,
    innerRef,
    disabled,
    entityTypeIri,
    size,
    disabledOptions,
  } = props

  const { t } = useTranslation()
  const classes = useStyles()
  const { data, isLoading, total, page, selected, anchor, methods } = useSmartSelect(props)
  const { actions, entityModal } = useActions(entityTypeIri, methods.refetch)

  useEffect(() => {
    if (innerRef) innerRef.current = methods
  }, [methods, innerRef])

  const onSearchChange = debounce((val: string) => {
    methods.setSearch(val)
  }, 500)

  const closeHandler = useCallback(() => {
    methods.setAnchor(undefined)
    if (onBlur) onBlur()
  }, [methods, onBlur])

  return (
    <div
      className={clsx(classes.root, { [classes.disabled]: disabled })}
      data-aqa-form-control={props.aqaDataAttr}
    >
      <Value
        value={selected}
        multiple={multiple}
        titleField={titleField}
        valueField={valueField}
        label={label || t('select.value')}
        onClick={methods.setAnchor}
        onDelete={methods.deleteSelected}
        error={Boolean(error)}
        hasClear={hasClear}
        clearValue={methods.clearValue}
        size={size}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
      <Popover open={Boolean(anchor)} anchorEl={anchor} onClose={closeHandler}>
        {isLoading && <CircularProgress size={20} className={classes.loader} />}
        <Search onChange={onSearchChange} openCreate={actions.createHandler} />
        <List
          data={data}
          titleKey={titleField}
          selected={selected}
          multiple={multiple}
          onSelect={methods.setSelected}
          selectValueField={valueField}
          clearAfterSetValue={clearAfterSetValue}
          clearValue={methods.clearValue}
          onEdit={actions.editHandler}
          canDelete={actions.canDelete}
          refetch={methods.refetch}
          disabledOptions={disabledOptions}
          valueField={valueField}
        />
        <Pagination total={total} page={page} onChange={(val) => methods.setPage(val)} />
      </Popover>
      {entityModal}
    </div>
  )
}

export default SmartSelect
