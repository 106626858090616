// Core
import { UseQueryResult, useQuery, useQueryClient } from 'react-query'
// Services
import { httpService } from 'core/data'
// Utils
import { getIdFromIri } from 'core/utils'

type UseGetAttributeData = (attributeIri: string) => UseQueryResult<any>

export const useGetAttributeData: UseGetAttributeData = (attributeIri) => {
  const cache = useQueryClient()

  const cachedItem = cache
    .getQueryData<any>('selected-attributes')
    ?.find((item: any) => item['@id'] === attributeIri)

  return useQuery(
    ['selected-attribute', attributeIri],
    async () => {
      const response: { data: any } = await httpService.get(
        `/attributes/${getIdFromIri(attributeIri)}`
      )
      return response.data
    },
    {
      initialData: () => {
        return cachedItem
      },
      // initialStale: Boolean(!cachedItem),
    }
  )
}
