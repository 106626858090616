import { Editor } from 'tinymce'
// types
import { ButtonApi } from './types'
// icon
import { plusMinusIcon } from './plus-minus-icon'

const snippetClass = 'collapse-snippet'
const snippetAttrs = `
  data-collapse-snippet=""
  data-expanded-text=""
  data-collase-text=""
  data-expand-up="false"
  data-expand-default="false"
  data-alignment="left"
  data-enable-expanded="false"
  data-enable-read-more="false"
  data-xs-only="false"
`

export const expandedSnippet = (editor: Editor) => {
  //To add a icon:
  editor.ui.registry.addIcon('plusMinusIcon', plusMinusIcon)

  const getSelected = (selectedBlocks: Element[]) => {
    const elements: Element[] = []

    selectedBlocks.forEach((el, index, curr) => {
      const parent = el?.parentElement
      const parentName = parent?.localName
      const isList = parentName === 'ol' || parentName === 'ul'
      const prevParent = curr[index - 1]?.parentElement
      if (el.localName !== 'ol' && el.localName !== 'ul') {
        if (isList) {
          if (parent === prevParent) {
            const last = elements[elements.length - 1]
            last.append(el.cloneNode(true))
            elements[elements.length - 1] = last
          } else {
            const newList = document.createElement(parentName!)
            newList.append(el.cloneNode(true))
            elements.push(newList)
          }
        } else {
          elements.push(el)
        }
      }
    })

    return elements
  }

  const insertSnippet = (editor: Editor, content = '') => {
    editor.insertContent(
      `<div class="${snippetClass}" ${snippetAttrs}>
        ${content ? `<p>${content}</p>` : `<p>Insert the content you want to collapse here</p>`}
      </div>`
    )
  }

  const getHtmlStringFromNodes = (childNodes: any): string => {
    if (typeof childNodes === 'string') {
      return childNodes
    }
    // @ts-ignore
    return [].reduce.call(
      childNodes,
      (html, node: any) => {
        return html + (node.outerHTML || node.nodeValue)
      },
      ''
    )
  }

  const isInsideElement = (editor: Editor, selector: string) => {
    const domEl = editor.selection.getNode()
    return editor.dom.getParent(domEl, selector)
  }

  const snippetInit = (editor: Editor, buttonApi: ButtonApi) => {
    editor.focus()
    setTimeout(() => {
      if (isInsideElement(editor, `.${snippetClass}`)) {
        buttonApi.setActive(true)
      } else {
        buttonApi.setActive(false)
      }
    }, 400)

    editor.on('keyup mouseup focusin focus', () => {
      setTimeout(() => {
        if (isInsideElement(editor, `.${snippetClass}`)) {
          buttonApi.setActive(true)
        } else {
          buttonApi.setActive(false)
        }
      }, 0)
    })
  }

  const findChild = (
    snippetChild: ChildNode,
    selectedBlock: Element,
    childOrder: 'firstChild' | 'lastChild'
  ): boolean => {
    if (snippetChild === selectedBlock) return true

    if (snippetChild[childOrder]) {
      return findChild(snippetChild[childOrder]!, selectedBlock, childOrder)
    }
    return false
  }

  const isFirstOrLastElement = (
    currentSnippet: Element,
    selectedBlocks: Element[],
    childOrder: 'firstChild' | 'lastChild'
  ) => {
    // @ts-ignore
    const selectedEl = childOrder === 'firstChild' ? selectedBlocks[0] : selectedBlocks.at(-1)!
    const snippetChild = currentSnippet[childOrder]!
    return findChild(snippetChild, selectedEl, childOrder)
  }

  const exitFromSnippet = (editor: Editor) => {
    const currentSnippet = editor.dom.getParent(editor.selection.getNode(), `.${snippetClass}`)
    if (!currentSnippet) return false

    const selectedBlocks = editor.selection
      .getSelectedBlocks()
      .filter(({ localName }) => localName !== 'ol' && localName !== 'ul')
    const isFirstEl = isFirstOrLastElement(currentSnippet, selectedBlocks, 'firstChild')
    const isLastEl = isFirstOrLastElement(currentSnippet, selectedBlocks, 'lastChild')
    const content = getSelected(selectedBlocks)
    const bm = editor.selection.getBookmark()

    if (isFirstEl && isLastEl) {
      editor.dom.remove(currentSnippet, true)
      editor.focus()
    } else if (isFirstEl) {
      editor.dom.remove(selectedBlocks)
      const p = document.createElement('p')
      p.style.display = 'none'
      editor.dom.replace(p, currentSnippet)
      editor.selection.moveToBookmark(bm)

      editor.execCommand(
        'mceInsertRawHTML',
        false,
        `
          ${getHtmlStringFromNodes(content)}
          ${currentSnippet.outerHTML}
        `
      )
    } else if (isLastEl) {
      editor.dom.remove(selectedBlocks)
      editor.execCommand(
        'mceInsertRawHTML',
        false,
        `
          </div>
          ${getHtmlStringFromNodes(content)}
        `
      )
    } else if (!isFirstEl && !isLastEl) {
      editor.dom.remove(selectedBlocks)
      editor.execCommand(
        'mceInsertRawHTML',
        false,
        `
          </div>
          ${getHtmlStringFromNodes(content)}
          <div class="${snippetClass}" ${snippetAttrs}>
        `
      )
    }
    editor.selection.moveToBookmark(bm)
  }

  editor.ui.registry.addToggleButton('snippetAdd', {
    icon: 'plusMinusIcon',
    onAction: (api) => {
      const selectedContent = editor.selection.getContent()
      if (isInsideElement(editor, `.${snippetClass}`)) api.setActive(true)

      if (!isInsideElement(editor, 'table')) {
        if (api.isActive()) {
          exitFromSnippet(editor)
        } else if (selectedContent.length) {
          const hasSnippet = selectedContent.includes(snippetClass)

          if (!hasSnippet) {
            insertSnippet(editor, selectedContent)
            api.setActive(true)
          }
        } else {
          insertSnippet(editor)
          api.setActive(true)
        }
      }
    },
    onSetup: (buttonApi: ButtonApi) => {
      snippetInit(editor, buttonApi)

      return () => {}
    },
  })
}
