import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { ActionsCellWrapper } from 'ui/data-grid/actions-cell-wrapper'
// Hooks
import { useNotify } from 'core/hooks'
import { useDelete } from 'modules/navigation/hooks'
import { Permissions, useAppContext } from 'core/app'
import { useTranslation } from 'react-i18next'
import { useConfirmation } from 'core/confirmation'
import { useRevalidate } from 'core/hooks/use-revalidate'
// Types
import { TNavigationFromList } from 'modules/navigation/types'

type Props = {
  resource: string
  rowData: TNavigationFromList
}

const ActionsColumn = (props: Props) => {
  const notify = useNotify()
  const {
    resource,
    rowData: { id },
  } = props

  const {
    actions: { userCan },
  } = useAppContext()

  const history = useHistory()
  const { mutate: remove } = useDelete(resource)

  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()

  const { revalidateAll } = useRevalidate()

  const removeWithConfirmation = useCallback(() => {
    setConfirmation({
      open: true,
      title: t('global.delete'),
      content: t('notify.delete'),
      onSuccess: () => {
        remove(id, {
          onSuccess: async () => {
            await revalidateAll()

            notify(`${resource} item was deleted`, {
              type: 'success',
            })
          },
        })
      },
    })
  }, [id, notify, remove, resource, revalidateAll, setConfirmation, t])

  const editClickHandler = useCallback(() => {
    history.push(`${resource}/${id}`)
  }, [history, id, resource])

  return (
    <ActionsCellWrapper>
      {userCan('navigation', Permissions.DELETE) && (
        <IconButton aria-label="delete" onClick={removeWithConfirmation} size="small">
          <Delete />
        </IconButton>
      )}

      {userCan('navigation', Permissions.EDIT) && (
        <IconButton aria-label="edit" onClick={editClickHandler} size="small">
          <Edit />
        </IconButton>
      )}
    </ActionsCellWrapper>
  )
}

export default ActionsColumn
