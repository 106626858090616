import { Editor } from 'tinymce'

type TPortraitSnippet = (editor: Editor) => void

export const portraitSnippet: TPortraitSnippet = (editor) => {
  editor.ui.registry.addButton('portraitSnippet', {
    text: 'portrait snippet',
    icon: 'gallery',
    tooltip: 'portrait snippet',
    onAction: () => {
      insertSnippet(editor)
    },
  })
}

const insertSnippet = (editor: any) => {
  editor.insertContent(
    `<div class="snippet snippet-portrait">
      <div>insert image here</div>
      <div>type name here</div>
    </div>`
  )
}
