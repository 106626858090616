// @ts-nocheck
import flatten from 'flatley'

type Error = Record<string, unknown>

export const getErrorsCount = (errors: Error | Error[] | undefined | string) => {
  if (!errors) return 0
  if (typeof errors === 'string') return 1

  const flattenErrors = flatten(errors)

  return Object.keys(flattenErrors).length
}
