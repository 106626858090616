import { useState, useCallback } from 'react'

type UseListSelected = () => {
  selected: string | null
  setSelected: (id: string) => void
}

export const useListSelected: UseListSelected = () => {
  const [selected, setSelectedState] = useState<string | null>(null)

  const setSelected = useCallback(
    (id: string) => {
      setSelectedState(selected === id ? null : id)
    },
    [setSelectedState, selected]
  )

  return {
    selected,
    setSelected,
  }
}
