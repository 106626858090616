import { createContext } from 'react'
import { Language } from 'core/data'

export const LanguageContext = createContext<Language>({
  currentLang: {
    '@id': '',
    name: '',
    code: '',
  },
  // @ts-ignore
  setCurrentLang: undefined,
})

LanguageContext.displayName = 'LanguageContext'
