import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const optionsPreviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 500,
      margin: theme.spacing(0.5, 0),
    },
    options: {
      marginBottom: theme.spacing(1),
    },
  })
)

export default optionsPreviewStyles
