import React, { FC } from 'react'
// Material Components
import { AppBar as MaterialAppBar, Toolbar } from '@material-ui/core'
// Components
import { Logo, Menu } from './components'
import { SiteSwitcher } from 'modules/sites'
import SearchBar from 'modules/global-search/search-bar'
// Styles
import appBarStyles from './app-bar.styles'

const AppBar: FC = (props) => {
  const classes = appBarStyles()

  return (
    <>
      <MaterialAppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Logo />
          <SiteSwitcher />
          <SearchBar />
          <Menu />
        </Toolbar>
      </MaterialAppBar>
    </>
  )
}

export default AppBar
