import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import Qs from 'qs'
//
import storage from '../storage'

export default class HttpService {
  client: AxiosInstance

  appLogout?: () => void

  onTokenExpired?: () => void

  showVersionConflictModal?: (newVersion: string) => void

  getBasePath() {
    const isDevMode = process.env.REACT_APP_ENV === 'develop'
    return isDevMode ? process.env.REACT_APP_API_URL : '/api'
  }

  constructor() {
    this.client = axios.create({
      baseURL: this.getBasePath(),
      headers: {
        'Accept-Language': '*',
        Accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      },
      paramsSerializer: {
        serialize: (params) => {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        },
      },
    })

    this.get = this.get.bind(this)
    this.post = this.post.bind(this)
    this.delete = this.delete.bind(this)
    this.put = this.put.bind(this)
    this.deleteKeepAlive = this.deleteKeepAlive.bind(this)
  }

  get<T>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.client.get(url, options)
  }

  post<T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.client.post(url, data, options)
  }

  patch<T>(url: string, data?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.client.patch(url, data, options)
  }

  put(url: string, data?: any, options?: AxiosRequestConfig) {
    return this.client.put(url, data, options)
  }

  delete(url: string, options?: AxiosRequestConfig) {
    return this.client.delete(url, options)
  }

  deleteKeepAlive(url: string) {
    const base = this.getBasePath()

    return fetch(`${base}${url}`, {
      method: 'DELETE',
      keepalive: true,
      headers: {
        JWTAuthorization: `Bearer ${storage.getToken()}`,
        'x-site-id': this.client.defaults.headers['X-SITE-ID'] as string,
      },
    })
  }

  addInterceptor(
    type: 'request' | 'response',
    interceptor: any,
    interceptorError: any = null
  ): void {
    this.client.interceptors[type].use(interceptor, interceptorError)
  }
}
