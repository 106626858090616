import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeSizes } from 'core/themes/default/default'

const { modalHeaderHeight } = defaultThemeSizes

const searchStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
      position: 'sticky',
      top: modalHeaderHeight,
      backgroundColor: theme.palette.common.white,
      zIndex: 5,
    },
    button: {
      paddingTop: 7,
      paddingBottom: 7,
      marginLeft: theme.spacing(1),
    },
    filterNote: {
      display: 'inline-block',
      marginLeft: theme.spacing(2),
    },
  })
)

export default searchStyles
