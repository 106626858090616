import React, { FC } from 'react'
// Material Components
import { Box, Slider, TextField, Typography } from '@material-ui/core'
// Options
import { marksColumnsContainer, marksWidthContainer } from './container.options'
// Custom Hooks
import {
  useContainerColumns,
  useContainerName,
  useContainerSlug,
  useContainerWidth,
} from 'modules/entity-layouts/hooks'
// UI
import AccordionCustom from 'ui/accordion'
import { useTranslation } from 'react-i18next'

type TProps = {
  setWidth?: number
  setColumns?: number
  setName?: string
  setSlug?: string
}

const LayoutContainerSettings: FC<TProps> = (props) => {
  const { setWidth, setColumns, setName, setSlug } = props
  const { t } = useTranslation()

  const handleChangeContainerWidth = useContainerWidth()
  const handleChangeContainerColumns = useContainerColumns()
  const handleChangeContainerName = useContainerName()
  const handleChangeContainerSlug = useContainerSlug()

  return (
    <AccordionCustom title={t('global.container')}>
      <Box display="flex" flexDirection="column" width="100%">
        <TextField
          variant="outlined"
          name="Container name"
          label={t('labels.container-name')}
          value={setName}
          onChange={handleChangeContainerName}
        />

        <br />

        <TextField
          variant="outlined"
          name="Container slug"
          label={t('labels.container-slug')}
          value={setSlug}
          onChange={handleChangeContainerSlug}
        />

        <br />

        <Typography gutterBottom>{t('labels.width')} (%)</Typography>
        <Slider
          value={setWidth}
          onChange={handleChangeContainerWidth}
          step={10}
          marks={marksWidthContainer}
          min={10}
          max={100}
          valueLabelDisplay="auto"
        />

        <br />

        <Typography gutterBottom>{t('labels.columns')}</Typography>
        <Slider
          value={setColumns}
          onChange={handleChangeContainerColumns}
          step={1}
          marks={marksColumnsContainer}
          min={1}
          max={12}
          valueLabelDisplay="auto"
        />
      </Box>
    </AccordionCustom>
  )
}

export default LayoutContainerSettings
