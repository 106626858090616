// Core
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// Components
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { DialogTitle } from 'ui'
import AttributesForm from 'modules/new-entity/components/attributes-form'
// Utils
import { getIdFromIri } from 'core/utils'
import { useNotify } from 'core/hooks'
import { httpService } from 'core/data'
import { EntityService } from 'modules/new-entity/utils/entity-service'
// Hooks
import { useEntityController } from 'modules/new-entity/hooks'
// Types
import { EavResourceType, MutationType } from 'modules/new-entity/types'

type TProps = {
  entityTypeIri: string | undefined
  setCreatedValue?: (value: any, shouldValidate?: boolean | undefined) => void
  setIsOpen: (val: boolean) => void
  isOpen: boolean
  entityId?: number
  isEdit?: boolean
  onSuccess?: () => void
}

const QuickCreate: FC<TProps> = (props) => {
  const { entityTypeIri, setCreatedValue, isOpen, setIsOpen, entityId, isEdit, onSuccess } = props
  const { t } = useTranslation()
  const entityTypeId = entityTypeIri && getIdFromIri(entityTypeIri)

  const notify = useNotify()

  const {
    mainAttributes,
    initialValues,
    validationSchema,
    entityType,
    formRef,
    isLoading,
    mutation,
    attributes,
  } = useEntityController(entityId, entityTypeId, EavResourceType.ENTITY, {
    queryKey: 'quick-create',
  })

  const submitHandler = useCallback(async () => {
    try {
      await EntityService.validateForm(formRef.current)
      let values = { ...formRef.current?.values }

      values = EntityService.generateValues(values, attributes)

      const mutationObject = {
        entityType: entityType['@id'],
        ...values,
      }

      const entityRes: any = await mutation.mutateAsync(mutationObject)
      const entData = await httpService.get<any>(`/entities/${getIdFromIri(entityRes.data['@id'])}`)

      setCreatedValue?.(entData.data)
      setIsOpen(false)
      onSuccess && onSuccess()
      notify(t('notify.success'), { type: 'success' })
    } catch (e) {
      if (e?.type !== 'validation') {
        notify('Creation error', { type: 'error' })
      }
    }
  }, [formRef, attributes, entityType, mutation, setCreatedValue, setIsOpen, notify, onSuccess, t])

  const title = isEdit ? `Edit ${entityType?.name}` : `Create ${entityType?.name}`

  return (
    <Dialog
      open={isOpen}
      id={'entity-modal'}
      onClose={() => setIsOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{!isLoading && title}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress size={50} />
        ) : (
          <AttributesForm
            type={MutationType.CREATE}
            mainAttributes={mainAttributes}
            initialValues={initialValues}
            validationSchema={validationSchema}
            resourceType={EavResourceType.ENTITY}
            typeOptions={entityType?.options}
            formRef={formRef}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={mutation.isLoading}
          endIcon={mutation.isLoading && <CircularProgress size={15} />}
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          {isEdit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default QuickCreate
