// Core
import React, { FC, memo } from 'react'
import { IconButton } from '@material-ui/core'
import { ViewList, ViewModule } from '@material-ui/icons'
import clsx from 'clsx'
// Hooks
import { useListContext } from 'core/data'
// Styles
import useStyles from './view-type.styles'

const ViewType: FC = () => {
  const classes = useStyles()
  const { viewType } = useListContext()

  return (
    <div className={classes.root}>
      <IconButton
        className={clsx({ [classes.active]: viewType?.type === 'table' })}
        onClick={() => viewType.setViewType('table')}
      >
        <ViewList />
      </IconButton>
      <IconButton
        className={clsx({ [classes.active]: viewType?.type === 'grid' })}
        onClick={() => viewType.setViewType('grid')}
      >
        <ViewModule />
      </IconButton>
    </div>
  )
}

export default memo(ViewType)
