import React, { FC } from 'react'
import useStyles from './preview-fields.styles'
import { Collapse } from '@material-ui/core'
import { Attribute } from 'core/types/attribute'
import { FieldsData } from './components'
import { useFormikContext } from 'formik'
import { setPreviewValues } from 'common/utils/attributes-options'
import { useGetData } from 'common/hooks/attributes-options/use-get-data'

type TProps = {
  attrData: Attribute
  isOpen: boolean
  isNested?: boolean
  parentData?: boolean
  pathToAttribute?: string
}

const PreviewFields: FC<TProps> = (props) => {
  const classes = useStyles()
  const { attrData, isOpen, isNested, parentData, pathToAttribute = '' } = props
  const {
    values: { options },
  } = useFormikContext<any>()

  const { newData } = useGetData(attrData, parentData, isNested)
  const setOptionsPreview = setPreviewValues(pathToAttribute, options, newData)

  return (
    <Collapse unmountOnExit in={isOpen}>
      <div className={classes.root}>
        <FieldsData
          attrData={attrData}
          values={setOptionsPreview}
          isNested={isNested}
          pathToAttribute={pathToAttribute}
        />
      </div>
    </Collapse>
  )
}

export default PreviewFields
