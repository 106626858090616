// Core
import React from 'react'
import { FormControl, Icon, MenuItem, Select } from '@material-ui/core'
import clsx from 'clsx'
// Types
import { TChangeWidgetWidth } from 'modules/new-entity/types'
// Styles
import useStyles from './widget-width.styles'

type Props = {
  widgetId: number
  widgetWidth: number
  changeWidgetWidth: TChangeWidgetWidth
  disabled: boolean
}

const WidgetWidth: React.FC<Props> = (props) => {
  const { widgetId, widgetWidth, changeWidgetWidth, disabled } = props

  const classes = useStyles()

  return (
    <FormControl
      variant="outlined"
      disabled={disabled}
      className={clsx(classes.widthSelect, {
        [classes.widthSelectDisabled]: disabled,
      })}
    >
      <Icon className={clsx('icon-desktop', classes.desktopIcon)} />
      <Select
        onChange={(event: React.ChangeEvent<any>) => {
          changeWidgetWidth(widgetId, event.target.value)
        }}
        value={widgetWidth}
      >
        <MenuItem value={100}>100%</MenuItem>
        <MenuItem value={50}>50%</MenuItem>
        <MenuItem value={33}>33%</MenuItem>
      </Select>
    </FormControl>
  )
}

export default WidgetWidth
