// Core
import React, { FC, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { ExpandMore, List as ListIcon } from '@material-ui/icons'
import { NavLink } from 'react-router-dom'
import { Pagination } from '@material-ui/lab'
// Hooks
import useEntitiesList from 'modules/eav/hooks/use-entities-list'
// Types
import { SearchResult } from '../types'
import { EntityListItem } from 'modules/eav/types'

type Props = {
  data: SearchResult
  searchTerm: string
  filters: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    accordion: {
      background: theme.palette.grey[100],
    },
    accordionTitle: {
      background: theme.palette.grey[300],
    },
  })
)

const getEntityTitle = (entity: EntityListItem) => {
  const draftVersion = entity.versions.find((version) => version.originalLabel === 'draft')

  if (draftVersion) {
    return draftVersion.name
  }

  return entity.name
}

const ResultItem: FC<Props> = (props) => {
  const { data, searchTerm, filters } = props
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const {
    data: entitiesData,
    isLoading,
    page,
    setPage,
    totalCount,
  } = useEntitiesList({
    entityTypeId: data.typeId,
    search: searchTerm,
    filters,
    queryOptions: { enabled: expanded },
  })

  return (
    <Accordion
      expanded={expanded}
      onChange={(e, exp) => setExpanded(exp)}
      className={classes.accordion}
    >
      <AccordionSummary expandIcon={<ExpandMore />} className={classes.accordionTitle}>
        <ListIcon style={{ marginRight: 10 }} />
        <Typography>
          {data.name} ({data.count})
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
      >
        {isLoading && <CircularProgress style={{ margin: '0 auto' }} />}
        <List>
          {entitiesData.map((item) => (
            <ListItem
              button
              component={NavLink}
              to={`/entities/${data.typeId}/edit/${item.id}`}
              key={item.id}
              style={{ display: 'block' }}
              target="_blank"
            >
              <ListItemText primary={getEntityTitle(item)} />
            </ListItem>
          ))}
        </List>
        <Pagination
          page={page}
          count={Math.round(totalCount / 30)}
          onChange={(e, page) => setPage(page)}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default ResultItem
