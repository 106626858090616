// Core
import { useState } from 'react'

type UseViewType = (viewType: string) => {
  type: string
  setViewType: (type: string) => void
}

export const useViewType: UseViewType = (viewType = 'table') => {
  const [type, setViewType] = useState(viewType)

  return {
    type,
    setViewType,
  }
}
