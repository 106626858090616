// Core
import { useCallback, useMemo, useState } from 'react'
import { useField } from 'formik'
import { useQuery } from 'react-query'
// Services
import { httpService } from 'core/data'
// Types
import { HydraResponse, TUserRole } from 'core/types'
import { Site } from 'modules/sites'
import {
  RolesData,
  SetSiteRoleFunc,
  SiteRoleMap,
  SiteRolesFieldValue,
  SiteRolesFieldValueItem,
  ListProps,
} from './types'

type RolesResponse = HydraResponse<TUserRole>
type SitesResponse = HydraResponse<Site>

const mapRolesData = (roles: TUserRole[]) => {
  const initial: RolesData = { byIri: {}, bySlug: {}, arr: roles, bySite: {} }

  const rolesMap: RolesData = roles.reduce((map, role) => {
    map.byIri[role['@id']] = role
    map.bySlug[role.slug] = role
    const rolesBySite = map.bySite[role.site] || []
    map.bySite[role.site] = [...rolesBySite, role]
    return map
  }, initial as RolesData)

  return rolesMap
}

const mapSiteRole = (value: SiteRolesFieldValue) => {
  return value.reduce<SiteRoleMap>((map, item) => {
    map[item.site] = item
    return map
  }, {})
}

const useController = () => {
  // Form fieldLayer
  const [field, , helpers] = useField<SiteRolesFieldValue>('siteRoles')
  const siteRoleMap = useMemo(() => mapSiteRole(field.value), [field.value])

  // Data layer
  const dataQ = useQuery(
    'user-roles-ui',
    () => {
      const sitesReq = httpService.get<SitesResponse>('/sites', { params: { pagination: false } })
      const rolesReq = httpService.get<RolesResponse>('/roles', {
        params: { pagination: false, groups: ['role:list'] },
      })

      return Promise.all([sitesReq, rolesReq])
    },
    {
      select: (res) => {
        const sitesData = res[0].data['hydra:member']
        const rolesData = res[1].data['hydra:member']

        return { sitesData, rolesData: mapRolesData(rolesData) }
      },
    }
  )

  // List layer
  const [selected, setSelected] = useState<string[]>([])
  const [search, setSearch] = useState('')
  const hasSelected = selected.length > 0

  const sitesData = dataQ.data?.sitesData || []
  let filteredData = sitesData

  if (search && search.length >= 3) {
    filteredData = sitesData.filter((site) => {
      const searchValue = search.toLowerCase()
      const siteNameValue = site.name.toLowerCase()
      return siteNameValue.includes(searchValue)
    })
  }

  const toggleSelected = useCallback((siteIri: string) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(siteIri)) {
        const newSelected = prevSelected.filter((selectedVal) => selectedVal !== siteIri)
        return newSelected
      }
      const newSelected = [...prevSelected, siteIri]
      return newSelected
    })
  }, [])

  const toggleAll = useCallback(() => {
    if (hasSelected) {
      setSelected([])
    } else {
      const allSitesIriArr = filteredData.map((site) => site['@id']) || []
      setSelected(allSitesIriArr)
    }
  }, [filteredData, hasSelected])

  const setSearchFilter = useCallback((value: string) => {
    setSelected([])
    setSearch(value)
  }, [])

  const listProps: ListProps = {
    listData: filteredData,
    search,
    setSearchFilter,
    selected,
    setSelected,
    hasSelected,
    toggleSelected,
    toggleAll,
  }

  // Business logic
  const setSiteRole = useCallback<SetSiteRoleFunc>(
    (site, role, siteRoleMap) => {
      const existRole = siteRoleMap[site]
      const isNone = role === 'none'
      if (existRole) {
        /**
         * If role is empty - remove {site, role} record
         * If not - assign it to existing
         */
        const newValue = field.value.filter((item) => item.site !== site)
        if (!isNone) {
          const copyOld = { ...existRole }
          copyOld.role = role
          newValue.push(copyOld)
        }
        helpers.setValue(newValue)
      } else if (!isNone) {
        const newRecord: SiteRolesFieldValueItem = { site, role }
        helpers.setValue([...field.value, newRecord])
      }
    },
    // helpers always trigger new renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.value]
  )

  const bulkSetRoleBySlug = useCallback(
    (roleSlug: string) => {
      const newValue = [...field.value]

      selected.forEach((siteIri) => {
        if (roleSlug === 'none') {
          const recordIndex = newValue.findIndex((val) => val.site === siteIri)
          if (recordIndex < 0) return
          newValue.splice(recordIndex, 1)
          return
        }

        const site = dataQ.data?.sitesData.find((site) => site['@id'] === siteIri)
        if (!site) return
        const siteRoles = dataQ.data?.rolesData.bySite[siteIri]
        if (!siteRoles) return
        const neededRole = siteRoles.find((role) => role.slug === roleSlug)
        if (!neededRole) return
        const existRole = siteRoleMap[siteIri]

        if (existRole) {
          const oldValue = newValue.find((val) => val.site === siteIri)
          if (!oldValue) return
          oldValue.role = neededRole['@id']
        } else {
          const newRecord: SiteRolesFieldValueItem = {
            site: siteIri,
            role: neededRole['@id'],
          }
          newValue.push(newRecord)
        }
      })

      helpers.setValue(newValue)
    },
    [dataQ.data, field.value, helpers, selected, siteRoleMap]
  )

  return {
    dataQ,
    siteRoleMap,
    setSiteRole,
    listProps,
    bulkSetRoleBySlug,
  }
}

export default useController
