import { unstable_createMuiStrictModeTheme, colors } from '@material-ui/core'

const darkTheme = unstable_createMuiStrictModeTheme({
  typography: {
    fontFamily: 'Nunito Sans',
  },
  palette: {
    primary: {
      main: colors.grey[800],
      light: colors.grey[500],
    },
    secondary: {
      main: colors.purple[700],
      light: colors.purple[400],
    },
    background: {
      default: colors.grey[100],
    },
    tonalOffset: 0.2,
  },
})

export default darkTheme
