// Core
import React, { FC, useMemo } from 'react'
import {
  Avatar,
  Box,
  Chip,
  FormControl,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
// Components
import Sidebar from '../sidebar'
// Hooks
import useDateUtils from 'core/date/use-date-utils'
// Types
import { EntityVersion } from 'modules/new-entity/types'

type Props = {
  onClose: () => void
  data: EntityVersion[]
  entityId: number
  entityTypeId: number
  originalId: number
  entityUrlBase: string
}

const getAvatarName = (name: string | null) => {
  if (!name) return '?'
  const splitName = name.split(' ')

  return `${splitName[0][0]}${splitName[1][0]}`
}

const History: FC<Props> = (props) => {
  const { onClose, data, entityId, originalId, entityUrlBase } = props
  const history = useHistory()

  const { formateDate } = useDateUtils()

  const preparedData = useMemo(() => {
    const newData = [...data]
      .filter((version) => version.originalLabel === 'revision')
      .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())

    // Replace first version to main entity
    if (newData[0]) {
      newData[0].id = originalId
    }

    return newData
  }, [data, originalId])

  const selectedVersion = preparedData.find((version) => version.id === entityId) || preparedData[0]

  const renderValue = () => {
    if (!selectedVersion) return ''
    return formateDate(selectedVersion.createdAt)
  }

  const versionChangeHandler = (value: string) => {
    history.push(`${entityUrlBase}/${value}`)
  }

  return (
    <Sidebar
      onClose={onClose}
      zIndex={100}
      header={<Typography variant="subtitle2">Version history</Typography>}
    >
      <Box px={2} py={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Version</InputLabel>
          <Select
            value={selectedVersion?.id}
            renderValue={renderValue}
            onChange={(e, value) => versionChangeHandler(e.target.value as string)}
            label="Version"
          >
            {preparedData.map((version, index) => {
              return (
                <MenuItem key={version.id} value={version.id}>
                  <ListItemText
                    primary={
                      <Box display="flex" style={{ columnGap: 5 }}>
                        <Typography>{version.actionName || 'created'}</Typography>
                        {index === 0 && <Chip label="latest" size="small" color="primary" />}
                      </Box>
                    }
                    secondary={formateDate(version.createdAt)}
                    style={{ textTransform: 'capitalize' }}
                  />
                  <ListItemAvatar>
                    <Tooltip title={version.createdBy || 'Unknown'} placement="left">
                      <Avatar style={{ background: '#3f51b5' }}>
                        {getAvatarName(version.createdBy)}
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Box>
    </Sidebar>
  )
}

export default History
