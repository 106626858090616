import { useMemo } from 'react'
import { DefaultValuesGenerator } from 'modules/new-entity/transformers'
import { generateValidationSchema } from 'modules/new-entity/utils'
import { useSitesContext } from '../../sites'

type UseInitialValues = (
  entity: any | null,
  data: any
) => {
  initialValues: { [key: string]: any }
  validationSchema: any
}

export const useInitialValues: UseInitialValues = (entity, data) => {
  const { locales } = useSitesContext()
  const localizations = locales.site

  return useMemo(() => {
    if (!data) {
      return {
        initialValues: {},
        validationSchema: {},
      }
    }

    const fixKey = entity?.['@type'] === 'Widget' ? 'setRepeats' : data.setRepeatsField

    const valuesData = {
      values: entity?.values,
      setRepeats: entity?.[fixKey],
    }

    const initialValues = new DefaultValuesGenerator(
      data?.attributes,
      localizations,
      // @ts-ignore
      valuesData
    ).getValues()

    const validationSchema = generateValidationSchema(data.attributes, localizations)

    return { initialValues, validationSchema }
  }, [data, entity, localizations])
}
