import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeSizes } from 'core/themes/default/default'

const { modalHeaderHeight } = defaultThemeSizes

const attributesModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    appBar: {
      height: modalHeaderHeight,
      minHeight: modalHeaderHeight,
      borderBottom: '1px solid #EEEEEE',
      backgroundColor: theme.palette.common.white,
    },
    toolBar: {
      minHeight: modalHeaderHeight,
    },
    tabs: {
      alignSelf: 'flex-end',
    },
    closeBtn: {
      marginLeft: 'auto',
    },
    custom: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100% - 64px)',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    tabPanel: {
      padding: theme.spacing(0, 3),
    },
  })
)

export default attributesModalStyles
