import { FormAttribute } from 'modules/new-entity/types'

const parser = (data: Record<string, any>, ids: string[] = []) => {
  const slugs = Object.keys(data || {})

  slugs.forEach((slug) => {
    const value = data[slug]?.value
    const type = data[slug]?.type
    const isJson = data[slug]?.isJson

    const valueType =
      typeof value === 'object' ? (Array.isArray(value) ? 'array' : 'object') : typeof value

    if (valueType === 'array') {
      value.forEach((repItem: any) => {
        return parser(repItem, ids)
      })
    }

    if (valueType === 'object') return parser(value, ids)

    if (type === 'text_editor') {
      const domParser = new DOMParser()
      const doc = domParser.parseFromString(value, 'text/html')
      const links = doc.querySelectorAll<HTMLAnchorElement>('a[data-type]')

      links.forEach((link) => {
        if (link.dataset.brand) ids.push(link.dataset.brand)
        if (link.dataset.entity) ids.push(link.dataset.entity)
        if (link.dataset.affiliate) ids.push(link.dataset.affiliate)
        if (link.dataset.ga_event) ids.push(link.dataset.ga_event)
      })
    }

    if (type === 'string' && isJson) {
      try {
        if (!value) return
        const parseValue = JSON.parse(value)

        if (parseValue.brand) ids.push(parseValue.brand)
        if (parseValue.entity) ids.push(parseValue.entity)
        if (parseValue.ga_event) ids.push(parseValue.ga_event)
        if (parseValue.affiliate) ids.push(parseValue.affiliate)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error in link parsing', e)
      }
    }
  })
}

export const referencesParser = (values: any, attrs: FormAttribute[]) => {
  const refsAttr = attrs.find((item) => item.attribute.slug === 'editor_references')
  if (!refsAttr) return

  const ids: string[] = []
  parser(values, ids)

  const uniqIds = new Set(ids)
  const attrValues: string[] = []
  uniqIds.forEach((item) => attrValues.push(`/api/entities/${item}`))

  values[refsAttr.attribute['@id']] = { value: attrValues }
}
