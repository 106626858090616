// Core
import React, { FC } from 'react'
import { Link } from '@material-ui/core'
//
// Styles
import useStyles from './url-field.styles'

type TProps = {
  rowData: any
  field: any
}

const UrlField: FC<TProps> = (props) => {
  const { rowData, field } = props

  const classes = useStyles()

  return (
    <Link className={classes.root} href={rowData[field]} target="_blank">
      {rowData[field]}
    </Link>
  )
}

export default UrlField
