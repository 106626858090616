// Core
import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
// Components
import AddFilter from './add-filter'
import Filter from './filter'
// Hooks
import usePrepareFiltersData from './use-prepare-filters-data'
// Types
import { FiltersProps } from './types'
import { Attribute } from 'modules/eav/types'

type Props = {
  isLoading: boolean
  loadingSkeleton?: boolean
  data: Attribute[]
} & FiltersProps

const Filters: FC<Props> = (props) => {
  const {
    isLoading,
    loadingSkeleton = true,
    data = [],
    selectedFilters,
    addFilter,
    changeFilterCondition,
    removeFilter,
    changeFilterValue,
  } = props

  const preparedData = usePrepareFiltersData(data)

  if (loadingSkeleton && isLoading) {
    return (
      <Box display="flex" alignItems="center">
        <Skeleton variant="rect" height={31} width={128} />
      </Box>
    )
  }

  return (
    <Box display="flex" style={{ columnGap: 20, rowGap: 10, flexWrap: 'wrap' }} alignItems="center">
      <AddFilter
        data={preparedData}
        selectedFilters={selectedFilters}
        onAddFilter={addFilter}
        isLoading={isLoading}
      />
      {selectedFilters.map((filter) => (
        <Filter
          key={filter.slug}
          data={filter}
          onDeleteFilter={removeFilter}
          onChangeCondition={changeFilterCondition}
          onChangeValue={changeFilterValue}
        />
      ))}
    </Box>
  )
}

export default Filters
