// Core
import React, { FC } from 'react'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
// Images
import loginLogo from 'assets/img/login-logo.svg'
// Components
import { SubmitButton, TextField } from 'core/form'
// Hooks
import useResetPasswordController from 'modules/auth/hooks/use-reset-password-controller'
import { useNotify } from 'core/hooks'
// Utils
import { confirmPasswordValidationRule, passwordValidationRule } from 'modules/auth/utils'
import { ApiErrorsService } from 'core/form/utils/api-errors'

type FormValues = {
  password: string
  repeatedPassword: string
}

const initialValues: FormValues = {
  password: '',
  repeatedPassword: '',
}

const validationSchema = yup.object({
  password: passwordValidationRule(),
  repeatedPassword: confirmPasswordValidationRule(),
})

const ResetPassword: FC = () => {
  const { loading, saveNewPassword } = useResetPasswordController()
  const notify = useNotify()

  const submitHandler = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      await saveNewPassword(values)
    } catch (error) {
      if (ApiErrorsService.isApiError(error)) {
        const apiErrorsService = new ApiErrorsService(helpers, error as any)
        apiErrorsService.setFormErrors()
      } else {
        notify('Something went wrong', { type: 'error' })
      }
    }
  }

  return (
    <Box flex={1} py={5} px={7} display="flex" flexDirection="column">
      <img src={loginLogo} alt="login-logo" width={160} height="auto" />
      <Box m="auto" maxWidth={444} width="100%">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          <Form>
            <>
              <Box mb={2}>
                <Typography component="h1" variant="h5">
                  Reset password
                </Typography>
              </Box>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Typography
                    component="p"
                    variant="caption"
                    color="textSecondary"
                    style={{ marginBottom: 10 }}
                  >
                    New Password must be a combination of 8 to 20 letters and numbers
                  </Typography>
                  <Box>
                    <TextField name="password" label="New password" type="password" required />
                  </Box>
                  <Box my={2}>
                    <TextField
                      name="repeatedPassword"
                      label="Confirm new password"
                      type="password"
                      required
                    />
                  </Box>
                  <Box display="flex" justifyContent="flex-end" gridGap={8}>
                    <Button color="primary" component="a" href="/">
                      Back to login
                    </Button>
                    <SubmitButton title="Save" />
                  </Box>
                </>
              )}
            </>
          </Form>
        </Formik>
      </Box>
    </Box>
  )
}

export default ResetPassword
