// Core
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// Material ui
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
// Components
import { DialogTitle } from 'ui'
import clsx from 'clsx'
import AttributesForm from 'modules/new-entity/components/attributes-form'
// Hooks
import { useGetNavigationContext } from '../../hooks'
import { useNotify } from 'core/hooks'
import { useQueryClient } from 'react-query'
import { useEntityController } from 'modules/new-entity/hooks'
// Types
import { EavResourceType, MutationType } from 'modules/new-entity/types'
// Utils
import { EntityService } from 'modules/new-entity/utils'
// Styles
import { useStyles } from './navigation-item-modal.styles'

type Props = {
  children?: never
}

const NavigationItemModal: FC<Props> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const notify = useNotify()
  const queryClient = useQueryClient()

  const {
    activeNavigationItem,
    navItemModalOpened,
    activeType,
    actions: { closeModal, setActiveNavigationItem, setNavItemModalOpened },
    itemType,
  } = useGetNavigationContext()

  const {
    mainAttributes,
    initialValues,
    validationSchema,
    entityType,
    formRef,
    isLoading,
    mutation,
    attributes,
  } = useEntityController(activeNavigationItem, activeType!, EavResourceType.ENTITY, {
    queryKey: 'nav-item',
  })

  const submitHandler = useCallback(async () => {
    try {
      await EntityService.validateForm(formRef.current)
      let values = { ...formRef.current?.values }

      values = EntityService.generateValues(values, attributes)

      const mutationObject = {
        entityType: entityType['@id'],
        ...values,
      }

      await mutation.mutateAsync(mutationObject)
      queryClient.invalidateQueries('navigation')

      setActiveNavigationItem(null)
      setNavItemModalOpened(false)
    } catch (e) {
      if (e?.type !== 'validation') {
        notify('Creation error', { type: 'error' })
      }
    }
  }, [
    formRef,
    attributes,
    entityType,
    mutation,
    queryClient,
    setActiveNavigationItem,
    setNavItemModalOpened,
    notify,
  ])

  if (isLoading) return null
  if (!activeType) return null

  return (
    <Dialog
      id={'navigation-modal'}
      maxWidth="lg"
      fullWidth
      open={navItemModalOpened}
      onClose={closeModal}
      className={clsx(classes.navigationModal, {
        'hide-for-item-entity': itemType === 'navigation_item_entity_group',
      })}
    >
      <DialogTitle onClose={closeModal}>{t('navigation.edit-navigation')}</DialogTitle>
      <DialogContent>
        <AttributesForm
          type={MutationType.EDIT}
          mainAttributes={mainAttributes}
          initialValues={initialValues}
          validationSchema={validationSchema}
          resourceType={EavResourceType.ENTITY}
          typeOptions={entityType?.options}
          formRef={formRef}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={submitHandler}>
          Save
        </Button>
      </DialogActions>
      {prompt}
    </Dialog>
  )
}

export default NavigationItemModal
