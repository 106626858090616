// TODO: list provider issue
import React, { FC, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
// Components
import { ModalFooter } from 'common/components'
import { CreateFields } from '../create-fields'
// Hooks
import { useGetActiveSite } from 'modules/sites'
// Styles
import useStyles from './main-form.styles'

type TProps = {
  id: string
  setRef?: (node: any) => void
  initialData: any
  onSubmitForm: (data: any) => void
}

const MainForm: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { id, setRef, initialData, onSubmitForm } = props
  const activeSite = useGetActiveSite()

  const validationSchema = useMemo(() => {
    /**
     * Check if site url is not internal
     */
    const sameSiteValidation = (value: string | null | undefined): boolean => {
      if (value) {
        try {
          const url = new URL(value)
          const siteUrl = new URL(activeSite?.url!)
          const isSubfolderSite = siteUrl.pathname.split('/')[1].length > 1
          const currentSubfolder = url.pathname.split('/')[1]
          const siteSubfolder = siteUrl.pathname.split('/')[1]
          const isSameHost = !isSubfolderSite
            ? url.host === siteUrl.host
            : `${url.host}/${currentSubfolder}` === `${siteUrl.host}/${siteSubfolder}`
          if (isSubfolderSite && isSameHost) return false
          const subfolders = activeSite?.subfolders || []
          const isSubfolderFromSystemSites = subfolders.includes(`/${currentSubfolder}`)
          if (isSameHost && !isSubfolderFromSystemSites) return false
        } catch {
          return true
        }
      }
      return true
    }

    return Yup.object().shape({
      affiliate: Yup.string()
        .nullable()
        .when('type', {
          is: (value) => value === 'affiliate_link',
          then: Yup.string().nullable().required('This value is required'),
        }),
      entity: Yup.string()
        .nullable()
        .when('type', {
          is: (value) => value === 'internal_link',
          then: Yup.string().nullable().required(),
        }),
      url: Yup.string()
        .nullable()
        .when('type', {
          is: (value) => value === 'custom_link',
          then: Yup.string()
            .nullable()
            .required()
            .test('same-domain', 'Only external domains can be configured a custom link', (val) =>
              sameSiteValidation(val)
            ),
        }),
    })
  }, [activeSite])

  return (
    <div id={id} className={classes.formsWrapper}>
      <Formik
        innerRef={setRef}
        initialValues={initialData}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        data-aqa-form="link-insertion"
        //enableReinitialize
      >
        {() => (
          <Form className={classes.mainForm}>
            <div>
              <CreateFields />
            </div>
            <ModalFooter>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginLeft: 'auto' }}
                data-aqa-action="save-link"
              >
                {t('links.save')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default MainForm
