import { useCallback } from 'react'
import { useField } from 'formik'

type UseDragEnd = (fieldName: string) => (result: any) => void

export const useDragEnd: UseDragEnd = (fieldName = 'setAttributes') => {
  const [{ value }, , { setValue }] = useField(fieldName)

  return useCallback(
    (result) => {
      const { destination, source } = result
      if (!destination) return
      if (destination.droppableId === source.droppableId && destination.index === source.index)
        return
      const startIndex = source.index
      const endIndex = destination.index
      const copyElement = value[startIndex]
      const copyValue = [...value]

      copyValue.splice(startIndex, 1)
      copyValue.splice(endIndex, 0, copyElement)

      setValue(copyValue)
    },
    [value, setValue]
  )
}
