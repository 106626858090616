import { createStyles, makeStyles } from '@material-ui/core/styles'
import { defaultThemeSizes } from 'core/themes/default/default'

const { rightSidebarWidth } = defaultThemeSizes

const sidebarStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: rightSidebarWidth,
      '& > div': {
        width: rightSidebarWidth,
        overflowX: 'hidden',
        padding: theme.spacing(3),
      },
    },
    appBar: {
      top: '-25px',
      height: 'auto',
      background: '#fff',
      padding: '20px 0',
    },
    buttonsBox: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
    },
  })
)

export default sidebarStyles
