// Core
import React, { FC, useCallback } from 'react'
import { Button } from '@material-ui/core'
// Hooks
import { useLanguage } from 'core/data'
// Types
import { Localization } from 'core/types'
// Styles
import useStyles from './form-lang.styles'

type Props = {
  children?: never
}

const FormLang: FC<Props> = () => {
  const classes = useStyles()
  const { currentLang, setCurrentLang, localizations } = useLanguage()
  const localized = localizations.length > 1

  const getVariant = (code: string) => (code === currentLang.code ? 'contained' : 'outlined')

  const setLocaleHandler = useCallback(
    (locale: Localization) => () => {
      setCurrentLang(locale)
    },
    [setCurrentLang]
  )

  if (!localized) return null

  return (
    <div className={classes.root}>
      {localizations.map((item) => (
        <Button variant={getVariant(item.code)} key={item.code} onClick={setLocaleHandler(item)}>
          {item.code}
        </Button>
      ))}
    </div>
  )
}

export default FormLang
