import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import { FormHolder } from 'ui'
import { TextField } from 'core/form'
import { useTranslation } from 'react-i18next'

type TProps = {}

const LayoutFormComponent: FC<TProps> = (props) => {
  const { t } = useTranslation()

  return (
    <FormHolder title={t('layout.plural')}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField localized name="name" label={t('labels.name')} />
        </Grid>
        <Grid item xs={12}>
          <TextField name={'slug'} label={t('labels.slug')} />
        </Grid>
      </Grid>
    </FormHolder>
  )
}

export default LayoutFormComponent
