// Core
import React, { FC } from 'react'
// Components
import RelationsField from 'core/form/components/relations-field'
// Types
import { AttributeData } from 'modules/new-entity/types'

type Props = {
  name: string
  data: AttributeData
  disabled?: boolean
  required?: boolean
}

const EntityTypeReference: FC<Props> = (props) => {
  const {
    data: { name, entityTypes },
    ...rest
  } = props

  return (
    <RelationsField
      source="entity_types"
      label={name}
      reqParams={{ id: entityTypes }}
      valueField="@id"
      {...rest}
    />
  )
}

export default EntityTypeReference
