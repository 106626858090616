import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      boxShadow: 'none',
      borderBottom: '1px solid #e0e0e0',
      '&:before': {
        display: 'none',
      },
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    title: {
      padding: 0,
    },
    content: {
      padding: '0 0 25px',
    },
  })
)

export default styles
