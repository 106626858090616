import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { ResourceVersion, ResourceWithVersions } from '../types'

type Params = {
  resource: string
  originalId: number
  currentId?: number
  versions: ResourceVersion[]
  enabled?: boolean
}

export default function useGetUsedSegments(params: Params) {
  const { resource, originalId, currentId, versions, enabled = true } = params

  return useQuery(
    ['used-segments', originalId],
    () => {
      const requests = versions.map((version) =>
        httpService.get<ResourceWithVersions>(`/${resource}/${version.id}`).then((res) => res.data)
      )

      return Promise.all(requests)
    },
    {
      enabled,
      select: (data) => {
        return data
          .filter((resourceItem) => resourceItem.id !== currentId)
          .map((resourceItem) => resourceItem.segments)
          .flat()
      },
    }
  )
}
