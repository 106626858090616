// Core
import React, { FC, memo, useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { Grid } from '@material-ui/core'
// Components
import { SmartSelectField } from 'core/form/components'
import SmartSelect from 'ui/smart-select'
import { useGetAttributesTypes } from 'common/hooks/attribute/use-get-attributes-types'
import { SmartSelectValue } from 'ui'
// Types
import { AttributeTypesList } from 'common/types'
import { useTranslation } from 'react-i18next'

type Props = {
  children?: never
}

type FormValues = {
  type: AttributeTypesList
  entityType: string
  defaultValues: { value: string }
  referenceOwner: string
}

const referencesArr = [
  AttributeTypesList.reference_one_to_one,
  AttributeTypesList.reference_many_to_one,
  AttributeTypesList.reference_many_to_many,
]

const reversedArr = [
  AttributeTypesList.reference_one_to_one_reversed,
  AttributeTypesList.reference_many_to_many_reversed,
  AttributeTypesList.reference_one_to_many_multiple_types,
  AttributeTypesList.reference_one_to_many,
]

const reverseMap: any = {
  [AttributeTypesList.reference_one_to_one_reversed]: AttributeTypesList.reference_one_to_one,
  [AttributeTypesList.reference_many_to_many_reversed]: AttributeTypesList.reference_many_to_many,
  [AttributeTypesList.reference_one_to_many_multiple_types]:
    AttributeTypesList.reference_many_to_one_multiple_types,
  [AttributeTypesList.reference_one_to_many]: AttributeTypesList.reference_many_to_one,
}

const ReferenceFields: FC<Props> = () => {
  /**
   * Get 'type' via formik context
   * to know which fields will be render
   */
  const {
    values: { type, entityType, defaultValues, referenceOwner },
    setFieldValue,
  } = useFormikContext<FormValues>()
  const { data: attributeTypes } = useGetAttributesTypes()
  const { t } = useTranslation()

  const checkReference = useMemo(() => {
    const isDirect = referencesArr.indexOf(type) >= 0
    const isReversed = reversedArr.indexOf(type) >= 0

    return {
      isReference: isDirect || isReversed,
      isDirect,
      isReversed,
    }
  }, [type])

  const attributesParams = useMemo(() => {
    const directType = reverseMap[type]
    const typeObj = attributeTypes?.find((item: any) => item.type === directType)

    return {
      attributeType: typeObj ? typeObj['@id'] : '',
    }
  }, [attributeTypes, type])

  const defaultValueChangeHandler = useCallback(
    (id: SmartSelectValue) => {
      setFieldValue('defaultValues', { value: id })
    },
    [setFieldValue]
  )

  const entityTypeChangeHandler = useCallback(() => {
    setFieldValue('defaultValues', [])
  }, [setFieldValue])

  const ownerChangeHandler = useCallback(() => {
    setFieldValue('entityType', '')
  }, [setFieldValue])

  const getEntityTypeParams = useMemo(() => {
    if (checkReference.isDirect) return {}

    return {
      hasAttribute: referenceOwner,
    }
  }, [referenceOwner, checkReference])

  if (!checkReference.isReference) return null

  return (
    <>
      {checkReference.isReversed && (
        <Grid item xs={6}>
          <SmartSelectField
            name={'referenceOwner'}
            source={'attributes'}
            label={t('labels.ref-owner')}
            valueField={'@id'}
            reqParams={attributesParams}
            onChange={ownerChangeHandler}
          />
        </Grid>
      )}
      <Grid item xs={6} hidden={checkReference.isReversed && !referenceOwner}>
        <SmartSelectField
          name={'entityType'}
          source={'entity_types'}
          label={t('labels.ref-to')}
          valueField={'@id'}
          reqParams={getEntityTypeParams}
          onChange={entityTypeChangeHandler}
        />
      </Grid>
      {checkReference.isDirect && entityType && (
        <Grid item xs={6}>
          <SmartSelect
            source="entities"
            label={t('labels.def-value')}
            valueField="@id"
            titleField="name"
            reqParams={{ entityType }}
            value={defaultValues ? defaultValues.value : ''}
            onChange={defaultValueChangeHandler}
            multiple={type === AttributeTypesList.reference_many_to_many}
          />
        </Grid>
      )}
    </>
  )
}

export default memo(ReferenceFields)
