import React, { FC } from 'react'
import { Edit } from 'core/data'
import { ResourceProps } from 'core/data'
import CrudForm from '../crud-form'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  width: Yup.number().min(0, 'Value cannot be negative'),
  height: Yup.number().min(0, 'Value cannot be negative'),
  quality: Yup.number()
    .min(0, 'Value must be from 0 to 100')
    .max(100, 'Value must be from 0 to 100'),
})

const MediaSizeEdit: FC<ResourceProps> = (props) => {
  return <Edit validationSchema={validationSchema} {...props} crudForm={CrudForm} />
}

export default MediaSizeEdit
