/**
 * Workaround to avoid layouts rerendering
 */

// Core
import React, { FC, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { MainLayout } from 'modules/layout'
import AuthLayout from 'modules/auth/components/auth-layout'

const getLayout = (pathName: string) => {
  if (['/login'].includes(pathName)) {
    return AuthLayout
  }

  if (pathName.includes('/reset-password')) {
    return Fragment
  }

  return MainLayout
}

const LayoutHandler: FC = ({ children }) => {
  const { pathname } = useLocation()

  const Layout = getLayout(pathname)

  return <Layout>{children}</Layout>
}

export default LayoutHandler
