import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const attributeRepeaterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    actionArea: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    separator: {
      display: 'inline-block',
      padding: theme.spacing(0, 4, 0, 2),
    },
    infoIcon: {
      marginLeft: theme.spacing(1),
    },

    label: {
      fontSize: '0.75rem',
      color: colors.blueGrey[900],
    },
    name: {
      color: theme.palette.common.black,
    },
    slug: {
      color: theme.palette.common.black,
    },
    dragArea: {
      marginTop: theme.spacing(3),
    },
  })
)

export default attributeRepeaterStyles
