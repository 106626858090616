import { useCallback } from 'react'
import { SortedAttribute } from '../types'
import { EntityService } from '../utils'

export const useMutatePersonalizedVersion = (
  attributes: SortedAttribute[] = [],
  isWidget = false
) => {
  return useCallback(
    async (fromId: number, name: string, segments: string[], id?: number) => {
      const findNamableAttr = attributes.find((attr) => attr.nameable)

      if (!findNamableAttr) {
        throw new Error('Create personalized version should have namable attribute')
      }

      if (id) {
        return EntityService.updateEntity(id, isWidget, {
          segments,
          // @ts-ignore
          values: [{ attribute: findNamableAttr.attribute['@id'], value: name }],
        })
      }

      const type = isWidget ? 'widgets' : 'entities'

      return EntityService.duplicateEntity(fromId, isWidget, {
        original: `/api/${type}/${fromId}`,
        originalLabel: 'personalized',
        segments,
        status: 'draft',
        // @ts-ignore
        values: [{ attribute: findNamableAttr.attribute['@id'], value: name }],
      })
    },
    [attributes, isWidget]
  )
}
