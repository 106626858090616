// Core
import React, { FC, useMemo, useState } from 'react'
import { useListController } from 'core/data'
import { useTranslation } from 'react-i18next'
// Material
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// Components
import { CustomForm, EntitiesList } from './components'
// Types
import { TNavigation } from '../../types'
// Hooks
import { useGetNavigationContext } from '../../hooks'
import { useAppContext } from 'core/app'
// Styles
import { useStyles } from './navigation-urlable-list.styles'

type TProps = {
  navigationData?: TNavigation
}

const NavigationUrlableList: FC<TProps> = ({ navigationData }) => {
  const classes = useStyles()
  const [expandedId, setExpandedId] = useState('')
  const { isEdit } = useGetNavigationContext()
  const { t } = useTranslation()
  const { user } = useAppContext()
  const hideCustom = user.activeRole.slug === 'freelancer'

  const resource = {
    name: 'entity_types',
  }

  const filters = useMemo(() => {
    return { urlable: true, hidden: false }
  }, [])

  // TODO ITEMS PER PAGE(FOR TEST)
  const { data } = useListController(resource, {
    filters,
  })

  const handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
    setExpandedId(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      {data &&
        data.map((type: any) => (
          <Accordion
            key={type.id}
            expanded={expandedId === type.id}
            onChange={handleChange(type.id)}
            className={classes.accordion}
            disabled={!isEdit}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{ root: classes.accordionSummary, content: classes.accordionContent }}
            >
              <Typography className={classes.heading}>{type.name}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {expandedId === type.id && (
                <EntitiesList navigationData={navigationData} typeId={type.id} />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      {!hideCustom && (
        <Accordion
          expanded={expandedId === 'custom-form'}
          onChange={handleChange('custom-form')}
          className={classes.accordion}
          disabled={!isEdit}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            classes={{ root: classes.accordionSummary, content: classes.accordionContent }}
          >
            <Typography className={classes.heading}>{t('navigation.custom-link')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <CustomForm />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  )
}

export default NavigationUrlableList
