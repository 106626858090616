import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const layoutSideSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottonTab: {
      width: '100%',
      '& > span': {
        pointerEvents: 'none',
      },
    },
  })
)

export default layoutSideSectionStyles
