// Core
import React, { FC, useState, useCallback } from 'react'
import { AppBar, Dialog, IconButton, Toolbar, Tab, Button } from '@material-ui/core'
import { TabContext } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
// Store
import { useSelector } from 'react-redux'
import { attributesModalIsOpen } from '../../store'
// Components
import { Transition } from './components'
import { TabList, TabPanel } from 'ui'
import { AttributeSelect } from '../index'
import AttributeForm from '../attribute-form'
// Hooks
import { useTabSetValue } from 'modules/types-crud/hooks/tabs/use-tab-set-value'
import { useTranslation } from 'react-i18next'

// Styles
import useStyles from './attributes-modal.styles'
import { useModalWarnings } from 'core/hooks'

type TProps = {
  data?: object
  onAddAttribute: (attributeIri: string) => void
  closeModal: () => void
}

const AttributesModal: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onAddAttribute, closeModal } = props
  const { tabValue, handleChange, setTabValue } = useTabSetValue()
  const modalIsOpen = useSelector(attributesModalIsOpen)
  const [selectedAttrIri, setSelectedIri] = useState()

  const {
    prompt,
    methods: { showWarningModal, setRef },
  } = useModalWarnings(closeModal)
  const {
    prompt: tabPrompt,
    methods: { showWarningModal: showWarningTab, setRef: setTabRef },
  } = useModalWarnings(() => setTabValue('tab-1'))

  const setFormRef = useCallback(
    (node) => {
      setRef(node)
      setTabRef(node)
    },
    [setRef, setTabRef]
  )

  const tabChangeHandler = useCallback(
    (e: any, value: string) => {
      if (value === 'tab-1') {
        showWarningTab()
      } else {
        handleChange(e, value)
      }
    },
    [handleChange, showWarningTab]
  )

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={modalIsOpen}
      onClose={showWarningModal}
      TransitionComponent={Transition}
      className={classes.root}
      classes={{
        paperScrollPaper: classes.custom,
      }}
    >
      <TabContext value={tabValue}>
        <AppBar color="default" position="sticky" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <TabList onChange={tabChangeHandler}>
              <Tab label={t('attrs.index')} value="tab-1" />
              <Tab label={t('attrs.create')} value="tab-2" />
            </TabList>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              className={classes.closeBtn}
              onClick={showWarningModal}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <TabPanel value={tabValue} index="tab-1">
          <AttributeSelect
            value={selectedAttrIri}
            onSelect={setSelectedIri}
            footerButton={
              <Button
                style={{ marginLeft: 'auto' }}
                autoFocus
                variant="contained"
                color="primary"
                disabled={!selectedAttrIri}
                onClick={() => onAddAttribute(selectedAttrIri)}
              >
                {t('attrs.add')}
              </Button>
            }
          />
        </TabPanel>

        <TabPanel unmountOnChange value={tabValue} index="tab-2">
          <AttributeForm setRef={setFormRef} onAttributeCreate={onAddAttribute} />
        </TabPanel>
      </TabContext>
      {prompt}
      {tabPrompt}
    </Dialog>
  )
}

export default AttributesModal
