import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import { Folder } from '@material-ui/icons'
import { useGetMediaFolders } from 'modules/media/hooks/use-get-media-folders'
import { DialogTitle } from 'ui'
import { Breadcrumbs } from '../breadcrumbs'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 0,
    width: '100%',
  },
  listItem: {
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

type Props = {
  title: string
  mainActionTitle: string
  selectedFolder: number
  disabledItems: number[]
  disableCurrentSelection?: boolean
  onMainActionClick: (folderId: number) => void
  actionLoading?: boolean
} & Pick<DialogProps, 'open' | 'onClose'>

export const FolderSelect: FC<Props> = ({
  title,
  mainActionTitle,
  onMainActionClick,
  selectedFolder: selectedFolderProp,
  actionLoading,
  disabledItems,
  disableCurrentSelection,
  ...dialogProps
}) => {
  const classes = useStyles()
  const { foldersTreeData, foldersData } = useGetMediaFolders()

  const [selectedFolder, setSelectedFolder] = useState<number | null>(selectedFolderProp)
  const [folderFilter, setFolderFilter] = useState<number | null>(selectedFolderProp)

  useEffect(() => {
    setSelectedFolder(folderFilter)
  }, [folderFilter])

  const foldersList = useMemo(() => {
    if (!foldersData) return []

    return foldersData.filter((folder) => {
      if (!folderFilter) {
        return !folder.parent
      }

      return folder.parent && folder.parent === `/api/media_folders/${folderFilter}`
    })
  }, [folderFilter, foldersData])

  const selectedFolderData = useMemo(() => {
    const findFolder = foldersData?.find((folder) => folder.id === selectedFolder)

    return findFolder
  }, [foldersData, selectedFolder])

  if (!foldersTreeData || !foldersData) return null

  const isItemDisabled = (id: number) => disabledItems.includes(id)
  const isMainActionDisabled = disableCurrentSelection && selectedFolder === selectedFolderProp

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { overflowX: 'hidden' } }}
    >
      <DialogTitle onClose={dialogProps.onClose} className={classes.title}>
        <Box mb={1}>
          {title} {selectedFolderData?.name}
        </Box>
        <Breadcrumbs
          foldersData={foldersData}
          folder={folderFilter!}
          onSelect={(id) => setFolderFilter(id)}
        />
      </DialogTitle>
      <DialogContent>
        <List>
          {foldersList?.map((folder) => (
            <Tooltip
              key={folder.id}
              title={isItemDisabled(folder.id) ? `Can't move folder into itself` : ''}
              placement="top-start"
              arrow
            >
              <ListItem
                selected={selectedFolder === folder.id}
                onDoubleClick={() => setFolderFilter(folder.id)}
                className={classes.listItem}
                disabled={isItemDisabled(folder.id)}
              >
                <ListItemIcon>
                  <Folder />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Link
                      component="button"
                      display="inline"
                      color="textPrimary"
                      underline={isItemDisabled(folder.id) ? 'none' : 'hover'}
                      disabled={isItemDisabled(folder.id)}
                      onClick={(e) => {
                        e.stopPropagation()
                        setFolderFilter(folder.id)
                      }}
                    >
                      {folder.name}
                    </Link>
                  }
                />
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => dialogProps.onClose?.({}, 'backdropClick')}>
          Cancel
        </Button>
        <Tooltip
          title={isMainActionDisabled ? 'Item is already in this folder' : ''}
          placement="left"
          arrow
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onMainActionClick(selectedFolder!)}
              disabled={!selectedFolder || actionLoading || isMainActionDisabled}
            >
              {mainActionTitle}
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}

type FolderSelectState = {
  title: string
  mainActionTitle: string
  onMainAction: (folderId: number) => void
  selectedFolder: number
  disabledItems?: number[]
  disableCurrentSelection?: boolean
  isLoading?: boolean
}

export const useFolderSelect = () => {
  const [state, setState] = useState<FolderSelectState | null>(null)

  const isOpen = Boolean(state)

  const FolderSelectModal = useMemo(() => {
    if (!state) return null

    return (
      <FolderSelect
        open={isOpen}
        title={state?.title}
        mainActionTitle={state.mainActionTitle}
        onMainActionClick={state.onMainAction}
        selectedFolder={state.selectedFolder}
        actionLoading={state.isLoading}
        disabledItems={state.disabledItems || []}
        disableCurrentSelection={state.disableCurrentSelection}
        onClose={() => setState(null)}
      />
    )
  }, [isOpen, state])

  const openFolderSelect = useCallback((state: FolderSelectState) => {
    setState(state)
  }, [])

  const closeFolderSelect = useCallback(() => {
    setState(null)
  }, [])

  const setIsLoading = useCallback((val: boolean) => {
    setState((prev) => {
      if (!prev) return null

      return {
        ...prev,
        isLoading: val,
      }
    })
  }, [])

  return {
    FolderSelectModal,
    openFolderSelect,
    closeFolderSelect,
    setIsLoading,
  }
}
