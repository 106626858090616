import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    hint: {
      color: colors.blueGrey[200],
      marginBottom: theme.spacing(1),
    },
    fieldWrapper: {
      padding: '20px 0',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      alignSelf: 'flex-start',
      '&.highlight': {
        '& .fieldItem': {
          border: '1px solid #f44336',
          borderRadius: 4,
        },
      },
    },
    fieldItem: {
      marginTop: 'auto',
      width: '100%',
    },
  })
)

export default styles
