// Core
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
// Root reducer
import { createRootReducer } from 'core/init/root-reducer'
// Root saga
import { rootSaga } from 'core/init/root-saga'
// Router
import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'

export const configureStore = (history: History, initialState: {} = {}) => {
  const composeEnhancers = composeWithDevTools({})
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  )

  sagaMiddleware.run(rootSaga)

  return store
}
