// Core
import React from 'react'
import ReactDOM from 'react-dom'
// Components
import { Root } from 'core/containers'
// Utils
import { unregister } from 'core/utils'
// Reset styles
import 'reset-css'
import 'normalize.css'
import './index.scss'
import 'assets/icons/style.css'
import 'assets/fonts/fonts.css'
import 'react-resizable/css/styles.css'

ReactDOM.render(
  // <React.StrictMode>
  <Root />,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister()
