import React, { FC } from 'react'
import { Badge, Box, Typography } from '@material-ui/core'
import { DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core'
import {
  DndContext as LibContext,
  PointerSensor,
  useSensor,
  useSensors,
  pointerWithin,
} from '@dnd-kit/core'
import { snapCenterToCursor } from '@dnd-kit/modifiers'
import { useDataGridContext } from '../../providers/data-grid-provider'
import { MediaIcon } from 'modules/media/components/media-icon'

export type DndContextProps = {
  onDragEnd?: (destinationId: number | undefined) => void
}

export const DndContext: FC<DndContextProps> = ({ children, onDragEnd }) => {
  const { setDraggableId } = useDataGridContext()

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  })

  const sensors = useSensors(pointerSensor)

  return (
    <LibContext
      sensors={sensors}
      modifiers={[snapCenterToCursor]}
      collisionDetection={pointerWithin}
      onDragStart={(e) => {
        setDraggableId(+e.active.id)
      }}
      onDragEnd={(e) => {
        const overId = e.over?.id
        onDragEnd?.(overId ? +overId : undefined)
        setDraggableId(null)
      }}
      onDragCancel={() => {
        setDraggableId(null)
      }}
    >
      {children}
      <DndOverlay />
    </LibContext>
  )
}

export type DndOverlayProps = {}

export const DndOverlay: FC<DndOverlayProps> = () => {
  const { selected, listData, draggableId } = useDataGridContext()
  if (!draggableId) return null

  if (selected.length === 0) return null

  const lastSelected = listData.find((item) => item.id === draggableId)
  const multipleSelection = selected.length > 1

  if (!lastSelected) return null

  return (
    <DragOverlay wrapperElement="span" style={{ width: 'auto' }}>
      <Badge badgeContent={selected.length} color="primary" invisible={!multipleSelection}>
        <Box
          boxShadow={1}
          bgcolor="white"
          borderRadius={8}
          px={2}
          py={1}
          display="flex"
          alignItems="center"
          minWidth="100%"
          maxWidth={230}
          style={{ gap: 16, pointerEvents: 'none' }}
          position="relative"
        >
          <MediaIcon media={lastSelected} size="list" />
          <Typography color="textSecondary" noWrap>
            {lastSelected.name}
          </Typography>
        </Box>
        {multipleSelection && (
          <Box
            position="absolute"
            width="100%"
            height="100%"
            zIndex={-1}
            top={6}
            left={6}
            boxShadow={1}
            bgcolor="white"
            borderRadius={8}
          />
        )}
      </Badge>
    </DragOverlay>
  )
}

type UseDndItemControllerProps = {
  id: string | number
  selected: number[]
  disabledDrop?: boolean
  disabledDrag?: boolean
}

export const useDndItemController = ({
  id,
  disabledDrag: disabledDragProp,
  disabledDrop: disabledDropProp,
  selected,
}: UseDndItemControllerProps) => {
  const { dndActive } = useDataGridContext()
  const disabledDrag = disabledDragProp
  const disabledDrop = disabledDropProp || selected.includes(+id)

  const {
    setNodeRef: setDragRef,
    listeners,
    attributes,
  } = useDraggable({ id, disabled: disabledDrag })

  const { setNodeRef: setDropRef, isOver } = useDroppable({ id, disabled: disabledDrop })

  const setRef = (node: HTMLElement | null) => {
    setDragRef(node)
    setDropRef(node)
  }

  return {
    setRef,
    listeners,
    attributes,
    isOver,
    dndActive,
  }
}
