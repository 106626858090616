import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    mainForm: {
      transition: 'transform .3s',
    },
    formsWrapper: {},
    hidden: {
      transform: 'translateX(-150%)',
      maxHeight: '800px',
      overflow: 'hidden',

      transition: 'transform .3s',
    },
  })
)

export default useStyles
