import React, { FC } from 'react'
import useStyles from './global-attributes-list.styles'
import { GlobalAttributeItem } from './components'
import { Grid, CircularProgress } from '@material-ui/core'
import { useListContext } from 'core/data'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: object
  onChange: (id: string) => void
  selectedId: string
}

const GlobalAttributesList: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedId, onChange } = props
  const { data, isLoading } = useListContext()

  if (isLoading) {
    return (
      <div className={classes.spiner}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Grid container spacing={4} className={classes.root}>
      {data && data.length > 0 ? (
        data.map((item: any) => (
          <GlobalAttributeItem
            clickHandler={onChange(item['@id'])}
            isActive={selectedId === item['@id']}
            key={item.slug}
            item={item}
            isSystem={item.system}
          />
        ))
      ) : (
        <div className={classes.searchEmpty}>{t('errors.attrs-notfound')}</div>
      )}
    </Grid>
  )
}

export default GlobalAttributesList
