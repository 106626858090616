// Core
import React, { FC, useCallback } from 'react'
import { Container, Tab } from '@material-ui/core'
import { TabContext } from '@material-ui/lab'
import { DataControllerProps, FormLang } from 'core/data'
import { useDispatch } from 'react-redux'
import cloneDeep from 'lodash.clonedeep'
import { useTranslation } from 'react-i18next'
// Components
import { CrudForm } from '../index'
import { TabList, TabPanel } from 'ui'
import { AttributeAddButton, AttributesModal, SelectedAttributesList } from 'common/components'
// Hooks
import { useTabSetValue } from '../../hooks/tabs/use-tab-set-value'
import { useAttributesModalOpen } from 'common/hooks/attributes-modal/use-attributes-modal-open'
import { useGetSelectedAttributes } from 'common/hooks'
import { useModalWarnings } from 'core/hooks'
// Actions
import { attributesModalOpen, showNestedForm } from 'common/store'
import { useTypeRosourceContext } from '../../hooks'

const FormContainer: FC<DataControllerProps> = (props) => {
  const { t } = useTranslation()
  const context = useTypeRosourceContext()
  /** [attributesContainer] - name of API key for atts
   *  for (ex. 'widgetTypeAttributes' or 'entityTypeAttributes')
   */
  const attributesContainer = context?.options.attrsName
  const resourceName = context?.options.resourceName

  const dispatch = useDispatch()
  const { type, values, setFieldValue } = props

  const { options } = values
  const attributes = values[attributesContainer]
  const attributeOptions = options?.attributeOptions

  const { tabValue, handleChange } = useTabSetValue()
  const modalOpen = useAttributesModalOpen('create')
  const { isLoading } = useGetSelectedAttributes(attributes)

  const closeModalHandler = useCallback(() => {
    dispatch(attributesModalOpen(false))
    dispatch(showNestedForm(false))
  }, [dispatch])

  const {
    methods: { closeModal },
  } = useModalWarnings(closeModalHandler)

  const addAttributeHandler = (attrIri: string) => {
    if (attributes.some((attrItem: any) => attrItem.attribute === attrIri)) {
      alert(t(`global.contains`))
      return
    }

    const copyData = cloneDeep(attributes)
    copyData.push({ attribute: attrIri })
    /**
     * Recompute sortOrder */
    copyData.forEach((item: any, index: number) => {
      item.sortOrder = index
    })
    setFieldValue(attributesContainer, copyData)
    closeModal()
  }

  const dragEndHandler = useCallback(
    (result) => {
      const { destination, source } = result
      if (
        !destination ||
        (destination.droppableId === source.droppableId && destination.index === source.index)
      ) {
        return
      }

      const startIndex = source.index
      const endIndex = destination.index
      const copyElement = cloneDeep(attributes[startIndex])
      const copyValue = cloneDeep(attributes)

      copyValue.splice(startIndex, 1)
      copyValue.splice(endIndex, 0, copyElement)

      /**
       * Recompute sortOrder */
      copyValue.forEach((item: any, index: number) => {
        item.sortOrder = index
      })
      setFieldValue(attributesContainer, copyValue)
    },
    [attributes, attributesContainer, setFieldValue]
  )

  const removeAttributeHandler = useCallback(
    (index: number) => () => {
      attributes.splice(index, 1)
      setFieldValue(attributesContainer, attributes)
    },
    [attributes, setFieldValue, attributesContainer]
  )

  const removeAttributeOptionsHandler = useCallback(
    (attrId: string) => () => {
      const oldData = attributeOptions || {}
      if (attributeOptions) {
        delete oldData[attrId]
      }

      // loop to find if deleted attribut is a Parent of attributes that have options
      for (const [key] of Object.entries(oldData)) {
        // we can delete only top level parent, so i check only if === 0
        if (key?.indexOf(attrId) === 0) {
          delete oldData[key]
        }
      }
    },
    [attributeOptions]
  )

  const removeAttributeDependenciesHandler = useCallback(
    (attrId: string) => () => {
      if (attributeOptions) {
        Object.values(attributeOptions).forEach((item: any) => {
          if (item.condition.dependsOn === attrId) {
            item.condition.dependsOn = ''
            item.condition.conditionValue = ''
          }
        })
      }
    },
    [attributeOptions]
  )

  return (
    <>
      <FormLang />
      <TabContext value={tabValue}>
        <TabList onChange={handleChange}>
          <Tab label={`${t(`global.${type}`)} ${t(`resource.${resourceName}`)}`} value="tab-1" />
          <Tab label={`${t(`resource.${resourceName}`)} ${t('attrs.index')}`} value="tab-2" />
        </TabList>

        <TabPanel unmountOnChange value={tabValue} index="tab-1">
          <CrudForm {...props} />
        </TabPanel>

        <TabPanel unmountOnChange value={tabValue} index="tab-2">
          <AttributeAddButton openHandler={modalOpen} />
          {!isLoading && (
            <Container maxWidth="md">
              <SelectedAttributesList
                id="entity-type-attributes-list"
                data={attributes}
                removeAttributeHandler={removeAttributeHandler}
                removeAttributeOptionsHandler={removeAttributeOptionsHandler}
                removeAttributeDependenciesHandler={removeAttributeDependenciesHandler}
                dragEndHandler={dragEndHandler}
              />
            </Container>
          )}
          <AttributesModal closeModal={closeModalHandler} onAddAttribute={addAttributeHandler} />
        </TabPanel>
      </TabContext>
    </>
  )
}

export default FormContainer
