import React, { FC } from 'react'
import { Box, Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export const SidebarPanelLoading: FC = () => {
  return (
    <Box height="inherit" overflow="auto">
      <Box p={2} py={2.5} display="flex" justifyContent="space-between" style={{ gap: 16 }}>
        <Box flex={1}>
          <Skeleton />
        </Box>
        <Box display="flex" style={{ gap: 8 }}>
          <Skeleton variant="rect" width={20} height={20} />
          <Skeleton variant="rect" width={20} height={20} />
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box padding={2}>
          <Skeleton variant="rect" width="100%" height={260} />
        </Box>
        {Array.from(Array(20).keys()).map((i) => (
          <Box padding={2} key={i}>
            <Skeleton />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
