import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

// @ts-ignore (json declare)
import en from './en/translations'

const translationsJson = {
  en: { translation: en },
}

export const i18n = i18next.use(initReactI18next).init({
  resources: translationsJson,
  fallbackLng: 'en',
  lng: 'en',
})
