import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: '480px',
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
}))

export default useStyles
