import React, { FC, useCallback } from 'react'
// Redux
import { useDispatch } from 'react-redux'
// Components
import { Button, Grid, Icon, IconButton, Typography } from '@material-ui/core'
import SmartSelect from 'ui/smart-select'
import { SelectedAttributesList } from 'common/components/index'
// Hooks
import { useDragEnd, useSetValue, useRemoveAttribute } from 'common/hooks'
// Store
import { showNestedForm } from 'common/store'
// Utils
import { v4 as uniqueId } from 'uuid'
// Styles
import useStyles from './attribute-repeater.styles'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: object
  nested?: boolean
  title?: string
}

const AttributeRepeater: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { nested = false, title } = props
  const dispatch = useDispatch()
  const { setValueHandler, value } = useSetValue()
  const dragEnd = useDragEnd('setAttributes')
  const removeAttributeHandler = useRemoveAttribute()
  const openNestedFormHandler = useCallback(() => {
    dispatch(showNestedForm(true))
  }, [dispatch])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h5'}> {title} </Typography>
        </Grid>

        <Grid item xs={nested ? 12 : 6}>
          <SmartSelect
            valueField={'@id'}
            onChange={setValueHandler}
            value={null}
            source={'attributes'}
            label={t('attrs.select-new')}
            clearAfterSetValue={true}
          />
        </Grid>

        {!nested && (
          <Grid item xs={6}>
            <div className={classes.actionArea}>
              <div className={classes.separator}>OR</div>
              <Button
                variant="contained"
                color="primary"
                onClick={openNestedFormHandler}
                startIcon={<Icon className="icon-plus-circle" />}
              >
                {t('attrs.create-new')}
              </Button>

              <IconButton className={classes.infoIcon}>
                <Icon className="icon-help-circle" />
              </IconButton>
            </div>
          </Grid>
        )}

        <Grid item xs={12}>
          <SelectedAttributesList
            id={uniqueId()}
            data={value}
            dragEndHandler={dragEnd}
            removeAttributeHandler={removeAttributeHandler}
            isNested
            disableOptions
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default AttributeRepeater
