import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const { contentBg } = defaultThemeColors

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      background: contentBg,
      borderTop: '1px solid rgb(196 196 196)',
    },
  })
)

export default styles
