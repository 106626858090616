import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { httpService } from 'core/data'
import { useConfirmation } from 'core/confirmation'
import { useTranslation } from 'react-i18next'

type UseDeleteOne = (resource: string) => UseMutationResult<unknown, unknown, number, number>

export const useDelete: UseDeleteOne = (resource) => {
  const queryClient = useQueryClient()
  const { setConfirmation } = useConfirmation()
  const { t } = useTranslation()

  return useMutation(
    (id: number) => {
      return httpService.delete(`/entities/${id}`)
    },
    {
      onError: (error: any, variables) => {
        if (error.response.status === 409) {
          setConfirmation({
            open: true,
            title: t('global.delete'),
            content: t('notify.delete'),
            onSuccess: () => {
              httpService.delete(`/entities/${variables}?force=true`).then(() => {
                queryClient.invalidateQueries(resource)
              })
            },
          })
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(resource)
      },
    }
  )
}
