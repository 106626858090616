// Core
import React, { FC } from 'react'
import { useGetAttributeData } from 'common/hooks'
import { useTranslation } from 'react-i18next'

type Props = {
  value: string
}

const GetTitle: FC<Props> = (props) => {
  const { value } = props
  const { data: attrData, isLoading, error } = useGetAttributeData(value)
  const { t } = useTranslation()

  if (isLoading) return <div>{t('global.loading')}</div>
  if (error) return <div>{t('global.error')}</div>

  return attrData.name
}

export default GetTitle
