import { AxiosRequestConfig, AxiosResponse } from 'axios'
import merge from 'lodash.merge'
import { httpService } from 'core/data'

const BASE_API_URL = '/frontend-read/v2'
const ENTITIES_BASE = `${BASE_API_URL}/entities`

type DataQuery = Record<string, any>

type EntityRes<Values = any> = AxiosResponse<{
  data: {
    id: number
    attributes: {
      '@url': string | null
      values: Values
    }
  }
}>

type EntityCollectionRes<Values = any> = AxiosResponse<{
  data: {
    id: number
    attributes: {
      '@url': string | null
      values: Values
    }
  }[]
}>

export class VulcanClientService {
  static async getEntityById<Data = any>(
    id: number,
    query: DataQuery = {},
    config: AxiosRequestConfig = {}
  ) {
    return httpService
      .post<EntityRes<Data>>(`${ENTITIES_BASE}/${id}`, query, config)
      .then((res) => res.data.data)
  }

  static async getEntityByType<Data = any>(
    type: string,
    query: DataQuery = {},
    config: AxiosRequestConfig = {}
  ) {
    const extendedConfig: AxiosRequestConfig = {
      params: {
        entityType: type,
      },
    }

    return httpService
      .post<EntityCollectionRes<Data>>(ENTITIES_BASE, query, merge(config, extendedConfig))
      .then((res) => res.data.data[0])
  }
}
