import React, { FC, memo } from 'react'
// Custom Components
import { TextField } from 'core/form/components'
// Styles
import useStyles from './enum-option.styles'
// Material Components
import { Box, Checkbox, FormControlLabel, Icon, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type TProps = {
  index: number
  isChecked: boolean
  onDelete: (_index: number) => void
  setDefault: () => void
  isValueExist: number
}

const EnumOption: FC<TProps> = (props) => {
  const { index, isChecked, onDelete, setDefault, isValueExist } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.root}>
      <Icon className="icon-drag-in-drop" />
      <TextField
        name={`attributeEnums.${index}.value`}
        className={classes.valueField}
        label={t('labels.select-value')}
      />
      <TextField name={`attributeEnums.${index}.name`} localized label={t('labels.select-name')} />
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={setDefault}
            name="default"
            disabled={isValueExist <= 0}
          />
        }
        label={t('labels.select-def')}
        className={classes.defaultBox}
      />
      <IconButton className="icon-delete" onClick={() => onDelete(index)} />
    </Box>
  )
}

export default memo(EnumOption)
