import React, { FC } from 'react'
import { Box, Link } from '@material-ui/core'
import { Media } from '../../types'
import { formatFileSize, getFileFormat } from '../../utils'
import { MediaIcon } from '../media-icon'
import { DetailItem } from '../detail-item'
import { DisplayRelation } from '../display-relation'

type Props = {
  data: Media
}

export const FileDetails: FC<Props> = ({ data }) => {
  return (
    <>
      <Box borderRadius="borderRadius" overflow="hidden" mb={2}>
        <MediaIcon media={{ ...data, type: 'media' }} size="preview" />
      </Box>
      <DetailItem title="File name" value={data.name} />
      <DetailItem title="File type" value={getFileFormat(data.sourceUrl)} />
      <DetailItem title="File size" value={formatFileSize(data.size)} />
      <DetailItem
        title="Dimensions"
        value={`${data.dimensions.width} x ${data.dimensions.height}`}
      />
      <DetailItem title="Uploaded by" value={<DisplayRelation id={data.createdBy} />} />
      <DetailItem
        title="File url"
        value={
          <Link target="_blank" href={data.sourceUrl}>
            {data.sourceUrl}
          </Link>
        }
      />
    </>
  )
}
