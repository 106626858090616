import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setSidebarPosition } from '../store'

type UseSidebarPosition = () => any

export const useSidebarPosition: UseSidebarPosition = () => {
  const dispatch = useDispatch()

  return useCallback(
    (event: any) => {
      dispatch(setSidebarPosition(event.target.value))
    },
    [dispatch]
  )
}
