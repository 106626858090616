// Core
import React, { FC, useCallback } from 'react'
import { MenuItem, MenuList } from '@material-ui/core'
// Components
import { SmartSelectProps, TitleField, WordBreakWrapper } from 'ui'
import Actions from './actions'
// utils
import { getTitleField } from 'ui/smart-select/utils/get-title-field'
// Styles
import useStyles from './list.styles'

type Props = {
  children?: never
  data: any[]
  titleKey: TitleField
  valueField: string
  onSelect?: (item: any) => void
  selected: any
  multiple?: boolean
  selectValueField: string
  clearAfterSetValue?: boolean
  clearValue: () => void
  onEdit?: (id: number) => void
  canDelete?: boolean
  refetch?: () => void
  disabledOptions?: SmartSelectProps['disabledOptions']
}

const List: FC<Props> = (props) => {
  const classes = useStyles()
  const {
    data,
    titleKey,
    onSelect,
    selected,
    selectValueField,
    multiple,
    clearAfterSetValue,
    clearValue,
    onEdit,
    canDelete,
    refetch,
    disabledOptions,
    valueField,
  } = props

  const clickHandler = useCallback(
    (item: any) => () => {
      if (onSelect) onSelect(item)
      if (clearAfterSetValue) clearValue()
    },
    [clearAfterSetValue, clearValue, onSelect]
  )

  const isActive = useCallback(
    (item) => {
      if (!selected) return false
      if (multiple) {
        return (
          selected &&
          selected.findIndex(
            (_item: any) => _item?.[selectValueField] === item?.[selectValueField]
          ) >= 0
        )
      }
      return item[selectValueField] === selected[selectValueField]
    },
    [selected, multiple, selectValueField]
  )

  return (
    <MenuList className={classes.root}>
      {data.map((item: any) => (
        <MenuItem
          onClick={clickHandler(item)}
          selected={isActive(item)}
          key={item[selectValueField]}
          className={classes.menuItem}
          disabled={disabledOptions?.includes(item[valueField])}
        >
          <WordBreakWrapper>{getTitleField(titleKey, item)}</WordBreakWrapper>
          <Actions entityId={item.id} onEdit={onEdit} canDelete={canDelete} refetch={refetch} />
        </MenuItem>
      ))}
    </MenuList>
  )
}

export default List
