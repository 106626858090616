import React, { FC } from 'react'
import { ResourceProps } from 'core/data'
import { FormContainer } from '../index'
import { Create } from 'core/data'
import { useTypeRosourceContext } from '../../hooks'
import { useInitialLocalization } from 'core/data'
import { useAppContext } from 'core/app'

const TypeCreate: FC<ResourceProps> = (props) => {
  const context = useTypeRosourceContext()
  const resourceName = context?.options.resourceName
  const addInitialLocalization = useInitialLocalization(['name'])
  const {
    actions: { getAdminResources },
  } = useAppContext()

  const additionalEntityFields =
    resourceName === 'entity_types'
      ? {
          urlable: false,
        }
      : null

  const initialProps = {
    [context?.options.attrsName]: [],
    options: {},
    name: '',
    slug: '',
    ...additionalEntityFields,
  }

  addInitialLocalization(initialProps)

  return (
    <Create
      onSuccess={() => {
        getAdminResources()
      }}
      initialData={initialProps}
      {...props}
      crudForm={FormContainer}
    />
  )
}

export default TypeCreate
