// Core
import React, { FC } from 'react'
import { Box } from '@material-ui/core'

type Props = {}

const DeviceField: FC<Props> = () => {
  return <Box>DeviceField</Box>
}

export default DeviceField
