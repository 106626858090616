import React, { FC, useCallback } from 'react'
import nestedFormStyles from './nested-form.styles'
import { Form, Formik } from 'formik'
import { FormLang } from 'core/data'
import { CreateFields } from '../index'
import { Button, Icon } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { attributesNestedFormIsOpen, showNestedForm } from 'common/store'
import { useModalWarnings } from 'core/hooks'
import { useInitialLocalization } from 'core/data'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: object
  submitHandler: any
  initialValues?: any
}

const NestedForm: FC<TProps> = (props) => {
  const addInitialLocalization = useInitialLocalization(['name', 'hint'])
  const initialProps = {
    attributeGroups: [],
    attribute_groups_cache: [],
    options: {
      fieldWidth: 12,
    },
    defaultValues: {
      value: '',
    },
    attributeEnums: [],
    slug: '',
    type: '',
  }
  addInitialLocalization(initialProps)

  const classes = nestedFormStyles()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { submitHandler } = props
  const nestedFormIsOpen = useSelector(attributesNestedFormIsOpen)

  const closeHandler = useCallback(() => {
    dispatch(showNestedForm(false))
  }, [dispatch])

  const {
    prompt,
    methods: { showWarningModal, setRef },
  } = useModalWarnings(closeHandler, true)

  return (
    <>
      <Formik
        innerRef={setRef}
        initialValues={initialProps || {}}
        onSubmit={submitHandler}
        //
      >
        {() => (
          <Form
            id={'repeater-nested-form'}
            className={clsx(classes.root, {
              [classes.open]: nestedFormIsOpen,
            })}
          >
            <div style={{ minHeight: 'calc(100vh - 189px)' }}>
              <div className={classes.header}>
                <FormLang />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={showWarningModal}
                  startIcon={<Icon className="icon-menu-close" />}
                >
                  {t('attrs.back')}
                </Button>
              </div>
              <CreateFields nested />
            </div>

            <div className={classes.footer}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<Icon className="icon-menu-close" />}
              >
                {t('attrs.create-and-add')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {prompt}
    </>
  )
}

export default NestedForm
