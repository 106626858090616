import React, { FC } from 'react'
import { DataControllerProps } from 'core/data'
import { Grid } from '@material-ui/core'
import { TextField } from 'core/form'
import { useTranslation } from 'react-i18next'

const CrudForm: FC<DataControllerProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField name="name" type="text" label={t('labels.name')} />
      </Grid>
    </Grid>
  )
}

export default CrudForm
