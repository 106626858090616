import React, { FC, useCallback, useState } from 'react'
import { TreeView } from '@material-ui/lab'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import { FolderItem } from './folder-item'
import { MediaFolderTreeNode } from '../../types'
import { Box } from '@material-ui/core'
import { Loading } from './loading'

type Props = {
  selected?: number | null
  onSelect: (id: number) => void
  data: MediaFolderTreeNode[]
  isLoading: boolean
  onEdit: (id: number) => void
}

export const MediaFolders: FC<Props> = ({ selected, onSelect, data, isLoading, onEdit }) => {
  const [expanded, setExpanded] = useState<string[]>(['0'])

  const expandHandler = useCallback((expandId: string) => {
    setExpanded((ids) => {
      if (ids.includes(expandId)) {
        return ids.filter((id) => id !== expandId)
      }

      return [...ids, expandId]
    })
  }, [])

  if (isLoading) return <Loading />

  return (
    <Box py={1} maxHeight="inherit" height="inherit" overflow="auto">
      <TreeView
        defaultCollapseIcon={<ArrowDropDown />}
        defaultExpandIcon={<ArrowRight />}
        selected={typeof selected !== 'number' ? '' : selected.toString()}
        expanded={expanded}
      >
        {data?.map((folder) => (
          <FolderItem
            key={folder.id}
            folder={folder}
            expanded={expanded}
            onEdit={onEdit}
            onExpand={expandHandler}
            onSelect={(id) => onSelect(+id)}
          />
        ))}
      </TreeView>
    </Box>
  )
}
