import { createContext, useContext } from 'react'
import { PageBuilderContextProps } from '../types'

export const PageBuilderContext = createContext<PageBuilderContextProps>(null!)

PageBuilderContext.displayName = 'PageBuilderContext'

export const usePageBuilderContext = () => {
  const context = useContext(PageBuilderContext)

  if (!context) {
    throw new Error(`Use 'usePageBuilderContext' only in PageBuilderProvider`)
  }

  return context
}
