// Core
import React, { FC } from 'react'
import {
  TableContainer,
  Table as TableMui,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableSortLabel,
} from '@material-ui/core'
import { defaultThemeSizes } from 'core/themes/default/default'
// Hooks
import { useDataViewContext } from './data-view-context'
// Components
import ActionsColumn from './actions-column'
import Column from './column'
import RowsSkeleton from './rows-skeleton'

type Props = {
  children?: never
}

const tableListOffset = [
  // App bar
  defaultThemeSizes.appBarHeight,
  // Page header
  defaultThemeSizes.pageHeaderHeight + 1,
  // Table paddings
  16,
  // Table header 1
  57,
  // Table header 2
  54,
  // Table footer
  57,
].reduce((result, item) => result + item, 0)

const Table: FC<Props> = () => {
  const { columnsData, rowsData, sorting, changeSorting, isDataLoading } = useDataViewContext()

  return (
    <TableContainer
      style={{
        maxHeight: `calc(100vh - ${tableListOffset}px)`,
      }}
    >
      <TableMui stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell width={104}>Actions</TableCell>
            {columnsData.map((column) => (
              <TableCell align="left" key={column.attrIri} style={{ maxWidth: 200 }}>
                <TableSortLabel
                  onClick={() => changeSorting(column)}
                  active={sorting?.column === column.slug}
                  direction={sorting?.direction}
                >
                  {column.title}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsData.map((row) => (
            <TableRow hover key={row['@id']}>
              <ActionsColumn rowData={row} />
              {columnsData.map((column) => (
                <Column key={column.attrIri} rowData={row} columnData={column} />
              ))}
            </TableRow>
          ))}
          {isDataLoading && <RowsSkeleton columnsCount={columnsData.length + 1} show />}
        </TableBody>
      </TableMui>
    </TableContainer>
  )
}

export default Table
