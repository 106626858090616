import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      paddingTop: '8px',
      '&.loader': {
        minHeight: 90,
      },
    },
    listItem: {
      margin: '3px 0',
      paddingTop: '2px',
      paddingBottom: '2px',
      cursor: 'default',
      '&.Mui-selected': {
        background: '#F4F6F8',
        borderRadius: '6px',
        '&:hover': {
          backgroundColor: '#F4F6F8',
        },
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    iconButton: {
      padding: '5px',
    },
  })
)
