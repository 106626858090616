// Core
import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { Permissions, useAppContext } from 'core/app'
// Types
import { ResourceProps } from 'core/data'
// Components
import PageHeader from 'modules/layout/components/page-header'
import { CreateTranslation, Pagination, TranslationTable } from './components'
import { TranslationProvider } from '../../context'

type TProps = {} & ResourceProps

export const EditLocalization: FC<TProps> = (props) => {
  const {
    actions: { userCan },
  } = useAppContext()

  return (
    <TranslationProvider>
      <PageHeader titleTransKey="translations" backPath="/translations" />
      <Box m={2} p={2} bgcolor="common.white">
        {userCan('translations', Permissions.CREATE, 'system') && <CreateTranslation />}

        <TranslationTable />
        <Pagination />
      </Box>
    </TranslationProvider>
  )
}
