import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const globalAttributesListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      overflowX: 'hidden',
    },
    searchEmpty: {
      padding: theme.spacing(2),
    },
    spiner: {
      display: 'flex',
      'justify-content': 'center',
      width: '100%',
      padding: theme.spacing(5),
    },
  })
)

export default globalAttributesListStyles
