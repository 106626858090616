import React, { FC } from 'react'
// Material Components
import { Container, Icon } from '@material-ui/core'
// Styles
import layoutStaticContainerStyles from './static-containers.styles'
import layoutGeneralStyles from '../../form-container.styles'
//
import clsx from 'clsx'

type TProps = {
  name: string
  isActive: boolean
  toggleStaticContainer(event: any): void
}

const LayoutStaticContainer: FC<TProps> = (props) => {
  const { name, isActive, toggleStaticContainer } = props
  const classes = layoutStaticContainerStyles()
  const classesGeneral = layoutGeneralStyles()

  return (
    <Container
      maxWidth={false}
      className={clsx(classes.root, isActive && classes.activeElement)}
      onClick={toggleStaticContainer}
    >
      <div className={classesGeneral.hint}>
        {isActive ? <Icon className="icon-check-square" /> : <Icon className="icon-square" />}
        {name}
      </div>
    </Container>
  )
}

export default LayoutStaticContainer
