import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const { purpleLight } = defaultThemeColors

const layoutSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#E0E0E0',
      border: `1px dashed ${purpleLight}`,
      borderRadius: '2px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      padding: theme.spacing(3),
      margin: theme.spacing(0, 0, 7),
    },
    rootWrapper: {
      width: '100%',
      display: 'flex',
      position: 'relative',
    },
    containerWrapper: {
      border: '1px dashed #B0BEC5',
      minHeight: '120px',
      padding: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      cursor: 'pointer',
    },

    // active Section
    activeSection: {
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
      background: purpleLight,
      border: '1px solid #9793DD',
      '& $containerWrapper': {
        border: '1px dashed #5850EC',
      },
      '& $sidebarHolder': {
        border: '1px dashed #5850EC',
        borderLeft: 'none',
      },
      '& $leftDirection': {
        '& $sidebarHolder': {
          borderLeft: '1px dashed #5850EC',
        },
      },
    },

    // clicker
    clicker: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },

    // inside container
    addContainerIcoWrap: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      order: 1,
    },
    addContainerIco: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: '#3F51B5',
      '& svg': {
        color: '#3F51B5',
      },
    },

    // drag
    dragWrapper: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      '& > span': {
        position: 'absolute',
        pointerEvents: 'all',
        fontSize: '24px',
        '&:nth-child(1)': {
          top: 0,
          left: 'calc(50% - 12px)',
        },
        '&:nth-child(2)': {
          top: 'calc(50% - 12px)',
          right: 0,
        },
        '&:nth-child(3)': {
          bottom: 0,
          left: 'calc(50% - 12px)',
        },
        '&:nth-child(4)': {
          top: 'calc(50% - 12px)',
          left: 0,
        },
      },
    },

    // directions
    leftDirection: {
      flexDirection: 'row-reverse',
      '& $sidebarHolder': {
        borderRight: 'none',
        borderLeft: '1px dashed #B0BEC5',
      },
    },

    // sidebar
    sidebarHolder: {
      padding: theme.spacing(1),
      border: '1px dashed #B0BEC5',
      borderLeft: 'none',
      '& > div': {
        height: 'calc(100% - 84px)',
        '& > div': {
          height: '100%',
          '& > div': {
            height: '100%',
          },
        },
      },
    },
    sidebarContainer: {
      margin: theme.spacing(1, 0),
      display: 'flex',
      padding: theme.spacing(0, 1),
      height: 'calc(100% - 20px)',
    },
  })
)

export default layoutSectionStyles
