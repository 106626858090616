import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import React, { FC } from 'react'

type Props = {
  isLoading?: boolean
} & ButtonProps

export const MutationButton: FC<Props> = (props) => {
  const { isLoading, startIcon, disabled, variant = 'contained', ...buttonProps } = props

  return (
    <Button
      variant={variant}
      disabled={isLoading || disabled}
      startIcon={
        <>
          {startIcon}
          {isLoading && <CircularProgress size={15} />}
        </>
      }
      {...buttonProps}
    />
  )
}
