// Core
import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
// Components
import { DataView } from 'core/data'
import DataViewNew from '../data-view'
import { DefaultActionColumns, getActions, getTableColumn } from 'core/data/components/data-view'
// Hooks
import { useTranslation } from 'react-i18next'
import { Permissions, useAppContext } from 'core/app'
// Types
import { EavResourceType } from 'modules/new-entity/types'

type Props = {
  children?: never
  resourceType: EavResourceType
}

const EntitiesList: FC<Props> = (props) => {
  const { resourceType } = props
  const { typeId } = useParams<{ typeId: string }>()
  const { t } = useTranslation()
  const {
    actions: { userCan, getResource },
  } = useAppContext()

  const resourceTypeName = useMemo(() => {
    const name = {
      camel: '',
      snake: '',
      res: '',
    }

    switch (resourceType) {
      case EavResourceType.ENTITY: {
        name.camel = 'entityType'
        name.snake = 'entity_types'
        name.res = EavResourceType.ENTITY
        break
      }
      case EavResourceType.TEMPLATE: {
        name.camel = 'entityType'
        name.snake = 'entity_types'
        name.res = EavResourceType.ENTITY
        break
      }
      case EavResourceType.WIDGET: {
        name.camel = 'widgetType'
        name.snake = 'widget_types'
        name.res = EavResourceType.WIDGET
      }
    }

    return name
  }, [resourceType])

  const resource = getResource(typeId, resourceTypeName.camel)

  const filters = useMemo(() => {
    const f = {
      [resourceTypeName.camel]: `/api/${resourceTypeName.snake}/${typeId}`,
      hidden: false,
    }

    if (resourceType === EavResourceType.TEMPLATE) {
      f.template = true
    }

    return { ...f }
  }, [resourceType, resourceTypeName, typeId])

  if (resourceType === EavResourceType.ENTITY) {
    return <DataViewNew typeId={Number(typeId)} key={typeId} />
  }

  return (
    <DataView
      resourceProps={{
        name: resourceTypeName.res,
        resourceType,
      }}
      controllerOptions={{
        filters,
      }}
      columns={[
        getActions(
          [
            userCan(
              resource?.slug ? resource?.slug : '',
              Permissions.DELETE,
              resourceTypeName.camel
            )
              ? DefaultActionColumns.DELETE
              : -1,
            userCan(resource?.slug ? resource?.slug : '', Permissions.EDIT, resourceTypeName.camel)
              ? DefaultActionColumns.EDIT
              : -1,
          ],
          t
        ),
        getTableColumn({ field: 'name' }),
        getTableColumn({ field: 'personalized', title: 'Personalized' }, 'boolean'),
      ]}
    />
  )
}

export default EntitiesList
