import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginLeft: 'auto',
    },
    active: {
      color: '#5850EC',
    },
  })
)

export default styles
