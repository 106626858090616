import React from 'react'
import { SmartSelectField } from 'core/form'
import { useTranslation } from 'react-i18next'

const SelectMediaSizes = () => {
  const { t } = useTranslation()

  return (
    <SmartSelectField
      multiple={true}
      valueField={'@id'}
      source={'media_sizes'}
      name={'mediaSizes'}
      label={t('media.select')}
    />
  )
}

export default SelectMediaSizes
