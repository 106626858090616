// Core
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { stringify } from 'query-string'
// Utils
import { getQuery, parseQueryFromLocation } from 'core/data'
import { isEmpty } from 'core/utils'
// Types
import { ListParamsMeta, ListParamsProps } from '../types'

type UseListParams = (
  filters: any,
  setReqEnabled?: (value: boolean) => void,
  filterChangeUrl?: boolean
) => ListParamsProps

export const useListParams: UseListParams = (filters, setReqEnabled, filterChangeUrl) => {
  const location = useLocation()
  const history = useHistory()
  const [meta, setMeta] = useState<ListParamsMeta>({
    total: 0,
  })
  const queryFromLocation = parseQueryFromLocation(location)
  const requestSignature = [location.search]
  const [filtersData, setFiltersData] = useState<any>({ ...filters })
  const [sortData, setSortData] = useState<any>({})
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 1,
    perPage: 50,
  })

  const params = useMemo(() => {
    return {
      filters: filtersData,
      pagination,
      sort: sortData,
    }
  }, [filtersData, pagination, sortData])

  const query = useMemo(
    () =>
      getQuery({
        queryFromLocation,
        params,
      }),
    [requestSignature, params] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setFiltersData({ ...filters })
      setPagination((prev) => ({
        ...prev,
        page: 1,
      }))
    }
  }, [filters])

  // get location params
  useEffect(
    () => {
      if (Object.keys(query).length > 0) {
        setFiltersData((prev: any) => ({
          ...prev,
          ...query?.filters,
        }))
        setPagination(query?.pagination)
        setSortData(query?.sort)
      }

      if (setReqEnabled) {
        setReqEnabled(true)
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const changeUrl = useCallback(
    (newParams: any) => {
      const { pathname } = location
      if (!isEmpty(newParams)) {
        history.push({
          search: `?${stringify({
            filters: JSON.stringify(newParams),
          })}`,
        })
      } else {
        history.push(`${pathname}`)
      }
    },
    [history, location]
  )

  const setPage = (page: number) => {
    setPagination((prev) => ({
      ...prev,
      page,
    }))
  }

  const setSort = useCallback(
    (sort: any) => {
      setSortData(sort)
      changeUrl(sort)
    },
    [changeUrl]
  )

  const setFilter = useCallback(
    (filterName: string, value: any, changeLocation: boolean = true) => {
      const newParams = {
        ...params.filters,
        [filterName]: value,
      }

      if (filterName === 'search') {
        setFiltersData({ ...newParams })
        setPagination((prev) => ({
          ...prev,
          page: 1,
        }))
      } else {
        setFiltersData({ ...newParams })
      }

      if (changeLocation && filterChangeUrl) changeUrl(newParams)
    },
    [params, changeUrl, filterChangeUrl]
  )
  const removeFilter = useCallback(
    (filterName: string, changeLocation: boolean = true) => {
      if (params.filters) {
        const filteredParams = Object.keys(params.filters)
          .filter((filter) => filter !== filterName)
          .reduce((obj: any, key: string) => {
            obj[key] = params.filters[key]
            return obj
          }, {})
        setFiltersData({ ...filteredParams })

        if (changeLocation && filterChangeUrl) changeUrl(filteredParams)
      }
    },
    [params, changeUrl, filterChangeUrl]
  )

  return {
    params,
    meta,
    methods: {
      setPage,
      setFilter,
      setSort,
      removeFilter,
      setMeta,
    },
  }
}
