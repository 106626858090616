import React, { FC, memo } from 'react'
import useStyles from './select-preview.styles'
import { Attribute } from 'core/types/attribute'
import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type TProps = {
  attrData: Attribute
}

const SelectPreview: FC<TProps> = (props) => {
  const classes = useStyles()
  const { attrData } = props
  const { t } = useTranslation()
  const { attributeEnums } = attrData

  const isChecked = (_value: string) => {
    if (!attrData.defaultValues.value) return false
    return attrData.defaultValues.value.includes(_value)
  }

  if (attributeEnums.length) {
    return (
      <>
        <Typography className={classes.title} variant={'body2'}>
          {attrData.attributeType.name} option items:
        </Typography>

        {attributeEnums.map((item: any) => {
          return (
            <div className={classes.optionItem} key={item.id}>
              <TextField
                className={classes.field}
                disabled
                value={item.name}
                variant={'outlined'}
                label={t('labels.name')}
              />
              <TextField
                className={classes.field}
                disabled
                value={item.value}
                variant={'outlined'}
                label={t('labels.value')}
              />
              <FormControlLabel
                control={<Checkbox checked={isChecked(item.value)} name="default" disabled />}
                label={t('labels.default')}
              />
            </div>
          )
        })}
      </>
    )
  }

  return null
}

export default memo(SelectPreview)
