import { useCallback } from 'react'
import { useField } from 'formik'

export const useRemoveAttribute = () => {
  const [{ value }, , { setValue }] = useField('setAttributes')
  return useCallback(
    (_index: number) => () => {
      value.splice(_index, 1)
      setValue(value)
    },
    [value, setValue]
  )
}
