import { Section } from 'core/types/layouts'
// utils
import { createSectionTemplate } from './get-section-template'

export const getDynamicLayout = (
  staticSections: Section[],
  dynamicLayoutSections: Section[]
): Section[] => {
  const containers = staticSections.map((sections) => sections.containers).flat()

  const dynamicSections = containers.map((container, order) =>
    createSectionTemplate(order + 1, container.id, dynamicLayoutSections[order]?.options.background)
  )

  return dynamicSections
}
