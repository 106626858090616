// Core
import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
// Components
import ResultItem from '../result-item/result-item'
// Types
import { SearchResult } from '../types'

type Props = {
  data: SearchResult[]
  searchTerm: string
  filters: any
}

const Results: FC<Props> = (props) => {
  const { data, searchTerm, filters } = props

  if (data.length === 0) {
    return (
      <Box textAlign="center" mb={4}>
        <Typography variant="h6">No results found</Typography>
        <Typography>Try another keyword or adjust your filters</Typography>
      </Box>
    )
  }

  return (
    <Box>
      {data.map((item) => (
        <ResultItem key={item.id} data={item} searchTerm={searchTerm} filters={filters} />
      ))}
    </Box>
  )
}

export default Results
