// Core
import { useCallback, useState } from 'react'
import { isAxiosError } from 'axios'
// Hooks
import { useAppContext } from 'core/app'
import { useNotify } from 'core/hooks'
// Services
import AuthService from '../services/auth-service'
// Types
import {
  ExpiredUserSession,
  LoginPayload,
  MfaData,
  ForgotPasswordPayload,
  ChangePasswordPayload,
  LoginFinishPayload,
} from '../types'

type Params = {
  onSuccessLogin?: (token: string, refreshToken: string) => void
}

export default function useLoginController(params: Params = {}) {
  const { onSuccessLogin } = params
  const notify = useNotify()

  const {
    actions: { login: launchAppWithUser },
  } = useAppContext()

  /**
   * Login
   */
  const [mfaState, setMfaState] = useState<MfaData | null>(null)
  const mfaOpen = Boolean(mfaState)
  const closeMfa = useCallback(() => setMfaState(null), [])

  const [expiredUserSession, setExpiredUserSession] = useState<ExpiredUserSession | null>(null)
  const passwordExpired = Boolean(expiredUserSession)
  const closePasswordExpired = useCallback(() => setExpiredUserSession(null), [])

  const loginFinish = async (data: LoginFinishPayload) => {
    if (data.password_expired) {
      const meRes = await AuthService.getCurrentUserByToken(data.token)
      closeMfa()

      setExpiredUserSession({
        userId: meRes.data.id,
        token: data.token,
      })
    } else if (onSuccessLogin) {
      onSuccessLogin(data.token, data.refresh_token)
    } else {
      launchAppWithUser(data.token, data.refresh_token)
    }
  }

  const login = async (data: LoginPayload) => {
    const loginRes = await AuthService.loginByEmail(data)

    const { enabled2fa } = loginRes.data

    if (enabled2fa) {
      setMfaState(loginRes.data)
    } else {
      await loginFinish(loginRes.data)
    }
  }

  const login2FA = async (authCode: string) => {
    if (!mfaState) {
      throw new Error('Something went wrong')
    }
    const loginRes = await AuthService.login2FA({ authCode, token: mfaState.token })

    await loginFinish(loginRes.data)
  }

  const changePasswordExpired = async (data: ChangePasswordPayload) => {
    if (!expiredUserSession) {
      throw new Error('Something went wrong')
    }
    try {
      await AuthService.changeUserPasswordExpired(
        expiredUserSession.userId,
        expiredUserSession.token,
        data
      )
      notify('The password has been changed', { type: 'success' })
      closePasswordExpired()
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 401) {
        notify('Session expired, please try again', { type: 'warning' })
        closePasswordExpired()
      }
      throw error
    }
  }

  /**
   * Reset password
   */
  const [resetOpen, setResetOpen] = useState(false)
  const [resetInitialEmail, setResetInitialEmail] = useState('')

  const openResetModal = useCallback(
    (email?: string) => {
      closePasswordExpired()
      setResetInitialEmail(email || '')
      setResetOpen(true)
    },
    [closePasswordExpired]
  )

  const resetPassword = async (data: ForgotPasswordPayload) => {
    try {
      await AuthService.forgotPassword(data)
      setResetOpen(false)
      notify('Email sent successfully', { type: 'success' })
    } catch (e) {
      notify('Something went wrong', { type: 'error' })
    }
  }

  return {
    login,
    mfaOpen,
    closeMfa,
    mfaState,
    login2FA,
    passwordExpired,
    closePasswordExpired,
    expiredUserSession,
    resetOpen,
    openResetModal,
    setResetOpen,
    resetInitialEmail,
    resetPassword,
    changePasswordExpired,
  }
}
