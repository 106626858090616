import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import searchStyles from './search.styles'
import { Button, Icon, TextField } from '@material-ui/core'
import { useListContext } from 'core/data'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: object
}

const Search: FC<TProps> = (props) => {
  const classes = searchStyles()
  const { t } = useTranslation()
  const {
    listParams: { methods },
  } = useListContext()

  const [value, setValue] = useState('')

  useEffect(() => {
    return () => {
      setValue('')
      methods.removeFilter('search')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      e.preventDefault()
      methods.setFilter('search', value)
    }
  }

  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  const searchHandler = useCallback(() => {
    methods.setFilter('search', value)
  }, [value, methods])

  const clearHandler = useCallback(() => {
    setValue('')
    methods.removeFilter('search')
  }, [methods])

  return (
    <div className={classes.root}>
      <TextField
        size="small"
        id="entity_attributes"
        placeholder={`${t('list.search-by')} ${t('global.name')}`}
        // label="Search by id"
        value={value}
        variant="outlined"
        name="entity_attributes_search"
        onChange={changeHandler}
        onKeyPress={onKeyDown}
        InputProps={{
          startAdornment: <Icon className="icon-search-default" />,
        }}
      />
      <Button color="primary" variant="outlined" className={classes.button} onClick={searchHandler}>
        {t('list.search')}
      </Button>
      <Button variant="outlined" color="primary" className={classes.button} onClick={clearHandler}>
        {t('list.clear')}
      </Button>
      {value.length !== 0 && (
        <div className={classes.filterNote}>
          {t('list.filter-by')} "{value}"
        </div>
      )}
    </div>
  )
}

export default Search
