import { useQuery } from 'react-query'
import { httpService } from 'core/data'

export const useGetLayouts = () => {
  return useQuery(
    'layouts',
    async () => {
      const res = await httpService.get<any>('/layouts')

      return res.data['hydra:member']
    },
    {}
  )
}
