import { SelectField } from 'core/components'
import React, { FC } from 'react'

type TProps = {
  data: any
  value?: string
}

const SelectConditionField: FC<TProps> = (props) => {
  const {
    data: { attributeEnums },
    value,
  } = props

  return <SelectField data={attributeEnums} name={'conditionValue'} value={value} />
}

export default SelectConditionField
