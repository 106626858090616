import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spiner: {
      display: 'flex',
      'justify-content': 'center',
      width: '100%',
      padding: theme.spacing(20),
    },
  })
)
