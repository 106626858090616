import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const containerStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      padding: theme.spacing(1),
      borderRadius: 4,
    },
    btnActive: {
      background: '#E6EBF0',
      color: theme.palette.primary.main,
    },
    popover: {
      marginTop: theme.spacing(0.5),
    },
  })
)

export default containerStyles
