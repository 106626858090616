// Core
import React, { FC } from 'react'
import { Box } from '@material-ui/core'
// Context
import { RedirectsProvider } from 'modules/redirects'
// Components
import { CreateRedirect, RedirectsTable, TableHeader } from 'modules/redirects'
import PageHeader from 'modules/layout/components/page-header'

type Props = {
  children?: never
}

const RedirectsPage: FC<Props> = () => {
  return (
    <RedirectsProvider>
      <PageHeader titleTransKey="redirects" />
      <Box p={2} m={2} bgcolor="common.white">
        <CreateRedirect />
        <TableHeader />
        <RedirectsTable />
      </Box>
    </RedirectsProvider>
  )
}

export default RedirectsPage
