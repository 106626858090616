// Core
import { Icon, IconButton, InputBase, Paper } from '@material-ui/core'
// Hooks
import { useListContext } from './context'
import React, { FC } from 'react'
// Styles
import { useStyles } from './styles'

type Props = {
  children?: never
}

const Search: FC<Props> = () => {
  const classes = useStyles()
  const {
    listProps: { search, setSearchFilter },
  } = useListContext()

  return (
    <Paper className={classes.searchRoot}>
      <InputBase
        value={search}
        onChange={(e) => setSearchFilter(e.target.value)}
        className={classes.searchInput}
        placeholder="Search..."
        endAdornment={
          <IconButton size="small" onClick={() => setSearchFilter('')}>
            <Icon className="icon-x" />
          </IconButton>
        }
      />
    </Paper>
  )
}

export default Search
