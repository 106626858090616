// Core
import React, { FC } from 'react'
//
import clsx from 'clsx'
import useStyles from './group-line.styles'

type TProps = {
  children: any
}

const GroupLine: FC<TProps> = (props) => {
  const { children } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrap, classes.flex)}>{children}</div>
    </div>
  )
}

export default GroupLine
