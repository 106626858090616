import React, { FC } from 'react'
import { TreeItem } from '@material-ui/lab'
import { IconButton, makeStyles } from '@material-ui/core'
import { Edit, Folder, FolderOpen } from '@material-ui/icons'
import { MediaFolderTreeNode } from '../../types'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      '& > .MuiTreeItem-content': {
        backgroundColor: theme.palette.grey[100],
      },
      '& > .MuiTreeItem-content > .MuiTreeItem-label': {
        backgroundColor: 'transparent',
      },
      '&:hover, &:focus > .MuiTreeItem-content > .MuiTreeItem-label': {
        backgroundColor: 'transparent',
      },
    },
  },
  itemContent: {
    position: 'relative',
    padding: theme.spacing(0.5, 0),
  },
  label: {
    position: 'unset',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: 'calc(100% - 43px)',
    paddingLeft: 0,
    '& > span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:hover': {
      '& .action': {
        opacity: 1,
      },
    },
  },
  action: {
    position: 'absolute',
    right: 0,
    borderRadius: 0,
    opacity: 0,
  },
  expandIcon: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}))

type Props = {
  folder: MediaFolderTreeNode
  expanded: string[]
  onEdit: (id: number) => void
  onExpand: (id: string) => void
  onSelect: (id: string) => void
}

export const FolderItem: FC<Props> = ({ folder, expanded, onEdit, onExpand, onSelect }) => {
  const classes = useStyles()

  return (
    <TreeItem
      nodeId={folder.id.toString()}
      onIconClick={() => onExpand(folder.id.toString())}
      onLabelClick={() => onSelect(folder.id.toString())}
      classes={{
        content: classes.itemContent,
        label: classes.label,
        iconContainer: classes.expandIcon,
      }}
      className={classes.root}
      label={
        <>
          {expanded.includes(folder.id.toString()) ? <Folder /> : <FolderOpen />}
          <span title={folder.name}>{folder.name}</span>
          {folder.parent && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                onEdit(folder.id)
              }}
              className={clsx(classes.action, 'action')}
            >
              <Edit fontSize="inherit" />
            </IconButton>
          )}
        </>
      }
    >
      {folder.children?.map((childFolder) => (
        <FolderItem
          key={childFolder.id}
          folder={childFolder}
          expanded={expanded}
          onEdit={onEdit}
          onExpand={onExpand}
          onSelect={onSelect}
        />
      ))}
    </TreeItem>
  )
}
