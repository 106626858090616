// Core
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import { TablePagination } from './table-pagination'
import { useDataViewContext } from './data-view-context'

type Props = {
  children?: never
}

const TableFooter: FC<Props> = () => {
  const { page, setPage, totalItems, perPage, isDataLoading, changePerPage } = useDataViewContext()

  const count = useMemo(() => {
    if (perPage === 'All') return 0
    return Math.ceil(totalItems / perPage)
  }, [perPage, totalItems])

  const [prevState, setPrevState] = useState({
    count,
    page,
    totalItems,
  })

  useEffect(() => {
    if (!page || !count || !totalItems) return

    setPrevState({ count, page, totalItems })
  }, [count, page, totalItems])

  const combinedCount = isDataLoading ? prevState.count : count
  const combinedPage = isDataLoading ? prevState.page : page
  const combinedTotalItems = isDataLoading ? prevState.totalItems : totalItems

  return (
    <Box
      py={1}
      px={1}
      borderTop={1}
      style={{ borderColor: '#e0e0e0' }}
      height={57}
      display="flex"
      alignItems="center"
    >
      <TablePagination
        page={combinedPage}
        totalCount={combinedTotalItems}
        pagesCount={combinedCount}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={changePerPage}
        disabled={isDataLoading}
      />
    </Box>
  )
}

export default TableFooter
