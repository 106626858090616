// Core
import React, { FC, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
// Components
// Types
import ActionBase from '../action-base'
import { Icon } from '@material-ui/core'

type Props = {
  rowData: any
}

const EditAction: FC<Props> = (props) => {
  const { rowData } = props
  const history = useHistory()
  const { typeId } = useParams()

  const clickHandler = useCallback(() => {
    const path = rowData.widgetType ? 'widgets' : rowData.template ? 'templates' : 'entities'

    history.push(`/${path}/${typeId}/edit/${rowData.id}`)
  }, [rowData, history, typeId])

  return (
    <ActionBase rowData={rowData} onClick={clickHandler} icon={<Icon className="icon-edit" />} />
  )
}

export default EditAction
