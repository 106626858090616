import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const WidgetTypesModalStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDialog-paperScrollPaper': {
        minHeight: 'calc(100vh - 64px)',
      },
    },
    searchWrap: {
      padding: theme.spacing(0, 3),
    },
  })
)

export default WidgetTypesModalStyle
