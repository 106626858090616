import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const attributeFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 0, 3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    items: {
      width: '100%',
      '& > div': {
        margin: theme.spacing(0.5, 1, 0.5, 0),
        maxWidth: 'calc(50% - 8px - 60px)',
        '& svg': {
          position: 'absolute',
          left: 0,
          '& + span': {
            padding: '0 14px 0 34px',
          },
        },
        '& span': {
          padding: '0 24px',
        },
      },
    },
    reset: {
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      '&:hover': {
        color: 'red',
      },
    },
  })
)

export default attributeFilterStyles
