import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { GetTitle } from 'core/components'
import { Field, FieldProps } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectItem } from '..'

type TProps = {
  data: any
  formProps: any
}

const SelectAttribute: FC<TProps> = (props) => {
  const { data, formProps } = props
  const { t } = useTranslation()

  return (
    <Field as="select" name="dependsOn">
      {({ field, meta }: FieldProps) => {
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="choose_attribute-select">{t('attrs.choose')}</InputLabel>
            <Select
              {...field}
              onChange={(event: any) => {
                field.onChange(event)
                formProps.setFieldValue('conditionValue', '')
              }}
              value={field.value || ''}
              error={!!meta.error}
              label={t('attrs.choose')}
              id="choose_attribute-select"
              renderValue={(value) => (value ? <GetTitle value={`${value}`} /> : null)}
            >
              <MenuItem value="">
                <em>{t('select.empty-value')}</em>
              </MenuItem>
              {data.map((item: any) => (
                <SelectItem key={item.attribute} value={item.attribute} attrIri={item.attribute} />
              ))}
            </Select>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default SelectAttribute
