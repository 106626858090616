import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { EntityType } from 'core/types'
import { getIdFromIri } from 'core/utils'
import QuickMutate from '../components/quick-create'
import { useTypePermissions } from 'modules/new-entity/hooks'

type Actions = {
  createHandler?: () => void
  editHandler?: (id: number) => void
  deleteHandler?: (id: number) => void
  canDelete?: boolean
}

export const useActions = (entityTypeIri: string | undefined, refetch: () => void) => {
  const [crateOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState<number | null>(null)

  const entityTypeQ = useQuery(
    `et-${entityTypeIri}`,
    () => {
      if (!entityTypeIri) {
        throw new Error('Id not exist')
      }

      const id = getIdFromIri(entityTypeIri)
      return httpService.get<EntityType>(`/entity_types/${id}`).then((res) => res.data)
    },
    {
      enabled: Boolean(entityTypeIri),
    }
  )

  // @ts-ignore
  const permissions = useTypePermissions(entityTypeQ?.data)

  const actions = useMemo(() => {
    const hasActions = !entityTypeIri || !entityTypeQ.data?.urlable
    const actions: Actions = {}

    if (!hasActions) return actions

    if (permissions.canCreate) {
      actions.createHandler = () => setCreateOpen(true)
    }

    if (permissions.canEdit) {
      actions.editHandler = (id: number) => setEditOpen(id)
    }

    if (permissions.canDelete) {
      actions.canDelete = permissions.canDelete
    }

    return actions
  }, [entityTypeQ.data, entityTypeIri, permissions])

  const entityModal = useMemo(() => {
    if (!entityTypeIri) return null

    const isOpen = crateOpen || Boolean(editOpen)
    const isEdit = Boolean(editOpen)

    if (isOpen) {
      return (
        <QuickMutate
          entityId={editOpen as number}
          isOpen={isOpen}
          setIsOpen={() => {
            setCreateOpen(false)
            setEditOpen(null)
          }}
          entityTypeIri={entityTypeIri}
          isEdit={isEdit}
          onSuccess={() => {
            refetch()
          }}
        />
      )
    }
    return null
  }, [crateOpen, entityTypeIri, editOpen, refetch])

  return { actions, entityModal }
}
