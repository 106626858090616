// Core
import { useContext } from 'react'
// Context
import { RedirectsContext } from '../context'
// Types
import { RedirectsContextProps } from '../types'

export const useRedirectsContext = () => {
  const context = useContext<RedirectsContextProps>(RedirectsContext)
  return context
}
