import { makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const colorText = '#263238'

const useStyles = makeStyles((theme: Theme) => ({
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loginBackground: {
    position: 'relative',
    width: '60%',
    order: 1,
    overflow: 'hidden',
    height: '100vh',
    '& img': {
      maxHeight: '100vh',
      height: '100vh',
      width: '100%',
      objectFit: 'cover',
    },
  },
  loginForm: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '80%',
    padding: '0 5%',
    boxSizing: 'border-box',
  },
  loginBgText: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '72px',
    fontWeight: 900,
    lineHeight: '84px',
    textAlign: 'left',
    color: '#fff',
    width: '65%',
    left: '10%',
  },
  logo: {
    maxWidth: '185px',
    width: '100%',
    transform: 'translateY(calc(100vh * -0.1))',
  },
  loginTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: colorText,
    marginBottom: theme.spacing(3),
  },
  loginSubTitle: {
    color: colors.blueGrey[600],
  },
  footer: {
    marginTop: theme.spacing(2),
    '& > p': {
      color: colorText,
    },
  },
}))

export default useStyles
