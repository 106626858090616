import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { addSection } from '../store'

type UseAddSection = () => any

export const useAddSection: UseAddSection = () => {
  const dispatch = useDispatch()

  return useCallback(
    (insertPlace: number) => {
      dispatch(addSection(insertPlace))
    },
    [dispatch]
  )
}
