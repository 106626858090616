import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalTypes, confirmSelector, cancelAction, confirmAction } from '../store/modal'

type UseModalContainer = {
  closeHandler: () => void
  confirmHandler: () => void
} & ModalTypes.IModalState

export const useModalContainer = (): UseModalContainer => {
  const state = useSelector(confirmSelector)
  const dispatch = useDispatch()

  const closeHandler = useCallback(() => {
    dispatch(cancelAction())
  }, [dispatch])

  const confirmHandler = useCallback(() => {
    dispatch(confirmAction())
  }, [dispatch])

  return { ...state, closeHandler, confirmHandler }
}
