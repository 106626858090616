import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const { contentBg } = defaultThemeColors

const containerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    wrap: {
      border: '1px dashed #BDBDBD',
      borderRadius: '2px',
      padding: theme.spacing(3),
      position: 'relative',
      backgroundColor: contentBg,

      '&:not($isDraggingOver)&:hover, &$isDropping': {
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
      },
    },
    isDragging: {
      borderColor: 'transparent',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
    },
    isDraggingOver: {},
    isDropping: {},
    topPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(1.5),
    },
    info: {
      display: 'flex',
      alignItems: 'center',
    },
    dndBtn: {
      marginRight: theme.spacing(1.5),
      '& span': {
        display: 'block',
      },
    },
    dndBtnDisabled: {
      opacity: 0.5,
    },
    hidden: {
      display: 'none',
    },
    toggleBtn: {
      marginRight: theme.spacing(1.5),
      padding: 0,

      '&:hover': {
        background: 'transparent',
      },
    },
    title: {
      fontSize: '1rem',
      lineHeight: '22px',
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
    settings: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    bg: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

export default containerStyles
