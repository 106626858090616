import { ReactNode, useCallback } from 'react'
import { toast, ToastOptions } from 'react-toastify'

interface INotifyFunc {
  (text: ReactNode, options?: ToastOptions): void
}

export const useNotify = (): INotifyFunc => {
  const notify = useCallback<INotifyFunc>((text, options?) => {
    toast(text, options)
  }, [])

  return notify
}
