// Core
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import produce from 'immer'
import cloneDeep from 'lodash.clonedeep'
// Hooks
import { useSitesContext } from '../../sites'
import {
  DefaultValuesGenerator,
  EntityWidget,
  ValuesGenerator,
} from 'modules/new-entity/transformers'
// Utils
import { dragEndEvent } from 'common/utils/custom-events'
import { EntityService } from '../utils'
import { createSectionTemplate } from '../utils'
import { getDynamicLayout } from '../utils/get-dynamic-sections'
import { getIdFromIri } from 'core/utils'
import { reorderContainers } from '../utils/reorder-containers'
// Types
import {
  EntitySettings,
  IEntity,
  PageBuilderContextProps,
  SetEntityOptionFunc,
  THEME_TWO_COLOR_SCHEME,
  WidgetsDataRef,
} from '../types'
import { IEntityWidget } from 'modules/new-entity/types'
import { Layout } from 'core/types/layouts'
import { useQuery } from 'react-query'
import { httpService } from 'core/data'

type PBP = PageBuilderContextProps
type Actions = PBP['actions']

export const useWidgetsController = (
  entity: IEntity,
  entitySettings: EntitySettings,
  setEntitySettings: SetEntityOptionFunc,
  isLoading: boolean,
  colorSchemeValue: string,
  builder: boolean
): PageBuilderContextProps => {
  const { locales } = useSitesContext()
  const localizations = locales.site
  const [widgets, setWidgets] = useState(entity?.entityWidgets || [])
  const [activeWidgets, setActiveWidgets] = useState<PBP['activeWidgets']>({})

  const widgetsDataRef = useRef<WidgetsDataRef>({})

  useEffect(() => {
    if (entity?.entityWidgets) {
      setWidgets(entity.entityWidgets)
    }
  }, [entity?.entityWidgets])

  const isWidgetExpanded = useCallback<Actions['isWidgetExpanded']>(
    (container, widget) => {
      return activeWidgets[container] && activeWidgets[container].includes(widget)
    },
    [activeWidgets]
  )

  const setWidgetRefData = useCallback<Actions['setWidgetRefData']>(
    (id, formRef, attributes) => {
      const expandWidget = () => {
        const findWidget = widgets.find((widget) => widget.id === id)
        if (!findWidget) return
        const { container } = findWidget.options

        setActiveWidgets(
          produce(activeWidgets, (draft) => {
            const prevWidgets = activeWidgets[container] || []
            if (!isWidgetExpanded(container, id)) {
              draft[container] = [...prevWidgets, id]
            }
          })
        )
      }

      if (formRef) {
        widgetsDataRef.current[id] = {
          attributes,
          formRef,
          expandWidget,
        }
      }
    },
    [activeWidgets, isWidgetExpanded, widgets]
  )

  const getWidgetRefData = useCallback<Actions['getWidgetRefData']>(
    (widgetId) => {
      try {
        if (!widgetsDataRef.current[widgetId]) return null

        return widgetsDataRef.current[widgetId]
      } catch (e) {
        return null
      }
    },
    [widgetsDataRef]
  )

  const toggleContainer = useCallback<Actions['toggleContainer']>(
    (container, widgets) => {
      const isExpanded = activeWidgets[container]

      setActiveWidgets(
        produce(activeWidgets, (draft) => {
          if (isExpanded) {
            delete draft[container]
          } else {
            draft[container] = widgets.map((widget) => widget.id)
          }
        })
      )
    },
    [activeWidgets]
  )

  const toggleWidget = useCallback<Actions['toggleWidget']>(
    (container, widget) => {
      const isExpanded = isWidgetExpanded(container, widget)

      setActiveWidgets(
        produce(activeWidgets, (draft) => {
          if (isExpanded) {
            const widgetIndex = activeWidgets[container].indexOf(widget)
            draft[container].splice(widgetIndex, 1)
          } else {
            const prevWidgets = activeWidgets[container] || []
            draft[container] = [...prevWidgets, widget]
          }
        })
      )
    },
    [activeWidgets, isWidgetExpanded]
  )

  const addWidget = useCallback<Actions['addWidget']>(
    async (container, widgetType, targetIndex, isOpened = true) => {
      const typeId = widgetType.id

      const { singleEntityTypeRes, attributesRes } = await EntityService.getAttributes(
        'widget_types',
        typeId
      )

      const attributes = EntityService.generateAttributes(
        attributesRes,
        singleEntityTypeRes,
        'widgetTypeAttributes'
      )

      const initial = new DefaultValuesGenerator(
        // @ts-ignore
        attributes,
        localizations
      ).getValues()

      // @ts-ignore
      const generatedValues = new ValuesGenerator(attributes, initial, 'entitySetRepeats').getData()

      let sortOrder = 0

      if (targetIndex === 'push') {
        sortOrder = widgets.length
      } else if (typeof targetIndex === 'number' && targetIndex >= 0) {
        sortOrder = targetIndex
      }

      const newWidget = new EntityWidget(
        container,
        entity?.layout || '',
        widgetType,
        sortOrder,
        generatedValues
      )

      setWidgets(
        produce(widgets, (draft) => {
          draft.splice(sortOrder, 0, newWidget)

          if (targetIndex !== 'push') {
            draft.forEach((widget, index) => {
              widget.sortOrder = index
            })
          }
        })
      )

      if (isOpened) {
        toggleWidget(container, newWidget.id)
      }
    },
    [entity?.layout, localizations, toggleWidget, widgets]
  )

  const removeWidget = useCallback<Actions['removeWidget']>(
    (widgetId) => {
      delete widgetsDataRef.current[widgetId]

      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)
          draft.splice(widgetIndex, 1)
          draft.forEach((widget, index) => {
            widget.sortOrder = index
          })
        })
      )
      //** Clear this widget error */
      // setErroredWidget(erroredWidget.filter((id: number) => id !== widgetId))
    },
    [widgets]
  )

  const changeWidgetWidth = useCallback<Actions['changeWidgetWidth']>(
    (widgetId, width) => {
      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)
          draft[widgetIndex].options.desktop_width = width
        })
      )
    },
    [widgets]
  )

  const changeWidgetVisibility = useCallback<Actions['changeWidgetVisibility']>(
    (widgetId, value) => {
      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)
          draft[widgetIndex].options.isHidden = value
        })
      )
    },
    [widgets]
  )

  const moveWidget = useCallback<Actions['moveWidget']>(
    (source, destination, droppableSource, droppableDestination) => {
      setWidgets((prevWidgets) => {
        const cloneWidgets = cloneDeep(prevWidgets)
        const convertedData: IEntityWidget[] = []
        const containers: { [containerId: string]: IEntityWidget[] } = {}

        cloneWidgets.forEach((widget) => {
          containers[`${widget.options.container}`] = []
        })

        cloneWidgets.forEach((widget) => {
          containers[`${widget.options.container}`].push(widget)
        })

        const sourceClone = cloneDeep(source)
        const destClone = cloneDeep(destination)
        const [removed] = sourceClone.splice(droppableSource.index, 1)

        removed.options.container = droppableDestination.droppableId
        destClone.splice(droppableDestination.index, 0, removed)

        containers[droppableSource.droppableId] = sourceClone
        containers[droppableDestination.droppableId] = destClone

        Object.keys(containers).forEach((container) => {
          convertedData.push(...containers[container])
        })

        convertedData.forEach((widget, index) => {
          widget.sortOrder = index
        })

        const containerId = droppableSource.droppableId
        const widgetId = removed.id
        const isExpanded = isWidgetExpanded(containerId, widgetId)

        if (isExpanded) {
          setActiveWidgets(
            produce(activeWidgets, (draft) => {
              const removedIndex = draft[containerId].indexOf(widgetId)
              draft[containerId].splice(removedIndex, 1)
            })
          )
        }

        return convertedData
      })
    },
    [activeWidgets, isWidgetExpanded]
  )

  const reorderWidgets = useCallback<Actions['reorderWidgets']>(
    (containerId, startIndex, endIndex) => {
      setWidgets((prevWidgets) => {
        /**
         * Fix after refactor */
        const cloneWidgets = cloneDeep(prevWidgets)
        const containers: { [containerId: string]: IEntityWidget[] } = {}
        const reorderedWidgets: IEntityWidget[] = []

        cloneWidgets.forEach((widget) => {
          containers[`${widget.options.container}`] = []
        })

        cloneWidgets.forEach((widget) => {
          containers[`${widget.options.container}`].push(widget)
        })

        const widgetsInContainer = cloneWidgets.filter(
          (widget) => widget.options.container === containerId
        )

        const [movedWidget] = widgetsInContainer.splice(startIndex, 1)
        widgetsInContainer.splice(endIndex, 0, movedWidget)

        containers[containerId] = widgetsInContainer

        Object.keys(containers).forEach((container) => {
          reorderedWidgets.push(...containers[container])
        })

        reorderedWidgets.forEach((widget, index) => {
          widget.sortOrder = index
        })
        return reorderedWidgets
      })
    },
    []
  )

  const moveWidgetUp = useCallback<Actions['moveWidgetUp']>(
    (widgetId) => {
      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)
          if (widgetIndex - 1 < 0) return
          const temp = draft[widgetIndex]
          draft[widgetIndex] = draft[widgetIndex - 1]
          draft[widgetIndex - 1] = temp

          draft.forEach((widget, index) => {
            widget.sortOrder = index
          })

          document.dispatchEvent(dragEndEvent)
        })
      )
    },
    [widgets]
  )

  const moveWidgetDown = useCallback<Actions['moveWidgetDown']>(
    (widgetId) => {
      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)
          if (widgetIndex + 1 >= draft.length) return
          const temp = draft[widgetIndex]
          draft[widgetIndex] = draft[widgetIndex + 1]
          draft[widgetIndex + 1] = temp

          draft.forEach((widget, index) => {
            widget.sortOrder = index
          })

          document.dispatchEvent(dragEndEvent)
        })
      )
    },
    [widgets]
  )

  const setGlobalWidget = useCallback<Actions['setGlobalWidget']>(
    (widgetId, globalWidget) => {
      setWidgets(
        produce(widgets, (draft) => {
          const widgetIndex = draft.findIndex((widget) => widget.id === widgetId)

          if (widgetIndex >= 0) {
            draft[widgetIndex].widget = globalWidget
            draft[widgetIndex].values = []
            draft[widgetIndex].entitySetRepeats = []
          }
        })
      )
    },
    [widgets]
  )

  const duplicateWidget = useCallback<Actions['duplicateWidget']>(
    (id) => {
      const findWidget = widgets.find((widget) => widget.id === id)
      const widgetDataRef = widgetsDataRef.current[id]
      if (!findWidget) return

      let values = {
        values: findWidget.values,
        entitySetRepeats: findWidget.entitySetRepeats,
      }

      if (widgetDataRef && !findWidget.widget) {
        const currentValues = EntityService.generateValues(
          widgetDataRef.formRef.values,
          widgetDataRef.attributes,
          true,
          true
        )

        values.values = currentValues.values
        values.entitySetRepeats = currentValues.entitySetRepeats
      } else {
        values = EntityService.clearIdsFromEntity(values)
      }

      const newOrder = findWidget.sortOrder + 1

      const newWidget = new EntityWidget(
        findWidget.options.container,
        findWidget.options.layout,
        findWidget.widgetType,
        newOrder,
        values,
        findWidget.widget
      )

      setWidgets(
        produce(widgets, (draft) => {
          draft.splice(newOrder, 0, newWidget)
          draft.forEach((widget, index) => {
            widget.sortOrder = index
          })
        })
      )
    },
    [widgets]
  )

  const layoutQ = useQuery(
    `entity-layout-${entity?.layout}`,
    () => {
      const id = getIdFromIri(entity.layout)
      return httpService.get<Layout>(`/layouts/${id}`).then((res) => res.data)
    },
    {
      enabled: Boolean(!isLoading && !entitySettings.dynamicLayout && builder),
    }
  )

  const isNewDesign = THEME_TWO_COLOR_SCHEME.includes(colorSchemeValue)

  const sections = useMemo(() => {
    return entitySettings.dynamicLayout && isNewDesign
      ? entitySettings.dynamicLayoutSections
      : layoutQ.data?.options.sections || []
  }, [
    entitySettings.dynamicLayout,
    entitySettings.dynamicLayoutSections,
    isNewDesign,
    layoutQ.data?.options.sections,
  ])

  const toggleDynamicLayout = useCallback(() => {
    const { dynamicLayout, dynamicLayoutSections } = entitySettings
    const staticSections = layoutQ.data?.options.sections || []

    if (dynamicLayout) {
      const staticContainers = staticSections.map((section) => section.containers).flat()

      const widgetsIdToRemove = dynamicLayoutSections
        .slice(4)
        .map(({ containers }) => containers[0].id)

      const filteredWidgets = cloneDeep(widgets).filter(
        ({ options }) => !widgetsIdToRemove.includes(options.container)
      )

      // set right widget order
      filteredWidgets.forEach((widget) => {
        const containerIndex = dynamicLayoutSections.findIndex(({ containers }) => {
          return containers[0].id === widget.options.container
        })

        if (containerIndex >= 0) {
          const staticContainerId = staticContainers[containerIndex].id

          widget.options.container = staticContainerId
        }
      })

      setWidgets(filteredWidgets)
    } else {
      setEntitySettings(
        'dynamicLayoutSections',
        getDynamicLayout(staticSections, dynamicLayoutSections)
      )
    }

    setEntitySettings('dynamicLayout', !dynamicLayout)
  }, [entitySettings, layoutQ.data?.options.sections, setEntitySettings, widgets])

  const addSection = useCallback<Actions['addSection']>(
    (sectionIndex) => {
      const sectionAmount = entitySettings.dynamicLayoutSections.length
      const sections = [...entitySettings.dynamicLayoutSections]

      sections.splice(sectionIndex ?? sectionAmount, 0, createSectionTemplate(0))

      reorderContainers(sections)

      setEntitySettings('dynamicLayoutSections', [...sections])
    },
    [entitySettings.dynamicLayoutSections, setEntitySettings]
  )

  const removeSection = useCallback<Actions['removeSection']>(
    ({ containerId, sectionId }) => {
      setWidgets((widgets) => {
        return widgets.filter((w) => w.options.container !== containerId)
      })

      const filteredDynamicSections = entitySettings.dynamicLayoutSections.filter(
        (s) => s.id !== sectionId
      )

      reorderContainers(filteredDynamicSections)

      setEntitySettings('dynamicLayoutSections', filteredDynamicSections)
    },
    [entitySettings.dynamicLayoutSections, setEntitySettings]
  )

  const reorderSection = useCallback<Actions['reorderSection']>(
    (dropIndex, destIndex) => {
      const cloneDynamicSections = cloneDeep(entitySettings.dynamicLayoutSections)

      const [movedSection] = cloneDynamicSections.splice(dropIndex, 1)
      cloneDynamicSections.splice(destIndex, 0, movedSection)

      reorderContainers(cloneDynamicSections)

      setEntitySettings('dynamicLayoutSections', cloneDynamicSections)
    },
    [entitySettings.dynamicLayoutSections, setEntitySettings]
  )

  const selectSectiontBg = useCallback<Actions['selectSectiontBg']>(
    (sectionId, colorName) => {
      entitySettings.dynamicLayoutSections.forEach((section) => {
        if (sectionId === section.id) {
          section.options.background = colorName
        }
      })

      setEntitySettings('dynamicLayoutSections', entitySettings.dynamicLayoutSections)
    },
    [entitySettings.dynamicLayoutSections, setEntitySettings]
  )

  const actions: Actions = {
    addWidget,
    changeWidgetWidth,
    changeWidgetVisibility,
    reorderWidgets,
    moveWidget,
    moveWidgetUp,
    moveWidgetDown,
    removeWidget,
    toggleContainer,
    isWidgetExpanded,
    toggleWidget,
    setWidgetRefData,
    setGlobalWidget,
    getWidgetRefData,
    duplicateWidget,
    toggleDynamicLayout,
    addSection,
    removeSection,
    reorderSection,
    selectSectiontBg,
  }

  return {
    widgets,
    sections,
    dynamicLayout: entitySettings.dynamicLayout && isNewDesign,
    activeWidgets,
    widgetsDataRef,
    actions,
    pageBuilderLoading: layoutQ.isLoading,
  }
}
