// Core
import React, { FC, useState } from 'react'
import { InputBase, Paper, createStyles, makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'
// Components
import SearchModal from '../search-modal/search-modal'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '2px 10px',
      display: 'flex',
      alignItems: 'center',
      width: 300,
      marginLeft: theme.spacing(1),
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
  })
)
type Props = {}

const SearchBar: FC<Props> = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Paper className={classes.root}>
        <Search />
        <InputBase
          readOnly
          onClick={() => setIsOpen(true)}
          placeholder="Search"
          className={classes.input}
        />
      </Paper>
      {isOpen && <SearchModal isOpen={isOpen} onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default SearchBar
