// Core
import React, { FC, ReactNode, Ref, useMemo } from 'react'
import { Box, Chip, Icon, IconButton, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { defaultThemeSizes } from 'core/themes/default/default'
// Types
import { EntityStatus } from 'modules/new-entity/types'

type Props = {
  title?: ReactNode
  titleTransKey?: string
  backPath?: string | null
  actions?: ReactNode
  status?: EntityStatus
  wrapperRef?: Ref<HTMLDivElement>
}

const statusNamesMap: Record<string, { title: string; bg: string; color: string }> = {
  draft: {
    title: 'Draft',
    color: '#1976D2',
    bg: '#E3F2FD',
  },
  publish: {
    title: 'Published',
    color: '#4CAF50',
    bg: '#E8F5E9',
  },
  archive: {
    title: 'Archived',
    color: '#E65100',
    bg: '#FFE0B2',
  },
}

const PageHeader: FC<Props> = (props) => {
  const { title, titleTransKey, actions, status, backPath, wrapperRef } = props

  const { t } = useTranslation()

  const pageTitle = useMemo(() => {
    if (titleTransKey) {
      return t(`list.${titleTransKey}`)
    }

    return title
  }, [t, title, titleTransKey])

  return (
    <Box
      position="sticky"
      style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', columnGap: 10 }}
      bgcolor="common.white"
      height={defaultThemeSizes.pageHeaderHeight + 1}
      top={defaultThemeSizes.appBarHeight}
      left={0}
      right={0}
      zIndex="1190"
      px={4}
      pl={backPath ? 2 : 4}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      // @ts-ignore
      ref={wrapperRef}
    >
      <Box flex={0} display="flex" alignItems="center" style={{ columnGap: 10 }} maxWidth="45%">
        {backPath && (
          <IconButton component={Link} to={backPath}>
            <Icon className="icon-chevron-left" />
          </IconButton>
        )}
        {pageTitle && (
          <Typography variant="subtitle1" noWrap style={{ maxWidth: '100%', fontWeight: 500 }}>
            {pageTitle}
          </Typography>
        )}
        {status && (
          <Chip
            label={statusNamesMap[status].title}
            size="small"
            variant="outlined"
            style={{
              borderRadius: 4,
              color: statusNamesMap[status].color,
              backgroundColor: statusNamesMap[status].bg,
              border: 'none',
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          />
        )}
      </Box>
      {actions && <Box>{actions}</Box>}
    </Box>
  )
}

export default PageHeader
