import { Merge } from 'core/types'

export type TUseGetLinkSaverArgs = {
  id: string
  navigationType: any
  navigationLinkIds: any
  isLoading?: (b: boolean) => void
  onSuccess?: (response: any) => void
  onError?: (error: any) => void
}

export enum EnumNavLinkTypes {
  NAVIGATION_LINK,
  NAVIGATION_CUSTOM,
}

export enum EnumSystemNavigationSlugs {
  REF_TO_NAVIGATION = 'navigation',
  REF_TO_PARENT = 'navigation_item_parent',
  POSITION = 'position',
  // For Entity
  REF_TO_ENTITY = 'navigation_item_entity',
  // For Custom
  REF_TO_CUSTOM = 'navigation_item_custom',
  CUSTOM_TITLE = 'navigation_label',
  CUSTOM_SLUG = 'link',
}

export enum EnumNavigationMethod {
  CREATE = 'create',
  MOVE = 'move',
}

export const specialTypes = Object.values(EnumSystemNavigationSlugs)

export const EnumNavLinkTypesIds: { [key in EnumNavLinkTypes]: number } = {
  [EnumNavLinkTypes.NAVIGATION_LINK]: 107,
  [EnumNavLinkTypes.NAVIGATION_CUSTOM]: 108,
}

type TSaverArgEntity = {
  type: EnumNavLinkTypes.NAVIGATION_LINK
  entityIri?: string
  parentIri?: string | null
  title: string
}

type TSaverArgCustom = {
  type: EnumNavLinkTypes.NAVIGATION_CUSTOM
  title: string
  slug: string
  parentIri?: string | null
}

type TChangerArgs = {
  type: EnumNavLinkTypes
  navItemId: number
  parentIri: string | null
  position: number
}

export type TNavigationMethod = {
  method: EnumNavigationMethod
}

export type TSaverArgs = TSaverArgEntity | TSaverArgCustom
export type TSaverArgsMerged = Merge<Merge<TSaverArgEntity, TSaverArgCustom>, TChangerArgs>

export type TUseGetLinkSaverRes = {
  saveNavigationItem: (r: TSaverArgs[], m: EnumNavigationMethod) => void
  changeParentNavigationItem: (r: TChangerArgs[], m: EnumNavigationMethod) => void
  loading: boolean
}

export type TNavigationMenuType = {
  name: string
}

export type TNavigationFromList = {
  '@id': string
  '@type': string
  entity: string
  entityType: string
  id: number
  title: string
  menuType: TNavigationMenuType
}

export type TNavigation = TNavigationFromList & {
  '@context': string
  items: TNavigationItem[]
}

export type TNavigationItem = {
  blankAttribute: boolean
  cssClass: string | null
  description: string | null
  entity: string
  entityType: string
  icon: any // TODO-type
  id: number
  parent: null
  position: number | null
  relAttribute: string | null
  slug: string | null
  title: string | null
  titleAttribute: string | null
  trackingEvent: null
  targetEntity: string
  type: string
}

export type TNavigationItemTransformed = {
  children: TNavigationItemTransformed[] | []
  expanded: boolean
} & TNavigationItem
