/* eslint-disable react-hooks/rules-of-hooks */
// Core
import React, { FC, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isJsonString } from 'modules/new-entity/utils'
// Material UI
import {
  TextFieldProps,
  Paper,
  Box,
  IconButton,
  Typography,
  Button,
  Icon,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import LinksModal from 'modules/new-entity/components/links-modal'
// Hooks
import { useGetActiveSite, useSitesContext } from 'modules/sites'
import useFieldController from 'core/form/hooks/use-field-controller'
// Utils
import { trailingSlash } from 'core/utils'
import { VulcanClientService } from 'modules/eav/services/vulcan-client.service'
// Styles
import useStyles from './link-field.styles'
import clsx from 'clsx'

type TProps = {
  name: string
  label: string
} & TextFieldProps

const LinkField: FC<TProps> = (props) => {
  const classes = useStyles()
  const [value, setValue] = useState('')
  const [openModal, toggleModalOpen] = useState(false)
  const { name, label, required, disabled } = props
  const [field, meta, helpers] = useFieldController(name)
  const { t } = useTranslation()

  const activeSite = useGetActiveSite()

  const { affiliateSettings } = useSitesContext()
  const {
    prefix: globalAffPrefix,
    alternative: { domain: altDomain },
  } = affiliateSettings

  const siteName = `${trailingSlash(altDomain || activeSite?.url)}${globalAffPrefix}/`

  const linkType = isJsonString(field.value) ? JSON.parse(field.value) : { type: 'custom_link' }

  useEffect(() => {
    if (isJsonString(field.value)) {
      const fieldValues = JSON.parse(field.value)
      switch (fieldValues.type) {
        case 'custom_link': {
          setValue(fieldValues.url)
          break
        }
        case 'affiliate_link': {
          VulcanClientService.getEntityById(fieldValues.affiliate, {
            values: { cloaked_url: [] },
          }).then((res) => {
            const url = res.attributes.values.cloaked_url
            setValue(`${siteName}${url}`)
          })
          break
        }
        case 'internal_link': {
          VulcanClientService.getEntityById(fieldValues.entity).then((res) => {
            let { anchor } = fieldValues
            anchor = anchor ? `/#${anchor}` : ''
            const url = res.attributes['@url']
            const slug = url === '/' ? '' : `/${url}`
            setValue(`${activeSite?.url}${slug}${anchor}`)
          })
          break
        }
        default: {
          setValue('')
        }
      }
    }
  }, [activeSite, field.value, siteName])

  const submitLinkAttribute = useCallback(
    (values: any) => {
      helpers.setValue(JSON.stringify(values))
      toggleModalOpen(false)
    },
    [helpers]
  )

  const openLinksModal = useCallback(() => {
    toggleModalOpen(true)
    helpers.setTouched(true)
  }, [helpers])

  const deleteLink = useCallback(() => {
    helpers.setValue('')
    setValue('')
  }, [helpers])

  return (
    <Box className={classes.root}>
      <FormControl component="fieldset" error={meta.hasError} required={required}>
        <FormLabel className={classes.label} component="legend">
          {label}
        </FormLabel>
        {value ? (
          <Paper className={classes.paper}>
            <Typography color={'primary'} className={classes.typography}>
              {linkType.type === 'custom_link' ? value : trailingSlash(value)}
            </Typography>
            <IconButton
              onClick={openLinksModal}
              color={'primary'}
              data-aqa-action="edit"
              disabled={disabled}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={deleteLink}
              color={'primary'}
              data-aqa-action="delete"
              disabled={disabled}
            >
              <DeleteIcon />
            </IconButton>
          </Paper>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={openLinksModal}
            startIcon={<Icon className="icon-plus-circle" />}
            data-aqa-action="insert"
            className={clsx({ [classes.error]: meta.hasError })}
            disabled={disabled}
          >
            {t('links.insert')}
          </Button>
        )}
        {meta.hasError && (
          <FormHelperText>
            {<span className={classes.helperText}>{meta.errorMsg}</span>}
          </FormHelperText>
        )}
      </FormControl>
      {openModal && (
        <LinksModal
          submitLink={submitLinkAttribute}
          toggleModalOpen={toggleModalOpen}
          value={field.value}
          openModal={openModal}
        />
      )}
    </Box>
  )
}

export default LinkField
