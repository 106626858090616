import React, { FC } from 'react'
import { Checkbox, TableBody, TableCell, TableRow } from '@material-ui/core'
import { useListContext } from 'core/data'
import { getIdFromIri } from 'core/utils'

type TableBodyProps = {
  data: any
  page: number
  createTableRow: (row: any) => void
  showCheckBox?: boolean
}

export const DataTableBody: FC<TableBodyProps> = (props) => {
  const {
    selected,
    setSelected,
    listParams: {
      params: {
        pagination: { perPage },
      },
    },
  } = useListContext()
  const { data, page, createTableRow, showCheckBox = false } = props
  const selectedId = selected ? getIdFromIri(selected) : ''
  return (
    <TableBody>
      {data.slice(page * perPage, page * perPage + perPage).map((row: any, index: number) => {
        const labelId = `enhanced-table-checkbox-${index}`

        return (
          <TableRow
            hover
            key={row.id}
            role={''}
            aria-checked={selectedId === row.id}
            tabIndex={-1}
            selected={selectedId === row.id}
          >
            {showCheckBox && (
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={() => setSelected(row.id)}
                  checked={selectedId === row.id}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
            )}

            {createTableRow(row)}
          </TableRow>
        )
      })}
    </TableBody>
  )
}
