import React, { FC } from 'react'
import { AttributeValidationRules } from 'core/types/attribute'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type TProps = {
  validation: AttributeValidationRules
}

const RegexPreview: FC<TProps> = (props) => {
  const { t } = useTranslation()
  const { validation } = props

  if (validation.regex) {
    return (
      <Typography variant={'caption'} component={'p'}>
        <strong>{t('attrs.regex')}:</strong> {validation.regex}
      </Typography>
    )
  }

  return null
}

export default RegexPreview
