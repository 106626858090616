import { useCallback } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
import { useConfirmation } from 'core/confirmation'

export const useActionWithConfirmation = (title: string, content: string) => {
  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()

  return useCallback(
    (onSuccess: () => void) => {
      setConfirmation({
        open: true,
        title: t(title),
        content: t(content),
        onSuccess,
      })
    },
    [setConfirmation, t, title, content]
  )
}
