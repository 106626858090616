import { Section } from 'core/types/layouts'
import { IEntityWidget } from '../types'

export const needConfirmation = (sections: Section[], widgets: IEntityWidget[]) => {
  if (sections.length <= 4) return false

  const sectionsToRemove = sections.slice(4)
  let hasWidgetToRemove = false

  for (let i = 0; i < sectionsToRemove.length; i++) {
    const [container] = sectionsToRemove[i].containers

    const widgetToRemove = widgets.find(({ options }) => options.container === container.id)

    if (widgetToRemove) {
      hasWidgetToRemove = true
      break
    }
  }

  return hasWidgetToRemove
}
