import { Attribute } from 'modules/eav/types'
import { ColumnData, Entity, EntityTypeAttribute, RowData, Value, ValuesMap } from './types'
import { Site } from 'modules/sites'

const DataService = {
  prepareColumnsData: (
    entityTypeAttrs: EntityTypeAttribute[],
    attrsData: Attribute[],
    site?: Site
  ) => {
    let columnsData = entityTypeAttrs.reduce<ColumnData[]>((prev, attr) => {
      if (!attr.showAsColumn || attr.nameable) return prev
      const fullAttrObject = attrsData.find((fullAttr) => fullAttr['@id'] === attr.attribute)
      if (!fullAttrObject) return prev

      const column: ColumnData = {
        title: fullAttrObject.name,
        attrIri: fullAttrObject['@id'],
        order: attr.columnOrder,
        attrType: fullAttrObject.attributeType.type,
        enums: fullAttrObject.attributeEnums,
        attrOptions: DataService._getAttrOptions(fullAttrObject),
        slug: fullAttrObject.slug,
      }

      prev.push(column)
      return prev
    }, [])

    columnsData.sort((a, b) => {
      if (a.order !== 0 && b.order === 0) return -1
      if (a.order === 0 && b.order !== 0) return 1
      return a.order - b.order
    })

    /**
     * Add attr with namable option as first column
     */
    const nameableAttr = entityTypeAttrs.find((attr) => attr.nameable)
    const nameableFullAttrObject = attrsData.find((attr) => attr['@id'] === nameableAttr?.attribute)

    if (nameableAttr && nameableFullAttrObject) {
      columnsData.unshift({
        title: 'Title',
        attrIri: nameableFullAttrObject['@id'],
        order: nameableAttr.columnOrder,
        attrType: nameableFullAttrObject.attributeType.type,
        enums: nameableFullAttrObject.attributeEnums,
        attrOptions: DataService._getAttrOptions(nameableFullAttrObject),
        slug: nameableFullAttrObject.slug,
        markedAsTitle: true,
      })
    }

    if (site && !site.additionalLocalizations.length) {
      columnsData = columnsData.filter((column) => column.slug !== 'localization')
    }

    columnsData.push({
      title: 'Created At',
      attrIri: '_createdAt',
      order: 0,
      attrType: 'date_time',
      enums: [],
      attrOptions: {},
      slug: '_createdAt',
    })

    columnsData.push({
      title: 'Updated At',
      attrIri: '_updatedAt',
      order: 0,
      attrType: 'date_time',
      enums: [],
      attrOptions: {},
      slug: '_updatedAt',
    })

    return columnsData
  },
  prepareRowsData: (entities: Entity[], showDraftValues = false, attrs: Attribute[]) => {
    const rowsData = entities.reduce<RowData[]>((prev, entity) => {
      let valuesToShow = entity.values
      const entityToShow = entity

      /**
       * In case if entity is urlable we have helper version of the main entity "draft"
       * We should change all displayed values in table form draft version
       * except "status" and "personalized" values
       */
      if (showDraftValues) {
        const draftVersion = entity.versions.find(
          (entityVersion) => entityVersion.originalLabel === 'draft'
        )

        if (!draftVersion) {
          throw new Error(`Entity doesn't have draft version`)
        }

        // Change not eaw values
        entityToShow.name = draftVersion.name
        entityToShow.createdAt = draftVersion.createdAt
        entityToShow.updatedAt = draftVersion.updatedAt

        const statusAttr = attrs.find((attr) => attr.slug === 'status')
        const personalizedAttr = attrs.find((attr) => attr.slug === 'personalized')

        if (!statusAttr || !personalizedAttr) {
          throw new Error('status|personalized attrs should be present for this functionality')
        }

        const originalStatusValue = valuesToShow.find((val) => val.attribute === statusAttr['@id'])

        const originalPersonalizedValue = valuesToShow.find(
          (val) => val.attribute === personalizedAttr['@id']
        )

        valuesToShow = draftVersion.values.map((val) => {
          if (val.attribute === originalStatusValue?.attribute) {
            return originalStatusValue
          }
          if (val.attribute === originalPersonalizedValue?.attribute) {
            return originalPersonalizedValue
          }
          return val
        })
      }

      const rowDataItem: RowData = {
        ...entityToShow,
        valuesMap: DataService._valuesToMap(valuesToShow),
      }
      prev.push(rowDataItem)
      return prev
    }, [])
    return rowsData
  },
  _valuesToMap: (values: Value[]) => {
    return values.reduce<ValuesMap>((map, value) => {
      map[value.attribute] = value
      return map
    }, {})
  },
  _getAttrOptions: (attribute: Attribute) => {
    if (Array.isArray(attribute.options)) return {}
    return attribute.options
  },
}

export default DataService
