// Core
import React, { FC, useCallback, useMemo } from 'react'
// ui
import { Box, Button, CircularProgress, Divider, Typography } from '@material-ui/core'
// Components
import AttributesForm from 'modules/new-entity/components/attributes-form'
import CollectionPreview from '../collection-preivew'
import RelationsSelect from 'ui/relations-select'
// hooks
import { useWidgetController } from 'modules/new-entity/hooks'
import { useGetActiveSite } from 'modules/sites'
// utils
import { getIdFromIri } from 'core/utils'
// Types
import {
  EavResourceType,
  IEntityWidget,
  MutationType,
  SetGlobalWidgetFunc,
  SetWidgetRefDataFunc,
} from 'modules/new-entity/types'

type Props = {
  data: IEntityWidget
  setWidgetRefData: SetWidgetRefDataFunc
  setGlobalWidget: SetGlobalWidgetFunc
  disabled: boolean
  prevValues?: any
  needRenderForm?: boolean
  submittedOnce?: boolean
}

const WidgetContent: FC<Props> = (props) => {
  const {
    data,
    setWidgetRefData,
    setGlobalWidget,
    disabled,
    prevValues,
    needRenderForm = false,
    submittedOnce,
  } = props

  const {
    isCollection,
    typeId,
    isLoading,
    isFetching,
    entityType,
    validationSchema,
    initialValues,
    attributes,
    global,
  } = useWidgetController(data)

  const activeSite = useGetActiveSite()

  const globalWidgetChangeHandler = useCallback(
    (e: any) => {
      setGlobalWidget(data.id, e)
    },
    [setGlobalWidget, data]
  )

  const isGlobalWidget = Boolean(data.widget)

  const formValues = useMemo(() => {
    if (isGlobalWidget) {
      return initialValues
    }
    return prevValues || initialValues
  }, [initialValues, isGlobalWidget, prevValues])

  const loading = isGlobalWidget ? isFetching : isLoading

  return (
    <>
      {!isCollection && !disabled && (
        <>
          <RelationsSelect
            value={data.widget}
            source="widgets"
            label="Select from global widgets"
            reqParams={{ widgetType: `/api/widget_types/${typeId}` }}
            onChange={globalWidgetChangeHandler}
            valueField="@id"
            viewAction={(option) => {
              const { id, widgetType } = option
              const widgetTypeId = getIdFromIri(widgetType)
              return `/${activeSite?.id}/widgets/${widgetTypeId}/edit/${id}`
            }}
          />
          <Box my={3}>
            <Divider />
          </Box>
        </>
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <AttributesForm
            resourceType={EavResourceType.WIDGET}
            type={MutationType.EDIT}
            mainAttributes={attributes}
            initialValues={formValues}
            validationSchema={validationSchema}
            typeOptions={entityType?.options}
            // @ts-ignore
            formRef={(formRef) => {
              if (!disabled) {
                setWidgetRefData(data.id, formRef, attributes)
              }
            }}
            disabled={disabled}
            widget={data}
            errorsLabelId={String(data.id)}
            needRenderForm={needRenderForm}
            validateOnMount={submittedOnce}
          />
          {!isCollection && (
            <Box mt={2}>
              {isGlobalWidget && (
                <Box mb={1}>
                  <Typography variant="caption">
                    *Any change you do will be updated in every place this global widget is used
                  </Typography>
                </Box>
              )}
              <Button
                onClick={() => global.saveGlobalWidget(isGlobalWidget ? 'edit' : 'create')}
                color="secondary"
                size="large"
                variant="contained"
                disabled={global.isLoading || disabled}
              >
                {isGlobalWidget ? 'Save global widget' : 'Save as global'}
              </Button>
            </Box>
          )}
        </>
      )}
      {isCollection && <CollectionPreview widgetData={data} />}
    </>
  )
}

export default WidgetContent
