import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { selectActiveElement } from '../store'

type UseActiveElement = (sectionId: string, containerId: string) => any

export const useActiveElement: UseActiveElement = (sectionId, containerId) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(selectActiveElement(sectionId, containerId))
  }, [dispatch, sectionId, containerId])
}
