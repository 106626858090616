import React, { FC, ReactNode } from 'react'
import { Box, CircularProgress, Divider } from '@material-ui/core'
import SearchField from 'ui/search-field'
import { ViewTypeSwitcher } from './view-type'

type Props = {
  hasCardView: boolean
  search: string
  onSearchChange: (value: string) => void
  actionsHolder?: ReactNode
  isLoading: boolean
  breadcrumbs?: ReactNode
}

export const Header: FC<Props> = ({
  hasCardView,
  search,
  onSearchChange,
  actionsHolder,
  isLoading,
  breadcrumbs,
}) => {
  const needBottomPanel = Boolean(breadcrumbs) || Boolean(hasCardView)

  return (
    <Box>
      <Box px={2} py={1} display="flex" alignItems="center" justifyContent="space-between">
        <SearchField size="small" value={search} onChange={onSearchChange} />
        <Box display="flex" alignItems="center">
          {actionsHolder}
          {isLoading && (
            <CircularProgress size="22px" style={{ display: 'inline-flex', marginLeft: 8 }} />
          )}
        </Box>
      </Box>
      {needBottomPanel && (
        <>
          <Divider />
          <Box display="flex" py={1} px={2} height={55} alignItems="center">
            {breadcrumbs}
            {hasCardView && <ViewTypeSwitcher />}
          </Box>
        </>
      )}
    </Box>
  )
}
