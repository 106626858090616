import { createContext, useContext } from 'react'
import { ListProps, RolesData, SetSiteRoleFunc, SiteRoleMap } from './types'

type ContextProps = {
  siteRoleMap: SiteRoleMap
  rolesData: RolesData
  setSiteRole: SetSiteRoleFunc
  bulkSetRoleBySlug: (roleSlug: string) => void
  listProps: ListProps
}

export const ListContext = createContext<ContextProps>(null!)

export const useListContext = () => useContext(ListContext)
