// Core
import React, { FC, useMemo, useState } from 'react'
// Context
import { LanguageContext } from './language-context'
// Hooks
import { useSitesContext } from 'modules/sites'

type Props = {
  notSystem?: boolean
}

const LanguageProvider: FC<Props> = ({ children, notSystem }) => {
  const { locales } = useSitesContext()
  const localizations = locales[notSystem ? 'site' : 'system']
  const [currentLang, setCurrentLang] = useState(localizations[0])

  const value = useMemo(
    () => ({
      currentLang,
      setCurrentLang,
      localizations,
    }),
    [currentLang, localizations]
  )

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}
export default LanguageProvider
