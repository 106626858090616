// Core
import React, { FC } from 'react'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { useDeleteEntity } from 'modules/new-entity/hooks'

type Props = {
  id: number
  refreshData: () => void
  resource: string
}

const DeleteAction: FC<Props> = (props) => {
  const { id, refreshData, resource } = props

  const { deleteEntity, deleteEntityM } = useDeleteEntity(id, {
    onSuccess: () => refreshData(),
    isWidgetType: resource === 'widgets',
  })

  return (
    <IconButton size="small" onClick={deleteEntity} disabled={deleteEntityM.isLoading}>
      <Delete />
    </IconButton>
  )
}

export default DeleteAction
