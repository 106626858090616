// Core
import React, { createContext, FC, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
// Hooks
import { useListController } from 'core/data'
// Services
import { httpService } from 'core/data'
// Types
import { Redirect, RedirectsContextProps } from '../types'

type Props = {}

export const RedirectsContext = createContext<RedirectsContextProps>(null!)

export const RedirectsProvider: FC<Props> = ({ children }) => {
  const queryClient = useQueryClient()
  const [modalOpen, setModalOpen] = useState(false)

  const dataController = useListController(
    {
      name: 'redirects',
      hasCreate: false,
      hasEdit: false,
      hasList: false,
    },
    {
      filterChangeUrl: false,
    }
  )

  const createMutation = useMutation<any, any, Redirect>(
    (values) => {
      return httpService.post('/redirects', values)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries('redirects')
      },
    }
  )

  const contextProps = useMemo<RedirectsContextProps>(
    () => ({
      createMutation,
      dataController,
      invalidate: () => queryClient.invalidateQueries('redirects'),
      modalOpen,
      setModalOpen,
    }),
    [createMutation, modalOpen, queryClient, dataController]
  )

  return <RedirectsContext.Provider value={contextProps}>{children}</RedirectsContext.Provider>
}
