// Core
import React, { createElement, ElementType, FC, useMemo } from 'react'
import { Route as ReactRouter, Switch } from 'react-router-dom'
// Components
import { Route } from 'core/router'
import { NotFound } from 'common/components'
// Hooks
import { useAppContext } from 'core/app'
// Types
import { Permissions } from 'core/app'
import { ResourceOptions, ResourceProps } from 'core/data'

type TProps = {
  path: string
  list?: ElementType<ResourceProps>
  create?: ElementType<ResourceProps>
  edit?: ElementType<ResourceProps>
  children?: never
}

const Resource: FC<TProps> = (props) => {
  const { path, list: List, create: Create, edit: Edit, ...restProp } = props

  const {
    actions: { userCan },
  } = useAppContext()

  const name = useMemo(() => path.slice(1), [path])
  const genProps = useMemo(() => ({ ...props, name }), [props, name])

  const options: ResourceOptions = {
    hasList: !!List,
    hasCreate: !!Create,
    hasEdit: !!Edit,
  }

  return useMemo(
    () => (
      <ReactRouter {...restProp} path={`${path}`}>
        <Switch>
          {List &&
            (userCan(name, Permissions.VIEW) ||
              userCan(name, Permissions.VIEW, 'system') ||
              userCan(name, Permissions.VIEW, 'widgetType')) && (
              <Route
                path={`${path}`}
                exact
                componentTo={createElement(List, { ...genProps, ...options })}
              />
            )}
          {Create &&
            (userCan(name, Permissions.CREATE) ||
              userCan(name, Permissions.CREATE, 'system') ||
              userCan(name, Permissions.CREATE, 'widgetType')) && (
              <Route
                path={`${path}/create`}
                exact
                componentTo={createElement(Create, { ...genProps, ...options })}
              />
            )}
          {Edit &&
            (userCan(name, Permissions.EDIT) ||
              userCan(name, Permissions.EDIT, 'system') ||
              userCan(name, Permissions.EDIT, 'widgetType')) && (
              <Route
                path={`${path}/:id`}
                exact
                componentTo={createElement(Edit, { ...genProps, ...options })}
              />
            )}
          <Route path="/*" componentTo={<NotFound />} />
        </Switch>
      </ReactRouter>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Create, Edit, List, name, props]
  )
}

export default Resource
