import React, { FC } from 'react'
import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core'

type TableHeadProps = {
  columns: any
  showCheckBox?: boolean
}

export const DataTableHead: FC<TableHeadProps> = (props) => {
  const { columns, showCheckBox = false } = props

  return (
    <TableHead>
      <TableRow>
        {showCheckBox && (
          <TableCell key={0} padding="checkbox">
            <Checkbox inputProps={{ 'aria-label': 'select all desserts' }} />
          </TableCell>
        )}

        {columns.map((column: any, ind: number) => (
          <TableCell key={ind + column.title} align="left">
            {column.title}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
