// Core
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import debounce from 'lodash.debounce'
// Hooks
import { useRedirectsContext } from '../../hooks'
import { useAppContext, Permissions } from 'core/app'
// Components
import { DataGridHeader } from 'ui'
import { Actions } from '../actions'

type Props = {
  children?: never
}

const TableHeader: FC<Props> = () => {
  const {
    actions: { userCan },
  } = useAppContext()
  const { dataController, setModalOpen } = useRedirectsContext()
  const [s, setS] = useState('')

  const headerWrapRef = useRef<HTMLDivElement>(null)

  const searchHandler = useCallback(
    (e) => {
      e.preventDefault()
      dataController.listParams.methods.setFilter('search', s)
    },
    [dataController.listParams.methods, s]
  )

  const clearHandler = useCallback(() => {
    setS('')
    dataController.listParams.methods.setFilter('search', '')
  }, [dataController.listParams.methods])

  const debounceSearch = useMemo(
    () => debounce(dataController.listParams.methods.setFilter, 500),
    [dataController.listParams.methods.setFilter]
  )

  const searchChangeHandler = useCallback(
    (value: string) => {
      setS(value)
      debounceSearch('search', value)
    },
    [debounceSearch]
  )

  return (
    <DataGridHeader
      onSearchSubmit={searchHandler}
      onSearchChange={searchChangeHandler}
      searchValue={s}
      onSearchClear={clearHandler}
      hasCreateButton={userCan('redirects', Permissions.CREATE, 'system')}
      buttonText="Create"
      isLoading={dataController.isFetching}
      wrapperRef={headerWrapRef}
      actionArea={<Actions actionsRef={headerWrapRef} />}
      buttonProps={{
        onClick: () => setModalOpen(true),
      }}
    />
  )
}

export default TableHeader
