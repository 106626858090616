import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { sortSections } from '../store'

type TUseDragEnd = () => (result: any) => void

export const useDragEnd: TUseDragEnd = () => {
  const dispatch = useDispatch()

  return useCallback(
    (result) => {
      const { destination, source } = result
      if (!destination) return
      if (destination.droppableId === source.droppableId && destination.index === source.index)
        return

      dispatch(sortSections(result))
    },
    [dispatch]
  )
}
