import React, { FC } from 'react'
import { useGetAttributeData } from 'common/hooks'
import { AttributeTypesList } from 'common/types'
import { BooleanConditionField, DefaultConditionField, SelectConditionField } from './components'
import { useTranslation } from 'react-i18next'

type TProps = {
  dependsValue: string
  conditionValue: string
}

const ConditionValuesField: FC<TProps> = (props) => {
  const { dependsValue, conditionValue } = props
  const { data: attrData, isLoading, error } = useGetAttributeData(dependsValue)
  const { t } = useTranslation()

  if (isLoading) return <div>{t('global.loading')}</div>
  if (error) return <div>{t('global.error')}</div>

  if (attrData.attributeType) {
    switch (attrData.attributeType.type) {
      case AttributeTypesList.select: {
        return <SelectConditionField data={attrData} value={conditionValue} />
      }
      case AttributeTypesList.boolean: {
        return <BooleanConditionField value={conditionValue} />
      }
      default: {
        return <DefaultConditionField value={conditionValue} />
      }
    }
  } else {
    return null
  }
}

export default ConditionValuesField
