/** Core */
import React, { FC, memo } from 'react'
import { Box } from '@material-ui/core'
import SortableTree from 'react-sortable-tree'
import { useTranslation } from 'react-i18next'
/** Components */
import { Collapse } from 'ui'
import { ActionArea } from './components'
/** Hooks */
import { useNavTreeHooks } from 'modules/navigation/hooks'
/** Styles */
import useStyles from './navigation-items-tree.styles'
import 'react-sortable-tree/style.css'

type TProps = {
  data?: object
  loading: boolean
}

const NavigationItemsTree: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { loading } = props
  const {
    state,
    actions: { dragEndHandler, onChangeHandler },
  } = useNavTreeHooks()

  return (
    <Collapse isOpen={true} label={t('navigation.menu-structure')}>
      <p className={classes.hint}>{t('navigation.hint')}</p>
      <Box className={classes.holder}>
        {loading && <Box className={classes.overlay}>{/*<CircularProgress />*/}</Box>}
        <SortableTree
          rowHeight={44}
          className={classes.treeComponent}
          style={{ height: 700 }}
          // maxDepth={3}
          treeData={state}
          onChange={onChangeHandler}
          generateNodeProps={(rowInfo) => ({
            buttons: [<ActionArea removeHandler={() => {}} node={rowInfo.node} key={1} />],
            title: [
              <div key={1} className={classes.itemTitleHolder}>
                <span className={classes.rowItemTitle}>
                  {rowInfo.node.navigationLabel || `(${t('navigation.no-label')})`}
                </span>
                &nbsp;
                {rowInfo.parentNode ? (
                  <span className={'sub-item'}>{t('navigation.sub-item')}</span>
                ) : (
                  ''
                )}
              </div>,
            ],
            className: classes.rowItem,
          })}
          onMoveNode={dragEndHandler}
        />
      </Box>{' '}
    </Collapse>
  )
}

export default memo(NavigationItemsTree)
