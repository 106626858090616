import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const { purpleLight } = defaultThemeColors

const layoutStaticContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '60px',
      borderRadius: '5px',
      margin: theme.spacing(0, 0, 7),
      padding: theme.spacing(2, 0),
      border: '1px dashed #B0BEC5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      background: '#E0E0E0',
      cursor: 'pointer',
    },
    activeElement: {
      borderColor: '#5850EC',
      background: purpleLight,
    },
  })
)

export default layoutStaticContainerStyles
