import { Action } from 'redux'
import { CreateAttribute } from '../../core/types/attribute'

export interface AttributesState {
  type: 'edit' | 'create' | 'preview' | null
  isOpen: boolean
  nestedForm: {
    isOpen: boolean
  }
}

export enum ActionTypes {
  ATTRIBUTES_MODAL_OPEN = '@attributes/ATTRIBUTES_MODAL_OPEN',
  CREATE_ATTRIBUTE = '@attributes/CREATE_ATTRIBUTE',
  SHOW_NESTED_FORM = '@attributes/SHOW_NESTED_FORM',
}

export interface AttributesModalOpenAction extends Action<ActionTypes.ATTRIBUTES_MODAL_OPEN> {
  payload: {
    type?: 'edit' | 'create' | 'preview'
    isOpen: boolean
  }
}

export interface CreateAttributeAction extends Action<ActionTypes.CREATE_ATTRIBUTE> {
  payload: {
    data: CreateAttribute
    type: 'default' | 'repeater' | ''
    formProps: any
    formikBag: any
    successAction: any
  }
}

export interface ShowNestedFormAction extends Action<ActionTypes.SHOW_NESTED_FORM> {
  payload: {
    isOpen: boolean
  }
}
