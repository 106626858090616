// Core
import React, { FC } from 'react'
import { CircularProgress } from '@material-ui/core'
// Styles
import { useStyles } from './spinner.styles'

type Props = {}

const Spinner: FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.spiner}>
      <CircularProgress />
    </div>
  )
}

export default Spinner
