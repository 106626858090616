import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { Box, LinearProgress } from '@material-ui/core'
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
import { RelationGridColumn } from '../../types'

type Props = {
  column: RelationGridColumn
  rowData: any
}

const getResourceId = (column: RelationGridColumn, rowData: any) => {
  const { field } = column
  const id = rowData[field]

  if (!id) {
    return null
  }

  if (typeof id === 'number') {
    return id
  }

  return +getIdFromIri(id)
}

export const RelationCell: FC<Props> = ({ column, rowData }) => {
  const { resource, titleField } = column
  const resourceId = getResourceId(column, rowData)

  const { data, isLoading, isError } = useQuery(
    ['grid-relation-column', resourceId],
    () => {
      return httpService.get<any>(`/${resource}/${resourceId}`).then((res) => res.data)
    },
    {
      enabled: Boolean(resourceId),
      retry: false,
    }
  )

  if (isError) {
    return <Box>Fetch resource error</Box>
  }

  if (isLoading) {
    return (
      <Box width={120}>
        <LinearProgress />
      </Box>
    )
  }

  if (!data) {
    return null
  }

  const fieldValue =
    typeof titleField === 'function' ? titleField(data) : data[titleField ?? 'name']

  return <div>{fieldValue}</div>
}
