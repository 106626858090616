import React, { FC } from 'react'
import { useFormikContext } from 'formik'
import { AttributeRepeater } from '../index'
import AttributeSelect from '../create-fields/components/attribute-select'
import { AttributeTypesList } from 'common/types'
import { useTranslation } from 'react-i18next'

type TProps = {
  nested?: boolean
}

const AdditionalOptions: FC<TProps> = (props) => {
  const {
    values: { type },
  } = useFormikContext()
  const { t } = useTranslation()
  const { nested = false } = props

  switch (type) {
    case AttributeTypesList.repeater: {
      return <AttributeRepeater title={t('repeater.add-item')} nested={nested} />
    }

    case AttributeTypesList.group: {
      return <AttributeRepeater title={t('repeater.add-group')} nested={nested} />
    }

    case AttributeTypesList.select: {
      return (
        <>
          <AttributeSelect />
        </>
      )
    }

    case AttributeTypesList.multi_select: {
      return <AttributeSelect />
    }

    default: {
      return null
    }
  }
}

export default AdditionalOptions
