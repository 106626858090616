import { AttributeTypesList } from 'common/types'
import {
  FilterCondition,
  FilterConditionMeta,
  FilterOption,
  FilterTypes,
  FilterValue,
  SelectedFilter,
} from './types'

export const filterConditions: Record<FilterCondition, FilterConditionMeta> = {
  equal: { label: { default: 'equal', string: 'is', number: '=' }, defaultValue: '' },
  not_equal: { label: { default: 'not equal', string: 'is not', number: '≠' }, defaultValue: '' },
  contain: { label: { default: 'contains' }, defaultValue: '' },
  not_contain: { label: { default: `doesn't contain` }, defaultValue: '' },
  empty: { label: { default: 'empty' }, defaultValue: true, emptyValue: true },
  not_empty: { label: { default: 'is not empty' }, defaultValue: true, emptyValue: true },
  range_gte: { label: { default: '', number: '>=' }, defaultValue: '' },
  range_gt: { label: { default: '', number: '>', date_time: 'is after' }, defaultValue: '' },
  range_lte: { label: { default: '', number: '<=' }, defaultValue: '' },
  range_lt: { label: { default: '<', date_time: 'is before' }, defaultValue: '' },
  range_btw: { label: { default: 'is between' }, defaultValue: { from: '', to: '' } },
}

export const getFilterTypeByAttrType = (attrType: string): FilterTypes => {
  if (attrType === 'string') {
    return 'string'
  }
  if (attrType === 'integer' || attrType === 'decimal') {
    return 'number'
  }
  if (attrType === 'date_time') {
    return 'date_time'
  }
  if (attrType === 'contain_reference') {
    return 'contain_reference'
  }

  return 'string'
}

export const conditionsByTypes: Record<FilterTypes, FilterCondition[]> = {
  string: ['contain', 'not_contain', 'equal', 'not_equal', 'empty', 'not_empty'],
  number: ['equal', 'not_equal', 'range_gte', 'range_gt', 'range_lte', 'range_lt'],
  date_time: ['equal', 'not_equal', 'range_gt', 'range_lt', 'range_btw', 'empty', 'not_empty'],
  contain_reference: ['equal'],
}

export const getConditionDefaultValue = (condition: FilterCondition) => {
  return filterConditions[condition].defaultValue
}

export const getDefaultsForType = (attrType: string) => {
  const filterType = getFilterTypeByAttrType(attrType)
  const defaultCondition = conditionsByTypes[filterType][0]
  const defaultValue = getConditionDefaultValue(defaultCondition)

  return {
    defaultCondition,
    defaultValue,
  }
}

export const getFilterLabel = (condition: FilterCondition, filterType: FilterTypes): string => {
  const conditionLabel = filterConditions[condition].label
  return conditionLabel[filterType] || conditionLabel.default
}

export const getFilterConditionOptions = (type: FilterTypes): FilterOption[] => {
  return conditionsByTypes[type].map((item) => ({ type: item, ...filterConditions[item] }))
}

export const filterHasValue = (value: FilterValue | null): boolean => {
  if (value && typeof value === 'object') {
    return Boolean(value.from && value.to)
  }

  return Boolean(value)
}

export const convertFiltersToParams = (filters: SelectedFilter[]): any => {
  const params: any = {}

  filters.forEach((filter) => {
    const { condition, value, slug } = filter
    if (!condition || !filterHasValue(value)) return

    if (condition.includes('range')) {
      const secondCondition = condition.split('_')[1]
      if (secondCondition === 'btw') {
        params[slug] = {
          range: {
            gte: value.from,
            lte: value.to,
          },
        }
      } else {
        params[slug] = { range: { [secondCondition]: value } }
      }
    } else if (condition === 'empty' || condition === 'not_empty') {
      params[slug] = { exists: condition === 'not_empty' }
    } else {
      params[slug] = { [condition]: value }
    }
  })

  return params
}

export const filterSupportedTypes: string[] = [
  AttributeTypesList.string,
  AttributeTypesList.slug,
  AttributeTypesList.integer,
  AttributeTypesList.decimal,
  AttributeTypesList.boolean,
  AttributeTypesList.select,
]
