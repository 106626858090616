import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const selectPreviewStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 500,
      marginBottom: theme.spacing(1),
    },
    optionItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1.5, 1),
      border: `1px solid ${colors.blueGrey[50]}`,
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
    },
    field: {
      width: '40%',
    },
  })
)

export default selectPreviewStyles
