// Core
import React, { FC } from 'react'
import { Dialog, DialogActions, DialogContent, DialogProps, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
// Components
import { DialogTitle } from 'ui'
import { SubmitButton, TextField } from 'core/form'

type FormValues = {
  email: string
}

const validationSchema = yup.object<FormValues>({
  email: yup.string().email().required(),
})

type Props = {
  initialEmail: string
  onSubmit: (data: FormValues) => void
} & DialogProps

const ForgotPasswordModal: FC<Props> = (props) => {
  const { initialEmail, onSubmit, ...dialogProps } = props

  const initialValues: FormValues = {
    email: initialEmail || '',
  }

  const submitHandler = (values: FormValues) => {
    onSubmit(values)
  }

  return (
    <Dialog {...dialogProps} maxWidth="sm" fullWidth>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={submitHandler}
      >
        <Form>
          <DialogTitle onClose={dialogProps.onClose as any}>Reset password</DialogTitle>
          <DialogContent>
            <Typography
              component="p"
              variant="caption"
              color="textSecondary"
              style={{ marginBottom: 10 }}
            >
              Enter your email below, and we'll send you email allowing you to reset password
            </Typography>
            <TextField label="Email" name="email" required />
          </DialogContent>
          <DialogActions>
            <SubmitButton variant="contained" color="primary" title="Send" />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default ForgotPasswordModal
