import React, { FC } from 'react'
import { DateGridColumn } from '../../types'
import useDateUtils from 'core/date/use-date-utils'

type Props = {
  column: DateGridColumn
  rowData: any
}

export const DateCell: FC<Props> = ({ column, rowData }) => {
  const value = rowData[column.field]
  const { formateDate } = useDateUtils()

  if (!value) return null

  return <>{formateDate(value)}</>
}
