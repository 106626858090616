// Core
import React, { FC, useCallback, useMemo } from 'react'
import { Pagination as MuiPagination } from '@material-ui/lab'
// Styles
import useStyles from './pagination.styles'

type Props = {
  children?: never
  page?: number
  total: number
  onChange?: (page: number) => void
}

const Pagination: FC<Props> = (props) => {
  const { page, total, onChange } = props
  const classes = useStyles()

  const count = useMemo(() => Math.ceil(total / 30), [total])

  const changeHandler = useCallback(
    (e: unknown, val: number) => {
      if (onChange) onChange(val)
    },
    [onChange]
  )

  if (count <= 1) return null

  return (
    <MuiPagination
      color="primary"
      page={page}
      count={count}
      onChange={changeHandler}
      className={classes.root}
    />
  )
}

export default Pagination
