import React, { FC } from 'react'
import { Typography } from '@material-ui/core'

type Url = { url: string }

type Props = {
  entityUrls: Url[]
}

const getUrl = (urls: Url[]) => {
  if (!Array.isArray(urls) || urls.length === 0) return null
  return urls[0].url === '/' ? '/' : `/${urls[0].url?.split('/').at(-1)}`
}

const SlugLabel: FC<Props> = ({ entityUrls }) => {
  const url = getUrl(entityUrls)

  if (!url) return null

  return (
    <Typography variant="caption" color="textSecondary" component="div" style={{ lineHeight: 1 }}>
      {url}
    </Typography>
  )
}

export default SlugLabel
