import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { TextField } from 'core/form'
// Material ui
import { Button, Grid, Typography } from '@material-ui/core'
// Hooks
import { useGetNavigationContext } from '../../../../hooks'
// Types
import { EnumNavigationMethod, EnumNavLinkTypes } from '../../../../types'

export const CustomForm = () => {
  const { t } = useTranslation()
  const { saveNavigationItem } = useGetNavigationContext()

  const initialData = {
    slug: '',
    title: '',
  }

  const handleSubmit = useCallback(
    (formProps, { resetForm }) => {
      saveNavigationItem(
        [
          {
            title: formProps.title,
            slug: formProps.slug.replace(/ /g, ''),
            type: EnumNavLinkTypes.NAVIGATION_CUSTOM,
          },
        ],
        EnumNavigationMethod.CREATE
      )
      resetForm()
    },
    [saveNavigationItem]
  )

  return (
    <Formik initialValues={initialData} onSubmit={handleSubmit}>
      {() => (
        <Form>
          <Grid container spacing={2} alignItems={'center'} style={{ padding: '15px 0' }}>
            <Grid item xs={2}>
              <Typography component={'p'}>{t('labels.url')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField name={'slug'} label={t('labels.https')} />
            </Grid>
            <Grid item xs={2}>
              <Typography component={'p'}>{t('labels.text')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField name={'title'} label={t('labels.text')} />
            </Grid>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginLeft: 'auto' }}
            >
              {t('attrs.create-menu')}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
