import { SelectEnum } from 'modules/new-entity/types'

export const enumToOptions = (enums: SelectEnum[]) => {
  return enums.map((item) => {
    const option = {
      label: item.name,
      value: item.id,
      // TODO rename value to id and _value to value
      _value: item.value,
    }

    return option
  })
}
