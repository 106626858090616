// Core
import React, { ComponentType, FC, createElement } from 'react'
import { Box, Grid, IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
// Components
import { SelectField } from 'core/form'
import OperatorDivider from '../operator-divider'
import DeviceField from './value-fields/device-field'
import LocationField from './value-fields/location-field'
// Types
import { SegmentRule, SegmentRuleTypes } from 'modules/segments/types'
import { ValueFieldProps } from './value-fields/types'

type Props = {
  data: SegmentRule
  isLast: boolean
  fieldsPath: string
  onDelete: () => void
}

const ValueFieldsMap: Record<SegmentRuleTypes, ComponentType<ValueFieldProps>> = {
  device: DeviceField,
  location: LocationField,
}

const RuleFields: FC<Props> = (props) => {
  const { data, isLast, fieldsPath, onDelete } = props

  return (
    <Box mb={1}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <SelectField
            label="Type"
            name={`${fieldsPath}.type`}
            options={[
              { value: 'location', label: 'Location' },
              // { value: 'device', label: 'Device' },
            ]}
            size="small"
            hasEmpty={false}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectField
            label="Operator"
            name={`${fieldsPath}.operator`}
            options={[
              { value: 'is', label: 'Is' },
              { value: 'is not', label: 'Is Not' },
            ]}
            size="small"
            hasEmpty={false}
          />
        </Grid>
        <Grid item xs={5}>
          {createElement(ValueFieldsMap[data.type], { fieldPath: `${fieldsPath}.data` })}
        </Grid>
        <Grid
          item
          xs={1}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <IconButton size="small" onClick={onDelete}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
      {!isLast && <OperatorDivider text="Or" />}
    </Box>
  )
}

export default RuleFields
