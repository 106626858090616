// Core
import { useQuery, UseQueryResult } from 'react-query'
import { httpService } from 'core/data'
import { toast } from 'react-toastify'
import { SmartSelectValue } from '../types'

type UseGetData = (
  source: string,
  page: number,
  search: string,
  reqParams: any,
  value: SmartSelectValue | null | undefined,
  onSuccess: (data: unknown) => void
) => UseQueryResult<any>

export const useGetData: UseGetData = (source, page, search, reqParams, value, onSuccess) =>
  useQuery(
    [source, page, search, reqParams, value],
    () => {
      const params: Record<string, any> = { page, search, ...reqParams }
      if (source === 'entities' && !reqParams.template) {
        params.term = { status: 'publish' }
      }

      if (!search) delete params.search

      return httpService.get(`/${source}`, { params })
    },
    {
      onSuccess,
      onError: (e: any) => {
        if (e.response.status) {
          toast.error(`${e.response.status}-${e.message}`)
        } else {
          console.error(e)
        }
      },
    }
  )
