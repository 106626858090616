import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  })
)
