import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '@global': {
        '.validation-group': {
          display: 'flex',
          '& .MuiFormControl-root': {
            minWidth: '30%',
            marginRight: 16,
            marginBottom: 16,
          },
        },
      },
    },
  })
)

export default useStyles
