import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const logoStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    },
    logo: {
      fontSize: '2.25rem',
      color: theme.palette.common.white,
    },
    text: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: '#FFF',
      marginLeft: theme.spacing(1.5),
    },
  })
)

export default logoStyles
