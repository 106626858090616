import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const actionAreaStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    actionArea: {
      zIndex: 1,
      position: 'absolute',
      display: 'flex',
      // flexDirection: 'column',

      justifyContent: 'space-between',
      alignItems: 'center',
      top: '-3px',
      left: '50%',
      transform: 'translateX(-50%)',
      padding: theme.spacing(0.3, 0),
      borderRadius: theme.spacing(0.8),
      backgroundColor: '#fff',
      border: `1px solid ${colors.blueGrey['100']}`,
      opacity: 0,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.standard,
      }),
    },

    actionBtn: {
      padding: theme.spacing(0.8),
    },
  })
)

export default actionAreaStyles
