// Core
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Grid } from '@material-ui/core'
// Components
import DraggableItem from 'modules/new-entity/components/page-builder/components/draggable-item'
// Types
import { DndTypes, IEntityWidget } from 'modules/new-entity/types'
import { SectionContainer } from 'core/types/layouts'

type Props = {
  widgets: IEntityWidget[]
  container: SectionContainer
  isDraggingOver: boolean
  handleInsertWidget: (containerId: string, widgetIndex: number) => void
  submittedOnce?: boolean
  disabled?: boolean
}

const Widgets: React.FC<Props> = (props) => {
  const { widgets, container, isDraggingOver, handleInsertWidget, submittedOnce, disabled } = props

  const containerId = container.id

  return (
    <Droppable droppableId={containerId} type={DndTypes.widget}>
      {(providedDrop) => {
        return (
          <Grid innerRef={providedDrop.innerRef} container spacing={1}>
            {widgets &&
              widgets.map((widget, widgetIndex: number) => {
                return (
                  <DraggableItem
                    disableMoveDown={widgets.length - 1 === widgetIndex}
                    disableMoveUp={widgetIndex === 0}
                    data={{
                      ...widget,
                      'container-slug': container.slug,
                    }}
                    draggableId={`${containerId}-${widget.id}`}
                    key={`${containerId}-${widget.id}`}
                    index={widgetIndex}
                    containerId={containerId}
                    onInsertWidget={handleInsertWidget}
                    isDraggingOver={isDraggingOver}
                    submittedOnce={submittedOnce}
                    disabled={disabled}
                  />
                )
              })}
            {providedDrop.placeholder}
          </Grid>
        )
      }}
    </Droppable>
  )
}

export default Widgets
