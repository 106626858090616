// Core
import React, { ComponentType, FC } from 'react'
import { Box, TextField } from '@material-ui/core'
// Components
import DateTimePicker from 'ui/date-time-picker'
import ContainReference from './contain-reference'
// Types
import { ValueProps } from './types'

type Props = {} & ValueProps

const valueComponents: Record<string, ComponentType<ValueProps>> = {
  contain_reference: ContainReference,
}

const FilterValue: FC<Props> = (props) => {
  const { slug, type, condition, value, onChange } = props
  const isDateTime = type === 'date_time'

  const ValueComponent = isDateTime ? DateTimePicker : TextField
  const valueComponentsProps: any = isDateTime ? {} : { variant: 'outlined' }

  if (valueComponents[type]) {
    const Component = valueComponents[type]
    return <Component {...props} />
  }

  if (condition === 'range_btw' && 'from' in value) {
    return (
      <Box>
        <Box>
          <ValueComponent
            value={value.from}
            onChange={(e) => onChange(slug, e.target.value, 'from')}
            size="small"
            label="From"
            type={type === 'number' ? 'number' : 'text'}
            disabled={!condition}
            fullWidth
            {...valueComponentsProps}
          />
        </Box>
        <Box mt={2}>
          <ValueComponent
            value={value.to}
            onChange={(e) => onChange(slug, e.target.value, 'to')}
            size="small"
            label="To"
            type={type === 'number' ? 'number' : 'text'}
            disabled={!condition || !value.from}
            fullWidth
            minDate={value.from}
            {...valueComponentsProps}
          />
        </Box>
      </Box>
    )
  }

  return (
    <ValueComponent
      value={value}
      onChange={(e) => onChange(slug, e.target.value)}
      size="small"
      label="value"
      type={type === 'number' ? 'number' : 'text'}
      disabled={!condition}
      {...valueComponentsProps}
    />
  )
}

export default FilterValue
