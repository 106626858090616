// Core
import React, { FC, useMemo } from 'react'
// Hooks
import useDateUtils from 'core/date/use-date-utils'
// Utils
import { getFilterLabel, getFilterTypeByAttrType } from '../utils'
// Types
import { FilterValue, SelectedFilter } from '../types'
import ContainReference from './contain-reference'

const FilterLabel: FC<{ data: SelectedFilter }> = ({ data }) => {
  const { formateDate } = useDateUtils()
  const filterType = getFilterTypeByAttrType(data.type)

  const conditionLabel = !data.condition ? '' : getFilterLabel(data.condition, filterType)

  const valueLabel = useMemo(() => {
    if (filterType === 'contain_reference') {
      return <ContainReference value={data.value} />
    }

    const formatValue = (value: FilterValue | null) => {
      if (filterType === 'date_time' && typeof value === 'string') {
        return formateDate(value)
      }
      return value
    }

    const valueLabel =
      data.condition === 'range_btw' && data.value && typeof data.value === 'object'
        ? `${formatValue(data.value?.from)} - ${formatValue(data.value?.to)}`
        : formatValue(data.value)

    return valueLabel
  }, [data.condition, data.value, filterType, formateDate])

  return (
    <span>
      <b>{data.title}</b> {conditionLabel} <b>{valueLabel}</b>
    </span>
  )
}

export default FilterLabel
