import React, { FC, useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { getIdFromIri } from 'core/utils'
import { generateBreadcrumbs } from 'modules/media/utils'
import { MediaFolder, MediaListItem } from 'modules/media/types'

type Props = {
  media: MediaListItem
  foldersData: MediaFolder[]
}

export const LocationCell: FC<Props> = ({ media, foldersData }) => {
  const path = useMemo(() => {
    const folderId = +getIdFromIri(media.folder)
    const breadcrumbs = generateBreadcrumbs(foldersData, folderId)

    return breadcrumbs.reduce((str, item, index) => {
      const separator = index === 0 ? '' : '/'
      return `${str}${separator}${item.name}`
    }, '')
  }, [foldersData, media])

  return (
    <Box maxWidth={200}>
      <Typography noWrap title={path} variant="body2">
        {path}
      </Typography>
    </Box>
  )
}
