import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const widgetItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      position: 'relative',
      flexWrap: 'wrap',
      padding: 0,
      marginTop: '4px',
      border: '1px solid transparent',
      boxShadow: '0px 4px 12px 1px rgba(0, 0, 0, 0.12)',
      borderRadius: theme.spacing(0.5),
      transition: theme.transitions.create(['border-color'], {
        duration: theme.transitions.duration.standard,
      }),
      backgroundColor: '#FFFFFF',
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      '&$isDraggingOver': {
        borderColor: 'transparent',
      },
      '&:has(.Mui-error)': {
        borderColor: theme.palette.error.main,
      },
    },
    isPreview: {
      marginTop: theme.spacing(1.25),
    },
    isGlobal: {
      backgroundColor: '#f4f2ff',
    },
    isDraggingOver: {},
    header: {
      position: 'relative',
      display: 'flex',
      width: '100%',
    },

    toggleWidget: {
      position: 'absolute',
      display: 'block',
      top: '-1px',
      right: '-1px',
      bottom: '-1px',
      left: '-1px',
      cursor: 'pointer',
    },
    hidden: {
      display: 'none',
    },
    dndBtn: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3, 1.5),

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        inset: 0,
      },
    },
    dndBtnDisabled: {
      opacity: 0.5,
    },
    content: {
      display: 'flex',
      flex: 1,
      padding: theme.spacing(2, 2, 2, 0),
    },
    toggleBtn: {
      marginRight: theme.spacing(1),
      padding: 0,

      '&:hover': {
        background: 'transparent',
      },
    },
    info: {
      display: 'inline',
      fontSize: '15px',
      lineHeight: 1.5,
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
      wordBreak: 'break-word',

      '& > *': {
        marginRight: theme.spacing(0.5),
      },
    },
    widgetIcon: {
      width: '24px',
      height: '24px',
      marginRight: theme.spacing(1),
      transform: 'translateY(6px)',
    },
    chip: {
      background: defaultThemeColors.purpleLight,
    },
    settings: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      marginLeft: 'auto',
    },
    expandPanel: {
      width: '100%',
      padding: theme.spacing(0, 2),
    },
    expandPanelOpen: {
      padding: theme.spacing(1.5, 2, 3),
    },
  })
)

export default widgetItemStyles
