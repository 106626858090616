// Core
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { FieldArray, FormikProps, getIn, isString } from 'formik'
import { Collapse } from '@material-ui/core'
// Components
import { Header, RenderValues } from './components'
import GroupWrapper from '../group-wrapper'
// Hooks
import useGroupErrors from '../../hooks/use-group-errors'
// Types
import { AttributeData, EavResourceType, TOptions } from 'modules/new-entity/types'

type TProps = {
  data: AttributeData
  name: string
  options: TOptions
  selfType: 'entity' | 'widget'
  resourceType: EavResourceType
  disabled?: boolean
  required?: boolean
}

const Repeater: FC<TProps> = (props) => {
  const { data, name, options, selfType, disabled, resourceType, required } = props

  const { hasError, errorsCount } = useGroupErrors(name)

  const disablePushInsert = useCallback(
    (values: any[]) => {
      if (!values || !data.validationRules.count?.max) return false
      return values.length >= data.validationRules.count?.max
    },
    [data.validationRules.count?.max]
  )

  const [collapseIsOpen, setCollapseIsOpen] = useState(true)

  useEffect(() => {
    if (!hasError) return
    setCollapseIsOpen(true)
  }, [hasError])

  const collapseHandler = useCallback(() => {
    setCollapseIsOpen(!collapseIsOpen)
  }, [collapseIsOpen])

  const canShowError = useCallback(
    (form: FormikProps<any>) => {
      return form.errors && isString(getIn(form.errors, name)) && hasError
    },
    [hasError, name]
  )

  return (
    <FieldArray
      name={name}
      render={({ form, insert, push, remove, move }) => {
        return (
          <GroupWrapper hasError={hasError}>
            <Header
              push={push}
              disablePushInsert={disablePushInsert(getIn(form.values, name))}
              name={data.name}
              hint={data.hint}
              attrData={data}
              collapseControl={collapseHandler}
              collapseValue={collapseIsOpen}
              disabled={disabled}
              errorsCount={errorsCount}
              required={required}
            />

            <Collapse in={collapseIsOpen}>
              <RenderValues
                resourceType={resourceType}
                selfType={selfType}
                name={name}
                values={getIn(form.values, name) || { value: [] }}
                attrData={data}
                // Actions
                insert={insert}
                remove={remove}
                move={move}
                disablePushInsert={disablePushInsert(getIn(form.values, name))}
                // Options
                options={options}
                disabled={disabled}
              />
            </Collapse>

            {canShowError(form) && <div style={{ color: 'red' }}>{getIn(form.errors, name)}</div>}
          </GroupWrapper>
        )
      }}
    />
  )
}

export default memo(Repeater)
