import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const widgetItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    widthSelect: {
      position: 'relative',
      width: '125px',

      '& .MuiOutlinedInput-input': {
        padding: '10.5px 14px 10.5px 50px',
      },

      '&::before': {
        position: 'absolute',
        display: 'block',
        content: '""',
        top: '1px',
        left: '40px',
        width: '1px',
        height: 'calc(100% - 2px)',
        backgroundColor: '#c4c4c4',
      },
    },
    widthSelectDisabled: {},
    desktopIcon: {
      position: 'absolute',
      top: '50%',
      left: '7px',
      transform: 'translateY(-50%)',

      '$widthSelectDisabled &': {
        opacity: 0.5,
      },
    },
  })
)

export default widgetItemStyles
