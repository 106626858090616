// Core
import { useQuery, UseQueryResult } from 'react-query'
// Hooks
import { useAppContext } from 'core/app'
// Services
import { ApiResponseCollection, httpService } from 'core/data'
// Utils
import { getIdFromIri } from 'core/utils'
import { sitesService } from '../services'
// Types
import { AffiliateSettings, Site, SiteSettings } from '../types'
import { Localization } from 'core/types'

type QueryData = {
  sites: Site[]
  affiliateSettings: AffiliateSettings
  locales: {
    system: Localization[]
    site: Localization[]
  }
  siteSettings: SiteSettings
}

type UseGetSites = (onSuccess?: (data: QueryData) => void) => UseQueryResult<QueryData>

const getLocalesByIds = (iris: string[], locales: Localization[]) => {
  return locales.filter((item) => iris.includes(item['@id']))
}

const getLocales = async (site: Site) => {
  const combineLocalesIds = [...site.systemLocalizations, ...site.siteLocalizations].map((item) =>
    getIdFromIri(item)
  )

  const { data } = await httpService.get<ApiResponseCollection<Localization>>('/localizations', {
    params: { code: combineLocalesIds },
  })

  return {
    system: getLocalesByIds(site.systemLocalizations, data['hydra:member']),
    site: getLocalesByIds(site.siteLocalizations, data['hydra:member']),
  }
}

export const useGetSites: UseGetSites = (onSuccess) => {
  const { user, activeSite } = useAppContext()

  return useQuery(
    ['sites-global'],
    async () => {
      const userSiteIds = user.sites.map((site: string) => getIdFromIri(site))
      const sitesReq = httpService.get<any>('/sites', {
        params: { pagination: false, id: userSiteIds },
      })
      const localesReq = getLocales(activeSite)
      const affiliateSettingsReq = sitesService.getAffiliateSettings(activeSite.token)
      const siteSettingsReq = sitesService.getSiteSettings(activeSite.token)

      const [sites, locales, affiliateSettings, siteSettings] = await Promise.all([
        sitesReq,
        localesReq,
        affiliateSettingsReq,
        siteSettingsReq,
      ])

      return {
        sites: sites.data['hydra:member'],
        locales,
        affiliateSettings,
        siteSettings,
      }
    },
    {
      onSuccess,
      enabled: Boolean(user),
      select: (data) => {
        data.sites = data.sites.filter((item: Site) => user.sites.includes(item['@id']))
        return data
      },
    }
  )
}
