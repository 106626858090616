import React, { FC, useCallback } from 'react'
// Material Components
import { Icon, IconButton } from '@material-ui/core'
// Components
import { User } from './components'
// Styles
import menuStyles from './menu.styles'
// Hooks
import { useAppContext } from 'core/app'

const Menu: FC = (props) => {
  const classes = menuStyles()
  const {
    actions: { logout },
  } = useAppContext()

  const logoutHandler = useCallback(() => {
    logout(true)
  }, [logout])

  return (
    <>
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <IconButton color="inherit" onClick={logoutHandler}>
          <Icon className="icon-log-out" />
        </IconButton>

        <User />
      </div>
    </>
  )
}

export default Menu
