import { httpService } from 'core/data'
import { HydraResponse } from 'core/types'
import { useQuery } from 'react-query'
import { MediaFolder } from '../types'
import { buildTree } from '../utils'
import { useGetActiveSite } from 'modules/sites'
import { useMemo } from 'react'

export const useGetMediaFolders = () => {
  const activeSite = useGetActiveSite()

  const { data, isLoading, refetch } = useQuery(
    ['media-folders'],
    () => {
      return httpService
        .get<HydraResponse<MediaFolder>>('/media_folders')
        .then((res) => res.data['hydra:member'])
    },
    {
      select: (data) => {
        return {
          foldersData: data,
          foldersTreeData: buildTree(data),
        }
      },
    }
  )

  const defaultFolder = useMemo(() => {
    if (!data?.foldersTreeData) return null
    const findFolder = data.foldersTreeData[0].children.find(
      (folder) => folder.site === activeSite?.['@id']
    )

    return findFolder?.id ?? null
  }, [activeSite, data?.foldersTreeData])

  return {
    foldersData: data?.foldersData,
    foldersTreeData: data?.foldersTreeData,
    isLoading,
    defaultFolder,
    refetchFolders: refetch,
  }
}
