import React, { FC, useMemo } from 'react'
// Types
import { Attribute } from 'core/types/attribute'
// Material Components
import { Typography } from '@material-ui/core'
// Styles
import useStyles from './options-preview.styles'
// Components
import { GetTitle } from 'core/components'
// Utils
import { avaiableNameable } from 'common/components/options-modal/utils/avaiable-for-nameable'
import { useTranslation } from 'react-i18next'
import PreviewItem from './preview-item'

type TProps = {
  attrData: Attribute
  values: any
  isNested?: boolean
}

type ToptionsArray = {
  isSpecialCase?: boolean
  title: string
  value: any
  note?: string
}

const OptionsPreview: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { values, attrData, isNested } = props

  const isAvaiableNameable: boolean = avaiableNameable.includes(attrData.attributeType.type)

  const optionsArray: ToptionsArray[] = useMemo(() => {
    return [
      {
        title: t('attrs.field-width'),
        value: values.fieldWidth ? values.fieldWidth : 12,
        note: t('global.cols'),
      },
      {
        title: t('attrs.localy-hidden'),
        value: values.hidden,
      },
      {
        title: t('attrs.show-as-column'),
        value: values.showAsColumn,
      },
      {
        title: t('attrs.column-order'),
        value: values.columnOrder,
      },
      {
        title: t('attrs.globally-hidden'),
        value: attrData.options.hidden,
      },
      {
        title: t('attrs.field-type'),
        value: attrData.options.type,
      },
      {
        title: t('attrs.show-in-sidebar'),
        value: attrData.options.show_in_sidebar,
      },
      {
        title: t('attrs.preserve-attr'),
        value: attrData.options.preservation,
      },
      {
        title: t('attrs.skip_from_copy'),
        value: attrData.options.skip_from_copy,
      },
      {
        title: t('attrs.show-as-links-modal'),
        value: attrData.options.show_links_modal,
      },
      {
        title: t('attrs.create-button'),
        value: attrData.options.create_entity_button,
      },
      {
        isSpecialCase: !isNested && isAvaiableNameable,
        title: t('attrs.as-title'),
        value: values.nameable ? `${values.nameable}` : 'false',
      },
    ]
    // eslint-disable-next-line
  }, [
    attrData.options.create_entity_button,
    attrData.options.hidden,
    attrData.options.preservation,
    attrData.options.show_in_sidebar,
    attrData.options.show_links_modal,
    attrData.options.type,
    isAvaiableNameable,
    isNested,
    t,
    values.fieldWidth,
    values.hidden,
    values.nameable,
    values.showAsColumn,
    values.columnOrder,
  ])

  return (
    <div className={classes.options}>
      {values !== null && (
        <>
          {values.dependsOn && (
            <>
              <Typography variant="caption">
                <strong>{t('condition.has')}</strong> <GetTitle value={values.dependsOn} />
              </Typography>
              <Typography variant="caption">
                <strong> {t('condition.with-value')} </strong> {values.condition}{' '}
                {values.conditionValue}
              </Typography>
            </>
          )}
        </>
      )}

      {optionsArray.map((item: ToptionsArray) => {
        return (
          <PreviewItem
            key={item.title}
            isShown={item.isSpecialCase}
            title={item.title}
            value={item.value}
          />
        )
      })}
    </div>
  )
}

export default OptionsPreview
