// Core
import React, { FC, ReactElement } from 'react'
import { Box } from '@material-ui/core'

type TProps = {
  title: string
}

const FormHolder: FC<TProps> = (props) => {
  const { children } = props

  return (
    <Box>
      <Box bgcolor="common.white" px={4} py={2}>
        {children as ReactElement}
      </Box>
    </Box>
  )
}

export default FormHolder
