// Core
import React, { FC } from 'react'
import { Select, TableCell } from '@material-ui/core'
// Utils
import { Priority } from 'modules/sitemap-settings/utils'
// Types
import { CellProps } from '../cell-props'

const PriorityCell: FC<CellProps> = (props) => {
  const { isEdit, data, form, name } = props

  return (
    <TableCell size="small" width="10%">
      {!isEdit ? (
        data.priority
      ) : (
        <Select native value={form.values.priority} name={name} onChange={form.handleChange}>
          {Priority.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
      )}
    </TableCell>
  )
}

export default PriorityCell
