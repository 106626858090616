import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
// hooks
import { useConfirmation } from 'core/confirmation'
import { useNotify } from 'core/hooks'
import { useRevalidate } from 'core/hooks/use-revalidate'
// Services
import { EntityService } from '../utils'
// Types
import { EntityStatus } from '../types'

type Params = {
  onSuccess?: () => void
}

export const useChangeEntityStatus = (
  entityId: number,
  status: EntityStatus,
  params: Params = {}
) => {
  const { setConfirmation } = useConfirmation()
  const { t } = useTranslation()
  const notify = useNotify()

  const { getEntityUrls, revalidateEntity } = useRevalidate()

  const changeStatusM = useMutation(
    async (newStatus: EntityStatus) => EntityService.changeEntityStatus(entityId, newStatus),
    {
      onSuccess: async () => {
        notify(`Successfully changed status`, { type: 'success' })
        params?.onSuccess?.()

        if (status === 'publish') {
          const entityUrls = await getEntityUrls('entities', entityId, 'entity')

          if (entityUrls) {
            await revalidateEntity(entityId, 'entity', ...entityUrls)
          }
        }
      },
      onError: () => {
        notify('Something went wrong', { type: 'error' })
      },
    }
  )

  const changeEntityStatus = useCallback(
    (newStatus: EntityStatus) => {
      const messages = {
        title: '',
        content: '',
      }

      if (newStatus === 'archive') {
        messages.title = t('global.archive')
        messages.content = t('notify.archive')
      } else if (newStatus === 'draft') {
        messages.title = 'Restore'
        messages.content = 'Status will be changed to “Draft” do you want to continue?'
      }

      setConfirmation({
        open: true,
        ...messages,
        onSuccess: () => changeStatusM.mutate(newStatus),
      })
    },
    [setConfirmation, t, changeStatusM]
  )

  return { changeEntityStatus, changeStatusM }
}
