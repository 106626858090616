// Core
import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
// Hooks
import { useRelationsError } from './use-relations-error'
import { useNotify } from 'core/hooks'
import { useConfirmation } from 'core/confirmation'
import { useRevalidate } from 'core/hooks/use-revalidate'
// Services
import { httpService } from 'core/data'

type Params = {
  invalidateKey?: string
  onSuccess?: () => void
  isWidgetType?: boolean
}

export const useDeleteEntity = (entityId: number, params: Params = {}) => {
  const { invalidateKey, onSuccess, isWidgetType } = params
  const queryClient = useQueryClient()
  const { notifyRelationsError, isRelationsError } = useRelationsError()
  const { setConfirmation } = useConfirmation()
  const notify = useNotify()
  const { t } = useTranslation()
  const mainPath = isWidgetType ? 'widgets' : 'entities'
  const { getEntityUrls, revalidateEntity } = useRevalidate()

  const deleteM = useMutation(
    async () => {
      const entityUrls = await getEntityUrls(mainPath, entityId, 'entity')

      await httpService.delete(`/${mainPath}/${entityId}`)

      if (entityUrls) {
        await revalidateEntity(entityId, 'entity', ...entityUrls)
      }
    },
    {
      onSuccess: () => {
        if (invalidateKey) {
          queryClient.invalidateQueries(invalidateKey)
        }
        notify(`Entity ${t('actions.deleted')}`, { type: 'success' })
        if (onSuccess) onSuccess()
      },
      onError: (error) => {
        if (isRelationsError(error)) {
          notifyRelationsError(error)
        } else {
          notify('Something went wrong', { type: 'error' })
        }
      },
    }
  )

  const deleteEntity = useCallback(() => {
    setConfirmation({
      open: true,
      title: t('global.delete'),
      content: t('notify.delete'),
      onSuccess: deleteM.mutate,
    })
  }, [deleteM.mutate, setConfirmation, t])

  return { deleteEntity, deleteEntityM: deleteM }
}
