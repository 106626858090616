import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const dashboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcome: {
      fontSize: '2.4rem',
      lineHeight: '100%',
      letterSpacing: '0.3px',
      color: '#3F51B5',
      gridArea: 'header',
      padding: '10px 0 20px 10px',
    },
    container: {
      padding: '20px 40px',
      display: 'flex',
      flexDirection: 'column',
    },
    box: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    },
    paper: {
      height: '100px',
      boxShadow: '0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)',
      borderRadius: '4px',
      margin: '12px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      transition: 'all 0.1s linear',
      boxSizing: 'border-box',
      border: '2px solid transparent',
      '&:hover': {
        border: '2px solid #3F51B5',
      },
    },
    icon: {
      verticalAlign: 'middle',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

export default dashboardStyles
