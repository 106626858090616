// Core
import React, { useMemo, useState } from 'react'
import clsx from 'clsx'
// Components
import ActionsPopover from 'common/components/actions-popover'
// Types
import { Action } from 'core/data/types/actions'
import { Actions as ActionsType } from 'modules/new-entity/components/page-builder/types'
// Styles
import useStyles from './actions.styles'

type Props = {
  actions: ActionsType
  disabled?: boolean
}

const commonActionProps = {
  action: () => {},
  condition: false,
}

const defaultMoveUpAction = {
  name: 'move-up',
  label: 'Move up',
  icon: 'icon-arrow-up',
  ...commonActionProps,
}

const defaultMoveDownAction = {
  name: 'move-down',
  label: 'Move down',
  icon: 'icon-arrow-down',
  ...commonActionProps,
}

const defaultDuplicateAction = {
  name: 'duplicate',
  label: 'Duplicate',
  icon: 'icon-copy',
  ...commonActionProps,
}

const defaultDeleteAction = {
  name: 'delete',
  label: 'Delete',
  icon: 'icon-trash-2',
  ...commonActionProps,
}

const Actions: React.FC<Props> = (props) => {
  const {
    actions: {
      moveUpAction,
      moveDownAction,
      duplicateAction,
      changeVisibilityAction,
      deleteAction,
    },
    disabled,
  } = props

  const [showActions, setShowActions] = useState(false)

  const classes = useStyles()

  const actions: Action[] = useMemo(() => {
    const actionWrapper = (action: () => void) => () => {
      action()
      setShowActions(false)
    }

    return [
      { ...defaultMoveUpAction, ...moveUpAction },
      { ...defaultMoveDownAction, ...moveDownAction },
      { ...defaultDuplicateAction, ...duplicateAction },
      ...(changeVisibilityAction ? [changeVisibilityAction] : []),
      { ...defaultDeleteAction, ...deleteAction },
    ].map((actionItem) => ({ ...actionItem, action: actionWrapper(actionItem.action) }))
  }, [changeVisibilityAction, deleteAction, duplicateAction, moveDownAction, moveUpAction])

  const hasActions = useMemo(() => actions.some((action) => action.condition), [actions])

  if (!hasActions) return null

  return (
    <ActionsPopover
      actions={actions}
      showActions={showActions}
      onShowActions={setShowActions}
      loading={false}
      disabled={disabled}
      isBtnRef
      btnClassName={clsx(classes.btn, { [classes.btnActive]: showActions })}
      popoverClassName={classes.popover}
      anchorOriginHorizontal="center"
      transformOriginHorizontal="center"
    />
  )
}

export default Actions
