import { SidebarPositionType } from './sidebar-position'

export type SectionOptions = {
  hasSidebar: boolean
  sidebarWidth: number
  sidebarPosition: SidebarPositionType
  background?: SectionBackground
}

export type SectionBackground = Backgrounds.LIGHT | Backgrounds.DARK

export enum Backgrounds {
  LIGHT = 'light',
  DARK = 'dark',
}
