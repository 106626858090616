// Core
import React, { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { CircularProgress } from '@material-ui/core'
// Components
import WidgetItem from '../../widget-item'
// Utils
import { getIdFromIri } from 'core/utils'
// Services
import { httpService } from 'core/data'
// Types
import { IEntity, IEntityWidget } from '../../../../../../types'

type Props = {
  widgetData: IEntityWidget
}

const CollectionPreview: FC<Props> = (props) => {
  const { widgetData } = props

  const [entityId, setEntityId] = useState(() => {
    const findValue = widgetData.values.find((valItem) => {
      return typeof valItem.value === 'string' && valItem.value.startsWith('/api/entities')
    })
    return findValue ? getIdFromIri(findValue.value) : undefined
  })

  /**
   * Global widgets collection select change watcher
   * Triggers in /attributes-form/components/form-control/utils/control-renderer.tsx
   * by CustomEvent('collection_change', { widgetId, id })
   */
  useEffect(() => {
    function handler(e: any) {
      const {
        detail: { id, widgetId },
      } = e

      if (widgetId === widgetData.id) {
        setEntityId(getIdFromIri(id))
      }
    }
    document.addEventListener('collection_change', handler)

    return () => {
      document.removeEventListener('collection_change', handler)
    }
  }, [widgetData])

  const entityQ = useQuery(
    ['entity', entityId],
    () => {
      return httpService.get<IEntity>(`/entities/${entityId}`).then((data) => data.data)
    },
    {
      enabled: Boolean(entityId),
    }
  )

  return (
    <div>
      {entityQ.isLoading && <CircularProgress />}
      {entityQ.data?.entityWidgets.map((item) => (
        <WidgetItem
          key={item.id}
          data={item}
          disableMoveUp={true}
          disableMoveDown={true}
          previewMode
        />
      ))}
    </div>
  )
}

export default CollectionPreview
