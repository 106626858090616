import React, { createContext, FC, useContext, useState } from 'react'

export type DataGridContextProps = {
  selected: number[]
  listData: any[]
  dndActive: boolean
  draggableId: number | null
  setDraggableId: (val: number | null) => void
}

export const DataGridContext = createContext<DataGridContextProps>(null!)

export const useDataGridContext = () => {
  return useContext(DataGridContext)
}

export type DataGridProviderProps = Pick<DataGridContextProps, 'listData' | 'selected'>

export const DataGridProvider: FC<DataGridProviderProps> = ({ children, ...props }) => {
  const [draggableId, setDraggableId] = useState<number | null>(null)

  return (
    <DataGridContext.Provider
      value={{
        ...props,
        dndActive: Boolean(draggableId),
        draggableId,
        setDraggableId,
      }}
    >
      {children}
    </DataGridContext.Provider>
  )
}
