// Core
import React, { FC, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useListController } from 'core/data'
import { useTranslation } from 'react-i18next'
// Material
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { Box, CircularProgress } from '@material-ui/core'
// Components
import { Pagination } from '../pagination'
import SearchField from 'ui/search-field'
// Types
import { EnumNavigationMethod, EnumNavLinkTypes, TNavigation } from 'modules/navigation/types'
// Hooks
import { useGetNavigationContext } from 'modules/navigation/hooks'
// Utils
import { getIdFromIri } from 'core/utils'
// Styles
import { useStyles } from './entities-list.styles'

type TProps = {
  navigationData?: TNavigation
  typeId: string
}

export const EntitiesList: FC<TProps> = ({ typeId, navigationData }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { saveNavigationItem } = useGetNavigationContext()
  const dataIds = navigationData?.items.map((item) => +getIdFromIri(item.targetEntity)) || []

  const handleToggle = (value: any) => () => {
    saveNavigationItem(
      [
        {
          entityIri: value['@id'],
          type: EnumNavLinkTypes.NAVIGATION_LINK,
          title: value.name,
        },
      ],
      EnumNavigationMethod.CREATE
    )
  }

  const resource = { name: 'entities' }

  const [search, setSearch] = useState('')

  const filters = useMemo(() => {
    return {
      entityType: `/api/entity_types/${typeId}`,
      original: true,
      filter: { _name: { contain: search }, status: { equal: 'publish' } },
    }
  }, [typeId, search])

  const {
    data,
    listParams: { params, methods, meta },
  } = useListController(resource, {
    filters,
  })

  return (
    <>
      <List className={clsx(classes.root, { loader: !data })}>
        <SearchField value={search} onChange={setSearch} size="small" />
        {data && (
          <Box mt={1}>
            {data.map((value: any) => {
              const labelId = `checkbox-list-label-${value.name}`

              return (
                <ListItem
                  className={classes.listItem}
                  key={value.id}
                  role={undefined}
                  dense
                  button
                  selected={dataIds.includes(value.id)}
                >
                  <ListItemText id={labelId}>{value.name}</ListItemText>
                  <IconButton onClick={handleToggle(value)} className={classes.iconButton}>
                    <AddIcon />
                  </IconButton>
                </ListItem>
              )
            })}
            <Pagination
              page={params.pagination.page}
              setPage={methods.setPage}
              total={meta.total}
            />
          </Box>
        )}
        {!data && (
          <Box mt={1} display="flex" justifyContent="center" padding={2}>
            <CircularProgress />
          </Box>
        )}
        {data?.length === 0 && (
          <Typography component="p" style={{ padding: '5px' }}>
            {t('navigation.nothing-to-show')}
          </Typography>
        )}
      </List>
    </>
  )
}
