import { useEffect, useState } from 'react'
import { WAIT_FOR_ACCESS_TIME } from './utils'

const initialValue = WAIT_FOR_ACCESS_TIME / 1000

export function useTimer(enabled = true, onFinish?: () => void) {
  const [counter, setCounter] = useState(initialValue)

  useEffect(() => {
    if (!enabled) return

    const interval = setInterval(() => {
      setCounter((prev) => prev - 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [counter, enabled])

  useEffect(() => {
    setCounter(initialValue)
  }, [enabled])

  useEffect(() => {
    if (counter === 0) {
      onFinish?.()
    }
  }, [counter, onFinish])

  const counterValue = counter < 0 ? 0 : counter

  return {
    counter: counterValue,
  }
}
