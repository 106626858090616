import { useCallback } from 'react'
import { AttributeType } from 'core/types/attribute-type'
import { useFormikContext } from 'formik'

type UseAttributeTypeSetValue = () => (iri: string, data: AttributeType[] | undefined) => void

export const getAttrType = (_data: AttributeType[], iri: string): string => {
  const attrType = _data.filter((item: AttributeType) => item['@id'] === iri)

  return attrType[0].type || ''
}

export const useAttributeTypeSetValue: UseAttributeTypeSetValue = () => {
  const { setFieldValue } = useFormikContext()

  return useCallback(
    (iri, data) => {
      if (!data) return
      const type = getAttrType(data, iri)

      setFieldValue('attributeType', iri)
      setFieldValue('type', type)
    },
    [setFieldValue]
  )
}
