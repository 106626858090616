import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 0, 3),

      '& button': {
        marginRight: theme.spacing(2),
      },
    },
  })
)

export default useStyles
