// Core
import React, { FC, memo } from 'react'
// Components
import { Box, Grid } from '@material-ui/core'
import { ActionArea, RenderFormControls } from '../index'
// Types
import { AttributeData, TOptions, EavResourceType } from 'modules/new-entity/types'
import { TActionTypes } from '../action-area/action-area'
// Styles
import useStyles from './render-values.styles'

type TProps = {
  values: any[]
  attrData: AttributeData
  name: string
  options: TOptions
  selfType: 'entity' | 'widget'
  resourceType: EavResourceType
  disabled?: boolean
} & TActionTypes

const RenderValues: FC<TProps> = (props) => {
  const classes = useStyles()
  const { values, attrData, name, options, selfType, disabled, resourceType } = props
  const { insert, remove, disablePushInsert, move } = props

  if (!values.length) return null

  return (
    <>
      {values.map((valueData: { key: string }, index: number) => {
        return (
          <Box key={`${name}-${valueData.key}`} className={classes.formControlItem}>
            <div className={classes.count}>
              <strong>{index + 1}</strong>
            </div>
            <Grid container spacing={2}>
              <RenderFormControls
                resourceType={resourceType}
                selfType={selfType}
                attrData={attrData}
                name={name}
                index={index}
                options={options}
                disabled={disabled}
              />
            </Grid>
            {!disabled && (
              <ActionArea
                attrData={attrData}
                index={index}
                insert={insert}
                remove={remove}
                move={move}
                disablePushInsert={disablePushInsert}
              />
            )}
          </Box>
        )
      })}
    </>
  )
}

export default memo(RenderValues)
