import { ChangeEvent, useCallback, useState } from 'react'

type UseTabSetValue = () => {
  tabValue: string
  handleChange: (event: ChangeEvent<{}>, newValue: string) => void
  setTabValue: (value: string) => void
}

export const useTabSetValue: UseTabSetValue = () => {
  const [tabValue, setTabValue] = useState('tab-1')
  const handleChange = useCallback((event: ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue)
  }, [])

  return {
    tabValue,
    handleChange,
    setTabValue,
  }
}
