// Core
import React, { FC, memo, useCallback } from 'react'
// Components
import { Box, FormLabel, Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
import GroupError from '../../../group-error'
// Classes
import { DefaultValuesGenerator } from 'modules/new-entity/transformers'
// Types
import { AttributeData } from 'modules/new-entity/types'
// Styles
import useStyles from './header.styles'
// Utils
import clsx from 'clsx'
import { useSitesContext } from 'modules/sites'

type TProps = {
  push: ({ id }: THandler) => void
  disablePushInsert: boolean
  name: string
  hint: string
  attrData: AttributeData
  collapseControl: () => void
  collapseValue: boolean
  disabled?: boolean
  errorsCount: number
  required?: boolean
}

type THandler = {
  id: string
}

const Header: FC<TProps> = (props) => {
  const classes = useStyles()
  const { locales } = useSitesContext()
  const localizations = locales.site
  const {
    name,
    hint,
    push,
    disablePushInsert,
    attrData,
    collapseControl,
    collapseValue,
    disabled,
    errorsCount,
    required,
  } = props

  const setItemHandler = useCallback(() => {
    if (disablePushInsert) return
    push(new DefaultValuesGenerator(attrData.setAttributes || [], localizations).getValues())
  }, [disablePushInsert, push, attrData.setAttributes, localizations])

  return (
    <div className={classes.header}>
      <Box display="flex">
        <Typography className={classes.hint} variant={'caption'} component={'p'}>
          {hint}
        </Typography>
        <Box display="flex" alignItems="center">
          <FormLabel required={required}>{name}</FormLabel>
          <GroupError count={errorsCount} />
        </Box>
      </Box>
      <div>
        <Tooltip title={'Insert new row'} placement={'left'} data-aqa-action="insert-row">
          <span>
            <IconButton
              disabled={disablePushInsert || disabled}
              size={'small'}
              onClick={setItemHandler}
              color={'primary'}
            >
              <Icon className={'icon-add-below'} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip placement={'left'} title={'collapse all'}>
          <span>
            <IconButton
              color={'primary'}
              onClick={collapseControl}
              data-aqa-action="expand-collapse"
              size="small"
            >
              <Icon
                className={clsx({
                  'icon-chevrons-closed': !collapseValue,
                  'icon-chevrons-open': collapseValue,
                })}
              />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

export default memo(Header)
