import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const systemSlugStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 'fit-content',
    },

    placeholder: {
      fontSize: '1.1em',
      flexShrink: 0,
    },

    link: {
      display: 'inline-block',
      fontSize: '1.1em',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '640px',

      '&.fill': {
        background: '#e0e0e0',
        padding: '7px 15px',
        borderRadius: '3px',
      },
    },

    test: {
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        flex: 1,
        minWidth: 0,
        '& > h3': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },

    input: {
      '& .MuiInputBase-input': {
        padding: theme.spacing(1, 0.7),
      },
    },

    buttonGroup: {
      marginLeft: theme.spacing(2),
    },

    helperText: {
      position: 'absolute',
      top: '100%',
      left: 0,
    },

    window: {
      display: 'flex',
      alignItems: 'baseline',
    },
  })
)

export default systemSlugStyles
