// Core
import React, { FC } from 'react'
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Icon,
  LinearProgress,
} from '@material-ui/core'
import { useQuery } from 'react-query'
// Services
import { httpService } from 'core/data'
// Utils
import { getIdFromIri } from 'core/utils'
// Components
import { useTranslation } from 'react-i18next'

type Props = {
  children?: never
  entityIri: string
  onDelete: () => void
}

const ValueItem: FC<Props> = (props) => {
  const { entityIri, onDelete } = props
  const entityId = getIdFromIri(entityIri)
  const { t } = useTranslation()

  const { data, isLoading, isError } = useQuery(['entity', entityId], () => {
    return httpService.get(`/entities/${entityId}`).then((data) => data.data)
  })

  if (isError)
    return (
      <div>
        {t('errors.fetching')} "${entityId}"
      </div>
    )
  if (isLoading) return <LinearProgress style={{ marginBottom: 10 }} />

  return (
    <ListItem>
      <ListItemText>{data.name}</ListItemText>
      <ListItemSecondaryAction>
        <IconButton type="button" onClick={onDelete}>
          <Icon className="icon-delete" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ValueItem
