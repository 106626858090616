// Services
import { httpService } from 'core/data'

export type EntityReferencesRes = (
  | {
      url: string
      entityType: string
      type: 'page'
    }
  | { type: 'navigation' }
)[]

export class RevalidationService {
  static async revalidate(siteUrl: string, body: Record<string, any>) {
    await httpService.post(`${siteUrl}/api/revalidate/`, body, {
      transformRequest: [
        (data, headers) => {
          delete headers.JWTAuthorization
          delete headers['x-version']
          return JSON.stringify(data)
        },
      ],
    })
  }

  static async getEntityReferences(entityId: number, entityType: 'entity' | 'media') {
    const response = await httpService
      .get<EntityReferencesRes>(`/entity_back_references/${entityId}/${entityType}`)
      .then((data) => data.data)
    return response
  }

  static async revalidateEntity(
    siteUrl: string,
    entityId: number,
    entityType: 'entity' | 'media',
    pageUrl: string | null,
    entityRefs?: EntityReferencesRes
  ) {
    if (!entityRefs) {
      entityRefs = await RevalidationService.getEntityReferences(entityId, entityType)
    }

    const isNavigationRef = entityRefs.some((ref) => ref.type === 'navigation')
    if (isNavigationRef) {
      await RevalidationService.revalidateAll(siteUrl)
      return
    }

    const refUrls = entityRefs.filter((ref) => ref.type === 'page').map((ref) => ref.url)
    const urls = [...(pageUrl ? [pageUrl] : []), ...refUrls]
    if (urls.length) {
      await RevalidationService.revalidate(siteUrl, {
        urls,
      })
    }
  }

  static async revalidateUrls(siteUrl: string, urls: string[]) {
    await RevalidationService.revalidate(siteUrl, { urls })
  }

  static async revalidateAll(siteUrl: string) {
    await RevalidationService.revalidate(siteUrl, { global: true })
  }
}
