// Core
import React, { FC, useMemo } from 'react'
import { createPortal } from 'react-dom'
// Components
import GroupError from '../group-error'
// Utils
import { getErrorsCount } from '../../utils'

type Props = {
  id?: string
  errors: any
}

const FormErrorIndicator: FC<Props> = (props) => {
  const { id, errors } = props
  const errorsCount = useMemo(() => getErrorsCount(errors), [errors])

  if (!id) return null

  const el = document.getElementById(`widget-title-error-${id}`)

  if (!el) return null

  return createPortal(<GroupError count={errorsCount} />, el)
}

export default FormErrorIndicator
