import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const buttonAddStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      position: 'absolute',
      width: '100%',
      padding: 0,
      opacity: 0,
      zIndex: 1,
      transition: 'all 0.3s ease',

      '&:hover': {
        opacity: 1,
        backgroundColor: 'transparent',
      },

      '&::before': {
        position: 'absolute',
        display: 'block',
        content: '""',
        width: '100%',
        borderRadius: '100px',
        backgroundColor: theme.palette.primary.light,
      },

      '& > .MuiButton-label': {
        width: 'auto',
      },

      '& > .MuiTouchRipple-root': {
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
    btnContainer: {
      height: '16px',

      '&::before': {
        height: '4px',
      },

      '& > .MuiTouchRipple-root': {
        height: '4px',
      },
    },
    btnWidget: {
      top: '-13px',
      height: '13px',

      '&::before': {
        height: '2px',
      },

      '& > .MuiTouchRipple-root': {
        height: '2px',
      },
    },
    iconWrap: {
      display: 'flex',
      padding: theme.spacing(0.5),
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      zIndex: 1,
    },
  })
)

export default buttonAddStyles
