// Core
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import setWith from 'lodash.setwith'
// Hooks
import useFilters from 'modules/eav/entity/filters/use-filters'
import { useGetActiveSite } from 'modules/sites'
// Services
import { httpService } from 'core/data'
import DataService from './data-service'
import { getIdFromIri } from 'core/utils'
// Types
import { Attribute } from 'modules/eav/types'
import { HydraResponse } from 'core/types'
import { ControllerProps, Entity, EntityType, PerPage } from './types'

type EntityTypeRes = EntityType
type EntityTypeAttrsRes = HydraResponse<Attribute>
type EntitiesRes = HydraResponse<Entity>

type Params = {
  typeId: number
}

type QueryParams = {
  entityType: string
  showAsColumn: boolean
  hidden: boolean
  page: number
  itemsPerPage?: number
  search: string
  order?: Record<string, 'asc' | 'desc'>
  filter?: any
  network?: string
  status?: string
}

const useController = (params: Params): ControllerProps => {
  const { typeId } = params

  const typIri = `/api/entity_types/${typeId}`
  const columnsQKey = `el-columns-${typeId}`
  const dataQKey = `el-data-${typeId}`

  const activeSite = useGetActiveSite()

  const columnsQ = useQuery(columnsQKey, async () => {
    const entityTypeReq = httpService.get<EntityTypeRes>(`/entity_types/${typeId}`)
    const entityTypeAttrsReq = httpService.get<EntityTypeAttrsRes>(
      `/entity_types/${typeId}/attributes`
    )
    const [entityTypeRes, entityTypeAttrsRes] = await Promise.all([
      entityTypeReq,
      entityTypeAttrsReq,
    ])

    return {
      columns: DataService.prepareColumnsData(
        entityTypeRes.data.entityTypeAttributes,
        entityTypeAttrsRes.data['hydra:member'],
        activeSite
      ),
      attributes: entityTypeAttrsRes.data['hydra:member'],
      entityType: entityTypeRes.data,
    }
  })

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState<PerPage>(20)
  const [search, setSearchState] = useState('')
  const [sorting, setSorting] = useState<ControllerProps['sorting']>(null)
  const [showArchived, setShowArchived] = useState(false)

  const changePerPage = useCallback<ControllerProps['changePerPage']>((value) => {
    setPage(1)
    setPerPage(value)
  }, [])

  const changeSorting = useCallback<ControllerProps['changeSorting']>((columnData) => {
    setSorting((prevSorting) => {
      const isColumnChanged = prevSorting?.column !== columnData.slug
      const needReset = !isColumnChanged && prevSorting?.direction === 'desc'

      if (needReset) {
        return null
      }

      const direction = prevSorting && !isColumnChanged ? 'desc' : 'asc'
      return { column: columnData.slug, direction }
    })
  }, [])

  const setSearch = useCallback((value: string) => {
    setPage(1)
    setSearchState(value)
  }, [])

  const filters = useFilters()

  const { filterParams } = filters

  // TODO: need to find better solution
  useEffect(() => {
    setPage(1)
  }, [filterParams, showArchived])

  const dataQ = useQuery(
    [dataQKey, page, perPage, search, sorting, filterParams, showArchived],
    async () => {
      const withNetwork = Boolean(columnsQ.data?.entityType.sharedBetweenNetworkSites)
      const network = withNetwork ? activeSite?.network : undefined

      const params: QueryParams = {
        entityType: typIri,
        showAsColumn: true,
        hidden: false,
        page,
        search,
        network,
      }

      if (perPage === 'All') {
        setWith(params, 'pagination', false)
      } else {
        setWith(params, 'itemsPerPage', perPage)
      }

      if (sorting) {
        params.order = {
          [sorting.column]: sorting.direction,
        }
      }

      if (filterParams) {
        params.filter = {
          ...filterParams,
          _network: {
            equal: network ? getIdFromIri(network) : undefined,
          },
        }
      }

      if (showArchived) {
        setWith(params, 'filter.status.equal', 'archive')
      }

      const entitiesRes = await httpService
        .get<EntitiesRes>('/entities', { params })
        .then((data) => data.data)

      return entitiesRes
    },
    {
      enabled: columnsQ.isFetched,
      select: (data) => {
        if (!columnsQ.data) {
          throw new Error('Columns data need be fetched before')
        }
        return {
          totalItems: data['hydra:totalItems'],
          data: DataService.prepareRowsData(
            data['hydra:member'],
            columnsQ.data?.entityType.urlable,
            columnsQ.data?.attributes
          ),
        }
      },
    }
  )

  const isLoading = columnsQ.isLoading || dataQ.isLoading
  const isFetching = columnsQ.isFetching || dataQ.isFetching
  const isDataLoading = dataQ.isLoading
  const isDataFetching = dataQ.isFetching
  const isColumnsLoading = columnsQ.isLoading
  const hasError = columnsQ.isError || dataQ.isError

  return {
    columnsData: columnsQ.data?.columns || [],
    rowsData: dataQ.data?.data || [],
    totalItems: dataQ.data?.totalItems || 0,
    entityType: columnsQ.data?.entityType!,
    attributes: columnsQ.data?.attributes!,
    isLoading,
    isFetching,
    isDataLoading,
    isDataFetching,
    isColumnsLoading,
    hasError,
    dataQKey,
    typeId,
    page,
    perPage,
    changePerPage,
    setPage,
    search,
    setSearch,
    sorting,
    changeSorting,
    filters,
    showArchived,
    setShowArchived,
    refetch: dataQ.refetch,
  }
}

export default useController
