// Core
import React, { FC, ReactElement } from 'react'
import { Box, Divider, Drawer, Grid, Paper, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

// TODO: I added new layout for edit page, can't reuse existing, need create separated and use in all places
const SIDEBAR_WIDTH = 360

type Props = {
  isLoading?: boolean
  title?: string
  sidebar?: ReactElement
}

const Loading: FC = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Skeleton height={60} />
    </Grid>
    <Grid item xs={12}>
      <Skeleton height={60} />
    </Grid>
    <Grid item xs={12}>
      <Skeleton height={60} />
    </Grid>
    <Grid item xs={12}>
      <Skeleton height={60} />
    </Grid>
  </Grid>
)

const FormPageLayout: FC<Props> = (props) => {
  const { isLoading, title, children, sidebar = null } = props

  return (
    <Box display="flex">
      <Box maxWidth={`calc(100% - ${360}px)`} flex={1}>
        <Box maxWidth={890} mx="auto" my={3} component={Paper}>
          <Box px={2} py={1}>
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Divider />
          <Box px={2} py={3}>
            {isLoading ? <Loading /> : children}
          </Box>
        </Box>
      </Box>
      <Drawer variant="permanent" anchor="right">
        <Box p={3} width={SIDEBAR_WIDTH}>
          {sidebar}
        </Box>
      </Drawer>
    </Box>
  )
}

export default FormPageLayout
