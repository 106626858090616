import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { httpService } from 'core/data'
// Hooks
import { useRevalidate } from 'core/hooks/use-revalidate'
import { useRelationsError } from 'modules/new-entity/hooks/use-relations-error'

type UseUpdateOne = (
  resource: string
) => UseMutationResult<unknown, unknown, { id: any; values: any }, number>

export const useUpdate: UseUpdateOne = (resource) => {
  const queryClient = useQueryClient()

  const { getEntityUrls, revalidateEntity } = useRevalidate()
  const { notifyRelationsError, isRelationsError, getRelationsErrorTitle } = useRelationsError()

  return useMutation(
    (props: { id: any; values: any }) => {
      const { id, values } = props
      return httpService.put(`/${resource}/${id}`, values)
    },
    {
      onSuccess: async ({ data }) => {
        const id = data['@id'].split('/').at(-1)

        queryClient.invalidateQueries(resource)

        const entityUrls = await getEntityUrls(resource, id, 'media')

        if (entityUrls) {
          await revalidateEntity(id, 'media', ...entityUrls)
        }
      },
      onError: (error) => {
        if (isRelationsError(error)) {
          const errorTitle = getRelationsErrorTitle(error)
          notifyRelationsError(error, errorTitle)
        }
      },
    }
  )
}
