// Hooks
import { useSitesContext } from './use-sites-context'
// Types
import { Site } from '../types'

type UseGetActiveSite = () => Site | undefined

export const useGetActiveSite: UseGetActiveSite = () => {
  const { activeSite, data } = useSitesContext()

  return data?.find((site) => site['@id'] === activeSite['@id'])
}
