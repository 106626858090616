import { useCallback, useEffect } from 'react'
import { isEmptyString } from 'core/utils'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

export const useSetValue = () => {
  const [{ value }, , { setValue }] = useField('setAttributes')
  const { t } = useTranslation()

  useEffect(() => {
    if (!value) {
      setValue([])
    }
  }, [setValue, value])

  const setValueHandler = useCallback(
    (id: any) => {
      const finalValue = value || []

      if (value.some((val: any) => val.attribute === id)) {
        // eslint-disable-next-line no-alert
        alert(t('alert.contains'))
        return
      }

      if (!isEmptyString(id)) {
        const attrData = {
          attribute: id,
        }
        setValue([...finalValue, attrData])
      }
    },
    [setValue, t, value]
  )

  return {
    setValueHandler,
    value,
  }
}
