// Core
import { useCallback, useState } from 'react'
import get from 'lodash.get'
import pick from 'lodash.pick'
import { toast } from 'react-toastify'
// Helpers
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
import { v4 as uniqueId } from 'uuid'
// Types
import { IEntity, AttributeData } from 'modules/new-entity/types'

type NestedAttr = { attribute: AttributeData }

type MixedAttrsData = NestedAttr | AttributeData

function loopAttributes(
  attrs: MixedAttrsData[],
  preservedAttrs: string[] = [],
  readonlyAttrs: string[] = [],
  skipAttrs: string[] = []
): { preservedAttrs: string[]; readonlyAttrs: string[]; slugAttr: string; skipAttrs: string[] } {
  let slugAttr = ''

  for (const attr of attrs) {
    const attrData: AttributeData = (attr as NestedAttr).attribute || (attr as AttributeData)

    // Find preservable attributes
    if (!Array.isArray(attrData.options) && attrData.options.preservation) {
      preservedAttrs = [...preservedAttrs, attrData['@id']]
    }

    // Find skip attributes
    if (!Array.isArray(attrData.options) && attrData.options.skip_from_copy) {
      skipAttrs = [...skipAttrs, attrData['@id']]
    }

    // Find readonly attributes
    if (attrData.readonly) {
      readonlyAttrs = [...readonlyAttrs, attrData['@id']]
    }

    // Find slug attributes
    if (attrData.system && attrData.slug === 'slug') {
      slugAttr = attrData['@id']
    }

    if (attr && attrs.length > 0) {
      loopAttributes(attrData.setAttributes as MixedAttrsData[], preservedAttrs)
    }
  }

  return {
    preservedAttrs,
    readonlyAttrs,
    slugAttr,
    skipAttrs,
  }
}

const getPreservedAttributes = async (attributes: any) => {
  return loopAttributes(attributes)
}

type ClearEntity = Pick<IEntity, 'entityWidgets' | 'values' | 'setRepeats' | 'entityType'>
type RepeatField = 'setRepeats' | 'entitySetRepeats'

const clearValues = (values: any[], setRepeats: any[], repeatsField: RepeatField) => {
  values.forEach((item, index) => {
    values[index] = { attribute: item.attribute, value: item.value }
  })

  if (setRepeats && setRepeats.length > 0) {
    setRepeats.forEach((item, index) => {
      setRepeats[index] = {
        set: item.set,
        sortOrder: item.sortOrder,
        values: item.values,
        [repeatsField]: item[repeatsField],
      }
      clearValues(item.values, item[repeatsField], repeatsField)
    })
  }
}

export const useInsertTemplate = (setEntityData: any) => {
  const [templatesModal, toggleTemplatesModal] = useState(false)

  const insertTemplate = useCallback(
    async (entityIri: string, withValues = true) => {
      try {
        const templateId = getIdFromIri(entityIri)
        const templateRes = await httpService.get<IEntity>(`/entities/${templateId}`)

        const neededFields = ['entityWidgets', 'values', 'setRepeats', 'entityType']
        const templateData: ClearEntity = pick(templateRes.data, neededFields)
        const typeId = getIdFromIri(templateData.entityType)

        const attributesRes = await httpService.get<any>(`/entity_types/${typeId}/attributes`)
        const attributes = attributesRes.data['hydra:member']
        const filterIris = await getPreservedAttributes(attributes)

        clearValues(templateData.values, templateData.setRepeats, 'setRepeats')

        // @ts-ignore
        templateData.entityWidgets = templateData.entityWidgets.map((item) => {
          clearValues(item.values, item.entitySetRepeats, 'entitySetRepeats')
          const neededFields = [
            'entitySetRepeats',
            'name',
            'options',
            'values',
            'widget',
            'widgetType',
            'sortOrder',
          ]
          return {
            ...pick(item, neededFields),
            isCreated: true,
            id: uniqueId(),
          }
        })

        if (!withValues) {
          // preserve only first level
          templateData.values = templateData.values.filter((item) => {
            return filterIris.preservedAttrs.includes(item.attribute)
          })
          templateData.setRepeats = templateData.setRepeats.filter((item) => {
            return filterIris.preservedAttrs.includes(item.attribute)
          })

          templateData.entityWidgets.forEach((item) => {
            item.values = []
            item.entitySetRepeats = []
          })
        }

        setEntityData((prevData: IEntity) => {
          templateData.values = templateData.values.filter((item) => {
            return !filterIris.skipAttrs.includes(item.attribute)
          })
          templateData.values = templateData.values.filter((item) => {
            return (
              filterIris.readonlyAttrs.includes(item.attribute) ||
              item.attribute !== filterIris.slugAttr
            )
          })
          const neededPrevValues = prevData.values.filter((item) => {
            return (
              filterIris.readonlyAttrs.includes(item.attribute) ||
              filterIris.skipAttrs.includes(item.attribute) ||
              item.attribute === filterIris.slugAttr
            )
          })

          templateData.values = [...neededPrevValues, ...templateData.values]

          const newVersion = (prevData.version || 0) + 1

          return {
            ...prevData,
            ...templateData,
            version: newVersion,
          }
        })
        toast.success('Template inserted successfully')
      } catch (e) {
        const message =
          get(e, ['response', 'data', 'hydra:description']) || 'Template insertion error'

        toast(message, {
          type: 'error',
        })
      }
    },
    [setEntityData]
  )

  return { insertTemplate, templatesModal, toggleTemplatesModal }
}
