// Core
import React, { FC } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// Styles
import useStyles from './accordion.styles'

type TProps = {
  title?: string
}

const AccordionCustom: FC<TProps> = (props) => {
  const { children, title } = props
  const classes = useStyles()

  return (
    <Accordion square defaultExpanded={true} className={classes.accordion}>
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
        className={classes.title}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.content}>{children}</AccordionDetails>
    </Accordion>
  )
}

export default AccordionCustom
