// Core
import React, { FC, memo, useCallback, useState } from 'react'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import clsx from 'clsx'
// Hooks
import { useGetAttributeData } from 'common/hooks'
// Types
import { SortedAttribute } from 'core/types/attribute'
import PreviewFields from '../preview-fields'
import ActionButtons from './components/action-buttons'
import Chips from './components/chips'
import ConditionalLogic from '../options-modal'
import { useTranslation } from 'react-i18next'
// Styles
import useStyles from './selected-attribute-item.styles'

type TProps = {
  data: SortedAttribute
  index?: number
  removeAttributeHandler?: any
  removeAttributeOptionsHandler?: any
  removeAttributeDependenciesHandler?: any
  disabled?: boolean
  isNested?: boolean
  parentData?: any
  disableOptions?: boolean
  pathToAttribute?: string
}

const SelectedAttributeItem: FC<TProps> = (props) => {
  const classes = useStyles()
  const {
    data: attribute,
    removeAttributeHandler,
    removeAttributeOptionsHandler,
    removeAttributeDependenciesHandler,
    disabled = false,
    isNested,
    parentData,
    disableOptions,
    pathToAttribute,
  } = props

  const [previewIsOpen, setPreviewIsOpen] = useState<boolean>(false)
  const [optionsIsOpen, setOptionsIsOpen] = useState<boolean>(false)

  const { data: attrData, isLoading, error } = useGetAttributeData(attribute.attribute)
  const { t } = useTranslation()

  const previewOpenHandler = useCallback(() => {
    setPreviewIsOpen(!previewIsOpen)
  }, [setPreviewIsOpen, previewIsOpen])

  const optionsOpenHandler = useCallback(() => {
    setOptionsIsOpen(!optionsIsOpen)
  }, [setOptionsIsOpen, optionsIsOpen])

  if (isLoading) return <LinearProgress className={classes.progress} />
  if (error) return <div>{t('global.error')}</div>

  return (
    <div
      className={clsx(classes.root, {
        [classes.system]: attrData.system || attrData.options.hidden,
        [classes.hidden]: attrData.options.hidden,
      })}
    >
      <div className={classes.card}>
        <div className={classes.dragButtonBox}>
          <Icon className="icon-dragindrop" />
        </div>

        <div className={classes.iconBox}>
          <Icon className="icon-content" />
        </div>

        <div className={classes.cardContent}>
          <div className={classes.flex}>
            <p className={classes.name}>
              {attrData.name} <span className={classes.slug}>#{attrData.slug}</span>
            </p>

            {attrData.hint && (
              <Tooltip placement="top" title={attrData.hint}>
                <IconButton style={{ padding: 4 }}>
                  <Icon className={'icon-info'} />
                </IconButton>
              </Tooltip>
            )}
          </div>

          <Chips attrData={attrData} />
        </div>

        <ActionButtons
          onChange={previewOpenHandler}
          deleteDisable={disabled}
          removeAttributeHandler={removeAttributeHandler}
          removeAttributeOptionsHandler={removeAttributeOptionsHandler}
          removeAttributeDependenciesHandler={removeAttributeDependenciesHandler}
          previewIsOpen={previewIsOpen}
          optionsOpen={optionsOpenHandler}
          disableOptions={disableOptions}
        />
      </div>

      <ConditionalLogic
        isOpen={optionsIsOpen}
        openHandler={optionsOpenHandler}
        attrData={attrData}
        isNested={isNested}
        parentData={parentData}
        pathToAttribute={pathToAttribute}
      />

      <PreviewFields
        isOpen={previewIsOpen}
        attrData={attrData}
        isNested={isNested}
        parentData={parentData}
        pathToAttribute={pathToAttribute}
      />
    </div>
  )
}

export default memo(SelectedAttributeItem)
