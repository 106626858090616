// Core
import React, { FC } from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'

type Props = {
  count: number
}

const GroupError: FC<Props> = (props) => {
  const { count } = props
  const theme = useTheme()

  if (count <= 0) return null

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      style={{
        height: 20,
        minWidth: 20,
        borderRadius: 10,
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        fontSize: 12,
      }}
      ml={0.5}
      component="span"
      className="Mui-error"
    >
      <Typography
        variant="button"
        color="inherit"
        style={{ fontSize: 'inherit', lineHeight: '100%' }}
      >
        {count}
      </Typography>
    </Box>
  )
}

export default GroupError
