import { useMemo } from 'react'
import { useLanguage } from 'core/data'

type UseInputValuePath = (localized: boolean, name: string) => string

const insert = <T>(arr: T[], index: number, newItem: T) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
]

export const useInputValuePath: UseInputValuePath = (localized: boolean, name: string) => {
  const { currentLang } = useLanguage()

  return useMemo(() => {
    if (localized) {
      if (!currentLang) return ''
      const withDotValue = name.endsWith('.value')
      let valuePath = name.replace(/\[|\]/g, '.').split('.')

      valuePath = insert(
        valuePath,
        withDotValue ? valuePath.length - 2 : valuePath.length - 1,
        `translations.${currentLang.code}`
      )

      return valuePath.join('.')
    }

    return name
  }, [localized, currentLang, name])
}
