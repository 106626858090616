import React, { FC } from 'react'
// Material Components
import { Box, FormControlLabel, Checkbox, Typography, Slider, Icon } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
// Types
import { SidebarPositionType } from 'core/types/layouts/entity-layouts/sidebar-position'
// Options
import { markWidthSidebar } from './section.options'
// Styles
import layoutSideSectionStyles from './section.styles'
// Custom Hooks
import { useSidebarPosition, useSidebarWidth, useToggleSidebar } from 'modules/entity-layouts/hooks'
import { useTranslation } from 'react-i18next'
// UI
import AccordionCustom from 'ui/accordion'

type TProps = {
  sidebarCheck?: boolean
  sidebarWidth?: number
  sidebarPosition?: SidebarPositionType
}

const LayoutSectionSettings: FC<TProps> = (props) => {
  const { sidebarCheck, sidebarWidth, sidebarPosition } = props
  const classes = layoutSideSectionStyles()
  const { t } = useTranslation()

  const handleToggleSidebar = useToggleSidebar()
  const handleChangeWidth = useSidebarWidth()
  const handleChangePosition = useSidebarPosition()

  return (
    <AccordionCustom title={t('global.sidebar')}>
      <Box display="flex" flexDirection="column" width="100%">
        <FormControlLabel
          control={
            <Checkbox
              checked={sidebarCheck}
              onChange={handleToggleSidebar}
              name="has-sidebar"
              color="primary"
            />
          }
          label={t('labels.has-sidebar')}
        />

        {sidebarCheck && (
          <>
            <ToggleButtonGroup
              size="medium"
              value={sidebarPosition}
              exclusive
              onChange={handleChangePosition}
            >
              <ToggleButton value="left" className={classes.bottonTab}>
                <Icon className="icon-sidebar" />
              </ToggleButton>
              <ToggleButton value="right" className={classes.bottonTab}>
                <Icon className="icon-sidebar-r" />
              </ToggleButton>
            </ToggleButtonGroup>

            <br />

            <Typography id="width-value" gutterBottom>
              {t('labels.width-sidebar')} (%)
            </Typography>
            <Slider
              value={sidebarWidth}
              onChange={handleChangeWidth}
              marks={markWidthSidebar}
              step={5}
              min={15}
              max={45}
              valueLabelDisplay="auto"
            />
          </>
        )}
      </Box>
    </AccordionCustom>
  )
}

export default LayoutSectionSettings
