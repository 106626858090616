// Core
import React, { FC } from 'react'
import { Icon, IconButton, TableCell } from '@material-ui/core'
import { Permissions, useAppContext } from 'core/app'

type Props = {
  deleteHandler: () => void
  editHandler: () => void
  isEdit: boolean
  permissionType: string
}

const ActionsCell: FC<Props> = (props) => {
  const {
    actions: { userCan },
  } = useAppContext()
  const { isEdit, deleteHandler, editHandler, permissionType } = props

  return (
    <TableCell size="small" align="right" width="10%">
      {userCan(permissionType, Permissions.EDIT, 'system') && (
        <IconButton type="button" onClick={deleteHandler}>
          {isEdit ? <Icon className="icon-x" /> : <Icon className="icon-delete" />}
        </IconButton>
      )}
      {userCan(permissionType, Permissions.DELETE, 'system') && (
        <IconButton type="button" onClick={editHandler}>
          {isEdit ? <Icon className="icon-check" /> : <Icon className="icon-edit" />}
        </IconButton>
      )}
    </TableCell>
  )
}

export default ActionsCell
