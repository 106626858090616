import React, { FC } from 'react'
import { Pagination as PaginationLab } from '@material-ui/lab'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

type TProps = {
  page: number
  setPage: (page: number) => void
  total: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      '& ul': {
        justifyContent: 'center',
      },
    },
  })
)

export const Pagination: FC<TProps> = (props) => {
  const classes = useStyles()
  const { page, setPage, total } = props

  const pageCount = Math.ceil(total / 30)

  if (pageCount <= 1) return null

  return (
    <div style={{ padding: '24px 8px', maxWidth: '100%' }}>
      <PaginationLab
        boundaryCount={2}
        count={pageCount}
        page={page}
        onChange={(e, page) => setPage(page)}
        color="primary"
        size="medium"
        className={classes.pagination}
      />
    </div>
  )
}
