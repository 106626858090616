// Core
import React, { FC } from 'react'
import { Button, TextField } from '@material-ui/core'
// Hooks
import { useTranslationController } from 'modules/translations/hooks'
// Styles
import useStyles from './create-translation.styles'

type Props = {
  children?: never
}

export const CreateTranslation: FC<Props> = () => {
  const classes = useStyles()
  const { form } = useTranslationController()

  return (
    <div className={classes.root}>
      <TextField
        className={classes.control}
        label="Origin text"
        name="slug"
        value={form.values.slug}
        onChange={form.handleChange}
        error={Boolean(form.errors.slug)}
        helperText={form.errors.slug}
      />
      <TextField
        className={classes.control}
        label="Translation"
        name="value"
        value={form.values.value}
        onChange={form.handleChange}
        error={Boolean(form.errors.value)}
        helperText={form.errors.value}
      />
      <Button
        className={classes.button}
        disabled={form.isSubmitting}
        type="button"
        onClick={form.submitForm}
        variant="contained"
        color="primary"
      >
        Create
      </Button>
    </div>
  )
}
