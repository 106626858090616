// Core
import React, { ComponentType, FC, ReactNode } from 'react'
import { Grid, Paper } from '@material-ui/core'
import clsx from 'clsx'
// Context
import { ListDataProvider } from 'core/data'
// Components
import { DataGrid, DataHeader, DataTable, Header } from './components'
import { Pagination } from 'core/components'
// Hooks
import { useListController } from 'core/data'
// Types
import { ResourceProps, ControllerOptions } from 'core/data'
// Styles
import useStyles from './data-view.styles'

type Props = {
  resourceProps: ResourceProps
  controllerOptions?: ControllerOptions
  sidebar?: ReactNode
  columns: any
  gridItemComponent?: ComponentType<any>
  hasGrid?: boolean
  defaultViewType?: string
  sort?: any
  filters?: any
  removeFilter?: string
  inModal?: boolean
  showCheckBox?: boolean
}

const DataView: FC<Props> = (props) => {
  const classes = useStyles()
  const {
    resourceProps,
    controllerOptions,
    sidebar,
    columns,
    gridItemComponent,
    hasGrid = false,
    children,
    inModal = false,
    showCheckBox,
  } = props

  const controllerProps = useListController(resourceProps, controllerOptions)

  const { viewType, data, isLoading, selected } = controllerProps

  return (
    <ListDataProvider controllerProps={controllerProps}>
      {!inModal && <Header />}
      <div className={clsx(classes.root, { 'view-modal': inModal })}>
        <Grid container spacing={1}>
          {sidebar && (
            <Grid item xs={3}>
              {sidebar}
            </Grid>
          )}
          <Grid item xs={sidebar && selected && children ? 6 : sidebar ? 9 : 12}>
            <Paper className={classes.paper}>
              <DataHeader hasGrid={hasGrid} />
              {viewType?.type === 'table' ? (
                <DataTable
                  showCheckBox={showCheckBox}
                  data={data}
                  columns={columns}
                  loading={isLoading}
                />
              ) : (
                hasGrid && (
                  <DataGrid
                    data={data}
                    inModal={inModal}
                    gridComponent={gridItemComponent}
                    loading={isLoading}
                  />
                )
              )}
              <Pagination />
            </Paper>
          </Grid>
          {children && selected && (
            <Grid item xs={3}>
              {children}
            </Grid>
          )}
        </Grid>
      </div>
    </ListDataProvider>
  )
}

export default DataView
