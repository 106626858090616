import { useMemo } from 'react'
import { Attribute, AttributeBase } from 'modules/eav/types'
import { filterSupportedTypes } from './utils'
import { FilterItemData } from './types'

export default function usePrepareFiltersData(
  data: Attribute[] | AttributeBase[]
): FilterItemData[] {
  return useMemo(() => {
    let prepared = data
    // Filter supported types
    prepared = data.filter((elem) => filterSupportedTypes.includes(elem.attributeType.type))
    // Add system filters
    const updatedAt: AttributeBase = {
      name: 'Updated at',
      slug: '_updatedAt',
      options: [],
      attributeEnums: [],
      attributeType: { type: 'date_time', name: '' },
      system: true,
    }

    const createdAt: AttributeBase = {
      name: 'Created at',
      slug: '_createdAt',
      options: [],
      attributeEnums: [],
      attributeType: { type: 'date_time', name: '' },
      system: true,
    }

    const referenceContains: AttributeBase = {
      name: 'Contains entity',
      slug: '_entity_reference',
      options: [],
      attributeEnums: [],
      attributeType: { type: 'contain_reference', name: '' },
      system: true,
    }

    prepared.unshift(referenceContains)
    prepared.unshift(updatedAt)
    prepared.unshift(createdAt)

    return prepared.map((attr) => ({
      attrData: attr,
      group: 'attributes',
    }))
  }, [data])
}
