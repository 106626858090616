import { SidebarPositionType } from 'core/types/layouts/entity-layouts/sidebar-position'
import {
  IToggleHeaderAction,
  ActionTypes,
  IToggleFooterAction,
  IAddSectionAction,
  IRemoveSectionAction,
  ISetActiveElementAction,
  IResetActiveElementAction,
  IAddContainerAction,
  IRemoveContainerAction,
  IToggleSidebarAction,
  ISetSidebarWidthAction,
  ISetContainerWidthAction,
  ISetContainerColumnsAction,
  ISetSidebarPositionAction,
  ISortSectionAction,
  ISetInitialOptionsAction,
  IClearLayoutStoreAction,
  ISetContainerNameAction,
  ISetContainerSlugAction,
} from './types'

export const setHeader = (): IToggleHeaderAction => ({
  type: ActionTypes.TOGGLE_HEADER,
})
export const setFooter = (): IToggleFooterAction => ({
  type: ActionTypes.TOGGLE_FOOTER,
})

export const addSection = (insertPlace: number): IAddSectionAction => ({
  type: ActionTypes.ADD_SECTION,
  payload: {
    insertPlace,
  },
})
export const removeSection = (id: string): IRemoveSectionAction => ({
  type: ActionTypes.REMOVE_SECTION,
  payload: {
    id,
  },
})
export const sidebar = (sidebarIsAdded: boolean): IToggleSidebarAction => ({
  type: ActionTypes.TOGGLE_SIDEBAR,
  payload: {
    sidebarIsAdded,
  },
})
export const setSidebarWidth = (sidebarWidth: number): ISetSidebarWidthAction => ({
  type: ActionTypes.SET_SIDEBAR_WIDTH,
  payload: {
    sidebarWidth,
  },
})
export const setSidebarPosition = (
  sidebarPosition: SidebarPositionType
): ISetSidebarPositionAction => ({
  type: ActionTypes.SET_SIDEBAR_POSITION,
  payload: {
    sidebarPosition,
  },
})
export const sortSections = (result: any): ISortSectionAction => ({
  type: ActionTypes.SORT_SECTION,
  payload: {
    result,
  },
})

export const addContainer = (): IAddContainerAction => ({
  type: ActionTypes.ADD_CONTAINER,
})
export const removeContainer = (id: string): IRemoveContainerAction => ({
  type: ActionTypes.REMOVE_CONTAINER,
  payload: {
    id,
  },
})
export const setContainerWidth = (containerWidth: number): ISetContainerWidthAction => ({
  type: ActionTypes.SET_CONTAINER_WIDTH,
  payload: {
    containerWidth,
  },
})
export const setContainerColumns = (containerColumns: number): ISetContainerColumnsAction => ({
  type: ActionTypes.SET_CONTAINER_COLUMNS,
  payload: {
    containerColumns,
  },
})
export const setContainerName = (containerName: string): ISetContainerNameAction => ({
  type: ActionTypes.SET_CONTAINER_NAME,
  payload: {
    containerName,
  },
})
export const setContainerSlug = (containerSlug: string): ISetContainerSlugAction => ({
  type: ActionTypes.SET_CONTAINER_SLUG,
  payload: {
    containerSlug,
  },
})

export const selectActiveElement = (
  sectionId: string,
  containerId: string
): ISetActiveElementAction => ({
  type: ActionTypes.SELECT_ELEMENT,
  payload: {
    sectionId,
    containerId,
  },
})
export const resetActiveElement = (): IResetActiveElementAction => ({
  type: ActionTypes.UNSELECT_ELEMENT,
})

export const setInitialOptions = (data: any): ISetInitialOptionsAction => ({
  type: ActionTypes.SET_INITIAL_OPTIONS,
  payload: {
    data,
  },
})
export const clearLayoutStore = (): IClearLayoutStoreAction => ({
  type: ActionTypes.CLEAR_LAYOUT_STORE,
})

export type EntityLayoutsActionTypes =
  | IToggleHeaderAction
  | IToggleFooterAction
  | IAddSectionAction
  | IRemoveSectionAction
  | ISetActiveElementAction
  | IResetActiveElementAction
  | IAddContainerAction
  | IRemoveContainerAction
  | IToggleSidebarAction
  | ISetSidebarWidthAction
  | ISetContainerWidthAction
  | ISetContainerColumnsAction
  | ISetContainerNameAction
  | ISetContainerSlugAction
  | ISetSidebarPositionAction
  | ISortSectionAction
  | ISetInitialOptionsAction
  | IClearLayoutStoreAction
