import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const enumOptionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#f7f7f7',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(0, 2, 0, 3),
      '& > div': {
        width: '70%',
        margin: theme.spacing(0, 2, 0, 0),
      },
    },
    defaultBox: {
      padding: theme.spacing(0, 2),
    },
    valueField: {
      marginLeft: theme.spacing(3),
    },
  })
)

export default enumOptionStyles
