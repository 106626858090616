// Core
import React, { FC } from 'react'
// Components
import RelationsField from 'core/form/components/relations-field'
// Hooks
import { useGetActiveSite } from 'modules/sites'
// Types
import { AttributeData } from 'modules/new-entity/types'

type Props = {
  attributeData: AttributeData
  name: string
  disabled?: boolean
}

const SiteReference: FC<Props> = ({ disabled, attributeData, name }) => {
  const activeSite = useGetActiveSite()

  return (
    <RelationsField
      label={attributeData.name}
      valueField="@id"
      name={name}
      source="sites"
      reqParams={{ network: activeSite?.network }}
      viewAction={(option) => `/${option.id}`}
      disabled={disabled}
    />
  )
}

export default SiteReference
