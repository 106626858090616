// Core
import React, { FC } from 'react'
import { Icon } from '@material-ui/core'
import clsx from 'clsx'
// Hooks
import { useListContext } from 'core/data'
// Styles
import useStyles from './empty-list.styles'
import { useTranslation } from 'react-i18next'

type Props = {
  children?: never
}

const EmptyList: FC<Props> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    resource: { name },
  } = useListContext()

  return (
    <div className={classes.root}>
      <Icon className={clsx('icon-file', classes.icon)} />
      <div>{t('list.empty', { name })}</div>
    </div>
  )
}

export default EmptyList
