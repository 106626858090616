import { ActionTypes, CancelAction, ConfirmAction, ConfirmDialogAction } from './types'

export const showConfirmDialog = (text: string, value: boolean): ConfirmDialogAction => ({
  type: ActionTypes.OPEN_MODAL,
  payload: { type: 'confirm', text, open: value },
})

export const confirmAction = (): ConfirmAction => ({
  type: ActionTypes.CONFIRM,
})

export const cancelAction = (): CancelAction => ({
  type: ActionTypes.CANCEL,
})

export type ModalActionTypes = ConfirmDialogAction
