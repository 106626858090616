import { AxiosRequestConfig } from 'axios'
//
import storage from '../../storage'

export const requestToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = storage.getToken()

  if (token) {
    config.headers.JWTAuthorization = `Bearer ${token}`
  }

  return config
}
