// Core
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
// Services/Utils
import { httpService } from 'core/data'
import {
  VERSION_CONFLICT_KEY,
  disableConflict,
  enableConflict,
  reloadPage,
  dispatchConflictEvent,
} from './utils'

const CheckVersion: FC = () => {
  const [open, setOpen] = useState(false)
  const [newVersion, setNewVersion] = useState<string | null>(null)

  const refreshHandler = useCallback(() => {
    disableConflict()
    reloadPage()
  }, [])

  useEffect(() => {
    httpService.showVersionConflictModal = (newVersion) => {
      enableConflict()
      dispatchConflictEvent()
      setOpen(true)
      setNewVersion(newVersion)
    }

    function storageChangeHandler(e: StorageEvent) {
      if (e.key === VERSION_CONFLICT_KEY && e.newValue === 'true') {
        setOpen(true)
      } else if (e.key === VERSION_CONFLICT_KEY) {
        reloadPage()
      }
    }

    window.addEventListener('storage', storageChangeHandler)

    return () => {
      window.removeEventListener('storage', storageChangeHandler)
    }
  }, [])

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogContent>
        <Typography align="center">
          A new version <b>{newVersion}</b> of Vulcan is available!
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={refreshHandler} variant="contained" color="primary">
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CheckVersion
