// Core
import React, { FC } from 'react'
import { ConnectedRouter } from 'connected-react-router'
// Hooks
import { useAppContext } from 'core/app'

interface RouterProps {}

export const Router: FC<RouterProps> = (props) => {
  const { children } = props
  const { history } = useAppContext()

  return <ConnectedRouter history={history}>{children}</ConnectedRouter>
}

export default Router
