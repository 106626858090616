// Core
import React, { FC, useMemo } from 'react'
// Components
import { RootLoader } from 'core/components'
import { DateLib } from 'core/date'
// Hooks
import { useGetSites } from '../hooks'
import { useAppContext } from 'core/app'
// Context
import { SitesContext } from './sites-context'
// Services
import { sitesService } from '../services'
// Types
import { SitesContextProps } from '../types'

type Props = {}

const SitesProvider: FC<Props> = (props) => {
  const { children } = props
  const {
    activeSite,
    actions: { setActiveSite },
  } = useAppContext()

  const sitesQuery = useGetSites(() => {
    DateLib.tz.setDefault(activeSite.timezone)
    sitesService.setTokenReqHeader(activeSite.token)
  })

  const contextProps = useMemo<SitesContextProps>(
    () => ({
      activeSite,
      setActiveSite,
      data: sitesQuery.data?.sites,
      isLoading: sitesQuery.isLoading,
      affiliateSettings: sitesQuery.data?.affiliateSettings!,
      siteSettings: sitesQuery.data?.siteSettings!,
      locales: {
        site: sitesQuery.data?.locales.site!,
        system: sitesQuery.data?.locales.system!,
      },
    }),
    [activeSite, sitesQuery, setActiveSite]
  )

  if (sitesQuery.isLoading) return <RootLoader />
  return <SitesContext.Provider value={contextProps}>{children}</SitesContext.Provider>
}

export default SitesProvider
