import { FC } from 'react'
import { createPortal } from 'react-dom'

type TProps = {
  component: JSX.Element
  selector: string
}

const Portal: FC<TProps> = (props) => {
  const { component, selector } = props
  const nodeElement = document.getElementById(selector)

  if (!nodeElement) return null

  return createPortal(component, nodeElement)
}

export default Portal
