// Core
import React, { FC, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button } from '@material-ui/core'
// Components
import PageHeader from 'modules/layout/components/page-header'
// Hooks
import { useListContext } from 'core/data'
import { Permissions, useAppContext } from 'core/app'
// Types
import { EavResourceType } from 'modules/new-entity/types'
import { AddCircle } from '@material-ui/icons'

const Header: FC = () => {
  const {
    resource: { name, resourceType, hasCreate },
  } = useListContext()

  const { typeId } = useParams<{ typeId?: string }>()

  const {
    actions: { getResource, userCan },
  } = useAppContext()

  const slug = getResource(typeId!)?.slug

  const canCreate = useMemo(() => {
    if (
      ![EavResourceType.ENTITY, EavResourceType.WIDGET, EavResourceType.TEMPLATE].includes(
        resourceType!
      ) &&
      !(name === 'entities' || name === 'widgets') &&
      !hasCreate
    ) {
      return false
    }

    if (slug && name === 'entities') return userCan(slug, Permissions.CREATE)

    if (name === 'navigations') return userCan('navigation', Permissions.CREATE)

    return (
      userCan(name, Permissions.CREATE, 'system') || userCan(name, Permissions.CREATE, 'widgetType')
    )
  }, [hasCreate, name, resourceType, slug, userCan])

  const createButtonPath = useMemo(() => {
    if (
      resourceType === EavResourceType.ENTITY ||
      resourceType === EavResourceType.WIDGET ||
      resourceType === EavResourceType.TEMPLATE
    ) {
      return `/${resourceType}/${typeId}/create`
    }
    return `/${name}/create`
  }, [name, resourceType, typeId])

  const backPath = useMemo(() => {
    if (typeId && [EavResourceType.TEMPLATE, EavResourceType.WIDGET].includes(resourceType!)) {
      return `/${resourceType}`
    }
    return null
  }, [resourceType, typeId])

  return (
    <PageHeader
      titleTransKey={resourceType || name}
      backPath={backPath}
      actions={
        <>
          {canCreate && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={createButtonPath}
              startIcon={<AddCircle />}
            >
              Create
            </Button>
          )}
        </>
      }
    />
  )
}

export default Header
