// Core
import React, { FC } from 'react'
import { Icon, IconButton, TableCell, TableRow, TextField } from '@material-ui/core'
import { ActionsCellWrapper } from 'ui/data-grid/actions-cell-wrapper'
// Hooks
import { useTranslationContext, useTranslationController } from 'modules/translations/hooks'
import { Permissions, useAppContext } from 'core/app'
// Types
import { Translation } from 'modules/translations/types'

type Props = {
  children?: never
  data: Translation
}

export const TableItem: FC<Props> = (props) => {
  const { data } = props
  const { code } = useTranslationContext()
  const { editHandler, deleteHandler, isEdit, form } = useTranslationController(data)
  const {
    actions: { userCan },
  } = useAppContext()

  const localeData = data.localizations.find((item) => item.locale === `/api/localizations/${code}`)

  return (
    <TableRow>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <b>{data.slug}</b>
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isEdit ? (
            <TextField
              error={Boolean(form.errors.value)}
              helperText={form.errors.value}
              value={form.values.value}
              name="value"
              onChange={form.handleChange}
            />
          ) : (
            <span>{localeData && localeData.value}</span>
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        <ActionsCellWrapper>
          {userCan('translations', Permissions.EDIT, 'system') && (
            <>
              <IconButton onClick={editHandler} size="small">
                <Icon className={`icon-${isEdit ? 'check' : 'edit'}`} />
              </IconButton>
              {isEdit && (
                <IconButton onClick={deleteHandler} size="small">
                  <Icon className={`icon-x`} />
                </IconButton>
              )}
            </>
          )}
          {userCan('translations', Permissions.DELETE, 'system') && (
            <IconButton onClick={deleteHandler} size="small" edge="end">
              <Icon className={`icon-delete`} />
            </IconButton>
          )}
        </ActionsCellWrapper>
      </TableCell>
    </TableRow>
  )
}
