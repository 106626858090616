import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors, defaultThemeSizes } from 'core/themes/default/default'

const { contentBg } = defaultThemeColors
const { appBarHeight } = defaultThemeSizes

const mainLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      paddingTop: appBarHeight,
      backgroundColor: contentBg,
      flexDirection: 'column',
      '& .content-wrapper': {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: defaultThemeSizes.drawerWidthClose,
      },
      '&:has([data-sidebar-opened="true"])': {
        '& .content-wrapper': {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: defaultThemeSizes.drawerWidth + defaultThemeSizes.drawerWidthClose,
        },
      },
    },
    toolbarGhost: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar,
    },
    grow: {
      flexGrow: 1,
    },
  })
)

export default mainLayoutStyles
