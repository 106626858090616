// Core
import React, { FC } from 'react'
import { CircularProgress, IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
// Hooks
import { useDeleteEntity } from 'modules/new-entity/hooks'
// Styles
import useStyles from './list.styles'

type Props = {
  entityId: number
  onEdit?: (id: number) => void
  canDelete?: boolean
  refetch?: () => void
}

const Actions: FC<Props> = (props) => {
  const { entityId, onEdit, canDelete, refetch } = props
  const classes = useStyles()
  const { deleteEntityM, deleteEntity } = useDeleteEntity(entityId, {
    onSuccess: () => {
      refetch?.()
    },
  })

  const editHandler = (e: any) => {
    e.stopPropagation()

    if (onEdit) {
      onEdit(entityId)
    }
  }

  const deleteHandler = (e: any) => {
    e.stopPropagation()
    deleteEntity()
  }

  return (
    <div className={classes.actions}>
      {deleteEntityM.isLoading ? (
        <CircularProgress size={15} />
      ) : (
        <>
          {onEdit && (
            <IconButton size="small" onClick={editHandler}>
              <Edit />
            </IconButton>
          )}
          {canDelete && (
            <IconButton size="small" onClick={deleteHandler}>
              <Delete />
            </IconButton>
          )}
        </>
      )}
    </div>
  )
}

export default Actions
