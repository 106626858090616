import { formatInTimeZone } from 'date-fns-tz'
import { useGetActiveSite } from 'modules/sites'
import { useCallback } from 'react'

export default function useDateUtils() {
  const site = useGetActiveSite()

  const formateDate = useCallback(
    (value: string | null) => {
      if (!site || !value) return ''

      const formattedDate = formatInTimeZone(
        new Date(value),
        site.timezone,
        `${site.dateFormat} ${site.timeFormat}`
      )

      return formattedDate
    },
    [site]
  )

  return {
    formateDate,
  }
}
