import cloneDeep from 'lodash.clonedeep'

export const transformLocalizations = (data: any): any => {
  if (!data || !data.localizations) return data
  data = cloneDeep(data)

  const translations: any = {}

  data.localizations.forEach((item: any) => {
    if (!item.locale) return

    let locale = item.locale.split('/')
    locale = locale[locale.length - 1]

    const valuesKeys = Object.keys(item).filter((key: string) => key !== 'locale' && key !== 'id')

    const values: any = {}
    valuesKeys.forEach((key) => {
      values[key] = item[key] || ''
    })

    translations[locale] = values
  })

  data.translations = translations

  return data
}
