// Core
import React, { FC } from 'react'
// Context
import { TypeResourceContext } from './types-context'
import { TypeResourceProps } from '../types'

type Props = {
  value: TypeResourceProps
}

const TypeResourceProvider: FC<Props> = (props) => {
  const { children } = props
  return <TypeResourceContext.Provider value={props.value}>{children}</TypeResourceContext.Provider>
}

export default TypeResourceProvider
