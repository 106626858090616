import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setFooter } from '../store'

type UseHandleSetFooter = () => () => void

export const useHandleSetFooter: UseHandleSetFooter = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(setFooter())
  }, [dispatch])
}
