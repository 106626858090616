import React, { FC } from 'react'
import useStyles from './attribute-add-button.styles'
import { Button, Icon } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: object
  openHandler: () => void
}

const AttributeAddButton: FC<TProps> = (props) => {
  const classes = useStyles()
  const { openHandler } = props
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        onClick={openHandler}
        startIcon={<Icon className="icon-plus-circle" />}
      >
        {t('attrs.adds')}
      </Button>
    </div>
  )
}

export default AttributeAddButton
