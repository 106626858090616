import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      paddingTop: 7,
      paddingBottom: 7,
      marginLeft: 10,
    },
    clearButton: {
      transition: 'opacity .3s ease',
    },
  })
)

export default styles
