import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigationModal: {
      '& .position, .navigation, .navigation_item_parent, .navigation_item_entity, .css_class, .description, .navigation_item_expanded':
        {
          display: 'none',
        },
      '&.hide-for-item-entity': {
        '& .link': {
          display: 'none',
        },
      },

      '& .MuiPaper-root': {
        minHeight: 'calc(100vh - 64px)',
      },
    },
  })
)
