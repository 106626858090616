// Core
import { useQuery } from 'react-query'
// Services
import { httpService } from 'core/data'
// Utils
import { getIdFromIri } from 'core/utils'
// Types
import { EntityType } from 'core/types'

export const useGetEntityType = (entityTypeIri: string, onlyId?: boolean) => {
  let id = ''
  if (onlyId) {
    id = entityTypeIri
  } else {
    id = getIdFromIri(entityTypeIri)
  }

  return useQuery<EntityType>(['entity-type', id], () => {
    return httpService.get<any>(`/entity_types/${id}`).then((res) => res.data)
  })
}
