// Core
import React, { createContext, FC, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
// Services
import { ApiResponseCollection, httpService } from 'core/data'
// Types
import { Translation, ContextPropsType } from '../types'
import { HydraResponse } from 'core/types'

type Props = {}

export const TranslationContext = createContext<ContextPropsType>(null!)

export const TranslationProvider: FC<Props> = ({ children }) => {
  const { code } = useParams()
  const [page, setPage] = useState(1)
  const queryClient = useQueryClient()

  const query = useQuery<unknown, unknown, ApiResponseCollection>(['translation', page], () => {
    return httpService
      .get<HydraResponse<Translation>>('/translations', { params: { page } })
      .then((data) => data.data)
  })

  const createMutation = useMutation<any, any, any>(
    (values) => {
      return httpService.post('/translations', values)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries('translation')
      },
    }
  )

  const contextProps = useMemo<ContextPropsType>(
    () => ({
      createMutation,
      setPage,
      page,
      query,
      code,
      invalidate: () => queryClient.invalidateQueries('translation'),
    }),
    [createMutation, page, query, code, queryClient]
  )

  return <TranslationContext.Provider value={contextProps}>{children}</TranslationContext.Provider>
}
