import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const createEditContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    contentHolder: {
      flex: 1,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    header: {
      fontWeight: 500,
      padding: theme.spacing(2, 2),
      margin: theme.spacing(0, -2, 2),
      fontSize: '1rem',
      borderBottom: '1px solid #EEEEEE',
    },
    contentWrapper: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0, 2, 4),
      margin: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
    },
  })
)

export default createEditContainerStyles
