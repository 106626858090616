export const isNullable = <T>(arg: T): boolean => {
  /**
   * Proof
   * https://stackoverflow.com/questions/38648087/checking-for-null-or-undefined
   */
  return arg == null
}

export const isString = <T>(arg: T): boolean => {
  return typeof arg === 'string'
}

export const isEmptyString = (arg: any): boolean => {
  return arg === ''
}
