import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      color: theme.palette.text.secondary,
    },
    userText: {
      marginLeft: theme.spacing(1),
      fontSize: '.88rem',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
  })
)

export default userStyles
