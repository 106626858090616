// Core
import React, { FC } from 'react'
import { LinearProgress } from '@material-ui/core'
import { useQuery } from 'react-query'
// Utils
import { getIdFromIri } from 'core/utils/data'
import { httpService } from 'core/data'

type Props = {
  children?: never
  referenceIri: string
}

const ReferenceTitle: FC<Props> = (props) => {
  const { referenceIri } = props
  const referenceId = getIdFromIri(referenceIri)

  const { data, isLoading } = useQuery(['entity-type', referenceId], async () => {
    const response = await httpService.get<any>(`/entity_types/${referenceId}`)
    return response.data.name
  })

  if (isLoading) return <LinearProgress color="secondary" />

  return <span>{data}</span>
}

export default ReferenceTitle
