import { History } from 'history'
import { httpService } from 'core/data'
import { HydraResponse } from 'core/types'
import { ACTIVE_SITE_STORAGE_KEY } from '../constants'
import { AffiliateSettings, Site } from '../types'
import { getIdFromIri } from 'core/utils'
import { VulcanClientService } from 'modules/eav/services/vulcan-client.service'

/**
 * @deprecated
 */
const isOldFormat = (data: string): boolean => {
  try {
    const tryParseData = JSON.parse(data)
    return Boolean(tryParseData['@id'])
  } catch (e) {
    return false
  }
}

const getIdFromLocation = (location: History['location']): number | null => {
  const { pathname } = location
  const potentialId = +pathname.split('/')[1]

  if (isNaN(potentialId) || typeof potentialId !== 'number') return null

  return potentialId
}

const fixPath = (history: History, siteId: number) => {
  const { pathname } = history.location
  history.replace(`${siteId}${pathname}`)
}
export class SitesService {
  /**
   * @deprecated
   */
  storageSetSite(sideId: string): void {
    localStorage.setItem(ACTIVE_SITE_STORAGE_KEY, sideId)
  }

  /**
   * @deprecated
   */
  storageGetActiveSite(): string | null {
    const siteDataFromStorage = localStorage.getItem(ACTIVE_SITE_STORAGE_KEY)
    let activeSiteData

    if (siteDataFromStorage && isOldFormat(siteDataFromStorage)) {
      const oldFormatData = JSON.parse(siteDataFromStorage)
      activeSiteData = oldFormatData['@id']
    } else {
      activeSiteData = siteDataFromStorage
    }

    return activeSiteData
  }

  setTokenReqHeader(token: string) {
    httpService.client.defaults.headers['X-SITE-ID'] = token
  }

  async getActiveSite(history: History, userSites: string[]): Promise<Site> {
    let activeSite: Site

    const siteId = getIdFromLocation(history.location)
    const userHasAccess = userSites.map((iri) => getIdFromIri(iri)).includes(`${siteId}`)

    if (userHasAccess) {
      const siteRes = await httpService.get<Site>(`/sites/${siteId}`).then((res) => res.data)
      activeSite = siteRes
    } else {
      const firstUserSiteId = getIdFromIri(userSites[0])
      const sitesRes = await httpService
        .get<HydraResponse<Site>>(`/sites`, { params: { id: [firstUserSiteId] } })
        .then((res) => res.data)
      // eslint-disable-next-line prefer-destructuring
      activeSite = sitesRes['hydra:member'][0]
      fixPath(history, +activeSite.id)
    }

    return activeSite
  }

  async getAffiliateSettings(siteToken: string) {
    const res = await VulcanClientService.getEntityByType(
      'global_affiliate_link_settings',
      {
        values: {
          alternative_cloaking: { alternative_cloaking_domain: [], amp_only: [] },
          affiliate_link_prefix: [],
        },
      },
      {
        headers: {
          'X-SITE-ID': siteToken,
        },
      }
    )

    const { values } = res.attributes
    const alternative = values.alternative_cloaking.values
    const prefix = values.affiliate_link_prefix.value
    const ampOnly = alternative.amp_only
    const domain = alternative.alternative_cloaking_domain

    const settings: AffiliateSettings = {
      prefix,
      alternative: {
        ampOnly,
        domain,
      },
    }

    return settings
  }

  async getSiteSettings(siteToken: string) {
    const res = await VulcanClientService.getEntityByType(
      'site',
      {
        values: {
          site_color_scheme: [],
          kandy_site_id: [],
        },
      },
      {
        headers: {
          'X-SITE-ID': siteToken,
        },
      }
    )

    return {
      colorScheme: res.attributes.values.site_color_scheme,
      kandySiteId: res.attributes.values.kandy_site_id,
    }
  }
}
