import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { attributesModalOpen } from '../../store'

type AttributesModalOpen = (type: 'edit' | 'create' | 'preview') => () => void

export const useAttributesModalOpen: AttributesModalOpen = (type) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(attributesModalOpen(true, type))
  }, [dispatch, type])
}
