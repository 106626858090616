import { FieldMetaProps, useField, useFormikContext } from 'formik'
import { useMemo } from 'react'

type EnhancedMeta = {
  hasError: boolean
  errorMsg: string | undefined
} & FieldMetaProps<any>

export const getErrorsState = (meta: FieldMetaProps<any>, validateOnMount: boolean = false) => {
  const hasError = validateOnMount ? Boolean(meta.error) : Boolean(meta.error && meta.touched)
  const errorMsg = hasError ? meta.error : undefined

  return {
    hasError,
    errorMsg,
  }
}

export default function useFieldController(fieldName: string) {
  const [field, meta, helpers] = useField(fieldName)
  const formik = useFormikContext()

  const enhancedMeta = useMemo<EnhancedMeta>(() => {
    const errorsState = getErrorsState(meta, formik.validateOnMount)

    return {
      ...meta,
      ...errorsState,
    }
  }, [formik.validateOnMount, meta])

  return [field, enhancedMeta, helpers] as const
}
