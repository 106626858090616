import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useNotify } from 'core/hooks'
import AuthService from '../services/auth-service'
import { ResetPasswordPayload } from '../types'

export default function useResetPasswordController() {
  const [loading, setLoading] = useState(true)
  const { token } = useParams<{ token: string }>()
  const history = useHistory()
  const notify = useNotify()

  const saveNewPassword = useCallback(
    async (data: ResetPasswordPayload) => {
      await AuthService.resetPassword(data, token)
      history.replace('/')
      notify('The password has been changed', { type: 'success' })
    },
    [history, notify, token]
  )

  useEffect(() => {
    const checkToken = async () => {
      try {
        await AuthService.checkResetPasswordToken(token)
      } catch (e) {
        history.replace('/')
      } finally {
        setLoading(false)
      }
    }

    checkToken()
  }, [token, history])

  return {
    loading,
    saveNewPassword,
  }
}
