/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react'
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import { useField } from 'formik'
// Styles
import useStyles from './radio-button.styles'
import { v4 as uniqueKey } from 'uuid'

type TProps = {
  options: { value: string | boolean; label: string | number }[]
  name: string
  label?: string
}

const RadioButton: FC<TProps> = (props) => {
  const classes = useStyles()
  const { options, name, label = '' } = props
  const [{ value = 'false' }, meta, { setValue }] = useField(name)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  return (
    <Box className={classes.root}>
      <FormControl component="fieldset" fullWidth error={Boolean(meta.error && meta.touched)}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          name={name}
          aria-label={label}
          value={String(value) || 'false'}
          onChange={handleChange}
        >
          {options.length &&
            options.map((item: any) => (
              <FormControlLabel
                key={uniqueKey()}
                value={String(item.value)}
                control={<Radio />}
                label={item.label}
              />
            ))}
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

export default RadioButton
