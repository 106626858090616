import React, { FC } from 'react'
import { Box, FormLabel, Paper } from '@material-ui/core'
import clsx from 'clsx'
import { schemes } from './schemes'
// hooks
import { useSitesContext } from 'modules/sites'
// types
import { SectionBackground } from 'core/types/layouts'
// Styles
import useStyles from './section-colorpicker.styles'

type Props = {
  label: string
  color: string
  clickHandler: (colorName: SectionBackground) => void
  disabled?: boolean
}

const SectionBg: FC<Props> = (props) => {
  const classes = useStyles()
  const { label, color, clickHandler, disabled } = props

  const {
    siteSettings: {
      colorScheme: { value: colorSchemeValue },
    },
  } = useSitesContext()

  const colors = schemes[colorSchemeValue].container_bg

  return (
    <Box className={clsx(classes.root, { [classes.disabled]: disabled })}>
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
      <Box className={classes.list}>
        {colors.map((colorItem, index) => {
          return (
            <Paper
              className={classes.paper}
              variant="outlined"
              onClick={() => clickHandler(colorItem.name)}
              data-value={index}
              style={{
                background: colorItem.value,
              }}
              key={index}
            >
              {color === colorItem.name && <span className={classes.checked} />}
            </Paper>
          )
        })}
      </Box>
    </Box>
  )
}

export default SectionBg
