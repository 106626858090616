// Core
import React, { ChangeEvent, FC } from 'react'
// Styles
import useStyles from './tab-list.styles'
//
import { TabList as TabsNav } from '@material-ui/lab'

type TProps = {
  onChange(event: ChangeEvent<{}>, newValue: string): void
  style?: any
}

const TabList: FC<TProps> = (props) => {
  const { children, onChange, ...rest } = props
  const classes = useStyles()

  return (
    <TabsNav {...rest} indicatorColor={'primary'} className={classes.root} onChange={onChange}>
      {children}
    </TabsNav>
  )
}

export default TabList
