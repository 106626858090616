// Core
import { useQuery } from 'react-query'
// Hooks
import { useAppContext } from 'core/app'
// Services
import { httpService } from 'core/data'
// Types
import { IUser } from 'core/types'

export default function useGetProfile() {
  const { user } = useAppContext()

  const query = useQuery({
    queryKey: 'profile-query',
    queryFn: () => httpService.get<IUser>(`/user_profiles/${user.id}`).then((res) => res.data),
    initialData: user,
  })

  return {
    data: query.data as IUser,
    isLoading: query.isLoading,
    refresh: query.refetch,
  }
}
