import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      flex: '1 1 0%',
      '&.view-modal': {
        padding: theme.spacing(2),
      },
    },
    paper: {
      padding: '0 0 1px',
    },
  })
)

export default styles
