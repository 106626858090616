// Core
import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { Grid } from '@material-ui/core'
// Components
import { SmartSelectField } from 'core/form'
import { SmartSelect, SmartSelectValue } from 'ui'
// Types
import { AttributeTypesList } from 'common/types'
import { useTranslation } from 'react-i18next'

type FormValues = {
  type: AttributeTypesList
  entityTypes: string[]
  defaultValues: { value: string }
  referenceOwner: string
}

type Props = {
  children?: never
}

const MultipleReference: FC<Props> = (props) => {
  /**
   * Get 'type' via formik context
   * to know which fields will be render
   */
  const {
    values: { type, entityTypes, defaultValues },
    setFieldValue,
  } = useFormikContext<FormValues>()
  const { t } = useTranslation()

  const [defaultEntityType, setDefEntType] = useState<string>(null!)

  const referenceChangeHandler = useCallback(() => {
    setDefEntType(null!)
    setFieldValue('defaultValues', [])
  }, [setFieldValue])

  const showDefault = useMemo(() => {
    if (type === AttributeTypesList.reference_many_to_many_multiple_types) return false
    return entityTypes && Array.isArray(entityTypes) && entityTypes.length > 0
  }, [entityTypes, type])

  const isIerarchical = useMemo(() => {
    return [AttributeTypesList.reference_many_to_one_hierarchical].includes(type)
  }, [type])

  if (
    ![
      AttributeTypesList.reference_many_to_one_multiple_entity_types,
      AttributeTypesList.reference_many_to_one_multiple_types,
      AttributeTypesList.reference_many_to_one_hierarchical,
      AttributeTypesList.reference_many_to_many_multiple_types,
    ].includes(type)
  ) {
    return null
  }

  return (
    <>
      <Grid item xs={6}>
        <SmartSelectField
          label={t('labels.ref-to')}
          valueField="@id"
          name="entityTypes"
          source="entity_types"
          multiple
          onChange={referenceChangeHandler}
        />
      </Grid>
      {showDefault && !isIerarchical && (
        <Grid item xs={6}>
          <SmartSelect
            label={t('labels.def-value')}
            valueField="@id"
            source="entity_types"
            reqParams={{ id: entityTypes }}
            onChange={(value: SmartSelectValue) => {
              setFieldValue('defaultValues', { value })
            }}
            value={defaultValues ? defaultValues.value : ''}
          />
        </Grid>
      )}
      {showDefault && isIerarchical && (
        <>
          <Grid item xs={3}>
            <SmartSelect
              label={t('labels.def-entity-type')}
              valueField="@id"
              source="entity_types"
              reqParams={{ id: entityTypes }}
              onChange={(entityTypeValue: SmartSelectValue) => {
                setDefEntType(entityTypeValue as string)
                setFieldValue('defaultValues', [])
              }}
              value={defaultEntityType}
            />
          </Grid>
          {defaultEntityType && (
            <Grid item xs={3}>
              <SmartSelect
                label={t('labels.def-entity')}
                valueField="@id"
                source="entities"
                titleField="name"
                reqParams={{ entityType: defaultEntityType }}
                onChange={(value: SmartSelectValue) => {
                  setFieldValue('defaultValues', { value })
                }}
                value={defaultValues?.value}
              />
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default memo(MultipleReference)
