import React, { FC, useCallback, useState } from 'react'
import { Box, Collapse as MaterialCollapse, Icon } from '@material-ui/core'
import collapseStyles from './collapse.styles'
import clsx from 'clsx'

type TProps = {
  label?: string
  isOpen: boolean
}

const Collapse: FC<TProps> = (props) => {
  const classes = collapseStyles()
  const { children, label, isOpen } = props
  const [expanded, setExpanded] = useState(isOpen)

  const clickHandler = useCallback(() => {
    setExpanded((prevState) => !prevState)
  }, [setExpanded])

  return (
    <div className={classes.root}>
      <Box className={classes.button} onClick={clickHandler}>
        {label && <span>{label}</span>}
        <Icon
          className={clsx('icon-chevrons-closed', {
            'icon-chevrons-open': expanded,
          })}
        />
        <span className={classes.line} />
      </Box>

      <MaterialCollapse in={expanded} timeout="auto">
        {children}
      </MaterialCollapse>
    </div>
  )
}

export default Collapse
