import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { RemoveRedEye } from '@material-ui/icons'
// Components
import PageHeader from 'modules/layout/components/page-header'
// Hooks
import { useGetLocalizations } from 'common/hooks/use-get-localizations'
import { Permissions, useAppContext } from 'core/app'
// Types
import { ResourceProps } from 'core/data'

export const LocalizationList: FC<ResourceProps> = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { data: sitesLocalizations } = useGetLocalizations()

  const {
    actions: { userCan },
  } = useAppContext()

  const handleClick = useCallback(
    (item) => {
      history.push(`translations/${item.code}`, { name: item.name })
    },
    [history]
  )

  return (
    <Grid item xs={12}>
      <PageHeader titleTransKey="localizations" />
      <Box m={2} p={2} bgcolor="common.white">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={20}>{t('table.show')}</TableCell>
              <TableCell>{t('table.name')}</TableCell>
              <TableCell>{t('table.code')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sitesLocalizations?.map((item) => (
              <TableRow key={item.code}>
                {userCan('translations', Permissions.VIEW, 'system') && (
                  <TableCell>
                    <Box py={1}>
                      <IconButton aria-label="edit" onClick={() => handleClick(item)} size="small">
                        <RemoveRedEye />
                      </IconButton>
                    </Box>
                  </TableCell>
                )}

                <TableCell>
                  <span>{item.name}</span>
                </TableCell>
                <TableCell>
                  <span>{item.code}</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Grid>
  )
}
