// types
import { DialogData, DialogInstanceApi, SetDataSnippet } from './types'

const snippetDialog = {
  createExpandedDialog: (dialogData: DialogData, setDataSnippet: SetDataSnippet) => {
    const { expandedText, collaseText, expandUp, expandDefault, alignment } = dialogData
    return {
      title: 'Edit Collapse snippet',
      body: {
        type: 'panel',
        items: [
          {
            type: 'checkbox',
            name: 'enableReadMore',
            label: 'Display as read more',
          },
          {
            type: 'input',
            name: 'expandedText',
            label: 'Expand text',
          },
          {
            type: 'input',
            name: 'collaseText',
            label: 'Collapse text',
          },
          {
            type: 'checkbox',
            name: 'expandUp',
            label: 'Expand content UP',
          },
          {
            type: 'checkbox',
            name: 'expandDefault',
            label: 'Expanded by default',
          },
          {
            type: 'grid',
            columns: 4,
            items: [
              {
                type: 'htmlpanel',
                html: '<div style="line-height: 36px">Alignment</div>',
              },
              {
                type: 'selectbox',
                name: 'alignment',
                items: [
                  { value: 'left', text: 'Left' },
                  { value: 'centered', text: 'Centered' },
                  { value: 'right', text: 'Right' },
                ],
              },
              {
                type: 'htmlpanel',
                html: '',
              },
              {
                type: 'htmlpanel',
                html: '',
              },
            ],
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Cancel',
        },
        {
          type: 'submit',
          text: 'Save',
          primary: true,
        },
      ],
      initialData: {
        expandedText,
        collaseText,
        expandUp,
        expandDefault,
        alignment,
        enableReadMore: false,
      },
      onChange: (dialogApi: DialogInstanceApi<DialogData>, details: any) => {
        if (details.name === 'enableReadMore') {
          dialogApi.redial(snippetDialog.createReadMoreDialog(dialogData, setDataSnippet))
        }
      },
      onSubmit: (dialogApi: DialogInstanceApi<DialogData>) => {
        const data = dialogApi.getData()
        setDataSnippet({ ...dialogData, ...data })
        dialogApi.close()
      },
    }
  },
  createReadMoreDialog: (dialogData: DialogData, setDataSnippet: SetDataSnippet) => {
    const { xsOnly } = dialogData
    return {
      title: 'Edit Collapse snippet',
      body: {
        type: 'panel',
        items: [
          {
            type: 'checkbox',
            name: 'enableReadMore',
            label: 'Display as read more',
          },
          {
            type: 'checkbox',
            name: 'xsOnly',
            label: 'Display on mobile only',
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Cancel',
        },
        {
          type: 'submit',
          text: 'Save',
          primary: true,
        },
      ],
      initialData: {
        enableReadMore: true,
        xsOnly,
      },
      onChange: (dialogApi: DialogInstanceApi<DialogData>, details: any) => {
        if (details.name === 'enableReadMore') {
          dialogApi.redial(snippetDialog.createExpandedDialog(dialogData, setDataSnippet))
        }
      },
      onSubmit: (dialogApi: DialogInstanceApi<DialogData>) => {
        const data = dialogApi.getData()
        setDataSnippet({ ...dialogData, ...data })
        dialogApi.close()
      },
    }
  },
}

export { snippetDialog }
