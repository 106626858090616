import { OpenSelectMediaFunc } from 'modules/media/select-media'
import { Media } from 'modules/media/types'
import { Editor } from 'tinymce'

type EditorMediaLibrary = (selectMediaHandler: OpenSelectMediaFunc, editor: Editor) => void

export const mediaLibrary: EditorMediaLibrary = (selectMediaHandler, editor) => {
  editor.ui.registry.addButton('mediaLibrary', {
    text: 'media library',
    icon: 'upload',
    tooltip: 'open media library',
    onAction: () => {
      selectMediaHandler({
        handler: (file) => {
          if (file.mimeType.includes('image')) {
            insertImage(file, editor)
          } else {
            insertFile(file, editor)
          }
        },
      })
    },
  })
}

const insertImage = (file: Media, editor: any) => {
  editor.insertContent(
    `<img 
      src='${file.sourceUrl}' 
      alt='${file.alt ? file.alt : ''}' 
      title='${file.title ? file.title : ''}'
      width='${file.dimensions.width ? file.dimensions.width : ''}'
      height='${file.dimensions.height ? file.dimensions.height : ''}'
      data-media='${file.id}'
      />`
  )
}

const insertFile = (file: Media, editor: any) => {
  editor.insertContent(
    `<a href=${file.sourceUrl} title=${file.title ? file.title : ''} >${file.filename}</a>`
  )
}
