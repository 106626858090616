import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { addContainer } from '../store'

type UseAddContainer = () => any

export const useAddContainer: UseAddContainer = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(addContainer())
  }, [dispatch])
}
