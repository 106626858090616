import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      position: 'relative',
      margin: theme.spacing(2, 0),
    },
    content: {
      backgroundColor: '#fff',
      flex: 1,
    },
    preview: {
      cursor: 'pointer',
      position: 'relative',
      width: 'fit-content',
      '& img': {
        minWidth: '100px',
        width: 'auto',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    fileIcon: {
      position: 'relative',
      color: '#546E7A',
      '& .MuiIcon-root': {
        fontWeight: 100,
        fontSize: '100px',
      },
    },
    format: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      padding: '0 4px',
      backgroundColor: '#fff',
      border: '1px solid #546E7A',
      borderRadius: 5,
      textTransform: 'uppercase',
    },
    info: {
      padding: 13,
      wordBreak: 'break-all',
    },
    icons: {
      position: 'absolute',
      zIndex: 10,
      top: theme.spacing(-2.5),
      background: 'rgba(0, 0, 0, 0.6)',
      color: '#fff',
      transform: 'scale(0.75)',
      '&.delete-icon': {
        right: theme.spacing(-3),
      },
      '&.edit-icon': {
        right: theme.spacing(2),
      },
      '&:hover': {
        background: '#000',
      },
    },
  })
)

export default styles
