import { createStyles, makeStyles } from '@material-ui/core/styles'
import { pink } from '@material-ui/core/colors'

const paperStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    disabled: {
      pointerEvents: 'none',
    },
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      margin: '15px -10px 0',
    },
    paperWrap: {
      textAlign: 'center',
      padding: '5px',
    },
    paper: {
      position: 'relative',
      width: '50px',
      height: '50px',
      margin: '5px 10px 10px',
      borderRadius: '50%',
      cursor: 'pointer',
      border: '0',
      boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.2)',
    },
    checked: {
      position: 'absolute',
      top: '-5px',
      right: '-5px',
      bottom: '-5px',
      left: '-5px',
      border: `3px solid ${pink[600]}`,
      borderRadius: '50%',
    },
  })
)

export default paperStyles
