import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const tagInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-tag': {
        maxWidth: 'calc(50% - 6px)',
      },
    },
  })
)

export default tagInputStyles
