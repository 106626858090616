import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      width: '100%',
      height: '945px',
      overflow: 'auto',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    accordion: {
      borderBottom: '1px solid #EEE',
      boxShadow: 'none',
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
      },
      '&.Mui-expanded': {
        margin: '0',
        borderBottom: '1px solid #b2b2b2',
        boxShadow: 'none',
      },
    },
    accordionSummary: {
      minHeight: '54px',
      height: '100%',
      '&.Mui-expanded': {
        minHeight: '54px',
        maxHeight: '100%',
        borderBottom: '1px solid #EEE',
      },
    },
    accordionContent: {
      display: 'block',
      '&.Mui-expanded': {
        margin: '12px 0',
      },
    },
    accordionDetails: {
      padding: '0 8px',
    },
  })
)
