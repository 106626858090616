// Core
import React, { FC } from 'react'
// Context
import { ListContext } from './list-context'
// Types
import { ListControllerProps } from '../types'

type Props = {
  controllerProps: ListControllerProps
}

const ListDataProvider: FC<Props> = (props) => {
  const { children, controllerProps } = props

  return <ListContext.Provider value={controllerProps}>{children}</ListContext.Provider>
}

export default ListDataProvider
