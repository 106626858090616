import React, { useMemo } from 'react'
import { Box, FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { FC } from 'react'
import { PerPage } from './types'

type Props = {
  page: number
  totalCount: number
  perPage: PerPage
  pagesCount: number
  disabled?: boolean
  onPageChange?: (page: number) => void
  onPerPageChange?: (value: PerPage) => void
}

const perPageOptions: PerPage[] = [20, 50, 100, 'All']

export const TablePagination: FC<Props> = (props) => {
  const { page, perPage, pagesCount, totalCount, disabled, onPageChange, onPerPageChange } = props

  const countText = useMemo(() => {
    if (perPage === 'All') return totalCount
    const from = page === 1 ? 1 : (page - 1) * perPage + 1
    const to = Math.min(totalCount, page * perPage)
    return `${from} - ${to} of ${totalCount}`
  }, [page, perPage, totalCount])

  return (
    <Box flex={1} display="flex" alignItems="center" justifyContent="center" position="relative">
      <Box flexShrink={0} position="absolute" left={0}>
        <Box display="flex" alignItems="center" style={{ gap: 16 }}>
          <Typography variant="body2" color="textSecondary">
            Items per page
          </Typography>
          <FormControl variant="outlined" size="small" disabled={disabled}>
            <Select value={perPage} onChange={(e) => onPerPageChange?.(e.target.value as PerPage)}>
              {perPageOptions.map((item) => (
                <MenuItem key={item} value={item.toString()}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body2" color="textSecondary">
            {countText}
          </Typography>
        </Box>
      </Box>
      {perPage !== 'All' && (
        <Box flexShrink={0}>
          <Pagination
            disabled={disabled}
            count={pagesCount}
            page={page}
            onChange={(_, p) => onPageChange?.(p)}
            color="primary"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Box>
  )
}
