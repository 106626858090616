import React, { FC, memo } from 'react'
import { List as ListRm } from '@material-ui/core'
// Components
import ListItem from './list-item'
import ListHeader from './list-header'
// Hooks
import { useListContext } from './context'
// Styles
import { useStyles } from './styles'

type Props = {
  children?: never
}

const List: FC<Props> = (props) => {
  const {
    listProps: { selected, toggleSelected, listData },
    rolesData,
    siteRoleMap,
    setSiteRole,
  } = useListContext()
  const classes = useStyles()

  return (
    <ListRm dense className={classes.list}>
      <ListHeader />
      {listData.map((site) => (
        <ListItem
          key={site.id}
          data={site}
          isSelected={selected.includes(site['@id'])}
          toggleSelected={toggleSelected}
          siteRoles={rolesData.bySite[site['@id']]}
          selectedSiteRole={siteRoleMap[site['@id']]?.role || ''}
          setSiteRole={setSiteRole}
          siteRoleMap={siteRoleMap}
        />
      ))}
    </ListRm>
  )
}

export default memo(List)
