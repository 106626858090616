import React, { FC } from 'react'
//
import { Edit } from 'core/data'
import { ResourceProps } from 'core/data'
import CrudFormLayout from '../crud-form'
import SidebarLayout from '../sidebar/sidebar'

const EditLayout: FC<ResourceProps> = (props) => {
  return <Edit {...props} crudForm={CrudFormLayout} sidebar={SidebarLayout} />
}

export default EditLayout
