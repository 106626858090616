// Core
import React, { FC } from 'react'
// Components
import RelationsSelect from 'ui/relations-select'
import EntityRelationsSelect from 'ui/relations-select/entity-relations-select'
// Hooks
import useFieldController from 'core/form/hooks/use-field-controller'
// Types
import { RelationsSelectProps } from 'ui/relations-select/relations-select'

type Props = {
  name: string
  skipSetValue?: boolean
  forceNewTabAction?: boolean
  entityTypeIri?: string
} & Omit<RelationsSelectProps, 'value'>

const RelationsField: FC<Props> = (props) => {
  const { source, name, onChange, skipSetValue, forceNewTabAction, entityTypeIri, ...rest } = props

  const [field, meta, { setValue }] = useFieldController(name)

  const isEntities = source === 'entities'
  const Component = isEntities ? EntityRelationsSelect : RelationsSelect

  return (
    <Component
      source={source}
      helperText={meta.errorMsg}
      error={meta.hasError}
      value={field.value}
      onChange={(val) => {
        if (!skipSetValue) setValue(val)
        onChange?.(val)
      }}
      onBlur={field.onBlur}
      {...(isEntities
        ? {
            forceNewTabAction,
            entityTypeIri,
          }
        : {})}
      {...rest}
    />
  )
}

export default RelationsField
