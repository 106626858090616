import React from 'react'
import { ChangeEvent, FC, useCallback } from 'react'
import { FormControl, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { TLayoutDataItem, TLayoutGetItem, TLayoutOptionsItem } from 'core/types'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

type Props = {
  data: any
}

const MultipleLayoutField: FC<Props> = (props) => {
  const { data } = props
  const { t } = useTranslation()

  const dataToOptions = (enums: TLayoutDataItem[]) => {
    return enums.map((item: TLayoutDataItem) => {
      const option: TLayoutOptionsItem = {
        label: item.name,
        value: item['@id'],
      }
      return option
    })
  }

  const enums: TLayoutDataItem[] = data || []
  const options: TLayoutOptionsItem[] = dataToOptions(enums)

  const nameOfLayout = (iri: string) => {
    let newValue
    options.forEach((item: TLayoutOptionsItem) => {
      if (item.value === iri) {
        newValue = item.label
        return newValue
      }
    })

    return newValue
  }

  const keepValue = (fieldValue: TLayoutGetItem[]) => {
    return fieldValue.map((item: TLayoutGetItem) => {
      const filteredValue: TLayoutOptionsItem = {
        label: nameOfLayout(item.layout),
        value: item.layout,
      }
      return filteredValue
    })
  }

  const [field, , helpers] = useField('entityTypeLayouts')
  const saveValue = field.value ? keepValue(field.value) : []

  const changeHandler = useCallback(
    (event: ChangeEvent<{}>, value: any) => {
      const getLayoutValue: TLayoutGetItem = value.map(
        (item: TLayoutOptionsItem, index: number) => ({
          layout: item.value,
          sortOrder: index,
        })
      )
      helpers.setValue(getLayoutValue)
    },
    [helpers]
  )

  return (
    <FormControl fullWidth variant="outlined">
      <Autocomplete
        fullWidth
        multiple
        id="combo-box-demo"
        options={options}
        getOptionLabel={(option: any) => option.label}
        value={saveValue}
        getOptionSelected={(option, value) => option.label === value.label}
        onChange={changeHandler}
        renderInput={(params) => (
          <TextField {...params} label={t('select.layouts')} variant="outlined" />
        )}
      />
    </FormControl>
  )
}

export default MultipleLayoutField
