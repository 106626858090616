/* eslint-disable no-console */
/* eslint-disable no-alert */
import { httpService } from 'core/data'
import { DefaultValuesGenerator, ValuesGenerator } from 'modules/new-entity/transformers'
import cloneDeep from 'lodash.clonedeep'
import { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useMutationRoteHooks } from './use-mutation-rote-hooks'

import {
  EnumNavigationMethod,
  EnumNavLinkTypes,
  EnumSystemNavigationSlugs,
  specialTypes,
  TSaverArgsMerged,
  TUseGetLinkSaverArgs,
  TUseGetLinkSaverRes,
} from '../types'
import { useSitesContext } from '../../sites'

type TEntityValues = {
  values: any[]
  setRepeats: any[]
}

const getEntity = (): TEntityValues => ({
  values: [],
  setRepeats: [],
})

const useGetLinkSaver = ({
  id,
  onError,
  navigationLinkIds,
  isLoading: isHttpLoading,
}: TUseGetLinkSaverArgs): TUseGetLinkSaverRes => {
  const { locales } = useSitesContext()
  const localizations = locales.site
  const [options, setOption] = useState<TSaverArgsMerged[]>(null!)
  const [methodNav, setMethodNav] = useState<
    EnumNavigationMethod.CREATE | EnumNavigationMethod.MOVE
  >(null!)
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)
  const { navigationData } = useMutationRoteHooks()

  type State = { [entityType: number]: { singleEntityTypeRes: any; attributesRes: any } }

  const [cachedNavigationAttrData, setCache] = useState<State>()

  useEffect(() => {
    if (!navigationLinkIds || cachedNavigationAttrData)
      return // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const [firstChildData, secondChildData] = await Promise.all(
        [navigationLinkIds.entity, navigationLinkIds.custom].map(async (entityType) => {
          const [{ data: singleEntityTypeRes }, { data: attributesRes }] = await Promise.all([
            httpService.get<any>(`/entity_types/${entityType}`),
            httpService.get<any>(`/entity_types/${entityType}/attributes`),
          ] as const)
          return { singleEntityTypeRes, attributesRes }
        })
      )
      const {
        singleEntityTypeRes: { slug },
      } = firstChildData
      setCache({
        [navigationLinkIds.entity]:
          slug === EnumSystemNavigationSlugs.REF_TO_ENTITY ? firstChildData : secondChildData,
        [navigationLinkIds.custom]:
          slug === EnumSystemNavigationSlugs.REF_TO_CUSTOM ? firstChildData : secondChildData,
      })
    })()
  }, [navigationLinkIds, cachedNavigationAttrData])

  const generateData = useCallback(
    async ({ entityId, typeId }: { entityId?: number; typeId: number }) => {
      // eslint-disable-next-line
      const [apiResourceName, attributesField, setRepeatsField] = [
        'entity_types',
        'entityTypeAttributes',
        'setRepeats',
      ] as const

      const [{ data: selfData }, singleEntityTypeRes, attributesRes] = await Promise.all([
        entityId
          ? httpService.get<TEntityValues>(`/entities/${entityId}`)
          : Promise.resolve({ data: getEntity() }),

        /** Cached data */
        Promise.resolve(cloneDeep(cachedNavigationAttrData?.[typeId].singleEntityTypeRes)),
        Promise.resolve(cloneDeep(cachedNavigationAttrData?.[typeId].attributesRes)),
      ] as const)

      const fullAttributes = attributesRes['hydra:member']

      const attributes = singleEntityTypeRes[attributesField].map((item: any) => {
        item.attribute = fullAttributes.find((attr: any) => item.attribute === attr['@id'])
        return item
      })

      const [navigationGroup] = attributes

      const relations = navigationGroup.attribute.setAttributes.reduce((acc: any, attr: any) => {
        if (specialTypes.includes(attr.attribute.slug)) {
          acc[attr.attribute['@id']] = attr.attribute.slug
        }
        return acc
      }, {})

      const valuesData = {
        values: selfData.values,
        setRepeats: selfData[setRepeatsField],
      }

      const initialValues = new DefaultValuesGenerator(
        //
        attributes,
        localizations,
        valuesData
      ).getValues()

      return {
        typeData: singleEntityTypeRes,
        attributes,
        initialValues,
        relations,
      }
    },
    [localizations, cachedNavigationAttrData]
  )

  useEffect(() => {
    if (!options) return // eslint-disable-next-line @typescript-eslint/no-extra-semi
    if (!methodNav) return // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      if (methodNav === EnumNavigationMethod.MOVE) {
        setMethodNav(null!)
        setLoading(true)

        const values = options.map((item) => {
          return {
            entity: item.navItemId,
            position: item.position,
            parent: item.parentIri,
          }
        })

        try {
          // isHttpLoading?.(true)
          const httpMethod = 'put'
          // eslint-disable-next-line no-await-in-loop
          await httpService[httpMethod](`/navigations/${id}/move`, values)
        } catch (e) {
          onError?.(e)
          alert(e)
        }
      }

      if (methodNav === EnumNavigationMethod.CREATE) {
        setLoading(true)
        setMethodNav(null!)
        await Promise.all(
          options.map(async (option) => {
            const typeId = (() => {
              switch (option.type) {
                case EnumNavLinkTypes.NAVIGATION_LINK:
                  return navigationLinkIds.entity
                case EnumNavLinkTypes.NAVIGATION_CUSTOM:
                  return navigationLinkIds.custom
              }
            })()
            const entityId = option.navItemId
            const isCreating = !option.navItemId

            const data = await generateData({ typeId, entityId })
            const values = new ValuesGenerator(data.attributes, data.initialValues).getData()
            const [group] = values.setRepeats

            const menuItemsLength = navigationData?.items.length || 0

            group.values.forEach((item: any, index: number) => {
              switch (data.relations[item.attribute]) {
                case EnumSystemNavigationSlugs.POSITION:
                  item.value = option.position || menuItemsLength
                  break
                case EnumSystemNavigationSlugs.CUSTOM_SLUG:
                  if (isCreating) {
                    item.value = option.slug
                  }
                  break
                case EnumSystemNavigationSlugs.CUSTOM_TITLE:
                  if (isCreating) {
                    item.value = option.title
                  }
                  break
                case EnumSystemNavigationSlugs.REF_TO_ENTITY:
                  if (isCreating) {
                    item.value = option.entityIri
                  }
                  break
                case EnumSystemNavigationSlugs.REF_TO_NAVIGATION:
                  if (isCreating) {
                    item.value = `/api/entities/${id}`
                  }
                  break
                case EnumSystemNavigationSlugs.REF_TO_PARENT:
                  if ('parentIri' in option) {
                    item.value = option.parentIri
                  }
                  break
              }
            })

            const entityValues: any = {
              entityType: data.typeData['@id'],
              ...values,
            }
            try {
              // isHttpLoading?.(true)
              const httpMethod = isCreating ? 'post' : 'put'
              // eslint-disable-next-line no-await-in-loop
              await httpService[httpMethod](
                isCreating ? `/entities` : `/entities/${option?.navItemId}`,
                entityValues
              )
            } catch (e) {
              onError?.(e)
            }
          })
        )
      }

      setLoading(false)
      setOption(null!)
      isHttpLoading?.(false)
      queryClient.invalidateQueries('navigation')
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, methodNav, id, generateData, queryClient, navigationLinkIds])

  const saveNavigationItem: TUseGetLinkSaverRes['saveNavigationItem'] = useCallback(
    (option, method) => {
      setOption(option)
      setMethodNav(method)
    },
    []
  )

  const changeParentNavigationItem: TUseGetLinkSaverRes['changeParentNavigationItem'] = useCallback(
    (option, method) => {
      setOption(option)
      setMethodNav(method)
    },
    []
  )

  return { saveNavigationItem, changeParentNavigationItem, loading }
}

export { useGetLinkSaver }
