import { AxiosError, AxiosRequestConfig } from 'axios'
import httpService from '../instance'
import packageJson from '../../../../../../package.json'

export const addVersion = (config: AxiosRequestConfig) => {
  if (!config.headers) return config

  if (process.env.REACT_APP_ENV !== 'develop') {
    config.headers['x-version'] = packageJson.version
  }

  return config
}

export const handlerVersionError = async (error: AxiosError) => {
  const { response } = error
  const status = response?.status

  if (status === 409) {
    const versionHeader = response?.headers['x-version']
    if (httpService.showVersionConflictModal) {
      httpService.showVersionConflictModal(versionHeader || 'No version in response')
    }
  }

  return Promise.reject(error)
}
