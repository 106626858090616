import React, { FC } from 'react'
import PageHeader from 'modules/layout/components/page-header'
import { MediaManager } from '../media-manager'
import { Box } from '@material-ui/core'

export const MediaPage: FC = () => {
  return (
    <>
      <PageHeader titleTransKey="media" />
      <Box p={2}>
        <MediaManager maxHeight="calc(100vh - 64px - 61px - 32px)" />
      </Box>
    </>
  )
}
