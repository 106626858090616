// Core
import React, { useCallback, useMemo, useState } from 'react'
// Components
import QuickMutate from 'ui/relations-select/quick-create'
// Hooks
import { useGetActiveSite } from 'modules/sites'
import { useTypePermissions } from 'modules/new-entity/hooks'
// Utils
import { getIdFromIri } from 'core/utils'
// Types
import { EntityType } from 'core/types'
import { RelationsSelectProps } from 'ui/relations-select/relations-select'
import { SelectActions, SelectHandler } from 'ui/relations-select/types'

type Params = {
  entityType?: EntityType
  valueField?: RelationsSelectProps['value']
  defaultValue?: RelationsSelectProps['defaultValue']
  multiple?: boolean
}

export type RenderModal = ({
  refetch,
  selectHandler,
}: {
  refetch: () => void
  selectHandler: SelectHandler
}) => JSX.Element | null

export const useActions = ({ entityType, valueField, defaultValue, multiple }: Params) => {
  const activeSite = useGetActiveSite()
  const [crateOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState<number | null>(null)

  // @ts-ignore
  const permissions = useTypePermissions(entityType)

  const actions = useMemo(() => {
    const hasActions = !entityType || !entityType?.urlable
    const actions: SelectActions = {}

    if (permissions.canView) {
      actions.viewAction = (option) => {
        return `/${activeSite?.id}/entities/${entityType?.id}/edit/${option.id}`
      }
    }

    if (!hasActions) return actions

    if (permissions.canCreate) {
      actions.createAction = () => setCreateOpen(true)
    }

    if (permissions.canEdit) {
      actions.editAction = (id) => setEditOpen(id)
    }

    if (permissions.canDelete) {
      actions.deleteAction = (id, value, selectHandler) => {
        if (!multiple && value && typeof value === 'string' && typeof defaultValue === 'string') {
          const valueId = getIdFromIri(value)
          if (+valueId === id) selectHandler(defaultValue)
        }
      }
    }

    return actions
  }, [entityType, permissions, activeSite, multiple, defaultValue])

  const renderModal: RenderModal = useCallback(
    ({ refetch, selectHandler }) => {
      if (!entityType) return null

      const isOpen = crateOpen || Boolean(editOpen)
      const isEdit = Boolean(editOpen)

      if (isOpen) {
        return (
          <QuickMutate
            entityId={editOpen as number}
            isOpen={isOpen}
            setIsOpen={() => {
              setCreateOpen(false)
              setEditOpen(null)
            }}
            entityTypeIri={entityType['@id']}
            isEdit={isEdit}
            onSuccess={(data) => {
              if (!isEdit && valueField) {
                selectHandler(data[valueField])
              }
              refetch()
            }}
          />
        )
      }
      return null
    },
    [entityType, crateOpen, editOpen, valueField]
  )

  return { actions, renderModal }
}
