// Core
import React, { FC, ReactNode, useMemo } from 'react'
import { Box, IconButton, Input, InputAdornment, TextField, makeStyles } from '@material-ui/core'
import { Search, Close } from '@material-ui/icons'
import clsx from 'clsx'

type Props = {
  value: string
  onChange: (value: string) => void
  inDropdown?: boolean
  actions?: ReactNode
  placeholder?: string
  size?: 'small' | 'medium'
}

const useStyles = makeStyles((theme) => ({
  input: {
    '&.Mui-focused .searchIcon': {
      transform: 'rotate(90deg)',
    },
    '& .searchIcon': {
      position: 'relative',
      transition: 'transform .2s ease',
    },
  },
  dropdownInput: {
    padding: theme.spacing(1),
    background: theme.palette.grey[100],
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
}))

const SearchField: FC<Props> = (props) => {
  const { value, onChange, inDropdown, actions, placeholder = 'Search', size } = props

  const classes = useStyles()

  const searchIcon = (
    <InputAdornment position="start">
      <Search className="searchIcon" />
    </InputAdornment>
  )

  const clearIcon = useMemo(
    () => (
      <InputAdornment position="end">
        <IconButton
          size="small"
          onClick={() => onChange('')}
          style={{ transition: '.3s ease', opacity: value ? 1 : 0 }}
        >
          <Close fontSize="inherit" />
        </IconButton>
        {actions}
      </InputAdornment>
    ),
    [value, onChange, actions]
  )

  if (inDropdown) {
    return (
      <Box>
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          startAdornment={searchIcon}
          endAdornment={clearIcon}
          fullWidth
          placeholder={placeholder}
          autoFocus
          className={clsx(classes.dropdownInput, classes.input)}
        />
      </Box>
    )
  }

  return (
    <Box>
      <TextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          startAdornment: searchIcon,
          endAdornment: clearIcon,
          className: classes.input,
        }}
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        autoFocus
        size={size}
      />
    </Box>
  )
}

export default SearchField
