// Core
import { useCallback, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useFormik } from 'formik'
// Services
import { httpService } from 'core/data'
// Utils
import { transformSettingsReq } from '../utils'

type MutationData = {
  type: 'update' | 'delete'
  data: any
}

export const useSettingItemController = <T>(initialData: T) => {
  const [isEdit, setIsEdit] = useState(false)
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (mutationData: MutationData) => {
      const { data, type } = mutationData
      const url = data.entityUrl ? 'sitemap_urls' : 'sitemap_indices'

      if (type === 'update') {
        const transformData = transformSettingsReq(data)
        return httpService.put(`/${url}/${data.id}`, transformData)
      }
      return httpService.delete(`/${url}/${data.id}`)
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries('sitemap-settings-data')
      },
    }
  )

  const form = useFormik<T>({
    initialValues: initialData,
    onSubmit: async (values) => {
      await mutation.mutateAsync({ type: 'update', data: values })
    },
  })

  const editHandler = useCallback(async () => {
    if (!isEdit) {
      setIsEdit(true)
    } else {
      await form.submitForm()
      setIsEdit(false)
    }
  }, [form, isEdit])

  const deleteHandler = useCallback(async () => {
    if (isEdit) {
      setIsEdit(false)
      form.setValues(initialData)
    } else {
      await mutation.mutateAsync({ type: 'delete', data: initialData })
      setIsEdit(true)
    }
  }, [initialData, form, isEdit, mutation, setIsEdit])

  return {
    isEdit,
    setIsEdit,
    mutation,
    form,
    editHandler,
    deleteHandler,
  }
}
