import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const selectRefStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(0.5),
      // padding: theme.spacing(2),
      // marginBottom: theme.spacing(1)
    },
  })
)

export default selectRefStyles
