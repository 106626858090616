// Core
import React, { FC } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Loading: FC = () => {
  const { search } = useLocation()
  const history = useHistory()
  const { t } = useTranslation()

  history.push(search.replace('?page=', ''))

  return <p>{t('global.loading')}</p>
}

export default Loading
