import { useQuery } from 'react-query'
// Hooks
import { useSitesContext } from 'modules/sites'
// Services
import { httpService } from 'core/data'
// Types
import { HydraResponse, Localization } from 'core/types'
// Utils
import { getIdFromIri } from 'core/utils'

export const useGetLocalizations = (localizations?: string[]) => {
  const { activeSite } = useSitesContext()

  const localizationsList = localizations || activeSite?.siteLocalizations
  const localizationCodes = localizationsList?.map((localization) => getIdFromIri(localization))

  const query = useQuery(['localizations', localizationCodes, activeSite?.id], () => {
    return httpService
      .get<HydraResponse<Localization>>('/localizations', {
        params: {
          code: localizationCodes,
        },
      })
      .then((data) => data.data['hydra:member'])
  })

  return {
    data: query?.data,
    isLoading: query.isLoading,
  }
}
