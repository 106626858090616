// Core
import React, { FC, useMemo, useState } from 'react'
import { Box, Icon, MenuItem, MenuList, Paper } from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Components
import { SiteItem } from './components'
import SearchField from 'ui/search-field'
// Hooks
import { useSitesContext } from '../../hooks'
// Types
import { Site } from 'modules/sites/types'
// Styles
import useStyles from './site-switcher.styles'

type Props = {}

function sortAndFilter(data: Site[] = [], term = '') {
  return data
    .toSorted((a, b) => a.name.localeCompare(b.name))
    .filter((site) => {
      const inName = site.name.toLowerCase().includes(term.toLowerCase())
      const inUrl = site.url.toLowerCase().includes(term.toLowerCase())
      return inName || inUrl
    })
}

export const SiteSwitcher: FC<Props> = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { data, activeSite } = useSitesContext()

  const [search, setSearch] = useState('')

  const filteredData = useMemo(() => {
    return sortAndFilter(data, search)
  }, [data, search])

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.switcher}>
          <Icon className="icon-list" />
          <div className={classes.title}>{t('list.sites')}</div>
        </div>
        <div className={clsx(classes.dropdown, classes.sitesDropdown, 'dropdown')}>
          <Paper>
            <SearchField value={search} onChange={setSearch} inDropdown />
            <MenuList className={classes.listRoot}>
              {filteredData?.length === 0 && <Box textAlign="center">No results</Box>}
              {filteredData?.map((site) => (
                <SiteItem
                  data={site}
                  key={site.id}
                  searchTerm={search}
                  isActive={site['@id'] === activeSite['@id']}
                />
              ))}
            </MenuList>
          </Paper>
        </div>
      </div>
      {activeSite && (
        <div className={classes.root}>
          <div className={classes.switcher}>
            <Icon className="icon-home" />
            <div className={classes.title}>{activeSite.name}</div>
          </div>
          <div className={clsx(classes.dropdown, 'dropdown')}>
            <Paper>
              <MenuList>
                <MenuItem component="a" target="_blank" href={activeSite.url}>
                  {t('select.visit')}
                </MenuItem>
                <Link
                  to={`/sites/${activeSite.id}`}
                  style={{ textDecoration: 'none', display: 'block', color: 'inherit' }}
                >
                  <MenuItem>{t('select.edit-site')}</MenuItem>
                </Link>
              </MenuList>
            </Paper>
          </div>
        </div>
      )}
    </div>
  )
}

export default SiteSwitcher
