import { useCallback, useState, useMemo } from 'react'
// Utils
import { getIdFromIri } from 'core/utils'
// Hooks
import { useDelete } from './use-delete'

export const useNavigationItemHook = () => {
  const { mutate: remove } = useDelete('navigation')

  const [activeNavigationItem, setActiveNavigationItem] = useState<number | null>(null)
  const [activeType, setActiveType] = useState<number | null>(null)
  const [itemType, setItemType] = useState<string>('')
  const [navItemModalOpened, setNavItemModalOpened] = useState(false)

  const editNavigationItem = useCallback(
    (itemId: number, typeId: number, type: string) => {
      setActiveNavigationItem(itemId)
      setActiveType(typeId)
      setNavItemModalOpened(true)
      setItemType(type)
    },
    [setNavItemModalOpened]
  )

  const closeModal = useCallback(() => {
    setActiveNavigationItem(null)
    setNavItemModalOpened(false)
  }, [])

  const deleteNavigationItem = useCallback(
    (iri: string) => {
      remove(+getIdFromIri(iri))
    },
    [remove]
  )

  const actions = useMemo(
    () => ({
      editNavigationItem,
      deleteNavigationItem,
      closeModal,
      setActiveNavigationItem,
      setNavItemModalOpened,
    }),
    [deleteNavigationItem, editNavigationItem, closeModal, setActiveNavigationItem]
  )

  return {
    activeNavigationItem,
    activeType,
    navItemModalOpened,
    actions,
    itemType,
  }
}
