import React, { useEffect } from 'react'

/** type */
type HookType = <T>(value: T, time?: number) => [T]

/** implement */
export const useDelayValueChange: HookType = (value, time = 500) => {
  const [initialValue, setValue] = React.useState(value)

  useEffect(() => {
    if (value) setValue(value)
    else
      setTimeout(() => {
        setValue(value)
      }, time)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return [initialValue]
}
