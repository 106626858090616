// Core
import React, { FC, useCallback, useEffect, useState } from 'react'
// Components
import { Editor } from '@tinymce/tinymce-react'
import LinksModal from 'modules/new-entity/components/links-modal'
// Hooks
import { useSelectMedia } from 'modules/media/select-media'
// Utils
import { toolbarMainConfig } from './utils/toolbar-main-config'
import { toolbarMinimalConfig } from './utils/toolbar-minimal-config'
import {
  mediaLibrary,
  imageContextToolbar,
  customLink,
  portraitSnippet,
  expandedSnippet,
  expandedSnippetDialog,
} from './plugins'
// Types
import { RichEditorProps } from './types'
import { Editor as TinyMCEEditor } from 'tinymce'

const RichEditor: FC<RichEditorProps> = (props) => {
  const [linksModal, toggleLinksModal] = useState(false)
  const [linkData, setLinkData] = useState({ value: '', submit: () => {} })
  const { value, onChange, minimal, ...rest } = props

  const openLinksModal = useCallback((callback, value) => {
    setLinkData({ value, submit: callback })
    toggleLinksModal(true)
  }, [])

  const { MediaManagerModal, openSelectMedia } = useSelectMedia()

  toolbarMainConfig.setup = (editor) => {
    mediaLibrary(openSelectMedia, editor)
    imageContextToolbar(editor)
    customLink(editor, openLinksModal, toggleLinksModal)
    portraitSnippet(editor)
    expandedSnippet(editor)
    expandedSnippetDialog(editor)
  }

  const [editorInstace, setEditorInstace] = useState<TinyMCEEditor | null>(null)

  const onInitEditor = useCallback((_, editor: TinyMCEEditor) => {
    setEditorInstace(editor)
  }, [])

  const dragEndListener = useCallback(() => {
    const { id, ...editor } = editorInstace!
    setTimeout(() => {
      editor.editorManager.execCommand('mceRemoveEditor', false, id)
      editor.editorManager.execCommand('mceAddEditor', false, id)
    }, 0)
  }, [editorInstace])

  useEffect(() => {
    if (editorInstace) {
      document.addEventListener('dragEnd', dragEndListener)
    }

    return () => document.removeEventListener('dragEnd', dragEndListener)
  }, [editorInstace, dragEndListener])

  return (
    <>
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        outputFormat="html"
        value={value || ''}
        init={minimal ? toolbarMinimalConfig : toolbarMainConfig}
        onEditorChange={onChange}
        {...rest}
        onInit={onInitEditor}
      />
      {linksModal && (
        <LinksModal
          submitLink={linkData.submit}
          value={linkData.value}
          toggleModalOpen={toggleLinksModal}
          openModal={linksModal}
        />
      )}
      {MediaManagerModal}
    </>
  )
}

export default RichEditor
