import React, { useState, useEffect, useCallback, RefObject } from 'react'
import { useHistory, useLocation, Prompt } from 'react-router-dom'
import { Location } from 'history'
import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core'
import isEqual from 'lodash.isequal'
import { useTranslation } from 'react-i18next'
import { hasFormBackupData } from 'core/check-version/utils'

type UnsavedChanges = (
  formRef: RefObject<any>,
  widgetControlledProps: any,
  initialWidgets?: any
) => {
  prompt: JSX.Element
  methods: {
    savedChanges: () => void
    checkUnsavedChanges: () => boolean
  }
}

export const useUnsavedEntity: UnsavedChanges = (
  formRef,
  widgetControlledProps,
  initialWidgets
) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = () => {
    setModalVisible(false)
  }

  const checkUnsavedChanges = useCallback(() => {
    const { initialValues, values } = formRef.current
    const checkEntityValues = isEqual(initialValues, values)

    const checkLengthWidgets = isEqual(initialWidgets, widgetControlledProps?.widgets)

    const widgetsRef = Object.values(widgetControlledProps?.widgetsDataRef.current)
    const checkWidgetsValues = widgetsRef.every((widget: any) => {
      return isEqual(widget.formRef?.initialValues, widget.formRef?.values)
    })
    const noDirty = checkEntityValues && checkLengthWidgets && checkWidgetsValues

    return !noDirty
  }, [formRef, initialWidgets, widgetControlledProps])

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    /** no trigger when query/hash change */
    if (location.pathname === nextLocation.pathname) return true

    if (hasFormBackupData()) return true

    /**
     * Checking entity values
     * */
    // @ts-ignore
    const { initialValues, values } = formRef.current
    const checkEntityValues = isEqual(initialValues, values)

    /**
     * Checking length of widgets
     * */
    const checkLengthWidgets = isEqual(initialWidgets, widgetControlledProps?.widgets)

    /**
     * Checking values of widgets
     * */
    const widgetsRef = Object.values(widgetControlledProps?.widgetsDataRef.current)
    const checkWidgetsValues = widgetsRef.every((widget: any) => {
      return isEqual(widget.formRef?.initialValues, widget.formRef?.values)
    })

    const noDirty = checkEntityValues && checkLengthWidgets && checkWidgetsValues

    if (!confirmedNavigation && !noDirty) {
      setModalVisible(true)
      setLastLocation(nextLocation)

      return false
    }

    return true
  }

  const handleConfirmNavigationClick = () => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }

  const savedChanges = useCallback(() => {
    setConfirmedNavigation(true)
  }, [])

  useEffect(
    () => {
      if (confirmedNavigation && lastLocation) {
        history.push(lastLocation.pathname)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, lastLocation, history]
  )

  // on refresh page
  const handleBlockedReload = useCallback(
    (e: any) => {
      if (hasFormBackupData()) return true
      /**
       * Checking entity values
       * */
      // @ts-ignore
      const { initialValues, values } = formRef.current
      const checkEntityValues = isEqual(initialValues, values)

      /**
       * Checking length of widgets
       * */
      const checkLengthWidgets = isEqual(initialWidgets, widgetControlledProps?.widgets)

      /**
       * Checking values of widgets
       * */
      const widgetsRef = Object.values(widgetControlledProps?.widgetsDataRef.current)
      const checkWidgetsValues = widgetsRef.every((widget: any) => {
        return isEqual(widget.formRef?.initialValues, widget.formRef?.values)
      })

      const noDirty = checkEntityValues && checkLengthWidgets && checkWidgetsValues

      if (!confirmedNavigation && !noDirty) {
        e.preventDefault()
        e.returnValue = t('modals.discard')
      }
    },
    [
      confirmedNavigation,
      formRef,
      initialWidgets,
      t,
      widgetControlledProps?.widgets,
      widgetControlledProps?.widgetsDataRef,
    ]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBlockedReload)
    return () => {
      window.removeEventListener('beforeunload', handleBlockedReload)
    }
  }, [handleBlockedReload])
  // end

  const routerPrompt = (
    <>
      <Prompt message={handleBlockedNavigation} />
      <Dialog
        open={modalVisible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('modals.discard')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleConfirmNavigationClick()} color="secondary">
            {t('modals.discard-btn')}
          </Button>
          <Button onClick={() => closeModal()} color="primary" autoFocus>
            {t('modals.keep-btn')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )

  return {
    prompt: routerPrompt,
    methods: {
      savedChanges,
      checkUnsavedChanges,
    },
  }
}
