import React, { FC, useMemo } from 'react'
import {
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
} from '@material-ui/core'
import { GridColumn } from '../../types'
import { RelationCell } from './relation-cell'
import { DateCell } from './date-cell'
import RowsSkeleton from 'modules/new-entity/components/data-view/rows-skeleton'
import clsx from 'clsx'
import { useDndItemController } from '../../features/dnd'

const useStyles = makeStyles((theme) => ({
  rowBase: {
    '&:hover .select-row-checkbox:not(.Mui-checked)': {
      opacity: '1',
    },
    '& .select-row-checkbox:not(.Mui-checked)': {
      opacity: 0,
    },
  },
  row: {
    cursor: 'pointer',
    transition: 'outline .3s ease, opacity .3s ease',
    outline: `1px solid transparent`,
    '&.MuiTableRow-hover:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&.Mui-selected': {
      backgroundColor: '#e7e5ff',
    },
  },
  outlined: {
    outlineColor: theme.palette.primary.main,
    background: '#e7e5ff',
  },
  disabled: {
    opacity: 0.5,
  },
  pending: {
    opacity: 0.5,
    cursor: 'wait',
  },
}))

type Props = {
  columns: GridColumn[]
  data: Record<string, any>[]
  isLoading: boolean
  selected: number[]
  isAllSelected: boolean
  hasSelectedInRange: boolean
  onRowSelect?: (id: number) => void
  onDoubleClickSelect?: (value: any) => void
}

export const TableList: FC<Props> = ({
  columns,
  data,
  isLoading,
  selected,
  isAllSelected,
  hasSelectedInRange,
  onRowSelect,
  onDoubleClickSelect,
}) => {
  return (
    <TableContainer style={{ maxHeight: '100%' }}>
      <Table stickyHeader size="small">
        <TableHeader
          columns={columns}
          selected={selected}
          isAllSelected={isAllSelected}
          hasSelectedInRange={hasSelectedInRange}
          onRowSelect={onRowSelect}
        />
        <TableBody>
          {isLoading && <RowsSkeleton columnsCount={columns.length + 1} show />}
          {data.map((rowData) => (
            <TableRowItem
              key={rowData.id}
              rowData={rowData}
              columns={columns}
              selected={selected.includes(rowData.id)}
              selectedRows={selected}
              hover={Boolean(onRowSelect)}
              onClick={() => onRowSelect?.(rowData.id)}
              onDoubleClick={() => onDoubleClickSelect?.(rowData)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type TableRowItemProps = {
  columns: GridColumn[]
  rowData: any
  selectedRows: number[]
} & TableRowProps

export const TableRowItem: FC<TableRowItemProps> = ({
  columns,
  rowData,
  selected,
  selectedRows,
  onClick,
  onDoubleClick,
  hover,
}) => {
  const classes = useStyles()

  const isPending = rowData.pending

  const { setRef, listeners, attributes, isOver, dndActive } = useDndItemController({
    id: rowData.id,
    selected: selectedRows,
    disabledDrop: rowData.type !== 'folder',
    disabledDrag: !selected,
  })

  return (
    <TableRow
      {...listeners}
      {...attributes}
      ref={setRef}
      selected={selected}
      onClick={!isPending ? onClick : undefined}
      onDoubleClick={!isPending ? onDoubleClick : undefined}
      hover={hover}
      className={clsx(classes.rowBase, classes.row, {
        [classes.outlined]: isOver,
        [classes.disabled]: dndActive && selected,
        [classes.pending]: isPending,
      })}
    >
      <TableCell padding="checkbox">
        <Checkbox
          disabled={isPending}
          className="select-row-checkbox"
          color="primary"
          checked={selected}
        />
      </TableCell>
      {columns.map((column) => (
        <TableCellRenderer key={column.field} column={column} rowData={rowData} />
      ))}
    </TableRow>
  )
}

type TableHeaderProps = {
  columns: GridColumn[]
  selected: number[]
  isAllSelected: boolean
  hasSelectedInRange: boolean
  onRowSelect?: (id: number) => void
}

const TableHeader: FC<TableHeaderProps> = ({
  columns,
  onRowSelect,
  selected,
  isAllSelected,
  hasSelectedInRange,
}) => {
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow className={classes.rowBase}>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={!isAllSelected && hasSelectedInRange}
            color="primary"
            onChange={() => onRowSelect?.(-1)}
            checked={hasSelectedInRange}
            className="select-row-checkbox"
          />
        </TableCell>
        {columns.map((column) => (
          <TableCell style={{ textTransform: 'capitalize' }} key={column.field}>
            {column.title ?? column.field}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

type TableCellRendererProps = {
  column: GridColumn
  rowData: Record<string, any>
}

const TableCellRenderer: FC<TableCellRendererProps> = ({ column, rowData }) => {
  const { type } = column

  const content = useMemo(() => {
    if (type === 'custom') {
      return column.cellRenderer({ rowData })
    }
    if (type === 'relation') {
      return <RelationCell column={column} rowData={rowData} />
    }
    if (type === 'date') {
      return <DateCell column={column} rowData={rowData} />
    }

    return rowData[column.field]
  }, [column, rowData, type])

  return <TableCell>{content}</TableCell>
}
