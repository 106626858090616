import { RefObject, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UseMutationResult, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import cloneDeep from 'lodash.clonedeep'
// Hooks
import { useNotify } from 'core/hooks'
import { useRelationsError } from './use-relations-error'
// Utils
import { EntityService, ValidationError } from '../utils'
import { getIdFromIri } from 'core/utils'
import { ApiErrorsService } from '../utils/api-errors'
import { sleep } from 'core/utils/sleep'
// Types
import {
  EavResourceType,
  FormRef,
  IEntity,
  IEntityWidget,
  SaveEntityActions,
  SortedAttribute,
  WidgetsDataRef,
} from '../types'
import { EntityType } from 'core/types'

type Params = {
  attributes: SortedAttribute[]
  formRef: FormRef
  mutation: UseMutationResult<any, any, any, any>
  entity: IEntity
  entityType: EntityType
  widgets: IEntityWidget[]
  widgetsDataRefs: RefObject<WidgetsDataRef>
  resourceType: EavResourceType
  isEdit: boolean
  savedChanges?: () => void
  id?: string
  hasVersions: boolean
}

export const useEntityMutation = (params: Params) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const notify = useNotify()
  const { t } = useTranslation()
  const [validationErrors, setValidationErrors] = useState<any>({})
  const [submittedOnce, setSubmittedOnce] = useState(false)

  const {
    formRef,
    attributes,
    entity,
    entityType,
    mutation,
    widgets,
    widgetsDataRefs,
    resourceType,
    isEdit,
    savedChanges,
    id,
    hasVersions,
  } = params

  const { notifyRelationsError, isRelationsError } = useRelationsError()

  const invalidateForm = useCallback(async () => {
    queryClient.invalidateQueries(`entity-${id}`)
    await queryClient.invalidateQueries('attributes-form')
  }, [id, queryClient])

  const redirect = useCallback(
    async (createdId: number) => {
      if (isEdit) {
        queryClient.invalidateQueries(`entity-${id}`)
        queryClient.invalidateQueries(`versions-data`)
        await queryClient.invalidateQueries('attributes-form')
      } else {
        history.push(`/${resourceType}/${entityType.id}/edit/${createdId}`)
      }
    },
    [isEdit, queryClient, history, resourceType, entityType, id]
  )

  const saveEntity = useCallback(
    async (action: SaveEntityActions) => {
      const isTemplate = resourceType === EavResourceType.TEMPLATE
      const values = { ...formRef.current?.values }
      let needClientValidation = true

      if (isTemplate || ['unpublish', 'draft'].includes(action)) {
        needClientValidation = false
      }

      if (hasVersions && action === 'default') {
        needClientValidation = false
      }

      try {
        /**
         * Validation entity and widgets
         * if status is not "draft"
         */
        if (needClientValidation) {
          setSubmittedOnce(true)
          const formValidation = await EntityService.validateForm(formRef.current)
          const widgetFormsValidation = await EntityService.validateWidgets(
            widgets,
            widgetsDataRefs.current!
          )
          const widgetsHasErrors = widgetFormsValidation.some(
            (widgetError) => widgetError.hasErrors
          )

          if (formValidation.hasErrors) {
            EntityService.scrollToError(formValidation.errors)
          } else if (widgetsHasErrors) {
            const widgetFormsWithErrors = widgetFormsValidation.filter(
              (widgetError) => widgetError.hasErrors
            )

            widgetFormsWithErrors.forEach((widgetError) => {
              if (widgetsDataRefs.current && widgetsDataRefs.current[widgetError.id]) {
                widgetsDataRefs.current[widgetError.id].expandWidget()
              }
            })

            await sleep(500)

            EntityService.scrollToError(widgetFormsWithErrors[0].errors)
          }

          if (formValidation.hasErrors || widgetsHasErrors) {
            throw new ValidationError('Validation Error')
          }
        } else {
          formRef.current?.setErrors({})
        }
        /**
         * Generate values for entity
         */
        const entityValues = EntityService.generateValues(values, attributes)
        /**
         * Pass widgets values from forms to widget values
         */
        const entityWidgets = EntityService.prepareWidgetsValues(widgets, widgetsDataRefs.current!)
        /**
         * Create mutation entity object with transformed values and widgets values
         */

        const widgetsObject = {
          widgetType: entityType['@id'],
        }

        const entityObject = {
          entityType: entityType['@id'],
          entityWidgets,
          layout: entity.layout,
          template: resourceType === EavResourceType.TEMPLATE,
        }

        const mutationObject = {
          action,
          localizations: [],
          ...entityValues,
          ...(resourceType === EavResourceType.WIDGET ? widgetsObject : entityObject),
          original: entity.original,
          originalLabel: entity.originalLabel,
          segments: entity.originalSegments,
        }

        const { data } = await mutation.mutateAsync(mutationObject)
        savedChanges && savedChanges()
        const id = +getIdFromIri(data['@id'])
        redirect(id)

        notify(t('notify.success'), { type: 'success' })
      } catch (e) {
        if (e?.type === 'validation') {
          notify('Validation Error', { type: 'error' })
        } else if (e?.type === 'cache-revalidation') {
          notify('Data saved, but cache not revalideted', { type: 'warning' })
        } else if (ApiErrorsService.isApiError(e)) {
          const apiErrorsService = new ApiErrorsService(formRef.current!, e as any)
          apiErrorsService.setFormErrors()
          setValidationErrors(apiErrorsService.formErrors)
          notify('Validation Error', { type: 'error' })
        } else if (isRelationsError(e)) {
          notifyRelationsError(e, 'Uniqueness error. These values are used in other entities')
        } else {
          notify('Something went wrong', { type: 'error' })
          console.dir(e)
        }
      }
    },
    [
      resourceType,
      formRef,
      hasVersions,
      attributes,
      widgets,
      widgetsDataRefs,
      entityType,
      entity,
      mutation,
      savedChanges,
      redirect,
      notify,
      t,
      isRelationsError,
      notifyRelationsError,
    ]
  )

  const generateEntityValues = useCallback(() => {
    const values = { ...formRef.current?.values }

    /**
     * Generate values for entity
     */
    const entityValues = EntityService.generateValues(values, attributes)
    /**
     * Pass widgets values from forms to widget values
     */
    const entityWidgets = EntityService.prepareWidgetsValues(
      widgets,
      widgetsDataRefs.current!,
      false,
      true
    )
    /**
     * Create mutation entity object with transformed values and widgets values
     */
    const entityObject = {
      entityWidgets,
      ...entityValues,
    }

    return entityObject
  }, [attributes, formRef, widgets, widgetsDataRefs])

  const saveAsTemplate = useCallback(async () => {
    try {
      /**
       * Generate values for entity
       */
      const values = cloneDeep(formRef.current?.values)

      for (const val of Object.values(values)) {
        // @ts-ignore
        if (val.type === 'slug') {
          // @ts-ignore
          val.value = ''
        }
      }

      const entityValues = EntityService.generateValues(values, attributes, false, true)

      /**
       * Pass widgets values from forms to widget values
       */
      const entityWidgets = EntityService.prepareWidgetsValues(
        widgets,
        widgetsDataRefs.current!,
        true
      )
      /**
       * Create mutation entity object with transformed values and widgets values
       */
      const mutationObject = {
        entityType: entityType['@id'],
        entityWidgets,
        localizations: [],
        layout: entity.layout,
        template: true,
        asTemplate: true,
        ...entityValues,
      }
      await mutation.mutateAsync(mutationObject)
      notify('Template creation success', { type: 'success' })
    } catch (e) {
      console.dir(e)
    }
  }, [attributes, entity, entityType, formRef, mutation, notify, widgets, widgetsDataRefs])

  return {
    saveEntity,
    saveAsTemplate,
    generateEntityValues,
    invalidateForm,
    validationErrors,
    submittedOnce,
  }
}
