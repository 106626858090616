import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const collapseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    button: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: theme.spacing(2, 0),
      fontWeight: 500,
      fontSize: '18px',
      color: 'rgba(0, 0, 0, 0.28)',
      '& :hover': {
        cursor: 'pointer',
      },
    },
    line: {
      display: 'block',
      backgroundColor: colors.blueGrey[50],
      height: 1,
      flexGrow: 1,
    },
  })
)

export default collapseStyles
