// Core
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
// Components
import LockScreen from './lock-screen'
import ControlRequestScreen from './control-request-screen'
// Hooks
import { useAppContext } from 'core/app'
import { useEvents } from 'core/events'
import { useMessages } from './use-messages'
import { useDeleteLock } from './use-delete-lock'
import { useRefreshLock } from './use-refresh-lock'
// Services/Utils
import { useNotify } from 'core/hooks'
import {
  ASK_CONTROL_EVENT,
  CANCEL_ASK_CONTROL,
  DENY_CONTROL_EVENT,
  LockService,
  userParser,
} from './utils'
// Types
import { AskControlData, LockData, LockedUser } from './types'
import { SaveEntityActions } from 'modules/new-entity/types'
import { EntityType } from 'core/types'

export function useSimultaneouslyEdit(
  entityId: string,
  entityType: EntityType,
  isEdit: boolean,
  isTemplate: boolean,
  saveEntity: (action: SaveEntityActions) => Promise<void>,
  saveLoading: boolean,
  invalidateForm: () => Promise<void>,
  disabled: boolean
) {
  const { user } = useAppContext()
  const { push } = useEvents()
  const notify = useNotify()
  const history = useHistory()

  const enabled = isEdit && !disabled
  const lockKey = `editing-entity-${entityId}`
  const MESSAGES_KEY = `/api/locks/${lockKey}`
  const isWidgets = entityType && entityType['@type'] === 'WidgetType'
  const typeId = entityType?.id
  const backPath = isTemplate
    ? `/templates/${typeId}`
    : isWidgets
    ? `/widgets/${typeId}`
    : `/entities/${typeId}`

  const [refresh, setRefresh] = useState(0)
  const [lockData, setLockData] = useState<LockData | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const lockedUser = useMemo<LockedUser>(() => {
    if (!lockData) return null
    return JSON.parse(lockData?.value)
  }, [lockData])
  const canEdit = lockedUser?.userId === user.id

  const [askControlData, setAskControlData] = useState<AskControlData | null>(null)
  const [waitControlLoading, setWaitControlLoading] = useState(false)

  const askControl = useCallback(() => {
    setWaitControlLoading(true)
    push(MESSAGES_KEY, {
      type: ASK_CONTROL_EVENT,
      data: userParser.stringify(user),
    }).then(() => {
      notify(
        `User ${lockedUser.userName} received your request. If he does not respond, you will get access within 30 seconds automatically`,
        { type: 'success' }
      )
    })
  }, [MESSAGES_KEY, lockedUser, notify, push, user])

  const denyAskControl = useCallback(() => {
    setAskControlData(null)
    push(MESSAGES_KEY, { type: DENY_CONTROL_EVENT, data: JSON.stringify(lockedUser) })
  }, [MESSAGES_KEY, lockedUser, push])

  const setAskControlHandler = useCallback((data: AskControlData) => setAskControlData(data), [])

  const denyControlHandler = useCallback(() => {
    if (!canEdit) {
      setWaitControlLoading(false)
    } else {
      setAskControlData(null)
    }
  }, [canEdit])

  const approveAccessHandler = useCallback(
    async (withSave?: boolean) => {
      if (withSave) {
        await saveEntity('draft')
      }
      const newLockData = LockService.generateLockData(lockKey, askControlData)
      await LockService.updateLock(lockKey, newLockData)
    },
    [askControlData, lockKey, saveEntity]
  )

  const forceGetControlAccess = useCallback(() => {
    const lockRecord = LockService.generateLockData(lockKey, user)

    try {
      LockService.updateLock(lockKey, lockRecord)
    } catch (e) {
      LockService.createLock(lockRecord)
    }
  }, [lockKey, user])

  const lockUpdatedHandler = useCallback(
    (data: LockData) => {
      setAskControlData(null)
      setLockData(data)
      setWaitControlLoading(false)

      /**
       * When lock updated and it is forced by another user
       * navigate user who currently edit from edit page
       */
      const userData = userParser.parse(data.value)

      if (userData.userId !== user.id) {
        history.push(backPath)
      }
    },
    [backPath, history, user]
  )

  const lockeDeletedHandler = useCallback(async () => {
    /**
     * Lock is removed when user leaves page or lock expires
     * If user was waiting access form invalidates
     * and for all users lock refresh in order to create new one
     */
    if (!canEdit) {
      await invalidateForm()
    }
    setRefresh((prev) => prev + 1)
  }, [canEdit, invalidateForm])

  const goBackHandler = useCallback(() => {
    if (waitControlLoading) {
      push(MESSAGES_KEY, { type: CANCEL_ASK_CONTROL })
    }
    history.push(backPath)
  }, [waitControlLoading, history, backPath, push, MESSAGES_KEY])

  const cancelControlRequestHandler = useCallback(() => {
    if (canEdit) {
      setAskControlData(null)
    }
  }, [canEdit])

  useEffect(() => {
    if (!enabled) return

    const init = async () => {
      setIsLoading(true)
      try {
        const lockRecord = LockService.generateLockData(lockKey, user)
        await LockService.createLock(lockRecord)
        setLockData(lockRecord)
      } catch (error) {
        if (LockService.isLockExistError(error)) {
          const lockData = await LockService.getLock(lockKey)
          setLockData(lockData)
        }
      } finally {
        setIsLoading(false)
      }
    }

    init()
  }, [lockKey, user, refresh, enabled])

  /**
   * Sockets events listeners
   */
  useMessages({
    canEdit,
    lockKey,
    enabled,
    lockedUser,
    onLockDeleted: lockeDeletedHandler,
    onAskControlRequest: setAskControlHandler,
    onDenyControl: denyControlHandler,
    onCancelRequestControl: cancelControlRequestHandler,
    onLockUpdated: lockUpdatedHandler,
  })

  /**
   * Leave edit listeners
   */
  useDeleteLock({ enabled, canEdit, lockKey })

  /**
   * Refresh lock on background
   */
  useRefreshLock({ enabled, canEdit, lockKey, currentUser: user })

  return {
    isLoading,
    lockScreen: !canEdit && lockData && (
      <LockScreen
        goBackHandler={goBackHandler}
        lockedUser={lockedUser}
        askControl={askControl}
        waitControlLoading={waitControlLoading}
        forceGetControlAccess={forceGetControlAccess}
      />
    ),
    askControlScreen: askControlData && (
      <ControlRequestScreen
        saveLoading={saveLoading}
        onApproveControl={approveAccessHandler}
        onClose={denyAskControl}
        data={askControlData}
      />
    ),
  }
}
