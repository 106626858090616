import { httpService } from 'core/data'
import {
  ChangePasswordPayload,
  ForgotPasswordPayload,
  Login2FAPayload,
  Login2FAResponse,
  LoginPayload,
  LoginResponse,
  ResetPasswordPayload,
} from '../types'

export default class AuthService {
  static loginByEmail(data: LoginPayload) {
    return httpService.post<LoginResponse>('/login', data)
  }

  static login2FA(data: Login2FAPayload) {
    return httpService.post<Login2FAResponse>('/login/2fa', data)
  }

  static forgotPassword(data: ForgotPasswordPayload) {
    return httpService.post('/reset-password', data)
  }

  static checkResetPasswordToken(token: string) {
    return httpService.get(`/reset-password/${token}`)
  }

  static resetPassword(data: ResetPasswordPayload, token: string) {
    return httpService.post(`/reset-password/${token}`, data)
  }

  static getCurrentUserByToken(token: string) {
    return httpService.get<{ id: number }>('/me', {
      headers: {
        JWTAuthorization: `Bearer ${token}`,
      },
    })
  }

  static changeUserPasswordExpired(userId: number, token: string, data: ChangePasswordPayload) {
    return httpService.post(`/users/${userId}/change_passwords`, data, {
      headers: {
        JWTAuthorization: `Bearer ${token}`,
      },
    })
  }

  static changePassword(userId: number, data: ChangePasswordPayload) {
    return httpService.post(`/users/${userId}/change_passwords`, data)
  }
}
