import React, { FC, useCallback } from 'react'
import { Pagination as PaginationLab } from '@material-ui/lab'
import { useTranslationContext } from '../../../../hooks'

type TProps = {
  data?: object
}

export const Pagination: FC<TProps> = (props) => {
  const perPage = 30
  const {
    query: { data, isLoading },
    page,
    setPage,
  } = useTranslationContext()

  const total = !isLoading ? data?.['hydra:totalItems'] : 0

  const changePageHandler = useCallback(
    (e: unknown, _page: number) => {
      setPage(_page)
    },
    [setPage]
  )

  if (!perPage) return null

  // @ts-ignore
  const pageCount = Math.ceil(total / perPage)

  if (pageCount <= 1) return null

  return (
    <div style={{ padding: '24px 8px' }}>
      <PaginationLab
        count={pageCount}
        page={page}
        onChange={changePageHandler}
        color="primary"
        size="medium"
        showFirstButton
        showLastButton
      />
    </div>
  )
}
