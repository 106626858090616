import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const modalFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2, 3),
      fontWeight: 'bold',
      fontSize: theme.spacing(5),
      height: `calc(100% - 200px)`,
      color: colors.blueGrey['600'],
    },
    notFound: {
      margin: theme.spacing(3, 0),
      fontSize: '36px',
    },
  })
)

export default modalFooterStyles
