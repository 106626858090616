// Core
import { useCallback } from 'react'
import { useQuery } from 'react-query'
// Utils
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
// Types
import { EntityListItem } from 'modules/eav/types'
import { HydraResponse } from 'core/types'
import { Site } from 'modules/sites'

type SitesResponse = HydraResponse<Site>

export const useLabelWithLocalization = () => {
  const query = useQuery(
    'all-sites',
    () => {
      return httpService
        .get<SitesResponse>('/sites', {
          params: { pagination: false },
        })
        .then((res) => res.data)
    },
    {
      select: (data) => {
        const sites = data?.['hydra:member'] || []

        const sitesLocalizations = sites.reduce<Record<string, string>>((acc, site) => {
          acc[site.id] = getIdFromIri(site.defaultLocale)
          return acc
        }, {})

        return sitesLocalizations
      },
    }
  )

  const getLabelWithLocalization = useCallback(
    (data) => {
      const entityData = data as EntityListItem
      const siteId = getIdFromIri(entityData.site)
      const localization = entityData.localization || query.data?.[siteId]
      const localizationString = localization ? ` (${localization})` : ''
      return `${entityData.name}${localizationString}`
    },
    [query.data]
  )

  return {
    getLabelWithLocalization,
  }
}
