import { Editor } from 'tinymce'

export const imageContextToolbar = (editor: Editor) => {
  editor.ui.registry.addContextToolbar('imagealignment', {
    predicate(node: any) {
      return node.nodeName.toLowerCase() === 'img'
    },
    items: 'alignleft aligncenter alignright | quicklink |',
    position: 'node',
    scope: 'node',
  })
}
