/* eslint-disable */
import deburr from 'lodash.deburr'
import trim from 'lodash.trim'

export const slugifyValue = (string: string ) => {
	if (!string) {
		return ''
	}
	return trim(
		deburr(string)
			.replace( /[\s\./]+/g, '-' )
			.replace( /[^\p{L}\p{N}_-]+/gu, '' )
			.toLowerCase(),
		'-'
	)
}
