import { ChangeEvent, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setContainerSlug } from '../store'

type UseContainerSlug = () => any

export const useContainerSlug: UseContainerSlug = () => {
  const dispatch = useDispatch()

  return useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setContainerSlug(event.target.value))
    },
    [dispatch]
  )
}
