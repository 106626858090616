import React, { FC } from 'react'
// Material Components
import { Typography } from '@material-ui/core'

type TPreviewItem = {
  isShown?: boolean
  title: string
  value: any
  note?: string
}

const PreviewItem: FC<TPreviewItem> = (props) => {
  const { title, value, note, isShown } = props

  if (isShown !== undefined && !isShown) return null
  if (!value) return null

  return (
    <Typography variant="caption" style={{ display: 'block' }}>
      <strong>{title}:</strong> {String(value)} {note}
    </Typography>
  )
}

export default PreviewItem
