export const withWrapValue = <T, Id>(value: T, id?: Id, skipIds = false) => {
  if (id && !skipIds) return { value, id }
  return { value }
}

export const getWrapValue = (obj: any) => {
  return obj?.value
}

export const withDotValue = (name: string) => {
  return `${name}.value`
}

export const withoutDotValue = (name: string) => {
  return name.endsWith('.value') ? name.replace('.value', '') : name
}
