// Core
import React, { FC, useMemo } from 'react'
// Components
import { DataView } from 'core/data'
import { ShowAction } from './components'
import CheckIcon from '@material-ui/icons/Check'
// Types
import { EavResourceType } from 'modules/new-entity/types'
// Columns
import { getTableColumn } from 'core/data/components/data-view'
import { useTranslation } from 'react-i18next'

type Props = {
  children?: never
  resourceType: EavResourceType
}

const TypesList: FC<Props> = (props) => {
  const { resourceType } = props
  const { t } = useTranslation()

  const dynamicColumns =
    resourceType !== EavResourceType.WIDGET
      ? [
          getTableColumn({
            title: t('table.urlable'),
            render: (rowData: any) => (rowData.urlable ? <CheckIcon /> : null),
          }),
        ]
      : []

  const filters = useMemo(() => {
    if (resourceType === EavResourceType.TEMPLATE) {
      return {
        hidden: false,
        urlable: true,
        single: false,
        builder: true,
      }
    }
    return {
      hidden: false,
    }
  }, [resourceType])

  const resourceName = useMemo(() => {
    switch (resourceType) {
      case EavResourceType.ENTITY: {
        return 'entity_types'
      }
      case EavResourceType.TEMPLATE: {
        return 'entity_types'
      }
      case EavResourceType.WIDGET: {
        return 'widget_types'
      }
    }
  }, [resourceType])

  return (
    <DataView
      resourceProps={{
        name: resourceName,
        resourceType,
      }}
      controllerOptions={{
        filters,
      }}
      columns={[
        getTableColumn({ title: t('table.show'), width: '100px' }, 'custom', ShowAction, null, {
          resourceType,
        }),
        getTableColumn({ title: t('table.name'), field: 'name' }),
        getTableColumn({ title: t('table.slug'), field: 'slug' }),
        ...dynamicColumns,
      ]}
    />
  )
}

export default TypesList
