import React, { FC, ReactNode } from 'react'
import { Box, Typography, Divider, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'

type Props = {
  title: string
  actions?: ReactNode
  onClose: () => void
}

export const SidebarHeader: FC<Props> = ({ title, actions, onClose }) => {
  return (
    <Box bgcolor="white" zIndex={20}>
      <Box p={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography style={{ fontWeight: 500 }} noWrap>
            {title}
          </Typography>
          <Box whiteSpace="nowrap">
            {actions}
            <IconButton size="small" onClick={onClose} edge="end">
              <Close />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  )
}
