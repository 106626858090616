import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import isEqual from 'lodash.isequal'
import { useTranslation } from 'react-i18next'

type ModalWarnings = (
  onDiscard?: () => void,
  clearForm?: boolean
) => {
  prompt: JSX.Element
  methods: { closeModal: () => void; showWarningModal: () => void; setRef: (node: any) => void }
}

export const useModalWarnings: ModalWarnings = (onDiscard, clearForm = false) => {
  const { t } = useTranslation()
  const [warningModalVisible, setWarningModalVisible] = useState(false)
  const formRef = useRef()

  const setRef = useCallback((node) => {
    formRef.current = node
  }, [])

  const closeWarningModal = useCallback(() => {
    setWarningModalVisible(false)
  }, [])

  const closeModal = useCallback(() => {
    if (!formRef.current) {
      closeWarningModal()
      if (onDiscard) onDiscard()
    } else {
      // @ts-ignore
      const { resetForm } = formRef.current
      closeWarningModal()
      if (onDiscard) onDiscard()
      if (clearForm) resetForm()
    }
  }, [clearForm, closeWarningModal, onDiscard])

  const showWarningModal = useCallback(() => {
    if (!formRef.current) return closeModal()
    // @ts-ignore
    const { initialValues, values } = formRef.current
    const isDirty = isEqual(initialValues, values)
    if (!isDirty) {
      setWarningModalVisible(true)
    } else {
      closeModal()
    }
  }, [closeModal])

  // on refresh page
  const handleBlockedReload = useCallback(
    (e: any) => {
      if (!formRef.current) return false
      // @ts-ignore
      const { initialValues, values } = formRef.current
      const isDirty = isEqual(initialValues, values)
      if (!isDirty) {
        e.preventDefault()
        e.returnValue = t('modals.discard')
      }
    },
    [t]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBlockedReload)
    return () => {
      window.removeEventListener('beforeunload', handleBlockedReload)
    }
  }, [handleBlockedReload])
  // end

  const routerPrompt = (
    <Dialog
      open={warningModalVisible}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('modals.discard')}</DialogTitle>
      <DialogActions>
        <Button onClick={closeModal} color="secondary">
          {t('modals.discard-btn')}
        </Button>
        <Button onClick={closeWarningModal} color="primary" autoFocus>
          {t('modals.keep-btn')}
        </Button>
      </DialogActions>
    </Dialog>
  )

  return {
    prompt: routerPrompt,
    methods: {
      closeModal,
      showWarningModal,
      setRef,
    },
  }
}
