import React, { FC } from 'react'
import useStyles from './chips.styles'
import { Chip } from '@material-ui/core'
import { Attribute } from 'core/types/attribute'
import { useTranslation } from 'react-i18next'

type TProps = {
  attrData: Attribute
}

const Chips: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { attrData } = props

  return (
    <div className={classes.chips}>
      <Chip
        color="primary"
        variant="outlined"
        size="small"
        label={t(`attr-types.${attrData.attributeType.type}`)}
      />
      {attrData.validationRules.required && <Chip size="small" label={t('chips.required')} />}
      {attrData.validationRules.unique && <Chip size="small" label={t('chips.unique')} />}
      {attrData.validationRules.global_unique && (
        <Chip size="small" label={t('chips.global_unique')} />
      )}
      {attrData.options.hidden && (
        <Chip size="small" color={'primary'} variant={'outlined'} label={t('chips.hidden')} />
      )}
      {attrData.validationRules.length && attrData.validationRules.length.min ? (
        <Chip size="small" label={`${t('chips.min')}: ${attrData.validationRules.length.min}`} />
      ) : null}
      {attrData.validationRules.length && attrData.validationRules.length.max ? (
        <Chip size="small" label={`${t('chips.max')}: ${attrData.validationRules.length.max}`} />
      ) : null}
      {attrData.validationRules.count && attrData.validationRules.count.min ? (
        <Chip size="small" label={`${t('chips.min')}: ${attrData.validationRules.count.min}`} />
      ) : null}
      {attrData.validationRules.count && attrData.validationRules.count.max ? (
        <Chip size="small" label={`${t('chips.max')}: ${attrData.validationRules.count.max}`} />
      ) : null}
      {attrData.validationRules.range && attrData.validationRules.range.min ? (
        <Chip size="small" label={`${t('chips.min')}: ${attrData.validationRules.range.min}`} />
      ) : null}
      {attrData.validationRules.range && attrData.validationRules.range.max ? (
        <Chip size="small" label={`${t('chips.max')}: ${attrData.validationRules.range.max}`} />
      ) : null}
    </div>
  )
}

export default Chips
