// Core
import React, { FC, memo } from 'react'
// Component
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { EnumOption } from './components'
import { Container, Typography } from '@material-ui/core'
// Hooks
import { useDragEnd, useEnumActions } from 'common/hooks'
// Types
import { EnumValue } from 'core/types/attribute'
// Styles
import attributeSelectStyles from './attribute-select.styles'
import { useTranslation } from 'react-i18next'

const AttributeSelect: FC = () => {
  const classes = attributeSelectStyles()
  const { t } = useTranslation()
  const { value, setFieldValueHandler, setDefaultValue, deleteValue, defaultChecked } =
    useEnumActions()

  const dragEnd = useDragEnd('attributeEnums')

  return (
    <>
      {value && value.length ? (
        <Container maxWidth={false} className={classes.enumsArea}>
          <DragDropContext onDragEnd={dragEnd}>
            <Droppable droppableId="selectors">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {value
                    ? value.map((itemData: EnumValue, index: number) => (
                        <Draggable index={index} key={itemData.dndId} draggableId={itemData.dndId}>
                          {(providedItem) => (
                            <div
                              ref={providedItem.innerRef}
                              {...providedItem.draggableProps}
                              {...providedItem.dragHandleProps}
                            >
                              <EnumOption
                                key={itemData.dndId}
                                index={index}
                                isValueExist={itemData.value.length}
                                isChecked={defaultChecked(itemData)}
                                setDefault={setDefaultValue(itemData)}
                                onDelete={deleteValue}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    : t('errors.no-enums')}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Container>
      ) : (
        <Typography variant="caption">{t('attrs.create-enum-hint')}</Typography>
      )}

      <Container maxWidth={false} className={classes.createArea} onClick={setFieldValueHandler}>
        <>{t('attrs.create-enum')}</>
      </Container>
    </>
  )
}

export default memo(AttributeSelect)
