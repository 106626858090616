import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { createAttribute } from '../../store'

type UseSubmitForm = (
  successAction?: any
) => (type: 'default' | 'repeater', formProps?: any) => (values: any, formikBag: any) => void

export const useSubmitForm: UseSubmitForm = (successAction) => {
  const dispatch = useDispatch()

  return useCallback(
    (type, formProps) => (values: any, formikBag: any) => {
      dispatch(createAttribute(values, type, formProps, formikBag, successAction))
    },
    [dispatch, successAction]
  )
}
