import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { clearLayoutStore } from '../store'

type UseClearStore = () => void

export const useClearStore: UseClearStore = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearLayoutStore())
    }
  }, [dispatch])
}
