import { MutationOptions, useMutation } from 'react-query'
import { httpService } from 'core/data/services'
import { useNotify } from 'core/hooks'

type Params = MutationOptions

export const useDeleteOne = (resource: string, params: Params = {}) => {
  const notify = useNotify()

  return useMutation(
    (value: number) => {
      return httpService.delete(`/${resource}/${value}`)
    },
    {
      ...params,
      onSuccess: (...p) => {
        params.onSuccess?.(...p)
        notify(`Successful removal of the resource '${resource}' item`, { type: 'success' })
      },
      onError: () => {
        notify('Something went wrong', { type: 'error' })
      },
    }
  )
}
