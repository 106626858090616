import React, { FC, useCallback } from 'react'
import { Pagination as PaginationLab } from '@material-ui/lab'
import { useListContext } from 'core/data'

type TProps = {
  data?: object
}

const Pagination: FC<TProps> = (props) => {
  const {
    listParams: {
      meta: { total },
      params,
      methods,
    },
  } = useListContext()
  const {
    pagination: { page, perPage },
  } = params

  const changePageHandler = useCallback(
    (e: unknown, _page: number) => {
      methods.setPage(_page)
    },
    [methods]
  )

  if (!perPage) return null

  const pageCount = Math.ceil(total / perPage)

  if (pageCount <= 1) return null

  return (
    <div style={{ padding: '24px 8px' }}>
      <PaginationLab
        count={pageCount}
        page={page}
        onChange={changePageHandler}
        color="primary"
        size="medium"
        showFirstButton
        showLastButton
      />
    </div>
  )
}

export default Pagination
