// Core
import { useContext } from 'react'
// Context
import { SitemapSettingsContext } from '../context'
// Types
import { SitemapSettingsContextProps } from '../types'

export const useSitemapSettingsContext = () => {
  const context = useContext<SitemapSettingsContextProps>(SitemapSettingsContext)

  return context
}
