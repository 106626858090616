// Core
import React, { FC } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
// Components
import RelationsSelect from 'ui/relations-select'
import SearchField from 'ui/search-field/search-field'
import Filters from '../filters'
import Results from '../results'
import Replace from '../replace/replace'
// Hooks
import useSearchController from '../use-search-controller'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const SearchModal: FC<Props> = (props) => {
  const { isOpen, onClose } = props
  const controllerProps = useSearchController()
  const { filters } = controllerProps

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={4}>
              <RelationsSelect
                label="Entity type"
                source="entity_types"
                value={controllerProps.entityType}
                valueField="@id"
                onChange={(val) => {
                  controllerProps.setEntityType(val as any)
                  filters.clearAllFilters()
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <SearchField
                value={controllerProps.value}
                onChange={(value) => controllerProps.setValue(value)}
              />
            </Grid>
          </Grid>
          <Filters
            data={controllerProps.filtersData || []}
            isLoading={controllerProps.filtersLoading}
            addFilter={filters.addFilter}
            removeFilter={filters.removeFilter}
            selectedFilters={filters.selectedFilters}
            changeFilterCondition={filters.changeFilterCondition}
            changeFilterValue={filters.changeFilterValue}
            clearAllFilters={filters.clearAllFilters}
          />
        </DialogTitle>
        <DialogContent>
          {controllerProps.isLoading && (
            <Box textAlign="center" position="absolute" right={30} top={90}>
              <CircularProgress />
            </Box>
          )}
          <Results
            data={controllerProps.data || []}
            searchTerm={controllerProps.searchTerm}
            filters={filters.filterParams}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          {controllerProps.totalCount > 0 && `${controllerProps.totalCount} results`}
          {controllerProps.replaceEnabled && (
            <Button
              color="primary"
              onClick={() => controllerProps.setReplaceOpen(true)}
              style={{ marginLeft: 'auto' }}
            >
              Replace
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={controllerProps.replaceEnabled && controllerProps.replaceOpen}
        onClose={() => controllerProps.setReplaceOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Replace
            initialReplaceFrom={controllerProps.searchTerm}
            filterParams={{
              search: controllerProps.searchTerm,
              filter: controllerProps.filters.filterParams,
              entityType: controllerProps.entityType,
            }}
            onSuccess={() => {
              controllerProps.setReplaceOpen(false)
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SearchModal
