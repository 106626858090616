import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useEntityStatusStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      marginBottom: theme.spacing(2),
    },
    wrapper: {
      display: 'flex',
    },
    addPanel: {
      width: '40%',
    },
    panelSelect: {
      marginBottom: theme.spacing(2),
    },
    list: {
      width: '60%',
      paddingLeft: theme.spacing(4),
      padding: 0,
      maxHeight: 220,
      overflow: 'auto',
    },
  })
)

export default useEntityStatusStyles
