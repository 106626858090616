import React, { FC } from 'react'
import { DataControllerProps } from 'core/data'
import { Grid } from '@material-ui/core'
import { TextField } from 'core/form'
import { useTranslation } from 'react-i18next'
import UserRoles from '../user-roles'

const CrudForm: FC<DataControllerProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField name="firstName" type="text" label={t('labels.first-name')} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="lastName" type="text" label={t('labels.last-name')} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="alias" type="text" label={t('labels.alias')} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="email" type="email" label={t('labels.email')} />
      </Grid>
      <Grid item xs={12}>
        <TextField name="password" type="password" label={t('labels.password')} />
      </Grid>
      <Grid item xs={12}>
        <UserRoles />
      </Grid>
    </Grid>
  )
}

export default CrudForm
