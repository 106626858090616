import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const editorFieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    label: {
      position: 'relative',
      transform: 'none',
      marginBottom: theme.spacing(1),
    },
  })
)

export default editorFieldStyles
