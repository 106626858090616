import { Editor } from 'tinymce'

type LinkValues = {
  brand: number | null
  entity: number | null
  ga_event: number | null
  affiliate: number | null
  new_tab: boolean
  no_follow: boolean
  type: string
  url: string | null
  link_text: string
  review: string
  anchor: string
}

export const customLink = (editor: Editor, modalOpen: any, modalClose: any) => {
  let linkData: any = ''
  //Util for key codes
  //@ts-ignore
  const global$1 = editor.editorManager.EditorManager.util.Tools.resolve('tinymce.util.VK')

  const linkImageFigure = (editor: any, fig: any, attrs: any) => {
    const img = editor.dom.select('img').filter((item: any) => item === fig)[0]

    if (img) {
      const a = editor.dom.create('a', attrs)
      img.parentNode.insertBefore(a, img)
      a.appendChild(img)
    }
  }

  const hasImage = (childList: HTMLCollection) => {
    return [].slice.call(childList).find((elm: any) => {
      return elm.nodeName === 'IMG'
    })
  }

  const updateLink = (editor: Editor, anchorElm: any, text: string[], linkAttrs: any) => {
    if (!hasImage(anchorElm.children)) {
      // eslint-disable-next-line no-prototype-builtins
      if (anchorElm.hasOwnProperty('innerText')) {
        anchorElm.innerText = text.join(' ')
      } else {
        anchorElm.textContent = text.join(' ')
      }
    }

    editor.dom.setAttribs(anchorElm, linkAttrs)
    editor.selection.select(anchorElm)
    linkData = getLinkData(editor, anchorElm)
  }

  const isImageFigure = (elm: any) => {
    return elm && elm.nodeName === 'FIGURE' && /\bimage\b/i.test(elm.className)
  }

  const isImage = (elm: any) => {
    // return elm && elm.nodeName === 'FIGURE' && /\bimage\b/i.test(elm.className)
    return elm && elm.nodeName === 'IMG'
  }

  const getAnchorElement = (editor: Editor, selectedElm?: any) => {
    selectedElm = selectedElm || editor.selection.getNode()
    if (isImageFigure(selectedElm)) {
      return editor.dom.select('a[href]', selectedElm)[0]
    }
    return editor.dom.getParent(selectedElm, 'a[href]')
  }

  const createLink = (editor: Editor, selectedElm: any, text: any, linkAttrs: any) => {
    if (isImage(selectedElm)) {
      linkImageFigure(editor, selectedElm, linkAttrs)
    } else {
      editor.insertContent(editor.dom.createHTML('a', linkAttrs, editor.dom.encode(text.join(' '))))
    }
  }

  const getSelected = (editor: Editor) => {
    return editor.selection.getContent({ format: 'text' })
  }

  const getLink = (editor: any, elm: any) => {
    return editor.dom.getParent(elm, 'a[href]')
  }

  const linkDomMutation = (editor: Editor, data: any, linkText: string[]) => {
    const selectedElm = editor.selection.getNode()
    const anchorElm = getAnchorElement(editor, selectedElm)
    const linkAttrs: any = { ...data }

    editor.undoManager.transact(() => {
      if (anchorElm) {
        editor.focus()
        updateLink(editor, anchorElm, linkText, linkAttrs)
      } else {
        createLink(editor, selectedElm, linkText, linkAttrs)
      }
    })
  }

  const link = (editor: Editor, data: any, linkText: string[]) => {
    linkDomMutation(editor, data, linkText)
  }

  const getLinkData = (editor: Editor, link: HTMLElement): any | null => {
    return JSON.stringify({
      link_text: link?.innerText.length
        ? link.innerText
        : getSelected(editor)
        ? getSelected(editor)
        : '',
      url: link?.getAttribute('href') || null,
      brand: Number(link?.getAttribute('data-brand')) || '',
      entity: Number(link?.getAttribute('data-entity')) || null,
      anchor: link?.getAttribute('data-anchor') || '',
      affiliate: Number(link?.getAttribute('data-affiliate')) || null,
      ga_event: Number(link?.getAttribute('data-ga_event')) || '',
      new_tab: link?.getAttribute('data-new_tab') === 'true',
      no_follow: link?.getAttribute('data-no_follow') === 'true',
      type: link?.getAttribute('data-type') || 'internal_link',
    })
  }

  const toggleState = (editor: Editor, toggler: any) => {
    editor.on('NodeChange', toggler)
    return () => {
      return editor.off('NodeChange', toggler)
    }
  }

  const toggleActiveState = (editor: Editor) => {
    return (api: any) => {
      return toggleState(editor, () => {
        api.setActive(
          !editor.mode.isReadOnly() && getAnchorElement(editor, editor.selection.getNode()) !== null
        )
      })
    }
  }

  const handleSubmit = (editor: Editor, data: LinkValues) => {
    const changedData: any = {
      href: data.url ? data.url : '#',
    }

    const linkText: string[] = data.link_text.split(' ')

    changedData['data-new_tab'] = data.new_tab ? true : null
    changedData['data-no_follow'] = data.no_follow ? true : null

    if (data.affiliate) {
      changedData['data-affiliate'] = data.affiliate
    }
    if (data.type) {
      changedData['data-type'] = data.type
    }
    if (data.ga_event) {
      changedData['data-ga_event'] = data.ga_event
    }
    if (data.brand) {
      changedData['data-brand'] = data.brand
    }
    if (data.entity) {
      changedData['data-entity'] = data.entity
    }
    if (data.anchor) {
      changedData['data-anchor'] = data.anchor
    }

    link(editor, changedData, linkText)
    modalClose()
  }

  const openDialog = (editor: Editor) => {
    modalOpen((values: any) => {
      handleSubmit(editor, values)
    }, linkData)
  }

  editor.ui.registry.addToggleButton('custom-link', {
    icon: 'link',
    text: 'custom link',
    onAction: () => {
      openDialog(editor)
    },
    onSetup: toggleActiveState(editor),
  })

  editor.on('click', (e) => {
    const link = getLink(editor, e.target)
    linkData = getLinkData(editor, link)
    if (link && global$1.metaKeyPressed(e)) {
      e.preventDefault()
    }
  })
}
