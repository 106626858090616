// Core
import React, { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
// Components
import { useTranslation } from 'react-i18next'
// Hooks
import { useRedirectController, useRedirectsContext } from 'modules/redirects'
// Styles
import useStyles from './create-redirect.styles'

type Props = {
  children?: never
}

const CreateRedirect: FC<Props> = () => {
  const classes = useStyles()
  const { modalOpen, setModalOpen } = useRedirectsContext()
  const { form } = useRedirectController()
  const { t } = useTranslation()

  const closeHandler = () => setModalOpen(false)

  return (
    <Dialog open={modalOpen} onClose={closeHandler}>
      <DialogTitle>Create redirect</DialogTitle>
      <DialogContent className={classes.root}>
        <TextField
          className={classes.control}
          label={t('redirects.type')}
          select
          SelectProps={{ native: true }}
          name="type"
          value={form.values.type}
          onChange={form.handleChange}
          error={Boolean(form.errors.type)}
          helperText={form.errors.type}
        >
          <option value="301">301</option>
          <option value="302">302</option>
        </TextField>
        <TextField
          className={classes.control}
          label={t('redirects.from')}
          name="fromUrl"
          value={form.values.fromUrl}
          onChange={form.handleChange}
          error={Boolean(form.errors.fromUrl)}
          helperText={form.errors.fromUrl}
        />
        <TextField
          className={classes.control}
          label={t('redirects.to')}
          name="toUrl"
          value={form.values.toUrl}
          onChange={form.handleChange}
          error={Boolean(form.errors.toUrl)}
          helperText={form.errors.toUrl}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          disabled={form.isSubmitting}
          type="button"
          onClick={closeHandler}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          disabled={form.isSubmitting}
          type="button"
          onClick={form.submitForm}
          variant="contained"
          color="primary"
        >
          {t('global.create')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateRedirect
