export const fieldWidthData = (t: any) => {
  const result = []

  /** With 12 cols */
  for (let i = 1; i <= 12; i++) {
    result.push({
      value: i,
      text: `${i} ${t('global.col')}`,
    })
  }

  return result
}

export const booleanValues = [
  {
    value: 'true',
    text: 'true',
  },
  {
    value: 'false',
    text: 'false',
  },
]
