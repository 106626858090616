import React, { FC } from 'react'
import { Box, Button, FormControl, FormHelperText, FormLabel } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
// Components
import { GridItem } from 'ui/attachment/components'
// Styles
import useStyles from './attachment.styles'

type AttachmentProps = {
  openPopup: () => void
  setValue: (value: string) => void
  value: string
  touched: boolean
  type: string
  error?: string
  label?: string
  disabled?: boolean
  slug?: string
  required?: boolean
  onEdit: () => void
}

const Attachment: FC<AttachmentProps> = (props) => {
  const { t } = useTranslation()
  const {
    openPopup,
    onEdit,
    value,
    label,
    error,
    setValue,
    touched,
    type,
    disabled,
    slug,
    required,
  } = props
  const classes = useStyles()
  const showError = Boolean(touched && error)

  return (
    <Box>
      <FormControl component="fieldset" error={Boolean(error && touched)} required={required}>
        <FormLabel component="legend">{label}</FormLabel>
        {!value && (
          <Button
            className={clsx(classes.button, { [classes.error]: showError })}
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
            onClick={openPopup}
            disabled={disabled}
            data-qa-media-button={slug}
          >
            {t('global.select')} {t(`global.${type}`)}
          </Button>
        )}
        {showError && (
          <FormHelperText>
            {<span className={classes.helperText}>{touched && error}</span>}
          </FormHelperText>
        )}
      </FormControl>
      {!!value && (
        <GridItem onEdit={onEdit} disabled={disabled} itemData={value} setValue={setValue} />
      )}
    </Box>
  )
}

export default Attachment
