// Core
import React, { FC } from 'react'
// Styles
import stylesCustomTabPanel from './tab-panel.styles'

type TProps = {
  children: React.ReactNode
  index: string | number
  value: string | number
  unmountOnChange?: boolean
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const TabPanel: FC<TProps> = (props) => {
  const { children, value, index, unmountOnChange = false, ...other } = props
  const classes = stylesCustomTabPanel()

  switch (unmountOnChange) {
    case true: {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={index.toString()}
          className={classes.root}
          {...other}
        >
          {value === index && children}
        </div>
      )
    }
    default: {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={index.toString()}
          className={classes.root}
          {...other}
        >
          {children}
        </div>
      )
    }
  }
}

export default TabPanel
