export const VERSION_CONFLICT_KEY = 'version_conflict'
export const FORM_BACKUP_KEY = 'form-data-backup'
export const CONFLICT_EVENT_KEY = 'version-conflict-event'

const conflictEvent = new Event(CONFLICT_EVENT_KEY)

export const dispatchConflictEvent = () => document.dispatchEvent(conflictEvent)

export const subscribeConflictEvent = (handler: () => void) => {
  document.addEventListener(CONFLICT_EVENT_KEY, handler)

  return {
    unsubscribe: () => {
      document.removeEventListener(CONFLICT_EVENT_KEY, handler)
    },
  }
}

export const enableConflict = () => localStorage.setItem(VERSION_CONFLICT_KEY, 'true')

export const disableConflict = () => localStorage.removeItem(VERSION_CONFLICT_KEY)

export const reloadPage = () => window.location.reload()

export const storeFormBackupData = (data: string) => sessionStorage.setItem(FORM_BACKUP_KEY, data)

export const getFormBackupData = () => sessionStorage.getItem(FORM_BACKUP_KEY)

export const clearFormBackupData = () => sessionStorage.removeItem(FORM_BACKUP_KEY)

export const hasFormBackupData = () => Boolean(sessionStorage.getItem(FORM_BACKUP_KEY))
