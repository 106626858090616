import React, { FC } from 'react'
import { Switch, Route as ReactRouter } from 'react-router-dom'
import { Route } from 'core/router'
import { NavigationList, NavigationMutationRoute } from 'modules/navigation/components'
import { NotFound } from 'common/components'
import { MutationType } from 'modules/new-entity/types'

type TProps = {
  children?: never
  path: string
}

const NavigationResource: FC<TProps> = (props) => {
  const { path } = props

  return (
    <ReactRouter {...props} path={`${path}`}>
      <Switch>
        <Route
          path={`${path}`}
          exact
          componentTo={<NavigationList name={'navigations'} hasCreate />}
        />
        <Route
          path={`${path}/create`}
          exact
          componentTo={<NavigationMutationRoute type={MutationType.CREATE} />}
        />
        <Route
          path={`${path}/:id`}
          exact
          componentTo={<NavigationMutationRoute type={MutationType.EDIT} />}
        />
        <Route path="/*" componentTo={<NotFound />} />
      </Switch>
    </ReactRouter>
  )
}

export default NavigationResource
