// Core
import React, { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { Box, CircularProgress } from '@material-ui/core'
// Components
import RelationsSelect from 'ui/relations-select'
import EntityRelationsSelect from 'ui/relations-select/entity-relations-select'
// Utils/Services
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
// Types
import { ValueProps } from './types'
import { Entity } from 'common/types'

type Props = {} & ValueProps

const ContainReference: FC<Props> = (props) => {
  const { slug, onChange, value } = props

  const [entityType, setEntityType] = useState('')

  const { isLoading } = useQuery(
    ['entity', value],
    () => {
      const entityId = getIdFromIri(value as string)
      return httpService.get<Entity>(`/entities/${entityId}`).then((res) => res.data)
    },
    {
      enabled: Boolean(value) && !entityType,
      onSuccess: (data) => {
        setEntityType(data.entityType)
      },
    }
  )

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Box>
      <Box>
        <RelationsSelect
          source="entity_types"
          valueField="@id"
          label="Select entity type"
          value={entityType}
          onChange={(val) => {
            setEntityType(val as string)
            onChange(slug, '')
          }}
          size="small"
        />
      </Box>
      {entityType && (
        <Box mt={2}>
          <EntityRelationsSelect
            valueField="id"
            value={value as string}
            label="Select entity"
            reqParams={{ entityType }}
            onChange={(val) => {
              onChange(slug, val as string)
            }}
            size="small"
          />
        </Box>
      )}
    </Box>
  )
}

export default ContainReference
