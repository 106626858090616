/** Core */
import React, { FC, useState } from 'react'
import { NavigationContext } from 'modules/navigation/context'
/** Components */
import { Box, CircularProgress, Grid } from '@material-ui/core'
import {
  NavigationBaseForm,
  NavigationUrlableList,
  NavigationItemsTree,
} from 'modules/navigation/components'
import { NavigationItemModal } from 'modules/navigation/components'
import PageHeader from 'modules/layout/components/page-header'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
/** Hooks */
import {
  useGetLinkSaver,
  useMutationRoteHooks,
  useNavigationItemHook,
} from 'modules/navigation/hooks'
/** Utils */
import clsx from 'clsx'
// Types
import { MutationType } from '../../../new-entity/types'
/** Styles */
import useStyles from './navigation-mutation-route.styles'

type TProps = {
  type: MutationType
}

const NavigationMutationRoute: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { type } = props
  const [loading, setLoading] = useState(false)

  const {
    isNavTypeResolver,
    navigationType,
    isEdit,
    navigationId,
    navigationLinkIds,
    navigationData,
  } = useMutationRoteHooks()

  const { activeType, navItemModalOpened, activeNavigationItem, actions, itemType } =
    useNavigationItemHook()

  // /**
  //  * Generate helper fns
  //  */

  const {
    saveNavigationItem,
    changeParentNavigationItem,
    loading: linkSaverLoading,
  } = useGetLinkSaver({
    id: navigationId,
    navigationType,
    navigationLinkIds,
    //
    onSuccess: () => console.log('Success'),
    onError: (e: any) => {
      if (e.response.status) {
        toast.error(`${e.response.status}-${e.message}`)
      } else {
        console.error(e)
      }
    },
    isLoading: (is) => setLoading(is),
  })

  return (
    <>
      <PageHeader
        title={
          type === MutationType.EDIT
            ? `${t('global.edit')} - ${navigationData?.title}`
            : `${t('global.create')}`
        }
        backPath="/navigations"
      />
      {isNavTypeResolver && (
        <NavigationContext.Provider
          // @ts-ignore
          value={{
            //
            isEdit,
            navigationId,
            navigationType,
            saveNavigationItem,
            changeParentNavigationItem,
            navigationLinkIds: navigationLinkIds!,
            navItemModalOpened,
            activeNavigationItem,
            activeType,
            actions,
            itemType,
          }}
        >
          <Grid container spacing={3} className={classes.root}>
            <Grid item xs={4} className={classes.urlableList}>
              <Box className={classes.content}>
                <NavigationUrlableList navigationData={navigationData} />
              </Box>
            </Grid>
            <Grid item xs={8} style={{ display: 'flex' }}>
              <Grid direction={'column'} container wrap={'nowrap'} spacing={3}>
                <Grid item xs={12} className={classes.form}>
                  <Box className={classes.content}>
                    <NavigationBaseForm type={type} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {isEdit && (
                    <Box className={clsx(classes.content, classes.heightFull)}>
                      {!loading ? (
                        <NavigationItemsTree loading={linkSaverLoading} />
                      ) : (
                        <div className={classes.spinner}>
                          <CircularProgress />
                        </div>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
              {activeNavigationItem && <NavigationItemModal />}
            </Grid>
          </Grid>
        </NavigationContext.Provider>
      )}
    </>
  )
}

export default NavigationMutationRoute
