// Core
import React, { FC, useCallback, useMemo, Dispatch, SetStateAction } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
// Components
import { DialogTitle } from 'ui'
// Hooks
import { useTranslation } from 'react-i18next'
import { useModalWarnings } from 'core/hooks'
import { MainForm } from './components'
import { isEmpty } from 'core/utils'

type TProps = {
  openModal: boolean
  toggleModalOpen: Dispatch<SetStateAction<boolean>>
  submitLink: (values: any) => void
  value: string
}

const LinksModal: FC<TProps> = ({ openModal, toggleModalOpen, submitLink, value }) => {
  const { t } = useTranslation()

  const data = useMemo(() => {
    let linksValue = null
    try {
      linksValue = JSON.parse(value)
    } catch (e) {
      console.log('parse error links', e)
    }

    return linksValue
  }, [value])

  const closeHandler = useCallback(() => {
    toggleModalOpen(false)
  }, [toggleModalOpen])

  const {
    prompt,
    methods: { showWarningModal, setRef },
  } = useModalWarnings(closeHandler)

  const initialProps = {
    type: 'internal_link',
    link_text: '',
    ga_event: '',
    brand: '',
    no_follow: false,
    new_tab: false,
    url: null,
    entity: null,
    affiliate: null,
    anchor: '',
  }

  return (
    <Dialog id={'links-modal'} maxWidth="lg" fullWidth open={openModal} onClose={closeHandler}>
      <DialogTitle onClose={showWarningModal}>
        {t(isEmpty(data) ? 'links.insert' : 'links.edit')}
      </DialogTitle>
      <DialogContent>
        <MainForm
          onSubmitForm={submitLink}
          initialData={data || initialProps}
          setRef={setRef}
          id="create-link-form"
        />
      </DialogContent>
      {prompt}
    </Dialog>
  )
}

export default LinksModal
