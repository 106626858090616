// Core
import React, { FC } from 'react'

type Props = {
  children?: never
}

const CoreWebVitalsPage: FC<Props> = () => {
  return (
    <iframe
      title="core-web-vitals"
      width="100%"
      style={{ height: 'calc(100% - 51px)' }}
      height="100%"
      src="https://datastudio.google.com/embed/reporting/16243871-f5b1-485b-b586-3e6ffe2919fc/page/BGYaC"
      frameBorder="0"
      allowFullScreen
    />
  )
}

export default CoreWebVitalsPage
