import { useCallback, useEffect } from 'react'
import { useField, useFormikContext } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { EnumValue } from 'core/types/attribute'

type UseEnumActions = () => {
  value: []
  setFieldValueHandler: () => void
  setDefaultValue: (itemData: EnumValue) => () => void
  deleteValue: (index: number) => void
  defaultChecked: (value: EnumValue) => boolean
}

type FormValues = {
  values: any
  type: string
  defaultValues: {
    value: string | string[]
  }
}

export const useEnumActions: UseEnumActions = () => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const [{ value }, , { setValue }] = useField('attributeEnums')

  useEffect(() => {
    if (value) {
      value.forEach((item: any, index: any) => {
        item.sortOrder = index
      })
    }
  }, [value])

  const setFieldValueHandler = useCallback(() => {
    const item: EnumValue = {
      dndId: uuidv4(),
      value: '',
      sortOrder: value ? value.length + 1 : 0,
      localizations: [
        {
          locale: '/api/localizations/en_US',
          name: '',
        },
      ],
    }
    if (!value) {
      setValue([item])
      return
    }

    setValue([...value, item])
  }, [setValue, value])

  const setDefaultValue = useCallback(
    (itemData: EnumValue) => () => {
      if (itemData) {
        if (values.type === 'select') {
          setFieldValue('defaultValues.value', itemData.value)
        } else if (values.type === 'multi_select' && Array.isArray(values.defaultValues.value)) {
          let copiedVal = [...values.defaultValues.value]

          const findIndex = copiedVal.indexOf(itemData.value)

          if (findIndex !== -1) {
            copiedVal.splice(findIndex, 1)
          } else {
            copiedVal = [...copiedVal, itemData.value]
          }
          setFieldValue('defaultValues.value', copiedVal)
        }
      }
    },
    [setFieldValue, values.defaultValues.value, values.type]
  )

  const deleteValue = useCallback(
    (index) => {
      const copiedVal = [...value]
      copiedVal.splice(index, 1)
      setValue([...copiedVal])
      value.forEach((item: any, index: any) => {
        item.sortOrder = index
      })

      if (values.type === 'multi_select' && Array.isArray(values.defaultValues.value)) {
        if (values.defaultValues) {
          const copiedVal = [...values.defaultValues.value]
          const deletedValue = value[index].value
          const findIndex = copiedVal.indexOf(deletedValue)
          if (findIndex !== -1) {
            copiedVal.splice(findIndex, 1)
          }
          setFieldValue('defaultValues.value', copiedVal)
        }
      } else if (values.type === 'select') {
        if (values.defaultValues.value) {
          const defaultVal = values.defaultValues.value
          const deletedValue = value[index].value
          if (defaultVal === deletedValue) {
            setFieldValue('defaultValues.value', '')
          }
        }
      }
    },
    [value, values, setValue, setFieldValue]
  )

  const defaultChecked = useCallback(
    (itemData) => {
      if (values.type === 'select' && values.defaultValues.value) {
        return values.defaultValues.value === itemData.value
      }
      if (values.type === 'multi_select') {
        return values.defaultValues.value.includes(itemData.value)
      }
      return false
    },
    [values.type, values.defaultValues.value]
  )

  return {
    value,
    setFieldValueHandler,
    setDefaultValue,
    deleteValue,
    defaultChecked,
  }
}
