// Core
import React, { ChangeEvent, FC, memo, useCallback } from 'react'
import {
  Checkbox,
  Divider,
  ListItem as ListItemRm,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
// Types
import { Site } from 'modules/sites'
import { TUserRole } from 'core/types'
import { SetSiteRoleFunc, SiteRoleMap } from './types'
// Styles
import { useStyles } from './styles'

type Props = {
  children?: never
  data: Site
  isSelected: boolean
  siteRoles: TUserRole[]
  selectedSiteRole: string
  setSiteRole: SetSiteRoleFunc
  siteRoleMap: SiteRoleMap
  toggleSelected: (siteIri: string) => void
}

type RoleSelectProps = {
  children?: never
  data?: TUserRole[]
  value?: string
  onChange?: (role: string) => void
}

export const RolesSelect: FC<RoleSelectProps> = ({ data, value = '', onChange }) => {
  const classes = useStyles()

  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (onChange) onChange(e.target.value)
    },
    [onChange]
  )

  if (!data) return null

  return (
    <TextField
      className={classes.rolesSelect}
      fullWidth
      label="Role"
      select
      variant="filled"
      size="small"
      value={value || 'none'}
      onChange={changeHandler as any}
    >
      <MenuItem value="none">None</MenuItem>
      {data?.map((role) => (
        <MenuItem key={role.id} value={role['@id']}>
          {role.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

const ListItem: FC<Props> = (props) => {
  const {
    data,
    isSelected,
    siteRoles,
    selectedSiteRole,
    setSiteRole,
    siteRoleMap,
    toggleSelected,
  } = props

  const roleChangeHandler = (role: string) => {
    setSiteRole(data['@id'], role, siteRoleMap)
  }

  return (
    <>
      <ListItemRm>
        <ListItemIcon>
          <Checkbox
            checked={isSelected}
            edge="start"
            disableRipple
            onChange={() => toggleSelected(data['@id'])}
          />
        </ListItemIcon>
        <ListItemText primary={data.name} secondary={data.url} />
        <ListItemSecondaryAction>
          <RolesSelect data={siteRoles} value={selectedSiteRole} onChange={roleChangeHandler} />
        </ListItemSecondaryAction>
      </ListItemRm>
      <Divider />
    </>
  )
}

export default memo(ListItem)
