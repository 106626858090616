/* eslint-disable prettier/prettier */
// Core
import { useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
// Utils
import { getIdFromIri } from 'core/utils'
// Services
import { httpService } from 'core/data'
// Types
import { SmartSelectProps } from '../types'

type UseGetInitialValues = (props: SmartSelectProps, setter: (data: any) => void) => void

export const useGetInitialValues: UseGetInitialValues = (props, setter) => {
  const { multiple, source, value, reqParams, multipleParam = 'id' } = props
  const inited = useRef(false)

  useEffect(() => {
    inited.current = true
  }, [])

  useQuery(
    ['entities', value],
    () => {
      let url = ''
      const params: any = {}

      if (multiple) {
        url = `/${source}`
        params[multipleParam] = (value as string[]).map((item) => getIdFromIri(item))

        if (reqParams?.entityType && source === 'entities') {
          params.entityType = reqParams.entityType
        }
      } else {
        const id = getIdFromIri(value as string)
        url = `/${source}/${id}`
      }

      if (multiple && (value as string[]).length === 0) {
        return Promise.resolve<any>({
          data: {
            'hydra:member': [],
          },
        })
      }

      return httpService.get<any>(url, { params })
    },
    {
      enabled: Boolean(value) && !inited.current,
      onError: () => {},
      onSuccess: (response) => {
        const hasHydra = response.data['hydra:member'] !== undefined
        const data = hasHydra ? response.data['hydra:member'] : response.data

        if (multiple && Array.isArray(value)) {
          const sortedData: any[] = []
          //@ts-ignore
          value.forEach((item) => {
            const findVal = data.find((i: any) => i['@id'] === item)

            sortedData.push(findVal)
          })

          setter(sortedData)
          return
        }

        setter(data)
      },
      retry: false,
    }
  )
}
