// Core
import React, { useCallback, useState } from 'react'
import { Button, ButtonBase, Icon, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
// Styles
import useStyles from './button-insert.styles'

type Props = {
  type: 'container' | 'widget'
  title: string
  onClick: () => void
  disabled?: boolean
}

const ButtonInsert: React.FC<Props> = (props) => {
  const { type, title, onClick, disabled } = props

  const [isVisible, setIsVisible] = useState(true)

  const classes = useStyles()

  const isContainer = type === 'container'
  const isWidget = type === 'widget'

  const handleClick = useCallback(() => {
    setIsVisible(false)
    onClick()

    setTimeout(() => {
      setIsVisible(true)
    }, 0)
  }, [onClick])

  if (!isVisible) return null

  return (
    <Tooltip placement="top" title={title}>
      <Button
        className={clsx(classes.btn, {
          [classes.btnContainer]: isContainer,
          [classes.btnWidget]: isWidget,
        })}
        onClick={handleClick}
        disabled={disabled}
      >
        <ButtonBase component="span" className={classes.iconWrap}>
          <Icon className="icon-plus" />
        </ButtonBase>
      </Button>
    </Tooltip>
  )
}

export default ButtonInsert
