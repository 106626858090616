import React, { FC, useCallback } from 'react'
import useStyles from './action-buttons.styles'
import { Box, Button, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useConfirmation } from 'core/confirmation'

type TProps = {
  data?: object
  onChange: () => void
  previewIsOpen: boolean
  deleteDisable: boolean
  removeAttributeHandler: () => void
  removeAttributeOptionsHandler: () => void
  removeAttributeDependenciesHandler: () => void
  optionsOpen: () => void
  disableOptions?: boolean
}

const ActionButtons: FC<TProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    onChange,
    previewIsOpen,
    deleteDisable,
    removeAttributeHandler,
    removeAttributeOptionsHandler,
    removeAttributeDependenciesHandler,
    optionsOpen,
    disableOptions,
  } = props

  const { setConfirmation } = useConfirmation()
  const removeWithConfirmation = useCallback(
    (onSuccess: () => void) => {
      setConfirmation({
        open: true,
        title: t('global.delete'),
        content: t('notify.delete-attr'),
        onSuccess,
      })
    },
    [setConfirmation, t]
  )

  return (
    <div className={classes.root}>
      <IconButton
        onClick={onChange}
        className={clsx('icon-chevron-down', {
          [classes.iconOpen]: previewIsOpen,
        })}
      />

      {!disableOptions && <IconButton onClick={optionsOpen} className="icon-settings" />}

      {!deleteDisable && (
        <Box p={2} display="flex" flexDirection="column" flexWrap="wrap" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              removeWithConfirmation(() => {
                removeAttributeHandler()
                if (!disableOptions) {
                  removeAttributeOptionsHandler()
                  removeAttributeDependenciesHandler()
                }
              })
            }
          >
            {t('global.delete')}
          </Button>
        </Box>
      )}
    </div>
  )
}

export default ActionButtons
