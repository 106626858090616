// Core
import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
// Components
import MfaModal from 'modules/auth/components/mfa-modal'
import LoginForm from 'modules/auth/components/login-form'
import ForgotPasswordModal from 'modules/auth/components/forgot-password-modal'
import ChangePasswordModal from 'modules/auth/components/change-password-modal'
// Hooks
import useLoginController from 'modules/auth/hooks/use-login-controller'

const Login: FC = () => {
  const { t } = useTranslation()

  const {
    login,
    mfaOpen,
    mfaState,
    closeMfa,
    login2FA,
    passwordExpired,
    closePasswordExpired,
    resetOpen,
    openResetModal,
    setResetOpen,
    resetInitialEmail,
    resetPassword,
    expiredUserSession,
    changePasswordExpired,
  } = useLoginController()

  return (
    <>
      <Box mb={2}>
        <Typography component="h1" variant="h5">
          {t('login.login-title')}
        </Typography>
      </Box>
      <LoginForm onSubmit={login} onResetClick={openResetModal} />
      {mfaOpen && mfaState && (
        <MfaModal isOpen={mfaOpen} onClose={closeMfa} mfaData={mfaState} onSubmit={login2FA} />
      )}
      {resetOpen && (
        <ForgotPasswordModal
          open={resetOpen}
          onClose={() => setResetOpen(false)}
          initialEmail={resetInitialEmail}
          onSubmit={resetPassword}
        />
      )}
      {passwordExpired && expiredUserSession && (
        <ChangePasswordModal
          open={passwordExpired}
          onClose={closePasswordExpired}
          onSubmit={changePasswordExpired}
          onResetClick={openResetModal}
          expired
        />
      )}
    </>
  )
}

export default Login
