import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const searchStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 0, 2),
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.common.white,
      zIndex: 5,
    },
    button: {
      paddingTop: 7,
      paddingBottom: 7,
      marginLeft: theme.spacing(1),
    },
    filterNote: {
      display: 'inline-block',
      marginLeft: theme.spacing(2),
    },
  })
)

export default searchStyles
