import fileSize from 'filesize'
import { MediaFolder, MediaFolderTreeNode } from './types'
import { getIdFromIri } from 'core/utils'

export function buildTree(data: MediaFolder[]): MediaFolderTreeNode[] {
  const idToNodeMap: { [id: string]: MediaFolderTreeNode } = {}
  const rootNodes: MediaFolderTreeNode[] = []

  data.forEach((item) => {
    idToNodeMap[item['@id']] = { ...item, children: [] }
  })

  data.forEach((item) => {
    const node = idToNodeMap[item['@id']]
    if (item.parent) {
      const parentNode = idToNodeMap[item.parent]
      if (parentNode) {
        parentNode.children.push(node)
      }
    } else {
      rootNodes.push(node)
    }
  })

  return [
    {
      '@id': '0',
      site: null,
      name: 'All files',
      id: 0,
      parent: null,
      hasChildren: true,
      children: rootNodes,
    },
  ]
}

export function generateBreadcrumbs(data: MediaFolder[], itemId: number): MediaFolder[] {
  const idToFolderMap = data.reduce<{ [id: number]: MediaFolder }>((map, item) => {
    map[item.id] = item
    return map
  }, {})

  const breadcrumbs: MediaFolder[] = []
  let currentItem: MediaFolder | null = idToFolderMap[itemId]

  while (currentItem) {
    breadcrumbs.unshift(currentItem)
    currentItem = currentItem.parent ? idToFolderMap[+getIdFromIri(currentItem.parent)] : null
  }

  return [
    {
      '@id': '0',
      site: null,
      name: 'All files',
      id: 0,
      parent: null,
      hasChildren: true,
    },
    ...breadcrumbs,
  ]
}

export const getFileFormat = (fileName: string) => {
  const split = fileName.split('.')
  return `${split[split.length - 1]}`
}

export const formatFileSize = (size: number) => fileSize(size)
