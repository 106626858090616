import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setHeader } from '../store'

type UseHandleSetHeader = () => () => void

export const useHandleSetHeader: UseHandleSetHeader = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(setHeader())
  }, [dispatch])
}
