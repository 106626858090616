import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2.5),
    minHeight: '160px',
  },

  title: {
    textTransform: 'uppercase',
    fontSize: '1.125rem',
  },

  description: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },

  sessionError: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
  },

  sessionErrorMessage: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },

  form: {
    maxWidth: '200px',
    textAlign: 'center',
  },

  error: {
    margin: '3px 0 0',

    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },

  submit: {
    marginTop: theme.spacing(2),
  },

  closeBtn: {
    position: 'absolute',
    right: '5px',
    top: '5px',
    padding: '7px',
  },
}))

export default useStyles
