import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'
import { colors } from '@material-ui/core'

const { purpleLight, disableBg, hiddenBg } = defaultThemeColors

const selectedAttributeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      marginBottom: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      overflow: 'hidden',
      transition: 'all .3s ease',
      border: `1px solid ${colors.blueGrey[100]}`,
      '&:hover': {
        boxShadow: ' 0px 0px 1px rgba(0, 0, 0, 0.31), 0px 10px 20px rgba(0, 0, 0, 0.25)',
      },
      outline: 'none',
    },

    flex: {
      display: 'flex',
      alignItems: 'center',
    },

    card: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
    },

    system: {
      background: disableBg,
    },

    hidden: {
      background: hiddenBg,
    },

    dragButtonBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },

    actonArea: {
      padding: theme.spacing(1),
    },

    iconBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      width: theme.spacing(7),
      height: theme.spacing(7),
      backgroundColor: purpleLight,
      borderRadius: theme.spacing(0.5),
    },

    icon: {
      fontSize: '3em',
    },

    cardContent: {
      flexBasis: `calc(100% - ${theme.spacing(7)}px)`,
      padding: theme.spacing(0, 1),
    },

    name: {
      fontSize: '1rem',
      fontWeight: 500,
      wordBreak: 'break-word',
    },

    slug: {
      fontSize: '1rem',
      color: colors.blueGrey[200],
    },

    type: {
      fontSize: '0.875rem',
      color: theme.palette.text.secondary,
    },

    progress: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
)

export default selectedAttributeItemStyles
