import React, { FC, useContext, useEffect, useState } from 'react'
import selectRefStyles from './select-ref.styles'
import { ReferenceInputContext } from '../reference-input/reference-input'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useField } from 'formik'
import Chip from '@material-ui/core/Chip'
import { useTranslation } from 'react-i18next'

type TProps = {
  optionText?: string
  multiple?: boolean
}

const SelectRef: FC<TProps> = (props) => {
  const { optionText = '', multiple = false } = props
  const { t } = useTranslation()
  const classes = selectRefStyles()
  const { data, dataTr, label, source, loading, error } = useContext(ReferenceInputContext)
  const [field, meta, helpers] = useField(source)
  const [defVal, setDefVal] = useState('')
  const [multipleVal, setMultipleVal] = useState([])

  useEffect(() => {
    if (!multiple) {
      setDefVal(field.value)
    } else {
      setMultipleVal(field.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value])

  const changeHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!multiple) {
      helpers.setValue(event.target.value as string)
    } else {
      helpers.setValue(event.target.value as string[])
    }
  }

  if (loading) return <div>{t('global.loading')}</div>
  if (error) return <div>{t('global.error')}</div>

  if (!data || !dataTr) return null

  return (
    <Box className={classes.root}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={`${source}-label`}> {label} </InputLabel>

        <Select
          labelId={`${source}-label`}
          name={source}
          id={`${source}-select`}
          value={defVal || multipleVal || []}
          onChange={changeHandler}
          label={label}
          multiple={multiple}
          error={!!meta.error}
          renderValue={(selected: any) => (
            <div>
              {multiple ? (
                (selected as string[]).map((value: any) => {
                  return <Chip key={value['@id'] || value} label={dataTr[value]?.name || value} />
                })
              ) : (
                <div>{dataTr[selected].name || selected}</div>
              )}
            </div>
          )}
        >
          {!multiple ? <MenuItem value="">{t('select.empty-value')}</MenuItem> : ''}

          {data
            ? data.map((item: any) => {
                return (
                  <MenuItem key={item[optionText] || item} value={item['@id'] || item}>
                    {item[optionText] || item}
                  </MenuItem>
                )
              })
            : ''}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SelectRef
