// Core
import React, { FC, useEffect } from 'react'
import { useFormikContext } from 'formik'
// Utils
import { RulesRenderer, typeRulesMap } from './utils'
// Types
import { AttributeTypesList } from 'common/types'
// Styles
import useStyles from './validation-rules.styles'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type FormValues = {
  type: AttributeTypesList
  validationRules: any
}

type Props = {
  children?: never
  previewMode?: boolean
  attributeType?: AttributeTypesList
}

const ValidationRules: FC<Props> = (props) => {
  const { previewMode, attributeType } = props
  const classes = useStyles()
  const { t } = useTranslation()
  /**
   * Get 'type' via formik context
   * to know which fields will be render
   */
  const {
    values,
    values: { type },
    setFieldValue,
  } = useFormikContext<FormValues>()

  /**
   * Prevent creating global_unique attribute without unique rule
   */
  useEffect(() => {
    if (!type) return
    if (values.validationRules?.unique !== true && values.validationRules?.global_unique === true) {
      setFieldValue('validationRules.unique', true)
    }
  }, [setFieldValue, values, type])

  if (!type) return null

  /**
   * Attribute type can be passed from props
   * In case if it does not exit, It'll get from form context
   */
  const rules = typeRulesMap[attributeType || type]

  if (typeof rules === 'undefined') {
    console.warn(`Rules for attribute type ${type} does not exist`)
    return null
  }

  return (
    <Grid item xs={12} className={classes.root}>
      <Typography style={{ marginBottom: '8px' }} component={'p'}>
        {t('labels.val-rules')}
      </Typography>
      {rules.map((rule, ind) => {
        return <RulesRenderer key={ind} rule={rule} isPreview={previewMode} />
      })}
    </Grid>
  )
}

export default ValidationRules
