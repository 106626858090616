import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const layoutStartSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#5850EC',
      color: '#fff',
      padding: theme.spacing(3, 0),
      margin: theme.spacing(5 * -1, 0, 2),
      cursor: 'pointer',
    },
  })
)

export default layoutStartSectionStyles
