import React, { FC } from 'react'
import { Form, Formik } from 'formik'
import { LanguageProvider } from 'core/data'
import { TextField } from 'core/form'
import { MediaFolder } from '../../types'

type Props = {
  formId: string
  folderData: Partial<MediaFolder>
  onSubmit: (values: Partial<MediaFolder>) => void
  disabled?: boolean
}

export const FolderForm: FC<Props> = ({ folderData, formId, onSubmit, disabled }) => {
  return (
    <LanguageProvider>
      <Formik initialValues={folderData} onSubmit={onSubmit} enableReinitialize>
        <Form id={formId}>
          <TextField
            autoFocus
            name="name"
            label="Internal name"
            required
            localized
            margin="normal"
            disabled={disabled}
          />
        </Form>
      </Formik>
    </LanguageProvider>
  )
}
