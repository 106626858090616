import { Action } from 'redux'
import { Section } from 'core/types/layouts'
import { SidebarPositionType } from 'core/types/layouts/entity-layouts/sidebar-position'

export interface IEntityLayoutState {
  options: {
    header: boolean
    footer: boolean
    sections: Section[]
  }
  activeElement: {
    sectionId: string | number
    containerId: string | number
  } | null
}

export enum ActionTypes {
  TOGGLE_HEADER = '@@ENTITY_LAYOUTS/TOGGLE_HEADER',
  TOGGLE_FOOTER = '@@ENTITY_LAYOUTS/TOGGLE_FOOTER',

  ADD_SECTION = '@@ENTITY_LAYOUTS/ADD_SECTION',
  REMOVE_SECTION = '@@ENTITY_LAYOUTS/REMOVE_SECTION',

  SELECT_ELEMENT = '@@ENTITY_LAYOUTS/SELECT_ELEMENT',
  UNSELECT_ELEMENT = '@@ENTITY_LAYOUTS/UNSELECT_ELEMENT',

  ADD_CONTAINER = '@@ENTITY_LAYOUTS/ADD_CONTAINER',
  REMOVE_CONTAINER = '@@ENTITY_LAYOUTS/REMOVE_CONTAINER',

  TOGGLE_SIDEBAR = '@@ENTITY_LAYOUTS/TOGGLE_SIDEBAR',
  SET_SIDEBAR_WIDTH = '@@ENTITY_LAYOUTS/SET_SIDEBAR_WIDTH',
  SET_SIDEBAR_POSITION = '@@ENTITY_LAYOUTS/SET_SIDEBAR_POSITION',

  SET_CONTAINER_WIDTH = '@@ENTITY_LAYOUTS/SET_CONTAINER_WIDTH',
  SET_CONTAINER_COLUMNS = '@@ENTITY_LAYOUTS/SET_CONTAINER_COLUMNS',
  SET_CONTAINER_NAME = '@@ENTITY_LAYOUTS/SET_CONTAINER_NAME',
  SET_CONTAINER_SLUG = '@@ENTITY_LAYOUTS/SET_CONTAINER_SLUG',

  SORT_SECTION = '@@ENTITY_TYPE/SORT_SECTION',

  SET_INITIAL_OPTIONS = '@@ENTITY_TYPE/SET_INITIAL_OPTIONS',
  CLEAR_LAYOUT_STORE = '@@ENTITY_TYPE/CLEAR_LAYOUT_STORE',

  SELECT_SIDEBAR = '@@ENTITY_TYPE/SELECT_SIDEBAR',
}

export interface IToggleHeaderAction extends Action<ActionTypes.TOGGLE_HEADER> {}
export interface IToggleFooterAction extends Action<ActionTypes.TOGGLE_FOOTER> {}

export interface IAddSectionAction extends Action<ActionTypes.ADD_SECTION> {
  payload: {
    insertPlace: number
  }
}
export interface IRemoveSectionAction extends Action<ActionTypes.REMOVE_SECTION> {
  payload: {
    id: string
  }
}
export interface IToggleSidebarAction extends Action<ActionTypes.TOGGLE_SIDEBAR> {
  payload: {
    sidebarIsAdded: boolean
  }
}
export interface ISetSidebarWidthAction extends Action<ActionTypes.SET_SIDEBAR_WIDTH> {
  payload: {
    sidebarWidth: number
  }
}
export interface ISetSidebarPositionAction extends Action<ActionTypes.SET_SIDEBAR_POSITION> {
  payload: {
    sidebarPosition: SidebarPositionType
  }
}
export interface ISortSectionAction extends Action<ActionTypes.SORT_SECTION> {
  payload: {
    result: any
  }
}

export interface IAddContainerAction extends Action<ActionTypes.ADD_CONTAINER> {}
export interface IRemoveContainerAction extends Action<ActionTypes.REMOVE_CONTAINER> {
  payload: {
    id: string
  }
}
export interface ISetContainerWidthAction extends Action<ActionTypes.SET_CONTAINER_WIDTH> {
  payload: {
    containerWidth: number
  }
}
export interface ISetContainerColumnsAction extends Action<ActionTypes.SET_CONTAINER_COLUMNS> {
  payload: {
    containerColumns: number
  }
}
export interface ISetContainerNameAction extends Action<ActionTypes.SET_CONTAINER_NAME> {
  payload: {
    containerName: string
  }
}
export interface ISetContainerSlugAction extends Action<ActionTypes.SET_CONTAINER_SLUG> {
  payload: {
    containerSlug: string
  }
}

export interface ISetActiveElementAction extends Action<ActionTypes.SELECT_ELEMENT> {
  payload: {
    sectionId: string
    containerId: string
  }
}
export interface IResetActiveElementAction extends Action<ActionTypes.UNSELECT_ELEMENT> {}

export interface ISetInitialOptionsAction extends Action<ActionTypes.SET_INITIAL_OPTIONS> {
  payload: {
    data: any
  }
}
export interface IClearLayoutStoreAction extends Action<ActionTypes.CLEAR_LAYOUT_STORE> {}
