import { createSelector, Selector } from 'reselect'
import { AttributesState } from './types'
import { IRootState } from 'core/init/types'

export const rootSelector: Selector<IRootState, AttributesState> = (state) => state.attributesModal

export const attributesModalIsOpen = createSelector(rootSelector, (state) => state.isOpen)
export const attributesNestedFormIsOpen = createSelector(
  rootSelector,
  (state) => state.nestedForm.isOpen
)
