import React, { FC } from 'react'
import { Grid, TablePagination } from '@material-ui/core'

type Props = {
  page: number
  perPage: number
  totalItems: number
  onPageChange: (page: number) => void
  onPerPageChange: (value: number) => void
}

export const Footer: FC<Props> = ({ page, perPage, totalItems, onPageChange, onPerPageChange }) => {
  return (
    <Grid container justifyContent="flex-end">
      <TablePagination
        component="div"
        page={page - 1}
        count={totalItems}
        onPageChange={(_, p) => onPageChange(p + 1)}
        rowsPerPage={perPage}
        rowsPerPageOptions={[20, 50, 100]}
        onRowsPerPageChange={(e) => onPerPageChange(+e.target.value)}
      />
    </Grid>
  )
}
