// Core
import React, { FC } from 'react'
import { Box, Link, Typography } from '@material-ui/core'

type Props = {
  onResetClick: () => void
  isLoading?: boolean
}

const ForgotPasswordLink: FC<Props> = ({ onResetClick, isLoading }) => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Box display="flex" alignItems="center" style={{ columnGap: 6 }}>
      <Typography variant="body1" color="textSecondary">
        Forgot password?{' '}
      </Typography>
      <Link
        variant="body1"
        component="button"
        type="button"
        onClick={onResetClick}
        disabled={isLoading}
        style={{ cursor: isLoading ? 'wait' : 'pointer' }}
      >
        Reset password
      </Link>
    </Box>
  )
}

export default ForgotPasswordLink
