import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      overflow: 'visible',
      paddingRight: 50,
      '&:hover': {
        '& $newTab': {
          opacity: 1,
        },
      },
    },
    active: {
      color: 'green',
    },
    newTab: {
      position: 'absolute',
      right: 10,
      opacity: 0,
      zIndex: 10,
    },
    title: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      lineHeight: 1,
      '&::before': {
        content: '',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
    },
  })
)

export default useStyles
