import react from 'react'
import { getIn, useFormikContext } from 'formik'
import { FormAttribute } from 'modules/new-entity/types'

const DEFAULT_CONDITION = true

export const useGetCondition = (options: any, allData: any, pathForConditions: string) => {
  const { values } = useFormikContext<any>()

  return react.useMemo<boolean>(() => {
    let myCondition: boolean = DEFAULT_CONDITION

    try {
      if (!options) return DEFAULT_CONDITION
      const {
        condition: { dependsOn, conditionValue },
      } = options

      const finded = allData.find((element: FormAttribute) => {
        return element.attribute['@id'] === dependsOn
      })
      if (!finded) return DEFAULT_CONDITION
      const typeData = finded.attribute.attributeType.type

      const rathToValue = `${pathForConditions}${dependsOn}`

      switch (typeData) {
        case 'select':
          {
            const enums = finded.attribute.attributeEnums
            const enumEl = enums.find((element: any) => element.value === conditionValue)
            const enumId = enumEl.id

            const value = getIn(values, rathToValue)?.value
            myCondition = value === enumId
          }
          break
        case 'boolean': {
          const value = String(Boolean(getIn(values, rathToValue)?.value))
          myCondition = value === String(conditionValue)
          break
        }
        case 'text':
        case 'string': {
          const value = String(getIn(values, rathToValue)?.value).trim()
          myCondition = value === String(conditionValue).trim()
        }
      }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return myCondition
    }
  }, [/*options, allData, */ values])
}
