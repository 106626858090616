import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStyles from './not-found.styles'

type TProps = {
  children?: never
}

const NotFound: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <img src="/not-found.svg" alt="" />
      <Typography className={classes.notFound}>{t('pages.not-found')}</Typography>
    </div>
  )
}

export default NotFound
