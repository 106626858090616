import { IEntity } from 'modules/new-entity/types'

type ReturnTypes = {
  entityUrl: string
}
type UseGetHierReference = (e?: IEntity) => ReturnTypes

export const useGetHierReference: UseGetHierReference = (entity) => {
  if (!entity?.entityUrls?.length) return { entityUrl: '' }
  return { entityUrl: entity.entityUrls[0]?.url ?? '' }
}
