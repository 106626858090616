import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const buttonAddStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(0.5, 1),
      minHeight: '35px',
      backgroundColor: defaultThemeColors.purpleLight,
      textTransform: 'none',
      color: theme.palette.primary.main,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
)

export default buttonAddStyles
