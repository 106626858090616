// Core
import React, { FC } from 'react'
// Components
import CrudForm from '../crud-form'
import Sidebar from '../crud-form/sidebar'
// Types
import { Create, ResourceProps } from 'core/data'
import { Segment } from 'modules/segments/types'

const initialValues: Segment = {
  name: '',
  priority: 0,
  segmentRuleGroups: [],
  active: true,
}

const CreateSegment: FC<ResourceProps> = (props) => {
  return <Create {...props} initialData={initialValues} crudForm={CrudForm} sidebar={Sidebar} />
}

export default CreateSegment
