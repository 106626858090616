import React, { FC } from 'react'
import { ResourceProps } from 'core/data'
import { Edit } from 'core/data'
import { FormContainer } from '../index'

const WidgetTypeEdit: FC<ResourceProps> = (props) => {
  return <Edit {...props} crudForm={FormContainer} />
}

export default WidgetTypeEdit
