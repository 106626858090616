import React, { FC } from 'react'
// Components
import { AppBar } from '../../components'
import { CssBaseline } from '@material-ui/core'
import MainSidebar from 'modules/layout/components/main-sidebar'
// Styles
import mainLayoutStyles from './main-layout.styles'

const MainLayout: FC = (props) => {
  const { children } = props
  const classes = mainLayoutStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar />
      <MainSidebar />

      <main className="content-wrapper">{children}</main>
    </div>
  )
}

export default MainLayout
