// Core
import React, { FC, ReactNode, RefObject, useRef } from 'react'
import { Box, Drawer } from '@material-ui/core'
// Styles
import useStyles from './form-holder.styles'
import { defaultThemeSizes } from 'core/themes/default/default'

type Props = {
  children: (asideRef: RefObject<HTMLElement>) => ReactNode
  disableSidebarScroll?: boolean
  header?: ReactNode
  dates?: ReactNode
}

export const FormHolder: FC<Props> = (props) => {
  const { children, disableSidebarScroll, header, dates } = props
  const classes = useStyles()
  const asidePortalRef = useRef(null)

  return (
    <div className={classes.root}>
      {header}
      <div className={classes.main}>
        <div className={classes.wrapper}>
          <div className={classes.content}>{children(asidePortalRef)}</div>
        </div>

        <Drawer
          variant="permanent"
          anchor="right"
          className={classes.aside}
          PaperProps={{
            className: 'global-sticky',
            style: {
              overflow: disableSidebarScroll ? 'hidden' : undefined,
              top: defaultThemeSizes.pageHeaderHeight + defaultThemeSizes.appBarHeight,
              zIndex: 1180,
              height: `calc(100% - ${
                defaultThemeSizes.pageHeaderHeight + defaultThemeSizes.appBarHeight
              }px)`,
            },
          }}
        >
          <Box
            // @ts-expect-error
            ref={asidePortalRef}
            display="flex"
            flexDirection="column"
            sx={{ pt: 1, px: 1 }}
            style={
              dates
                ? {
                    height: `calc(100% - 53px)`,
                    overflowY: 'auto',
                  }
                : {}
            }
          />
          {dates}
        </Drawer>
      </div>
    </div>
  )
}
