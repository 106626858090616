import { useCallback, useEffect, useRef } from 'react'

export default () => {
  const scrollBlocked = useRef<boolean>()

  const blockScroll = useCallback(() => {
    const { body } = document
    if (!body || !body.style || scrollBlocked.current) return

    const scrollBarWidth = window.innerWidth - body.offsetWidth
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue('padding-right'), 10) || 0
    body.style.overflow = 'hidden' /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`
    body.dataset.blockScroll = 'true'

    scrollBlocked.current = true
  }, [])

  const allowScroll = useCallback(() => {
    const { body } = document
    if (!body || !body.style || !scrollBlocked.current) return

    body.style.overflow = ''
    body.style.paddingRight = ''
    body.dataset.blockScroll = ''

    scrollBlocked.current = false
  }, [])

  useEffect(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth
    document.body.style.setProperty('--scrollbar-compensation', `${scrollBarCompensation}px`)
  }, [])

  return { blockScroll, allowScroll }
}
