// Core
import { useCallback, useMemo, useState } from 'react'
import { FieldArrayRenderProps } from 'formik'
// Utils
import { getIdFromIri } from 'core/utils'
// Types
import { AttributeData } from 'modules/new-entity/types'

export const useReferenceController = (attributeData: AttributeData) => {
  const [entityType, setEntityType] = useState('')
  const [entity, setEntity] = useState('')

  const entityTypeIds = useMemo(
    () => attributeData.entityTypes.map((iri) => getIdFromIri(iri)),
    [attributeData]
  )

  const addHandler = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => {
      arrayHelpers.push(entity)
      setEntity('')
    },
    [entity]
  )

  return {
    entityType,
    setEntityType,
    entity,
    setEntity,
    entityTypeIds,
    addHandler,
  }
}
