// Core
import { useContext } from 'react'
// Context
import { TranslationContext } from '../context'
// Types
import { ContextPropsType } from '../types'

export const useTranslationContext = () => {
  return useContext<ContextPropsType>(TranslationContext)
}
