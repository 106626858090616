import { ActionTypes, IThemeState } from './types'
import { ThemeActionTypes } from './actions'
import produce from 'immer'

const initialState: IThemeState = {
  defaultTheme: true,
}

const reducer = (state: IThemeState = initialState, action: ThemeActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SWITCH_THEME: {
        draft.defaultTheme = action.payload.val
        break
      }
    }
  })

export default reducer
