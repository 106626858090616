import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export const Loading: FC = () => {
  return (
    <Box p={2} overflow="auto" height="inherit">
      {Array.from(Array(10).keys()).map((i) => (
        <Box mb={1} py={1} key={i}>
          <Skeleton variant="rect" />
        </Box>
      ))}
    </Box>
  )
}
