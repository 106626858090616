import React, { FC, useEffect } from 'react'
// Redux Hooks
import { useSelector } from 'react-redux'
// Material Components
import { Tab, Box } from '@material-ui/core'
import { TabContext } from '@material-ui/lab'
// Components
import {
  LayoutStartSection,
  LayoutFormComponent,
  LayoutSection,
  LayoutStaticContainer,
} from './components'
// Store
import {
  activeSectionIdSelector,
  footerIsAddedSelector,
  headerIsAddedSelector,
  sectionsSelector,
} from 'modules/entity-layouts/store'
// DND
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
// Custom Hooks
import {
  useClearStore,
  useDragEnd,
  useHandleSetFooter,
  useHandleSetHeader,
  useSetInitialOptions,
} from '../../hooks'
import { useTabSetValue } from 'modules/types-crud/hooks/tabs/use-tab-set-value'
import { TabPanel, TabList } from 'ui'
// Styles
import layoutGeneralStyles from './form-container.styles'
import { useTranslation } from 'react-i18next'

type TProps = {
  type: 'create' | 'edit'
  values: any
  setFieldValue(field: string, value: any): void
}

const FormContainerLayout: FC<TProps> = (props) => {
  const classes = layoutGeneralStyles()
  const { t } = useTranslation()
  const { type, values, setFieldValue } = props

  const { tabValue, handleChange } = useTabSetValue()
  const onDragEnd = useDragEnd()

  const header = useSelector(headerIsAddedSelector)
  const footer = useSelector(footerIsAddedSelector)
  const sections = useSelector(sectionsSelector)
  const activeSectionid = useSelector(activeSectionIdSelector)

  useClearStore()
  useSetInitialOptions(values, type)
  const handleSetHeader = useHandleSetHeader()
  const handleSetFooter = useHandleSetFooter()

  useEffect(() => {
    setFieldValue('options', {
      header,
      footer,
      sections,
    })
  }, [footer, header, sections, setFieldValue])

  return (
    <Box>
      <TabContext value={tabValue}>
        <TabList onChange={handleChange}>
          <Tab label={t('labels.layout-name')} value="tab-1" />
          <Tab label={t('labels.layout-builder')} value="tab-2" />
        </TabList>

        <TabPanel value={tabValue} index="tab-1">
          <LayoutFormComponent />
        </TabPanel>

        <TabPanel value={tabValue} index="tab-2">
          <div className={classes.layoutBuilderWrap}>
            <LayoutStaticContainer
              name={t('layout.header')}
              isActive={header}
              toggleStaticContainer={handleSetHeader}
            />
            {sections.length < 1 && <LayoutStartSection />}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="sections">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {sections
                      ? sections.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(providedItem) => (
                              <div
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                              >
                                <LayoutSection
                                  key={item.id}
                                  data={item}
                                  isActiveSection={item.id === activeSectionid}
                                  hasSidebar={item.options.hasSidebar}
                                  sidebarWidth={item.options.sidebarWidth}
                                  sidebarPosition={item.options.sidebarPosition}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))
                      : 'Nothing to show...'}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <LayoutStaticContainer
              name={t('layout.footer')}
              isActive={footer}
              toggleStaticContainer={handleSetFooter}
            />
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default FormContainerLayout
