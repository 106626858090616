import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FieldInputProps, useField } from 'formik'
import { slugifyValue } from 'modules/new-entity/utils'
import { FieldMetaProps } from 'formik/dist/types'
import { useGetActiveSite, useSitesContext } from 'modules/sites'
import { trailingSlash } from 'core/utils'

type ReturnType = {
  field: FieldInputProps<any>
  siteLink: string
  meta: FieldMetaProps<any>
  fieldState: string
  showInput: boolean
  htmlElRef: MutableRefObject<HTMLDivElement | null>
  siteUrl: string
  actions: {
    inputChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
    setValueHandler: () => void
    cancelHandler: () => void
    inputFocusHandler: () => void
    onBlurHandler: () => void
  }
}

type TUseSystemSlug = (name: string, data: any, entityUrl?: string) => ReturnType

export const useSystemSlug: TUseSystemSlug = (name, data, entityUrl) => {
  const htmlElRef = useRef<HTMLDivElement | null>(null)
  const activeSite = useGetActiveSite()
  const { affiliateSettings } = useSitesContext()

  const [field, meta, helpers] = useField(name)
  const fieldValue = field.value?.replace('/', '')
  const [showInput, setShowInput] = useState(false)
  const [fieldState, setFieldState] = useState<string>(fieldValue)
  const [fieldPrevState, setFieldPrevState] = useState<string>(fieldValue)

  const entityUrlObj = useMemo(() => {
    if (entityUrl?.length) {
      // clear last / from string 'parent/exa/mple/' (to split then widout empty '')
      const urlArrRem = entityUrl.replace(/\/+$/, '')
      // strint go array ['parent', 'exa', 'mple']
      const urlArr = urlArrRem.split('/')
      // cut last element as string 'mple'
      const slug = urlArr.pop()
      // take array ['parent', 'exa'] as parent (join to string with '/' => parent/exa/)
      const parent = `${urlArr.length && urlArr[0] !== '' ? `${urlArr.join('/')}/` : ''}`
      return { parent, slug }
    }
    return {
      parent: '',
      slug: '',
    }
  }, [entityUrl])

  const prefix = useMemo(() => {
    if (data.slug === 'cloaked_url') {
      const affPrefix = affiliateSettings.prefix
      return `${affPrefix}/`
    }

    return ''
  }, [affiliateSettings, data.slug])

  // Site link
  const siteLink = useMemo(() => {
    let siteUrl = trailingSlash(activeSite?.url)
    const affAlterDomain = affiliateSettings.alternative.domain

    if (data.slug === 'cloaked_url' && affAlterDomain) {
      siteUrl = trailingSlash(affAlterDomain)
    }

    return `${siteUrl}${prefix}${entityUrlObj?.parent}`
  }, [activeSite?.url, entityUrlObj?.parent, prefix, data, affiliateSettings])

  useEffect(() => {
    if (entityUrlObj.slug?.length) {
      setFieldState(entityUrlObj.slug || '')
      setFieldPrevState(entityUrlObj.slug || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set hidden input state
  useEffect(() => {
    if (fieldValue && !fieldState) {
      setFieldState(fieldValue)
      setFieldPrevState(fieldValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue])

  // Actions
  const inputChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldState(slugifyValue(event.target.value))
  }, [])

  const setValueHandler = useCallback(() => {
    const value = fieldState === '' ? '/' : slugifyValue(fieldState)
    helpers.setValue(value)
    setFieldPrevState(value)
    setShowInput(false)
  }, [helpers, fieldState])

  const cancelHandler = useCallback(() => {
    helpers.setValue(slugifyValue(fieldPrevState))
    setFieldState(slugifyValue(fieldPrevState))
    setShowInput(false)
  }, [fieldPrevState, helpers])

  const inputFocusHandler = useCallback(() => {
    setShowInput(true)
    Promise.resolve().then(() => {
      htmlElRef.current && htmlElRef.current.getElementsByTagName('input')[0].focus()
    })
  }, [])

  const onBlurHandler = useCallback(() => {
    setShowInput(false)
  }, [])

  const actions = useMemo(
    () => ({
      inputChangeHandler,
      inputFocusHandler,
      onBlurHandler,
      cancelHandler,
      setValueHandler,
    }),
    [inputChangeHandler, inputFocusHandler, onBlurHandler, cancelHandler, setValueHandler]
  )

  return {
    field,
    siteLink,
    meta,
    fieldState,
    showInput,
    htmlElRef,
    actions,
    siteUrl: activeSite?.url || '',
  }
}
