// Core
import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import AccountCircle from '@material-ui/icons/AccountCircle'
// Hooks
import useGetProfile from 'modules/user/hooks/use-get-profile'
// Styles
import userStyles from './user.styles'

type Props = {}

const User: FC<Props> = () => {
  const classes = userStyles()

  const { data } = useGetProfile()

  return (
    <Button
      aria-label="account of current user"
      aria-haspopup="true"
      color="inherit"
      component={NavLink}
      to="/profile"
    >
      <AccountCircle style={{ width: 40, height: 40 }} />
      <span className={classes.userText}>{`${data?.firstName} ${data?.lastName}`}</span>
    </Button>
  )
}

export default User
