import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '@material-ui/core'

const navigationItemsTreeStyles = makeStyles((theme: Theme) =>
  createStyles({
    holder: {
      position: 'relative',
      padding: '20px 0',
      borderTop: '1px solid rgba(0, 0, 0, .1)',
    },

    hint: {
      color: colors.blueGrey[600],
      marginBottom: theme.spacing(2),
    },
    overlay: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',

      background: 'rgba(255, 255 ,255 ,0.3)',
      zIndex: 10,
      cursor: 'not-allowed',
    },
    treeComponent: {
      '& .rst__nodeContent': {
        minWidth: '50%',
      },
      '& .rst__rowWrapper': {
        padding: '3px 10px 3px 0',
      },
    },
    rowItem: {
      borderRadius: '8px',
      overflow: 'hidden',
      backgroundColor: '#F4F6F8',
      '& .rst__moveHandle': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'unset',
        border: 'none',
        backgroundColor: '#F4F6F8',

        '&:after': {
          content: '"\\ea65"',
          fontFamily: 'icomoon !important',
          fontSize: '26px',
        },
      },
      '& .rst__rowContents': {
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
      },
      '& .sub-item': {
        color: colors.blueGrey[200],
      },
    },
    itemTitleHolder: {
      display: 'flex',
      alignItems: 'center',
    },
    rowItemTitle: {
      display: 'inline-block',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 1,
      maxWidth: 200,
    },
  })
)

export default navigationItemsTreeStyles
