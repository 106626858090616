import React, { FC } from 'react'
import MainForm from './components/main-form'
import { httpService, transformLocalizations, useInitialLocalization } from 'core/data'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CircularProgress } from '@material-ui/core'
import { Attribute } from 'core/types/attribute'
import { useNotify } from 'core/hooks'

type TProps = {
  onAttributeCreate?: (attrIri: string) => void
  setRef?: (node: any) => void
  attrId?: number
  onEditSuccess?: (values: Attribute) => void
}

const AttributeForm: FC<TProps> = (props) => {
  const { onAttributeCreate, setRef, attrId, onEditSuccess } = props
  const notify = useNotify()
  const isEdit = Boolean(attrId)
  const queryClient = useQueryClient()

  const attrQ = useQuery(
    ['attribute', attrId],
    async () => {
      const attrData = await httpService
        .get<Attribute>(`/attributes/${attrId}`)
        .then((res) => res.data)

      return attrData
    },
    {
      enabled: isEdit,
      select: (data) => {
        return {
          ...data,
          attributeType: data.attributeType['@id'],
          type: data.attributeType.type,
        }
      },
    }
  )

  const editM = useMutation(
    async (values: Attribute) => {
      await httpService.put(`/attributes/${values.id}`, values)
      return values
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('selected-attributes')
        notify('Attribute successfully edited', { type: 'success' })
        onEditSuccess?.(data)
      },
    }
  )

  const addInitialLocalization = useInitialLocalization(['name', 'hint'])

  let initialProps = isEdit
    ? attrQ.data
    : {
        attributeGroups: [],
        attributeType: '',
        attribute_groups_cache: [],
        options: {
          fieldWidth: 12,
        },
        defaultValues: {
          value: '',
        },
        attributeEnums: [],
        slug: '',
        type: '',
      }

  if (attrQ.isLoading) {
    return <CircularProgress />
  }

  if (!isEdit) {
    addInitialLocalization(initialProps)
  } else {
    initialProps = transformLocalizations(initialProps)
  }

  return (
    <MainForm
      initialData={initialProps}
      setRef={setRef}
      setCreatedAttributeAction={onAttributeCreate}
      onSubmit={isEdit ? editM.mutate : undefined}
      id="create-attribute-form"
      isEdit={isEdit}
    />
  )
}

export default AttributeForm
