import React, { FC, useCallback } from 'react'
import useStyles from './fields-data.styles'
import { Typography } from '@material-ui/core'
import { Attribute } from 'core/types/attribute'
import { v4 as uniqueId } from 'uuid'
import DefaultValuePreview from '../default-value-preview'
import SelectPreview from '../select-preview'
import ReferencesPreview from '../references-preview'
import { OptionsPreview } from '../index'
import { SelectedAttributesList } from '../../../index'
import RegexPreview from '../regex-preview'
import { AttributeTypesList } from 'common/types'
import { useTranslation } from 'react-i18next'

type TProps = {
  attrData: Attribute
  values: any
  isNested?: boolean
  pathToAttribute?: string
}

const id = uniqueId().toString()

const FieldsData: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { attrData, values, isNested, pathToAttribute } = props
  const { type } = attrData.attributeType

  const getTitleText = useCallback(
    (type: AttributeTypesList) => {
      switch (type) {
        case AttributeTypesList.repeater: {
          return t('list.repeater')
        }
        case AttributeTypesList.group: {
          return t('list.group')
        }
        default:
          return ''
      }
    },
    [t]
  )

  if (
    //
    type === AttributeTypesList.repeater ||
    type === AttributeTypesList.group
  ) {
    return (
      <>
        <OptionsPreview attrData={attrData} values={values} isNested={isNested} />
        {attrData.setAttributes.length !== 0 && (
          <>
            <DefaultValuePreview attrData={attrData} />
            <Typography className={classes.title} variant={'body2'}>
              {getTitleText(type)}
            </Typography>
            <SelectedAttributesList
              disabled
              id={id}
              data={attrData.setAttributes}
              isNested
              pathToAttribute={pathToAttribute}
            />
          </>
        )}
      </>
    )
  }

  if (
    //
    type === AttributeTypesList.select ||
    type === AttributeTypesList.multi_select
  ) {
    return (
      <>
        <OptionsPreview attrData={attrData} values={values} isNested={isNested} />
        <SelectPreview attrData={attrData} />
      </>
    )
  }

  return (
    <>
      <ReferencesPreview attrData={attrData} />
      <DefaultValuePreview attrData={attrData} />
      <RegexPreview validation={attrData.validationRules} />
      <OptionsPreview attrData={attrData} values={values} isNested={isNested} />
    </>
  )
}

export default FieldsData
