// Core
import React, { ChangeEvent, FC, useCallback } from 'react'
import { Checkbox, Icon, LinearProgress, Select, TableCell, TableRow } from '@material-ui/core'
import clsx from 'clsx'
// Components
import { ActionsCell, FrequencyCell, PriorityCell } from '../common'
import { useTranslation } from 'react-i18next'
// Hooks
import { useGetEntityType, useSettingItemController } from 'modules/sitemap-settings/hooks'
// Utils
import { Priority, Months } from 'modules/sitemap-settings/utils'
// Types
import { SitemapIndex } from 'modules/sitemap-settings/types'
// Styles
import useStyles from './setting-item.styles'

type Props = {
  data: SitemapIndex
}

const EntityTypeSettingItem: FC<Props> = (props) => {
  const { data } = props
  const classes = useStyles()
  const {
    //
    isEdit,
    form,
    editHandler,
    deleteHandler,
    mutation,
  } = useSettingItemController<SitemapIndex>(data)
  const { t } = useTranslation()

  const entityTypeQuery = useGetEntityType(data.entityType!)

  const divideByPeriodHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue('dividedByPeriod', e.target.checked)

      if (!form.values.importantPeriodDuration) {
        form.setFieldValue('importantPeriodDuration', '1')
        form.setFieldValue('importantPeriodPriority', '0')
      }
    },
    [form]
  )

  return (
    <TableRow className={clsx({ [classes.loading]: mutation.isLoading })}>
      <TableCell size="small" width="20%">
        {entityTypeQuery.isLoading ? <LinearProgress /> : entityTypeQuery.data?.name}
      </TableCell>
      <PriorityCell name="priority" isEdit={isEdit} data={data} form={form} />
      <FrequencyCell name="frequency" isEdit={isEdit} data={data} form={form} />
      <TableCell size="small" width="10%">
        {!isEdit ? (
          data.lastModifiedEnabled ? (
            <Icon className="icon-check-circle" />
          ) : (
            <Icon className="icon-x-circle" />
          )
        ) : (
          <Checkbox
            checked={form.values.lastModifiedEnabled}
            name="lastModifiedEnabled"
            onChange={(e) => form.setFieldValue('lastModifiedEnabled', e.target.checked)}
          />
        )}
      </TableCell>
      <TableCell size="small" width="25%">
        {!isEdit ? (
          data.dividedByPeriod ? (
            <div className={classes.monthsCellWrapper}>
              <Icon className="icon-check-circle" />
              {data.importantPeriodDuration &&
                `${t('sitemap.last')} ${data.importantPeriodDuration} ${t('sitemap.months')} `}
              {data.importantPeriodPriority &&
                ` ${data.importantPeriodPriority} ${t('sitemap.priority-l')}`}
            </div>
          ) : (
            <Icon className="icon-x-circle" />
          )
        ) : (
          <>
            <Checkbox
              checked={form.values.dividedByPeriod}
              name="dividedByPeriod"
              onChange={divideByPeriodHandler}
            />
            {form.values.dividedByPeriod && (
              <>
                <span style={{ marginRight: 5 }}>{t('sitemap.last')}</span>
                <Select
                  native
                  value={form.values.importantPeriodDuration}
                  name="importantPeriodDuration"
                  onChange={form.handleChange}
                >
                  {Months.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <span style={{ margin: '0 5px' }}>{t('sitemap.months')}</span>
                <Select
                  native
                  value={form.values.importantPeriodPriority}
                  name="importantPeriodPriority"
                  onChange={form.handleChange}
                >
                  {Priority.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <span style={{ marginLeft: 5 }}>{t('sitemap.priority-l')}</span>
              </>
            )}
          </>
        )}
      </TableCell>
      <ActionsCell
        permissionType="sitemap_indices"
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        isEdit={isEdit}
      />
    </TableRow>
  )
}

export default EntityTypeSettingItem
