// Core
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Hooks
import { useNotify } from 'core/hooks'
// Services
import { httpService } from 'core/data'

export const useResetMfa = (name: string) => {
  const notify = useNotify()
  const { t } = useTranslation()
  const { id } = useParams()

  const resetMfa = useMutation(
    () => {
      return httpService.post(`/${name}/${id}/reset_2fa`)
    },
    {
      onSuccess: () => {
        notify(t('notify.mfa-reset-success'), { type: 'success' })
      },
      onError: () => {
        notify(t('notify.mfa-reset-error'), { type: 'error' })
      },
    }
  )

  return { resetMfa }
}
