import { UseQueryResult, useQuery } from 'react-query'
import { httpService } from 'core/data'
import { AttributeType } from 'core/types/attribute-type'

type UseGetAttributesTypes = () => UseQueryResult<AttributeType[]>

export const useGetAttributesTypes: UseGetAttributesTypes = () => {
  return useQuery(
    'attribute-types',
    async () => {
      const res = await httpService.get<any>('/attribute_types')

      return res.data['hydra:member']
    },
    {}
  )
}
