// Core
import React, { ComponentType, createElement, FC } from 'react'
import { Grid } from '@material-ui/core'
// Components
import EmptyList from '../empty-list'
import Spinner from 'ui/spinner'
// Hooks
import { useListContext } from 'core/data'

type Props = {
  children?: never
  gridComponent?: ComponentType<any>
  data: { id: string }[] | undefined
  inModal: boolean
  loading: boolean
}

const DataGrid: FC<Props> = (props) => {
  const { selected, setSelected } = useListContext()
  const { gridComponent, data, inModal, loading } = props

  if (loading) {
    return <Spinner />
  }

  return (
    <Grid container spacing={1} style={{ padding: 24 }}>
      {data?.length === 0 && <EmptyList />}
      {data?.map((item) => (
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          xl={3}
          key={item.id}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {createElement(gridComponent || 'div', {
            itemData: item,
            inModal,
            selected,
            setSelected,
          })}
        </Grid>
      ))}
    </Grid>
  )
}

export default DataGrid
