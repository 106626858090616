import React, { FC } from 'react'
import rootLoaderStyles from './root-loader.styles'
import { CircularProgress } from '@material-ui/core'

type TProps = {}

const RootLoader: FC<TProps> = () => {
  const classes = rootLoaderStyles()

  return (
    <div className={classes.root}>
      <CircularProgress size={50} />
    </div>
  )
}

export default RootLoader
