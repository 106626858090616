import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      '& .MuiTableCell-stickyHeader': {
        backgroundColor: '#fff',
      },
      '&:first-child': {
        marginTop: theme.spacing(2),
      },
    },
    title: {
      ...theme.typography.subtitle1,
      marginBottom: theme.spacing(1),
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1, 3),
      boxShadow: theme.shadows[2],
      backgroundColor: '#fff',
    },
  })
)
