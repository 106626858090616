import { createStyles, makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() =>
  createStyles({
    select: {
      width: 'auto',
      minWidth: 160,
    },
  })
)

export default styles
