export const iconsList = [
  'icon-tablet1',
  'icon-add-above',
  'icon-add-below',
  'icon-desktop',
  'icon-mobile',
  'icon-page-builder1',
  'icon-sidebarright',
  'icon-selectbox',
  'icon-content',
  'icon-chevrons-closed',
  'icon-chevrons-open',
  'icon-drag-in-drop',
  'icon-dragindrop',
  'icon-page-builder',
  'icon-preview',
  'icon-widget',
  'icon-access_logo',
  'icon-check-default',
  'icon-close-default',
  'icon-comment',
  'icon-dashboard',
  'icon-delete',
  'icon-favorite_border-default',
  'icon-favorite-default',
  'icon-file_upload',
  'icon-filter_list-default',
  'icon-folder',
  'icon-format_bold-default',
  'icon-format_italic-default',
  'icon-get_app-default',
  'icon-googleplus_reshare-default',
  'icon-grade',
  'icon-grade-default',
  'icon-home',
  'icon-insert-drive',
  'icon-lock',
  'icon-menu-close',
  'icon-menu-open',
  'icon-more_horiz',
  'icon-more_horiz-1',
  'icon-more_vert-default',
  'icon-not_interested-default',
  'icon-open_in_new-default',
  'icon-outline-arrow_downward-24px-default',
  'icon-outline-arrow_forward-24px-default',
  'icon-outline-drafts-24px-default',
  'icon-outline-error_outline-24px',
  'icon-outline-exit_to_app-24px',
  'icon-outline-flag-24px-default',
  'icon-outline-inbox-24px-default',
  'icon-outline-keyboard_arrow_down-24px-default',
  'icon-outline-keyboard_arrow_left',
  'icon-outline-keyboard_arrow_left-24px-default',
  'icon-outline-keyboard_arrow_right',
  'icon-outline-keyboard_arrow_right-24px-default',
  'icon-outline-keyboard_arrow_up-24px-default',
  'icon-outline-keyboard_backspace-24px-default',
  'icon-outline-notifications-24px',
  'icon-outline-payment-24px-default',
  'icon-outline-people_outline-24px-default',
  'icon-outline-person_add-24px',
  'icon-outline-person-24px-default',
  'icon-outline-receipt-24px-default',
  'icon-outline-refresh-24px-default',
  'icon-outline-send-24px-default',
  'icon-outline-web_asset-24px-default',
  'icon-reply_all-default',
  'icon-reply-default',
  'icon-search-default',
  'icon-send-default',
  'icon-settings-default',
  'icon-share-default',
  'icon-star_border-default',
  'icon-view_agenda-default',
  'icon-view_day-default',
  'icon-view_module-default-Copy-1',
  'icon-view_module-default',
  'icon-view_week-default',
  'icon-access_time-default',
  'icon-add-default',
  'icon-arrow_drop_down',
  'icon-arrow_drop_down-default',
  'icon-attach_file-default',
  'icon-attach_money-default',
  'icon-bar_chart',
  'icon-baseline-add_photo_alternate-24px-default',
  'icon-baseline-edit-24px-default',
  'icon-baseline-mail_outline-24px',
  'icon-calendar',
  'icon-cancel-24px',
  'icon-outline-keyboard_arrow_right1',
  'icon-star',
  'icon-warning',
  'icon-alert',
  'icon-check_circle',
  'icon-close',
  'icon-first_page',
  'icon-last_page',
  'icon-outline-keyboard_arrow_left1',
  'icon-logo',
  'icon-arrow-down',
  'icon-arrow-down-circle',
  'icon-arrow-down-left',
  'icon-arrow-down-right',
  'icon-arrow-left',
  'icon-arrow-left-circle',
  'icon-arrow-right',
  'icon-arrow-right-circle',
  'icon-arrow-up',
  'icon-arrow-up-circle',
  'icon-arrow-up-left',
  'icon-arrow-up-right',
  'icon-at-sign',
  'icon-award',
  'icon-bar-chart',
  'icon-bar-chart-2',
  'icon-battery',
  'icon-battery-charging',
  'icon-bell',
  'icon-bell-off',
  'icon-bluetooth',
  'icon-bold',
  'icon-book',
  'icon-bookmark',
  'icon-book-open',
  'icon-box',
  'icon-briefcase',
  'icon-calendar1',
  'icon-camera',
  'icon-camera-off',
  'icon-cast',
  'icon-check',
  'icon-check-circle',
  'icon-check-square',
  'icon-chevron-down',
  'icon-chevron-left',
  'icon-chevron-right',
  'icon-chevrons-down',
  'icon-chevrons-left',
  'icon-chevrons-right',
  'icon-chevrons-up',
  'icon-chevron-up',
  'icon-chrome',
  'icon-circle',
  'icon-clipboard',
  'icon-clock',
  'icon-cloud',
  'icon-cloud-drizzle',
  'icon-cloud-lightning',
  'icon-cloud-off',
  'icon-cloud-rain',
  'icon-cloud-snow',
  'icon-code',
  'icon-codepen',
  'icon-command',
  'icon-compass',
  'icon-copy',
  'icon-corner-down-left',
  'icon-corner-down-right',
  'icon-corner-left-down',
  'icon-corner-left-up',
  'icon-corner-right-down',
  'icon-corner-right-up',
  'icon-corner-up-left',
  'icon-corner-up-right',
  'icon-cpu',
  'icon-credit-card',
  'icon-crop',
  'icon-crosshair',
  'icon-database',
  'icon-delete1',
  'icon-disc',
  'icon-dollar-sign',
  'icon-download',
  'icon-download-cloud',
  'icon-droplet',
  'icon-edit',
  'icon-edit-2',
  'icon-edit-3',
  'icon-external-link',
  'icon-eye',
  'icon-eye-off',
  'icon-facebook',
  'icon-fast-forward',
  'icon-feather',
  'icon-file',
  'icon-file-minus',
  'icon-file-plus',
  'icon-file-text',
  'icon-film',
  'icon-filter',
  'icon-flag',
  'icon-folder1',
  'icon-folder-minus',
  'icon-folder-plus',
  'icon-gift',
  'icon-git-branch',
  'icon-git-commit',
  'icon-github',
  'icon-gitlab',
  'icon-git-merge',
  'icon-git-pull-request',
  'icon-globe',
  'icon-grid',
  'icon-hard-drive',
  'icon-hash',
  'icon-headphones',
  'icon-heart',
  'icon-help-circle',
  'icon-home1',
  'icon-image',
  'icon-inbox',
  'icon-info',
  'icon-instagram',
  'icon-italic',
  'icon-layers',
  'icon-layout',
  'icon-life-buoy',
  'icon-link',
  'icon-link-2',
  'icon-linkedin',
  'icon-list',
  'icon-loader',
  'icon-lock1',
  'icon-log-in',
  'icon-log-out',
  'icon-mail',
  'icon-map',
  'icon-map-pin',
  'icon-maximize',
  'icon-maximize-2',
  'icon-menu',
  'icon-message-circle',
  'icon-message-square',
  'icon-mic',
  'icon-mic-off',
  'icon-minimize',
  'icon-minimize-2',
  'icon-minus',
  'icon-minus-circle',
  'icon-minus-square',
  'icon-monitor',
  'icon-moon',
  'icon-more-horizontal',
  'icon-more-vertical',
  'icon-move',
  'icon-music',
  'icon-navigation',
  'icon-navigation-2',
  'icon-octagon',
  'icon-package',
  'icon-paperclip',
  'icon-pause',
  'icon-pause-circle',
  'icon-percent',
  'icon-phone',
  'icon-phone-call',
  'icon-phone-forwarded',
  'icon-phone-incoming',
  'icon-phone-missed',
  'icon-phone-off',
  'icon-phone-outgoing',
  'icon-pie-chart',
  'icon-play',
  'icon-play-circle',
  'icon-plus',
  'icon-plus-circle',
  'icon-plus-square',
  'icon-pocket',
  'icon-power',
  'icon-printer',
  'icon-radio',
  'icon-refresh-ccw',
  'icon-refresh-cw',
  'icon-repeat',
  'icon-rewind',
  'icon-rotate-ccw',
  'icon-rotate-cw',
  'icon-rss',
  'icon-save',
  'icon-scissors',
  'icon-search',
  'icon-send',
  'icon-server',
  'icon-settings',
  'icon-share',
  'icon-share-2',
  'icon-shield',
  'icon-shield-off',
  'icon-shopping-bag',
  'icon-shopping-cart',
  'icon-shuffle',
  'icon-sidebar',
  'icon-sidebar-r',
  'icon-skip-back',
  'icon-skip-forward',
  'icon-slack',
  'icon-slash',
  'icon-sliders',
  'icon-smartphone',
  'icon-speaker',
  'icon-square',
  'icon-star1',
  'icon-stop-circle',
  'icon-sun',
  'icon-sunrise',
  'icon-sunset',
  'icon-tablet',
  'icon-tag',
  'icon-target',
  'icon-terminal',
  'icon-thermometer',
  'icon-thumbs-down',
  'icon-thumbs-up',
  'icon-toggle-left',
  'icon-toggle-right',
  'icon-trash',
  'icon-trash-2',
  'icon-trending-down',
  'icon-trending-up',
  'icon-triangle',
  'icon-truck',
  'icon-tv',
  'icon-twitter',
  'icon-type',
  'icon-umbrella',
  'icon-underline',
  'icon-unlock',
  'icon-upload',
  'icon-upload-cloud',
  'icon-user',
  'icon-user-check',
  'icon-user-minus',
  'icon-user-plus',
  'icon-users',
  'icon-user-x',
  'icon-video',
  'icon-video-off',
  'icon-voicemail',
  'icon-volume',
  'icon-volume-1',
  'icon-volume-2',
  'icon-volume-x',
  'icon-watch',
  'icon-wifi',
  'icon-wifi-off',
  'icon-wind',
  'icon-x',
  'icon-x-circle',
  'icon-x-square',
  'icon-youtube',
  'icon-zap',
  'icon-zap-off',
  'icon-zoom-in',
  'icon-zoom-out',
  'icon-activity',
  'icon-airplay',
  'icon-alert-circle',
  'icon-alert-octagon',
  'icon-alert-triangle',
  'icon-align-center',
  'icon-align-justify',
  'icon-align-left',
  'icon-align-right',
  'icon-anchor',
  'icon-aperture',
  'icon-archive',
]
