// Core
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Chip, MenuItem, Popover, TextField } from '@material-ui/core'
// Components
import FilterLabel from './filter-label'
import FilterValue from './filter-value'
// Utils
import {
  filterConditions,
  filterHasValue,
  getFilterConditionOptions,
  getFilterLabel,
  getFilterTypeByAttrType,
} from './utils'
// Types
import { FilterCondition, Filters, SelectedFilter } from './types'

type Props = {
  data: SelectedFilter
  onDeleteFilter: (slug: string) => void
  onChangeCondition: Filters['changeFilterCondition']
  onChangeValue: Filters['changeFilterValue']
}

const Filter: FC<Props> = (props) => {
  const { data, onDeleteFilter, onChangeCondition, onChangeValue } = props
  const chipRef = useRef(null)
  const [filterRef, setFilterRef] = useState<null | HTMLElement>(null)
  const filterType = getFilterTypeByAttrType(data.type)

  const conditions = useMemo(() => getFilterConditionOptions(filterType), [filterType])
  const needValue = data.condition && !filterConditions[data.condition].emptyValue
  const isActive = data.condition && filterHasValue(data.value)

  useEffect(() => {
    setFilterRef(chipRef.current)
  }, [])

  return (
    <>
      <Chip
        innerRef={chipRef}
        color={isActive ? 'primary' : 'default'}
        onClick={(e) => setFilterRef(e.currentTarget)}
        onDelete={() => onDeleteFilter(data.slug)}
        label={<FilterLabel data={data} />}
      />
      <Popover
        anchorEl={filterRef}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(filterRef)}
        onClose={() => setFilterRef(null)}
      >
        <Box p={2} minWidth={207} maxWidth={230}>
          <Box>
            <TextField
              value={data.condition}
              onChange={(e) => onChangeCondition(data.slug, e.target.value as FilterCondition)}
              variant="outlined"
              size="small"
              label="Condition"
              select
              fullWidth
            >
              {conditions.map((condition) => (
                <MenuItem key={condition.type} value={condition.type}>
                  {getFilterLabel(condition.type, filterType)}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {needValue && (
            <Box mt={2}>
              <FilterValue
                slug={data.slug}
                type={filterType}
                condition={data.condition!}
                value={data.value!}
                onChange={onChangeValue}
              />
            </Box>
          )}
        </Box>
      </Popover>
    </>
  )
}

export default Filter
