import React, { FC } from 'react'
// Material Components
import { Box, Container, Icon, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
// Redux Hooks
import { useSelector } from 'react-redux'
// Store
import { activeContainerIdSelector } from 'modules/entity-layouts/store'
// Types
import { Section } from 'core/types/layouts'
import { SidebarPositionType } from 'core/types/layouts/entity-layouts/sidebar-position'
// Components
import LayoutContainer from '../container'
// Styles
import layoutGeneralStyles from '../../form-container.styles'
import layoutSectionStyles from './section.styles'
// Custom Hooks
import { useTranslation } from 'react-i18next'
import {
  useActiveElement,
  useAddContainer,
  useAddSection,
  useDisactiveElements,
  useRemoveSection,
} from 'modules/entity-layouts/hooks'
//
import clsx from 'clsx'

type TProps = {
  data: Section
  isActiveSection: boolean
  hasSidebar: boolean | undefined
  sidebarWidth: number | 0
  sidebarPosition: SidebarPositionType
}

const LayoutSection: FC<TProps> = (props) => {
  const { data, isActiveSection, hasSidebar, sidebarWidth, sidebarPosition } = props
  const classes = layoutSectionStyles()
  const classesGeneral = layoutGeneralStyles()
  const { t } = useTranslation()

  const activeContainerid = useSelector(activeContainerIdSelector)

  const handleRemoveSection = useRemoveSection(data.id)
  const handleAddSection = useAddSection()
  const handleAddContainer = useAddContainer()
  const handleRemoveActiveElement = useDisactiveElements()
  const handleActiveSection = useActiveElement(data.id, '')

  return (
    <Container
      className={clsx(classes.root, isActiveSection && classes.activeSection)}
      maxWidth={false}
    >
      <Box className={classes.clicker} onClick={handleActiveSection} />

      <div className={classes.dragWrapper}>
        <Icon className="icon-drag-in-drop" />
        <Icon className="icon-dragindrop" />
        <Icon className="icon-drag-in-drop" />
        <Icon className="icon-dragindrop" />
      </div>

      {isActiveSection && (
        <>
          <IconButton
            className={clsx(classesGeneral.topIco, classesGeneral.generalIco)}
            onClick={() => handleAddSection(0)}
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <IconButton
            className={clsx(classesGeneral.bottomIco, classesGeneral.generalIco)}
            onClick={() => handleAddSection(1)}
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleRemoveSection()
              handleRemoveActiveElement()
            }}
            className={clsx(classesGeneral.removeIco, classesGeneral.generalIco)}
          >
            <CloseIcon />
          </IconButton>
        </>
      )}

      <Box
        className={clsx(
          classes.rootWrapper,
          sidebarPosition === 'left' ? classes.leftDirection : ''
        )}
      >
        <Box className={classes.clicker} onClick={handleActiveSection} />

        <Box
          className={classes.containerWrapper}
          width={hasSidebar ? (100 - sidebarWidth) / 100 : 1}
        >
          {data.containers.length < 1 && !isActiveSection && (
            <div className={classesGeneral.hint}>
              <Icon className="icon-selectbox" />
              {t('labels.select-section')}
            </div>
          )}

          {isActiveSection && (
            <Box className={classes.addContainerIcoWrap}>
              <div className={classes.addContainerIco}>
                <IconButton onClick={handleAddContainer}>
                  <AddCircleOutlineIcon />
                </IconButton>
                {t('labels.add-container')}
              </div>
            </Box>
          )}

          {data.containers
            .filter((container) => container.sidebar === false)
            .map((container) => (
              <LayoutContainer
                key={container.id}
                id={container.id}
                width={container.options.width}
                columns={container.options.columns}
                isActiveContainer={container.id === activeContainerid}
                {...props}
              />
            ))}
        </Box>

        {hasSidebar && (
          <Box className={classes.sidebarHolder} width={sidebarWidth / 100}>
            {data.containers
              .filter((container) => container.sidebar === true)
              .map((container) => (
                <LayoutContainer
                  isSidebar={container.sidebar}
                  key={container.id}
                  id={container.id}
                  width={container.options.width}
                  columns={container.options.columns}
                  isActiveContainer={container.id === activeContainerid}
                  {...props}
                />
              ))}
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default LayoutSection
