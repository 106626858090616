import { v4 as uniqueId } from 'uuid'

export class FileToUpload {
  id: string

  file: File

  constructor(file: File) {
    this.id = uniqueId()
    this.file = file
  }
}
