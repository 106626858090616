// Core
import React, { FC, memo } from 'react'
// Components
import { Divider, Icon, IconButton, Tooltip } from '@material-ui/core'
// Utils
import clsx from 'clsx'
import { useSitesContext } from 'modules/sites'
// Classes
import { DefaultValuesGenerator } from 'modules/new-entity/transformers'
// Types
import { AttributeData } from 'modules/new-entity/types'
// Styles
import useStyles from './action-area.styles'

type TProps = {
  index: number
} & TActionTypes

type InsertProps = {
  id: string
}

export type TActionTypes = {
  insert: (index: number, { id }: InsertProps) => void
  move: (indexFrom: number, indexTo: number) => void
  remove: (index: number) => void
  disablePushInsert: boolean
  attrData: AttributeData
}

const ActionArea: FC<TProps> = (props) => {
  const classes = useStyles()
  const { locales } = useSitesContext()
  const localizations = locales.site
  const { index, insert, move, remove, disablePushInsert, attrData } = props

  return (
    <div className={clsx('action--area', classes.actionArea)}>
      <Tooltip title={'Move up'} placement={'top'}>
        <IconButton
          className={classes.actionBtn}
          size={'small'}
          onClick={() => move(index, index - 1)}
          color={'primary'}
        >
          <Icon className={'icon-arrow-up'} />
        </IconButton>
      </Tooltip>

      <Tooltip title={'Move down'} placement={'top'}>
        <IconButton
          className={classes.actionBtn}
          size={'small'}
          onClick={() => move(index, index + 1)}
          color={'primary'}
        >
          <Icon className={'icon-arrow-down'} />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip title={'Add new before'} placement={'top'}>
        <span>
          <IconButton
            disabled={disablePushInsert}
            className={classes.actionBtn}
            size={'small'}
            onClick={() => {
              insert(
                index,
                new DefaultValuesGenerator(attrData.setAttributes || [], localizations).getValues()
              )
            }}
            color={'primary'}
          >
            <Icon className={'icon-add-above'} />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={'Add new after'} placement={'top'}>
        <span>
          <IconButton
            disabled={disablePushInsert}
            className={classes.actionBtn}
            size={'small'}
            onClick={() => {
              insert(
                index + 1,
                new DefaultValuesGenerator(attrData.setAttributes || [], localizations).getValues()
              )
            }}
            color={'primary'}
          >
            <Icon className={'icon-add-below'} />
          </IconButton>
        </span>
      </Tooltip>
      <Divider orientation="vertical" flexItem />

      <Tooltip title={'Remove'} placement={'top'}>
        <IconButton className={classes.actionBtn} onClick={() => remove(index)} color={'secondary'}>
          <Icon className={'icon-trash'} />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default memo(ActionArea)
