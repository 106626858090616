// Core
import React, { FC, useState } from 'react'
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  IconButton,
  ListSubheader,
  Tooltip,
} from '@material-ui/core'
// Components
import Search from './search'
import { RolesSelect } from './list-item'
// Hooks
import { useListContext } from './context'
// Styles
import { useStyles } from './styles'

type Props = {
  children?: never
}

const HelpTooltip: FC = () => {
  const text = `The list contains roles grouped by role "slug". When you click the "Apply" button, the selected role will be applied to each of the selected sites if it exists.`
  return (
    <Tooltip title={text}>
      <IconButton size="small" color="primary">
        <Icon className="icon-flag" />
      </IconButton>
    </Tooltip>
  )
}

const ListHeader: FC<Props> = () => {
  const classes = useStyles()
  const {
    listProps: { toggleAll, hasSelected },
    rolesData,
    bulkSetRoleBySlug,
  } = useListContext()

  const [selectedRole, setSelectedRole] = useState<string>('none')
  const roles = Object.values(rolesData.bySlug)
  const activeRoleSlug =
    selectedRole === 'none' ? selectedRole : rolesData.byIri[selectedRole]?.slug

  return (
    <div>
      <ListSubheader>Roles</ListSubheader>
      <div className={classes.actionsRoot}>
        <Checkbox checked={hasSelected} edge="start" onChange={() => toggleAll()} />
        <Search />
        {hasSelected && (
          <div className={classes.bulk}>
            <RolesSelect data={roles} value={selectedRole} onChange={setSelectedRole} />
            <HelpTooltip />
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => bulkSetRoleBySlug(activeRoleSlug)}
            >
              Apply
            </Button>
          </div>
        )}
      </div>
      <Divider />
    </div>
  )
}

export default ListHeader
