import { useCallback, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import { FilterValue, Filters, SelectedFilter } from './types'
import { convertFiltersToParams, getConditionDefaultValue, getDefaultsForType } from './utils'

export default function useFilters(): Filters {
  const [filterParams, setFilterParams] = useState({})
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([])

  const setParams = useMemo(() => debounce(setFilterParams, 1000), [])

  useEffect(() => {
    setParams(convertFiltersToParams(selectedFilters))
  }, [selectedFilters, setParams])

  const addFilter = useCallback<Filters['addFilter']>((slug, title, type) => {
    const { defaultCondition, defaultValue } = getDefaultsForType(type)

    const newFiler: SelectedFilter = {
      slug,
      title,
      type,
      condition: defaultCondition,
      value: defaultValue,
    }

    setSelectedFilters((prev) => [...prev, newFiler])
  }, [])

  const removeFilter = useCallback<Filters['removeFilter']>((slug) => {
    setSelectedFilters((prev) => {
      return prev.filter((item) => item.slug !== slug)
    })
  }, [])

  const changeFilterCondition = useCallback<Filters['changeFilterCondition']>((slug, condition) => {
    setSelectedFilters((prev) => {
      return prev.map((filter) => {
        if (filter.slug === slug) {
          const defaultValue = getConditionDefaultValue(condition)
          return { ...filter, condition, value: defaultValue }
        }

        return filter
      })
    })
  }, [])

  const changeFilterValue = useCallback<Filters['changeFilterValue']>((slug, value, range) => {
    setSelectedFilters((prev) => {
      return prev.map((filter) => {
        if (filter.slug === slug) {
          let newValue: FilterValue = value

          if (range && typeof filter.value === 'object') {
            newValue = { ...filter.value, [range]: value }
          }

          return { ...filter, value: newValue }
        }
        return filter
      })
    })
  }, [])

  const clearAllFilters = useCallback(() => {
    setSelectedFilters([])
  }, [])

  const hasSelectedParams = useMemo(() => {
    let has = false

    if (Object.keys(filterParams).length > 0) {
      has = true
    }

    return has
  }, [filterParams])

  return {
    selectedFilters,
    filterParams,
    addFilter,
    changeFilterCondition,
    changeFilterValue,
    removeFilter,
    clearAllFilters,
    hasSelectedParams,
  }
}
