// Core
import React, { FC } from 'react'
import { Icon, IconButton, ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import clsx from 'clsx'
// Types
import { Site } from 'modules/sites/types'
// Styles
import useStyles from './site-item.styles'

type Props = {
  data: Site
  isActive: boolean
  searchTerm?: string
}

const getHighlightedText = (text: string, highlight: string) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}
        >
          {part}
        </span>
      ))}{' '}
    </span>
  )
}

export const SiteItem: FC<Props> = (props) => {
  const { data, isActive, searchTerm = '' } = props
  const classes = useStyles()

  return (
    <MenuItem className={classes.root}>
      <ListItemIcon>
        <Icon className={clsx('icon-home', { [classes.active]: isActive })} />
      </ListItemIcon>
      <Typography
        className={classes.title}
        color="primary"
        component="a"
        href={`/${data.id}`}
        variant="inherit"
      >
        {getHighlightedText(data.name, searchTerm)}
        <Typography component="span" display="block" variant="caption" color="textSecondary">
          {getHighlightedText(data.url, searchTerm)}
        </Typography>
      </Typography>
      <IconButton
        className={classes.newTab}
        component="a"
        href={`/${data.id}`}
        target="_blank"
        size="small"
        aria-label="Open in new tab"
      >
        <Icon className="icon-external-link" />
      </IconButton>
    </MenuItem>
  )
}

export default SiteItem
