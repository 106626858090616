import React, { FC } from 'react'
//
import { FormControl, InputLabel, MenuItem, Select as SelectInput } from '@material-ui/core'
import { Field, FieldProps } from 'formik'
import { WordBreakWrapper } from 'ui'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: any[]
  name: string
  label?: string
  defaultValue?: any
  value?: any
}

const SelectField: FC<TProps> = (props) => {
  const { t } = useTranslation()
  const { data, name, label } = props

  return (
    <Field as="select" name={name}>
      {({ field, meta }: FieldProps) => {
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel id={`field-${name}-label`}>{label || t('labels.set-val')}</InputLabel>
            <SelectInput
              {...field}
              value={field.value || ''}
              error={!!meta.error}
              labelId={`field-${name}-label`}
              label={label || t('labels.set-val')}
              id={`field-${name}-select`}
            >
              {data &&
                data.map((item: any) => (
                  <MenuItem key={Math.random()} value={item.value}>
                    <WordBreakWrapper children={item.text || item.name} />
                  </MenuItem>
                ))}
            </SelectInput>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default SelectField
