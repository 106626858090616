import { AttributeTypesList } from 'common/types'
import { FormAttribute } from './types'
// eslint-disable-next-line import/no-cycle
import { AttributeValue } from './attribute-value'

export class ValuesGenerator {
  getData() {
    return {
      values: this.values,
      [this.repeatKey]: this.setRepeats,
    }
  }

  private values: any

  private setRepeats: any

  constructor(
    private attributesData: FormAttribute[],
    private formValues: any,
    private repeatKey: string = 'setRepeats',
    private skipIds: boolean = false
  ) {
    this.values = []
    this.setRepeats = []
    this.repeatKey = repeatKey

    attributesData.forEach((attrData) => {
      const {
        attribute: {
          attributeType: { type },
        },
        attribute,
      } = attrData
      const isRepeater = type === AttributeTypesList.repeater
      const isGroup = type === AttributeTypesList.group

      if (isRepeater) {
        const repeaterValues = this.formValues[attribute['@id']]?.value || []
        repeaterValues.forEach((item: any, index: number) => {
          const value = new AttributeValue(
            attrData,
            item,
            index,
            this.repeatKey,
            this.skipIds
          ).getValue()
          this.setRepeats.push(value)
        })
      } else if (isGroup) {
        const groupValues = this.formValues[attribute['@id']]
        const values = new ValuesGenerator(
          attrData?.attribute.setAttributes || [],
          groupValues.value,
          this.repeatKey,
          this.skipIds
        ).getData()

        this.setRepeats.push({
          /**
           * must have
           * with repeat attr ID */
          ...(groupValues?.id && !this.skipIds ? { id: groupValues?.id } : null),
          // ...(() => (groupValues?.id ? { id: groupValues?.id } : null))(),
          set: attribute['@id'],
          sortOrder: 0,
          values: values.values,
          [this.repeatKey]: values[this.repeatKey] || [],
        })
      } else {
        const value = new AttributeValue(
          attrData,
          formValues,
          undefined,
          this.repeatKey,
          this.skipIds
        ).getValue()
        if (value !== null && !value) return

        if (Array.isArray(value)) {
          this.values = [...this.values, ...value]
        } else {
          this.values.push(value)
        }
      }
    })
  }
}
