import { Editor } from 'tinymce'
import { snippetDialog } from './dialog-config'
// types
import { GetDataSnippet, SetDataSnippet } from './types'

const snippetClass = 'collapse-snippet'

export const expandedSnippetDialog = (editor: Editor) => {
  const isInsideElement = (editor: Editor, selector: string) => {
    const domEl = editor.selection.getNode()
    return editor.dom.getParent(domEl, selector)
  }

  const getDataSnippet: GetDataSnippet = (snippet) => {
    const expandedText = snippet.getAttribute('data-expanded-text') || ''
    const collaseText = snippet.getAttribute('data-collase-text') || ''
    const expandUp = snippet.getAttribute('data-expand-up') === 'true'
    const expandDefault = snippet.getAttribute('data-expand-default') === 'true'
    const alignment = snippet.getAttribute('data-alignment') || 'left'
    const enableExpanded = snippet.getAttribute('data-enable-expanded') === 'true'
    const enableReadMore = snippet.getAttribute('data-enable-read-more') === 'true'
    const xsOnly = snippet.getAttribute('data-xs-only') === 'true'

    return {
      expandedText,
      collaseText,
      expandUp,
      expandDefault,
      alignment,
      enableExpanded,
      enableReadMore,
      xsOnly,
    }
  }

  const setDataSnippet: SetDataSnippet = (data) => {
    const domEl = editor.selection.getNode()
    const snippet = editor.dom.getParent(domEl, `.${snippetClass}`)

    snippet?.setAttribute('data-expanded-text', data.expandedText)
    snippet?.setAttribute('data-collase-text', data.collaseText)
    snippet?.setAttribute('data-expand-up', data.expandUp.toString())
    snippet?.setAttribute('data-expand-default', data.expandDefault.toString())
    snippet?.setAttribute('data-alignment', data.alignment.toString())
    snippet?.setAttribute('data-enable-expanded', data.enableExpanded.toString())
    snippet?.setAttribute('data-enable-read-more', data.enableReadMore.toString())
    snippet?.setAttribute('data-xs-only', data.xsOnly.toString())
  }

  editor.ui.registry.addButton('snippetSettings', {
    text: 'Collapse snippet',
    icon: 'settings',
    onAction: () => {
      const snippet = editor.dom.getParent(editor.selection.getNode(), `.${snippetClass}`)

      if (snippet) {
        const dialogData = getDataSnippet(snippet)
        const { createExpandedDialog, createReadMoreDialog } = snippetDialog
        const createDialog = dialogData.enableReadMore ? createReadMoreDialog : createExpandedDialog
        // @ts-ignore
        editor.windowManager.open(createDialog(dialogData, setDataSnippet))
      }
    },
    onSetup: (buttonApi: any) => {
      buttonApi.setDisabled(true)
      setTimeout(() => {
        if (isInsideElement(editor, `.${snippetClass}`)) {
          buttonApi.setDisabled(false)
        } else {
          buttonApi.setDisabled(true)
        }
      }, 400)

      editor.on('keyup mouseup focusin focus click', () => {
        setTimeout(() => {
          if (isInsideElement(editor, `.${snippetClass}`)) {
            buttonApi.setDisabled(false)
          } else {
            buttonApi.setDisabled(true)
          }
        }, 0)
      })
      return () => {}
    },
  })
}
