import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Paper, Container, Icon, Typography, Box } from '@material-ui/core'
import useStyles from './dashboard.styles'
import { Permissions, useAppContext } from '../../app'
import clsx from 'clsx'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { useHistory } from 'react-router-dom'
import { NoteAdd } from '@material-ui/icons'

import { useSitesContext } from 'modules/sites'
import { navList } from './navigation-list'

type TProps = {}

const Dashboard: FC<TProps> = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { activeSite } = useSitesContext()

  const {
    actions: { userCan },
  } = useAppContext()

  const createHandler = useCallback(
    (slug) => {
      if (slug === 'media') {
        history.push(`/${slug}`)
      } else {
        history.push(`/${slug}/create`)
      }
    },
    [history]
  )

  const navigationList = useMemo(() => navList(t), [t])

  return (
    <Container className={classes.container}>
      <Typography component="p" className={classes.welcome}>
        {t('labels.welcome')} {activeSite.name}
      </Typography>
      <Box className={classes.box}>
        {navigationList.map(
          (item) =>
            item.show_in_dashboard &&
            userCan(item.slug, Permissions.CREATE, 'system') && (
              <Paper className={classes.paper} key={item.title}>
                <div className={classes.title}>
                  <Icon className={clsx(item.icon, classes.icon)} />
                  <Typography component="p" style={{ padding: '0 10px' }}>
                    {item.title}
                  </Typography>
                </div>
                <div>
                  {userCan(item.slug, Permissions.CREATE, 'system') && (
                    <div
                      className={classes.title}
                      style={{ cursor: 'pointer' }}
                      onClick={() => createHandler(item.slug)}
                    >
                      {item.slug === 'media' ? (
                        <NoteAdd color="primary" />
                      ) : (
                        <AddCircleOutlineIcon color="primary" />
                      )}
                      <Typography component="p" color="primary" style={{ paddingLeft: '5px' }}>
                        {item.slug === 'media' ? t('media.upload-files') : t('list.create')}
                      </Typography>
                    </div>
                  )}
                </div>
              </Paper>
            )
        )}
      </Box>
    </Container>
  )
}

export default Dashboard
