// Core
import React, { FC } from 'react'
import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

type Props = {
  onChange?: (e: { target: { value: string } }) => void
} & Omit<DateTimePickerProps, 'onChange'>

const defaultProps: Partial<DateTimePickerProps> = {
  ampm: false,
  format: 'DD/MM/yyyy HH:mm',
  variant: 'inline',
  inputVariant: 'outlined',
}

const DateTimePicker: FC<Props> = (props) => {
  const { onChange, value } = props

  const changeHandler = (pickerValue: MaterialUiPickersDate) => {
    const value = pickerValue ? pickerValue.toISOString() : ''
    onChange?.({ target: { value } })
  }

  return (
    <MuiDateTimePicker
      {...props}
      {...defaultProps}
      onChange={changeHandler}
      value={value || null}
    />
  )
}

export default DateTimePicker
