import React, { FC } from 'react'
// Redux Hooks
import { useSelector } from 'react-redux'
// Components
import { LayoutContainerSettings, LayoutSectionSettings } from './conponents'
// Store
import {
  activeSectionIdSelector,
  activeContainerIdSelector,
  getActiveContainerSelector,
  getActiveSectionSelector,
} from 'modules/entity-layouts/store'
// Material Components
import { Box } from '@material-ui/core'

type TProps = {}

const SidebarLayout: FC<TProps> = (props) => {
  const activeSectionId = useSelector(activeSectionIdSelector)
  const activeContainerId = useSelector(activeContainerIdSelector)
  const currentSection = useSelector(getActiveSectionSelector(activeSectionId))
  const currentSectionSidebar = currentSection.activeSection?.options.hasSidebar
  const currentSectionSidebarWidth = currentSection.activeSection?.options.sidebarWidth
  const currentSectionSidebarPosition = currentSection.activeSection?.options.sidebarPosition
  const currentContainer = useSelector(
    getActiveContainerSelector(activeSectionId, activeContainerId)
  )
  const currentContainerWidth = currentContainer.activeContainer?.options.width
  const currentContainerColumns = currentContainer.activeContainer?.options.columns
  const currentContainerName = currentContainer.activeContainer?.name
  const currentContainerSlug = currentContainer.activeContainer?.slug

  return (
    <Box>
      {activeSectionId && activeSectionId !== '' && (
        <>
          <LayoutSectionSettings
            sidebarCheck={currentSectionSidebar}
            sidebarWidth={currentSectionSidebarWidth}
            sidebarPosition={currentSectionSidebarPosition}
          />
        </>
      )}
      {activeContainerId && (
        <>
          <LayoutContainerSettings
            setWidth={currentContainerWidth}
            setColumns={currentContainerColumns}
            setName={currentContainerName}
            setSlug={currentContainerSlug}
          />
        </>
      )}
    </Box>
  )
}

export default SidebarLayout
