import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const { contentBg } = defaultThemeColors

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '284px',
      minWidth: '284px',
      maxHeight: '380px',
      minHeight: '380px',
      alignContent: 'flex-start',
      overflowY: 'auto',
    },
    item: {
      border: '1px solid #c4c4c4',
      padding: '10px',
      display: 'flex',
      margin: '5px',
      background: '#f4f6f8',
      borderRadius: '4px',
      cursor: 'pointer',
      '&:hover': {
        background: '#e4e9ec',
      },
    },
    search: {
      background: contentBg,
      width: '100%',
      '&>div': {
        padding: '10px',
        '&:hover': {
          '&:not(.Mui-disabled):before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
          },
        },
        '&:before': {
          borderBottom: '1px solid rgb(196 196 196)',
        },
      },
    },
    icon: {
      cursor: 'pointer',
      width: '48px',
      height: '48px',
      borderRadius: '50%',
      margin: '0 15px 0 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #c4c4c4',
    },
    chooser: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

export default styles
