import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: 300,
      overflowY: 'auto',
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:hover > div': {
        opacity: 1,
      },
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 10,
      opacity: 0,
    },
  })
)

export default styles
