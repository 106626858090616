/**
 * Helper hook for ./use-simultaneously-edit
 * This hook is responsible for refreshing lock data on background
 */
import { useEffect } from 'react'
import { LockService, REFRESH_TIME } from './utils'

type Params = {
  enabled: boolean
  canEdit: boolean
  lockKey: string
  currentUser: any
}

export function useRefreshLock({ enabled, canEdit, lockKey, currentUser }: Params) {
  useEffect(() => {
    if (!enabled) return

    const refreshLock = () => {
      const lockRecord = LockService.generateLockData(lockKey, currentUser)
      LockService.updateLock(lockKey, lockRecord)
    }

    if (!canEdit) return

    const intervalId = setInterval(() => refreshLock(), REFRESH_TIME)

    return () => {
      clearInterval(intervalId)
    }
  }, [canEdit, currentUser, enabled, lockKey])
}
