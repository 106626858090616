// Core
import React, { FC } from 'react'
import { Box } from '@material-ui/core'
// Components
import { SwitcherField } from 'core/form'

const Sidebar: FC = () => {
  return (
    <Box>
      <SwitcherField name="active" label="Activated" />
    </Box>
  )
}

export default Sidebar
