import React, { FC, useEffect, useState } from 'react'
import { httpService } from 'core/data'

type TProps = {
  source: string
  reference?: string | null
  label?: string
  choices?: string[]
}

type TReferenceInput = {
  data: any[]
  dataTr: any[]
  loading: boolean
  error: any | null
} & Omit<TProps, 'reference'>

const initialVal: TReferenceInput = {
  data: [],
  dataTr: [],
  source: '',
  label: 'Name',
  loading: false,
  error: null,
  choices: [],
}

export const ReferenceInputContext = React.createContext<TReferenceInput>(initialVal)

const ReferenceInput: FC<TProps> = (props) => {
  const { reference, source, label, children, choices } = props
  const [data, setData] = useState<any>(null)
  const [dataTr, setDataTr] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (reference) {
      setLoading(true)
      httpService
        .get<any>(`/${reference}`)
        .then((res) => {
          setData(res.data['hydra:member'])
        })
        .catch((e) => {
          setError(e)
          console.error(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (choices) {
      setData(choices)
    }
  }, [reference, choices])

  useEffect(() => {
    if (!data) return

    const resourceBiId: any = {}

    data.forEach((item: any) => {
      resourceBiId[item['@id']] = item
    })
    setDataTr(resourceBiId)
  }, [choices, data])

  if (reference && choices) {
    throw new Error(
      `
       reference && choices can't work both, choose one of them...
      
       Component: <ReferenceInput/>
      `
    )
  }

  return (
    <ReferenceInputContext.Provider
      value={{
        data,
        dataTr,
        source,
        label,
        loading,
        error,
      }}
    >
      {children}
    </ReferenceInputContext.Provider>
  )
}

export default ReferenceInput
