import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent, Tab, Tabs } from '@material-ui/core'
// Types
import { DialogTitle, TabPanel } from 'ui'
import { LocalOptions } from './components/local-options'
import { Attribute } from 'core/types/attribute'
import AttributeForm from '../attribute-form'

type TProps = {
  isOpen: boolean
  attrData: Attribute
  openHandler: () => any
  isNested?: boolean
  parentData?: boolean
  pathToAttribute?: string
}

const ConditionalLogic: FC<TProps> = (props) => {
  const { t } = useTranslation()

  const { isOpen, openHandler, attrData, isNested, parentData, pathToAttribute } = props
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={openHandler}>
      <DialogTitle onClose={openHandler}>
        {t('attrs.name')}: {attrData.name}
      </DialogTitle>
      <Tabs value={activeTab} onChange={(_, val) => setActiveTab(val)} indicatorColor="primary">
        <Tab label="Local settings" />
        <Tab label="Global settings" />
      </Tabs>
      <DialogContent dividers>
        <TabPanel value={activeTab} index={0}>
          <LocalOptions
            attrData={attrData}
            openHandler={openHandler}
            isNested={isNested}
            parentData={parentData}
            pathToAttribute={pathToAttribute}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <AttributeForm attrId={attrData.id as number} onEditSuccess={openHandler} />
        </TabPanel>
      </DialogContent>
    </Dialog>
  )
}

export default ConditionalLogic
