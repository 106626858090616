// eslint-disable-next-line import/no-extraneous-dependencies
import { RawEditorSettings } from 'tinymce'

type Settings = {
  selector?: undefined
  target?: undefined
} & RawEditorSettings

export const toolbarMinimalConfig: Settings = {
  height: 250,
  menubar: false,
  plugins: ['advlist autolink lists link ', 'insertdatetime'],
  toolbar:
    'undo redo | ' +
    'formatselect | ' +
    'bold italic forecolor backcolor |' +
    'alignleft aligncenter ' +
    'alignright alignjustify | ' +
    'bullist numlist outdent indent | ' +
    'removeformat | insertdatetime | help',
  // skin: 'material-classic',
  // content_css: 'material-classic',
  // icons: 'material',
}
