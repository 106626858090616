// Core
import React, { FC, useCallback } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { isAxiosError } from 'axios'
// Components
import { SubmitButton, TextField } from 'core/form'
import ForgotPasswordLink from '../forgot-password-link'
// Types
import { LoginPayload } from 'modules/auth/types'
// Styles
import useStyles from './login-form.styles'

type Props = {
  onSubmit: (data: LoginPayload) => void
  onResetClick: (currentEmail: string) => void
}

const initialValues: LoginPayload = {
  email: '',
  password: '',
}

const ValidationSchema = yup.object<LoginPayload>({
  email: yup.string().email().required(),
  password: yup.string().required().min(3),
})

const LoginForm: FC<Props> = ({ onSubmit, onResetClick }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const submitHandler = useCallback(
    async (values: LoginPayload, formHelpers: FormikHelpers<LoginPayload>) => {
      try {
        await onSubmit(values)
      } catch (e) {
        if (isAxiosError(e)) {
          formHelpers.setFieldError('email', e.response?.data?.message || 'Something went wrong')
        }
      }
    },
    [onSubmit]
  )

  return (
    <Formik
      onSubmit={submitHandler}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      enableReinitialize
    >
      {({ values }) => (
        <Form className={classes.form}>
          <TextField
            name="email"
            placeholder={t('login.email-field')}
            label={t('login.email-field')}
            className={classes.formControl}
          />
          <TextField
            name="password"
            type="password"
            placeholder={t('login.pass-field')}
            label={t('login.pass-field')}
            className={classes.formControl}
          />
          <ForgotPasswordLink onResetClick={() => onResetClick(values.email)} />
          <Box mt={2}>
            <SubmitButton title={t('global.log-in')} />
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
