import pickBy from 'lodash.pickby'
import { parse } from 'query-string'

const validQueryParams = ['page', 'sort', 'order', 'filters']

const parseObject = (query: any, field: any) => {
  if (query[field] && typeof query[field] === 'string') {
    try {
      query[field] = JSON.parse(query[field])
    } catch (err) {
      delete query[field]
    }
  }
}

const getNumberOrDefault = (possibleNumber: string | number | undefined, defaultValue: number) => {
  const parsedNumber =
    typeof possibleNumber === 'string' ? parseInt(possibleNumber, 10) : possibleNumber
  return isNaN(parsedNumber as number) ? defaultValue : parsedNumber
}

const hasCustomParams = (params: any) => {
  return params && params.filters && (Object.keys(params.filters).length > 0 || params.sort != null)
}

export const parseQueryFromLocation = ({ search }: any) => {
  const query = pickBy(parse(search), (v, k) => validQueryParams.indexOf(k) !== -1)

  parseObject(query, 'filters')
  parseObject(query, 'sort')
  return query
}

export const getQuery = ({ queryFromLocation, params }: any) => {
  const query: any =
    Object.keys(queryFromLocation).length > 0
      ? queryFromLocation
      : hasCustomParams(params)
      ? { ...params }
      : { filters: {} }

  return {
    ...query,
    pagination: { page: getNumberOrDefault(query.page, 1), perPage: 30 },
  }
}
