import { Box } from '@material-ui/core'
import React, { FC } from 'react'

export const ActionsCellWrapper: FC = ({ children }) => {
  return (
    <Box display="inline-flex" py={1} style={{ gap: 8 }}>
      {children}
    </Box>
  )
}
