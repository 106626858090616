// Core
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'
import { useField } from 'formik'
import { isNullable } from 'core/utils'

type TProps = {
  label: string
  name: string
  isChecked?: boolean
  classname?: any
  disabled?: boolean
}

const SwitcherField: FC<TProps> = (props) => {
  const { name, isChecked = false, label, classname, disabled } = props
  const [{ value }, , { setValue }] = useField(name)

  /**
   * Set initial value in formik state
   */
  useEffect(() => {
    if (isNullable(value)) setValue(isChecked)
  }, [value, setValue, isChecked])

  /**
   * Get initial value of switcher
   */
  const getInitialValue = useMemo(() => {
    if (isNullable(value)) return isChecked
    return Boolean(value)
  }, [isChecked, value])

  /**
   * Custom switcher change handler
   */
  const handleChange = useCallback(() => {
    setValue(!value)
  }, [value, setValue])

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={disabled}
          className={classname}
          checked={getInitialValue}
          onChange={handleChange}
          color="primary"
        />
      }
      label={label}
    />
  )
}

export default SwitcherField
