export const booleanVisualisation = (t: any) => {
  return [
    {
      value: 'checkbox',
      text: t('attrs.checkbox'),
    },
    {
      value: 'switch',
      text: t('attrs.switch'),
    },
  ]
}
export const selectVisualisation = (t: any) => {
  return [
    {
      value: 'radio_button',
      text: t('attrs.r-button'),
    },
    {
      value: 'select',
      text: t('attrs.select'),
    },
  ]
}
