// Core
import { useCallback } from 'react'
import { useMutation } from 'react-query'
// Hooks
import { useInitData } from './use-init-data'
import { useInitialValues } from './use-initial-values'
import { usePageBuilderContext } from 'modules/new-entity/context'
import { useNotify } from 'core/hooks'
import { useRevalidate } from 'core/hooks/use-revalidate'
// Utils
import { getIdFromIri } from 'core/utils'
// Services
import { httpService } from 'core/data'
import { EntityService } from '../utils'
// Types
import { IEntityWidget } from '../types'

export const useWidgetController = (widgetObject: IEntityWidget) => {
  const notify = useNotify()
  const {
    actions: { getWidgetRefData, setGlobalWidget, toggleWidget },
  } = usePageBuilderContext()
  const typeId = widgetObject.widgetType.id
  const isCollection = widgetObject?.widgetType.slug === 'global_widget_collection'

  const { data, isLoading, isFetching } = useInitData(widgetObject.id, typeId, 'widget', {
    initialData: widgetObject,
  })

  const { initialValues, validationSchema } = useInitialValues(data?.entity, data)

  const { revalidateEntity } = useRevalidate()

  const globalWidgetM = useMutation<any, any, any>(
    ({ data, isEdit }) => {
      const globalWidgetId = getIdFromIri(widgetObject.widget!)
      const method = isEdit ? 'put' : 'post'
      const path = isEdit ? `/widgets/${globalWidgetId}` : '/widgets'
      return httpService[method](path, data)
    },
    {
      onSuccess: async ({ data }, { isEdit }) => {
        if (isEdit) {
          const id = +getIdFromIri(data['@id'])
          await revalidateEntity(id, 'entity', null)
        }
      },
    }
  )

  const saveGlobalWidget = useCallback(
    async (action: 'create' | 'edit') => {
      try {
        /**
         * Get widget ref data from global collection
         */
        const widgetRefData = getWidgetRefData(widgetObject.id)
        if (!widgetRefData) return
        /**
         * Validate form on client
         */
        await EntityService.validateForm(widgetRefData.formRef)

        /**
         * Generate values for global widget (without values ids)
         */
        const values = EntityService.generateValues(
          widgetRefData.formRef.values,
          widgetRefData.attributes,
          false,
          true
        )

        /**
         * Create new widget object
         */
        const newWidget = {
          widgetType: widgetObject.widgetType['@id'],
          localizations: [],
          ...values,
        }

        /**
         * Send request to create new global widget and set is to local
         */
        const isEdit = action === 'edit'
        const widgetRes = await globalWidgetM.mutateAsync({ data: newWidget, isEdit })
        const newWidgetId = widgetRes.data['@id']

        setGlobalWidget(widgetObject.id, newWidgetId)
        // Collapse widget to prevent invalidation issue
        toggleWidget(widgetObject.options.container, widgetObject.id)

        isEdit
          ? notify('The global widget is updated', { type: 'success' })
          : notify('The widget is stored as global and installed locally', { type: 'success' })
      } catch (e) {
        if (e?.type === 'validation') {
          notify('Validation error', { type: 'error' })
        } else {
          console.dir(e)
        }
      }
    },
    [getWidgetRefData, globalWidgetM, notify, setGlobalWidget, toggleWidget, widgetObject]
  )

  const global = {
    saveGlobalWidget,
    isLoading: globalWidgetM.isLoading,
  }

  return {
    isLoading,
    isFetching,
    attributes: data?.attributes || [],
    initialValues,
    validationSchema,
    isCollection,
    typeId,
    entityType: data?.entityType,
    widgetData: data?.entity,
    global,
  }
}
