import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const chipsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(0.5, 0),
      '& > *': {
        margin: '1px 4px 1px 0',
      },
      '& .MuiChip-root': {
        borderRadius: theme.spacing(0.5),
        maxWidth: '280px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  })
)

export default chipsStyles
