// TODO: list provider issue
import React, { FC } from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@material-ui/core'
// Core
import { FormLang, LanguageProvider } from 'core/data'
// Components
import { CreateFields } from '../index'
import { ModalFooter } from 'common/components'
// Styles
import clsx from 'clsx'
import useStyles from './main-form.styles'
import { useSubmitForm } from 'common/hooks/attribute/use-submit-form'
import Portal from '../../../portal'
import NestedForm from '../nested-form'
import { useSelector } from 'react-redux'
import { attributesNestedFormIsOpen } from '../../../../store'
import { useTranslation } from 'react-i18next'
import { Attribute } from 'core/types/attribute'

type TProps = {
  id: string
  setCreatedAttributeAction?: (data: any) => void
  onSubmit?: (values: Attribute) => void
  setRef?: (node: any) => void
  initialData: any
  isEdit: boolean
}

const MainForm: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { id, setCreatedAttributeAction, setRef, initialData, isEdit, onSubmit } = props
  const onSubmitMainForm = useSubmitForm(setCreatedAttributeAction)
  const onSubmitNestedForm = useSubmitForm()
  const nestedFormIsOpen = useSelector(attributesNestedFormIsOpen)

  return (
    <div id={id} className={classes.formsWrapper}>
      <LanguageProvider>
        <Formik
          innerRef={setRef}
          initialValues={initialData}
          onSubmit={onSubmit ?? onSubmitMainForm('default')}
          enableReinitialize
        >
          {(formProps) => (
            <Form
              className={clsx(classes.mainForm, {
                [classes.hidden]: nestedFormIsOpen,
              })}
            >
              <FormLang />
              <div style={{ minHeight: 'calc(100vh - 100px)' }}>
                <CreateFields isEdit={isEdit} />
              </div>
              <ModalFooter>
                <Button
                  autoFocus
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginLeft: 'auto' }}
                >
                  {isEdit ? 'Save' : t('attrs.create')}
                </Button>
              </ModalFooter>

              <Portal
                selector={'create-attribute-form'}
                component={<NestedForm submitHandler={onSubmitNestedForm('repeater', formProps)} />}
              />
            </Form>
          )}
        </Formik>
      </LanguageProvider>
    </div>
  )
}

export default MainForm
