// Core
import React, { FC, useCallback, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useField } from 'formik'
import debounce from 'lodash.debounce'
// Hooks
import { useLanguage } from 'core/data'
import { useListController } from 'core/data'
// Services
import { httpService } from 'core/data'
// Styles
import tagInputStyles from './tag-input.styles'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: object
}

const TagInput: FC<TProps> = (props) => {
  const classes = tagInputStyles()
  const { t } = useTranslation()

  const { data, isLoading, listParams } = useListController(
    {
      name: 'attribute_groups',
    },
    {
      filterChangeUrl: false,
    }
  )

  const { currentLang } = useLanguage()
  const [{ value: tagsValue }, , { setValue: tagSetValue }] = useField('attributeGroups')
  const [{ value: cacheValue }, , { setValue: cacheSetValue }] = useField('attribute_groups_cache')

  const debouncedCallback = debounce((searchValue: string) => {
    listParams.methods.setFilter('attribute_groups', searchValue)
  }, 1000)

  const debounced = useCallback(debouncedCallback, [debouncedCallback])

  useEffect(() => {
    if (!tagsValue && !cacheValue) {
      tagSetValue([])
      cacheSetValue([])
    }
  }, [tagsValue, cacheValue, tagSetValue, cacheSetValue])

  const onInputChangeHandler = useCallback(
    (e: any) => {
      if (!e || !e.target || !e.target.value) return
      debounced(e.target.value)
    },
    [debounced]
  )

  const createHandler = useCallback(
    (event) => {
      event.persist()
      event.stopPropagation()
      if (event.key === 'Enter' && event.target.value !== '') {
        event.preventDefault()
        const tagData: any = {
          translations: {
            [`${currentLang.code}`]: {
              name: `${event.target.value}`,
            },
          },
        }

        httpService
          .post('/attribute_groups', tagData)
          .then((resp: any) => {})
          .finally(() => {
            listParams.methods.setFilter('attributeGroups', event.target.value)
          })
      }
    },
    // eslint-disable-next-line
    [currentLang.code, httpService]
  )

  const onSelectHandler = useCallback(
    (_value) => {
      const getIRIfromValue = _value.map((item: any) => item['@id'])

      tagSetValue(getIRIfromValue)
      cacheSetValue(_value)
    },
    [tagSetValue, cacheSetValue]
  )

  return (
    <Autocomplete
      loading={isLoading}
      multiple
      value={cacheValue || []}
      options={data || []}
      filterSelectedOptions={true}
      getOptionLabel={(option: any) => option.name}
      getOptionSelected={(option, value) => option.name === value.name}
      onInputChange={onInputChangeHandler}
      className={classes.root}
      renderInput={(_params) => {
        return (
          <TextField
            {..._params}
            name={'attributeGroups'}
            variant="outlined"
            label={t('attrs.tags')}
            placeholder={t('attrs.tags-hint')}
            onKeyDown={createHandler}
          />
        )
      }}
      onChange={(e, selectValue) => {
        onSelectHandler(selectValue)
      }}
    />
  )
}

export default TagInput
