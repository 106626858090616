// Core
import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from '@material-ui/core'
// Components
import { WordBreakWrapper } from 'ui'
// Hooks
import { useGetActiveSite } from 'modules/sites'
import { useGetLocalizations } from 'modules/translations/hooks'
import useFieldController from 'core/form/hooks/use-field-controller'

type Props = {
  name: string
  label: string
  disabled?: boolean
}

const LocalizationSelect: FC<Props> = (props) => {
  const { name, label, disabled } = props

  const [field, meta, helpers] = useFieldController(name)

  const changeHandler = useCallback(
    (e: ChangeEvent<{ value: unknown }>) => {
      helpers.setValue(e.target.value)
    },
    [helpers]
  )

  const activeSite = useGetActiveSite()

  const { data: localizations, isLoading } = useGetLocalizations(
    activeSite ? [activeSite.defaultLocale, ...activeSite.additionalLocalizations] : []
  )

  const options = useMemo(
    () =>
      localizations
        ?.map((localization) => {
          const isDefault = activeSite?.defaultLocale === localization['@id']
          return {
            label: `${localization.code} ${localization.name}`,
            value: isDefault ? '' : localization.code,
            code: localization.code,
            isDefault,
          }
        })
        .sort((a, b) => {
          if (a.isDefault && !b.isDefault) return -1
          if (!a.isDefault && b.isDefault) return 1
          return 0
        }),
    [activeSite, localizations]
  )

  if (!activeSite?.additionalLocalizations.length) {
    return null
  }

  return (
    <FormControl fullWidth variant="outlined" error={meta.hasError}>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Select
        {...field}
        value={field.value || ''}
        onChange={changeHandler}
        label={label}
        disabled={disabled || isLoading}
        displayEmpty
        // @ts-expect-error show shrinked label
        notched
      >
        {isLoading ? (
          <MenuItem value={field.value || ''}>
            <LinearProgress
              style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: 40 }}
            />
          </MenuItem>
        ) : (
          options?.map((item) => (
            <MenuItem value={item.value} key={item.code}>
              <WordBreakWrapper children={item.label} />
            </MenuItem>
          ))
        )}
      </Select>
      {meta.hasError && <FormHelperText>{meta.errorMsg}</FormHelperText>}
    </FormControl>
  )
}

export default LocalizationSelect
