/**
 * Helper hook for ./use-simultaneously-edit
 * This hook is responsible for catching cases when user leave edit
 * in this case lock should be deleted
 */
import { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppContext } from 'core/app'
import { LockService } from './utils'

type Params = {
  enabled: boolean
  canEdit: boolean
  lockKey: string
}

export function useDeleteLock({ enabled, canEdit, lockKey }: Params) {
  const {
    actions: { setLogoutAction },
  } = useAppContext()
  const history = useHistory()
  const location = useLocation()
  const pathnameRef = useRef(location.pathname)

  /**
   * Catch when user close tab
   */
  useEffect(() => {
    if (!enabled || !canEdit) return

    const handler = () => {
      LockService.deleteLock(lockKey)
    }

    window.addEventListener('beforeunload', handler)

    setLogoutAction(async () => {
      await LockService.deleteLock(lockKey)
    })

    return () => {
      window.removeEventListener('beforeunload', handler)
      setLogoutAction(null)
    }
  }, [canEdit, enabled, lockKey, setLogoutAction])

  /**
   * Catch when user leave page
   */
  useEffect(() => {
    const unsubscribe = history.listen((loc) => {
      const pageChanged = loc.pathname !== pathnameRef.current

      if (pageChanged && canEdit) {
        LockService.deleteLock(lockKey)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [history, canEdit, lockKey])
}
