import { ActionTypes, AttributesState } from './types'
import produce from 'immer'
import { AttributesModalActions } from './actions'

const initialState: AttributesState = {
  isOpen: false,
  type: null,
  nestedForm: {
    isOpen: false,
  },
}

const reducer = (state: AttributesState = initialState, action: AttributesModalActions) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.ATTRIBUTES_MODAL_OPEN: {
        draft.isOpen = action.payload.isOpen
        break
      }

      case ActionTypes.SHOW_NESTED_FORM: {
        const { isOpen } = action.payload

        draft.nestedForm.isOpen = isOpen
        break
      }

      default:
        break
    }
  })
}

export default reducer
