export type PermissionsMap = {
  [slug: string]: Permissions[]
}

export enum Permissions {
  VIEW = 'VIEW',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  PUBLISH = 'PUBLISH',
}
