import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 300,
      border: '#546E7A 2px dashed',
      backgroundColor: '#F5F5F5',
      borderRadius: 4,
      cursor: 'pointer',
      transition: 'box-shadow .2s ease',
      outline: 'none',
      padding: theme.spacing(2),
      '&.active': {
        boxShadow: 'inset 0px 0px 24px #536DFE;',
      },
      '& p': {
        whiteSpace: 'normal',
        textAlign: 'center',
      },
    },
    icon: {
      fontSize: 30,
    },
    text: {
      fontSize: 16,
      color: '#546E7A',
      marginTop: 24,
    },
    placeholder: {
      marginTop: 0,
    },
  })
)

export default styles
