// Core
import React, { FC } from 'react'
import { capitalize, Select, TableCell } from '@material-ui/core'
// Utils
import { Frequency } from 'modules/sitemap-settings/utils'
// Types
import { CellProps } from '../cell-props'

const FrequencyCell: FC<CellProps> = (props) => {
  const { isEdit, data, form, name } = props

  return (
    <TableCell size="small" width="15%">
      {!isEdit ? (
        capitalize(data.frequency)
      ) : (
        <Select native value={form.values.frequency} name={name} onChange={form.handleChange}>
          {Frequency.map((item) => (
            <option key={item} value={item}>
              {capitalize(item)}
            </option>
          ))}
        </Select>
      )}
    </TableCell>
  )
}

export default FrequencyCell
