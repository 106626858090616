import React, { FC, useState } from 'react'
import { TreeItem, TreeView } from '@material-ui/lab'
import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { useRelationsSelectContext } from 'ui/relations-select/context'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { SiteItem } from './site-item'
import { HydraResponse } from 'core/types'

type SearchResult = {
  id: string
  name: string
  typeId: number
  count: number
}

type SearchResponse = HydraResponse<SearchResult>

type Props = {
  networkIri: string
}

export const List: FC<Props> = ({ networkIri }) => {
  const { search, selectHandler, value } = useRelationsSelectContext()

  const [selected, setSelected] = useState<string[]>([])

  const q = useQuery(['entities', networkIri, search], () => {
    return httpService
      .get<SearchResponse>('/entity_counts', {
        params: {
          groupBy: 'site',
          network: networkIri,
          original: true,
          filter: {
            _name: {
              contain: search,
            },
            status: {
              equal: 'publish',
            },
          },
        },
      })
      .then((res) => res.data['hydra:member'])
  })

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds)
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={selected}
      onNodeSelect={handleSelect}
      style={{ padding: '0 16px' }}
    >
      {q.data?.map((item) => (
        <TreeItem
          key={item.typeId}
          nodeId={item.typeId.toString()}
          label={`${item.name} (${item.count})`}
        >
          <SiteItem siteId={item.typeId} onSelect={selectHandler} value={value} search={search} />
        </TreeItem>
      ))}
    </TreeView>
  )
}
