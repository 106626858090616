import React, { FC, SyntheticEvent } from 'react'
import { Grid, Box, Typography, makeStyles } from '@material-ui/core'
import { MediaListItem } from 'modules/media/types'
import { MediaIcon } from '../media-icon'

const useStyles = makeStyles(() => ({
  folderTitle: {
    all: 'unset',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

type Props = {
  media: MediaListItem
  onFolderTitleClick?: (folderId: number) => void
}

export const NameCell: FC<Props> = ({ media, onFolderTitleClick }) => {
  const classes = useStyles()

  const titleProps =
    media.type === 'folder'
      ? {
          className: classes.folderTitle,
          component: 'button',
          onClick: (e: SyntheticEvent) => {
            e.stopPropagation()
            onFolderTitleClick?.(media.id)
          },
        }
      : {}

  return (
    <Grid container alignItems="center" spacing={1} wrap="nowrap">
      <Grid item>
        <MediaIcon media={media} size="list" />
      </Grid>
      <Grid item>
        <Box maxWidth={200}>
          <Typography variant="body2" noWrap {...titleProps}>
            {media.name}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
