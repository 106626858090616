// Core
import React, { FC } from 'react'
import { Button, ButtonProps } from '@material-ui/core'
import { Add } from '@material-ui/icons'

type Props = {
  text: string
} & ButtonProps

const AddButton: FC<Props> = ({ text, ...rest }) => (
  <Button {...rest} variant="contained" color="primary" startIcon={<Add />} size="small">
    {text}
  </Button>
)

export default AddButton
