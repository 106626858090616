// Core
import React, { FC, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { formatInTimeZone } from 'date-fns-tz'
// hooks
import { useGetActiveSite } from 'modules/sites'

type Props = {
  lastUpdatedAt?: string
  lastPublishedAt?: string
  originalCreatedAt?: string
}

type Data = {
  updatedAt: null | string
  publishedAt: null | string
}

const UpdatedDates: FC<Props> = ({ lastUpdatedAt, lastPublishedAt, originalCreatedAt }) => {
  const site = useGetActiveSite()

  const data = useMemo(() => {
    const data: Data = {
      updatedAt: null,
      publishedAt: null,
    }

    if (!site) return data

    if (lastUpdatedAt) {
      data.updatedAt = formatInTimeZone(
        new Date(lastUpdatedAt),
        site.timezone,
        `${site.dateFormat} ${site.timeFormat}`
      )
    }

    if (lastPublishedAt && lastPublishedAt !== originalCreatedAt) {
      data.publishedAt = formatInTimeZone(
        new Date(lastPublishedAt),
        site.timezone,
        `${site.dateFormat} ${site.timeFormat}`
      )
    }

    return data
  }, [lastPublishedAt, lastUpdatedAt, originalCreatedAt, site])

  if (!data.updatedAt && !data.publishedAt) return null

  return (
    <Box
      width="100%"
      pt={1}
      mt={1}
      display="flex"
      flexDirection="column"
      gridRowGap={4}
      style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}
    >
      {data.updatedAt && (
        <Box>
          Last Updated: <b>{data.updatedAt}</b>
        </Box>
      )}
      {data.publishedAt && (
        <Box>
          Last Published: <b>{data.publishedAt}</b>
        </Box>
      )}
    </Box>
  )
}
export default UpdatedDates
