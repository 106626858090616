// Core
import React, { FC } from 'react'
// Core Components
import { Resource } from 'core/data'
// Components for resource
import { WidgetTypeCreate, WidgetTypeEdit, WidgetTypeList } from '../../components'
import { TypeResourceProvider } from '../../context'

type TypeResource = {
  path: string
  attrsName: string
  children?: never
}

const TypeResourceComponent: FC<TypeResource> = (props) => {
  return (
    <TypeResourceProvider
      value={{
        options: {
          attrsName: props.attrsName,
          // Get resource name from path
          resourceName: props.path.slice(1),
        },
      }}
    >
      <Resource
        create={WidgetTypeCreate}
        edit={WidgetTypeEdit}
        list={WidgetTypeList}
        path={props.path}
      />
    </TypeResourceProvider>
  )
}

export default TypeResourceComponent
