// Core
import React, { FC, useCallback, useState } from 'react'
// Context
import { ListDataProvider } from 'core/data'
// Components
import { AttributeFilter, GlobalAttributesList, Search } from './components'
import { ModalFooter } from '../index'
import { Pagination } from 'core/components'
// Hooks
import { useListController } from 'core/data'

type TProps = {
  value?: string
  onSelect?: (attributeIri: string) => void
  footerButton?: JSX.Element
}

const AttributeSelect: FC<TProps> = (props) => {
  const { value = '', onSelect, footerButton } = props
  const [selectedId, setSelectedId] = useState(value)

  const resource = {
    name: 'attributes',
  }

  const listController = useListController(resource, {
    filterChangeUrl: false,
  })

  const onChange = useCallback(
    (id: string) => () => {
      setSelectedId(id)
      if (onSelect) onSelect(id)
    },
    [onSelect]
  )

  return (
    <ListDataProvider controllerProps={listController}>
      <div style={{ minHeight: 'calc(100vh - 206px)' }}>
        <Search />
        <AttributeFilter />
        <GlobalAttributesList selectedId={selectedId} onChange={onChange} />
      </div>

      <ModalFooter>
        <Pagination />
        {footerButton}
      </ModalFooter>
    </ListDataProvider>
  )
}

export default AttributeSelect
