// Core
import React, { FC } from 'react'
// Context
import { ListContext } from './context'
// Hooks
import useController from './use-controller'
// Types
import List from './list'
import { Skeleton } from '@material-ui/lab'

type Props = {
  children?: never
}

const UserRoles: FC<Props> = () => {
  const { dataQ, siteRoleMap, setSiteRole, listProps, bulkSetRoleBySlug } = useController()

  if (dataQ.isLoading)
    return (
      <div>
        <Skeleton variant="rect" height={60} style={{ marginBottom: 15 }} />
        <Skeleton variant="rect" height={60} style={{ marginBottom: 15 }} />
        <Skeleton variant="rect" height={60} style={{ marginBottom: 15 }} />
        <Skeleton variant="rect" height={60} style={{ marginBottom: 15 }} />
      </div>
    )
  if (!dataQ.data) return <div>No Data</div>

  const {
    data: { rolesData },
  } = dataQ

  return (
    <ListContext.Provider
      value={{ siteRoleMap, rolesData, setSiteRole, listProps, bulkSetRoleBySlug }}
    >
      <List />
    </ListContext.Provider>
  )
}

export default UserRoles
