// Core
import React, { FC, useMemo, useState } from 'react'
// Context
import { ConfirmationContext } from '../../context'

type Props = {}

export const ConfirmationProvider: FC<Props> = ({ children }) => {
  const [state, setConfirmation] = useState({
    open: false,
    title: '',
    content: '',
    onSuccess: () => null,
  })

  const value = useMemo(
    () => ({
      ...state,
      setConfirmation,
    }),
    [state]
  )

  return <ConfirmationContext.Provider value={value}>{children}</ConfirmationContext.Provider>
}
