import React, { useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useConfirmation } from '../../hooks'
import { useTranslation } from 'react-i18next'

export const ConfirmationWindow = () => {
  const { open, title, content, note, onSuccess, setConfirmation } = useConfirmation()
  const { t } = useTranslation()

  const handleClose = useCallback(
    (type?: 'success') => {
      if (type === 'success') {
        onSuccess()
      }
      setConfirmation({
        open: false,
        title: '',
        content: '',
        onSuccess: () => null,
      })
    },
    [onSuccess, setConfirmation]
  )

  return (
    <div>
      <Dialog open={open} onClose={() => handleClose()} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
          {note && <DialogContentText style={{ color: 'red' }}>{note}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color="primary">
            {t('notify.disagree')}
          </Button>
          <Button onClick={() => handleClose('success')} color="secondary" autoFocus>
            {t('notify.agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
