// Core
import React, { FC } from 'react'
import * as Yup from 'yup'
// Components
import { Edit } from 'core/data'
import CrudForm from '../crud-form'
// Types
import { ResourceProps } from 'core/data'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
})

const NetworkEdit: FC<ResourceProps> = (props) => {
  return <Edit validationSchema={validationSchema} {...props} crudForm={CrudForm} />
}

export default NetworkEdit
