// Core
import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { LinearProgress } from '@material-ui/core'
// Utils/Services
import { httpService } from 'core/data'
// Types
import { Entity } from 'common/types'
import { FilterValue } from '../types'

type Props = {
  value: FilterValue
}

const ContainReference: FC<Props> = ({ value }) => {
  const { data, isLoading } = useQuery(
    ['entity', value],
    () => {
      return httpService.get<Entity>(`/entities/${value}`).then((res) => res.data)
    },
    {
      enabled: Boolean(value),
    }
  )

  if (isLoading) {
    return <LinearProgress />
  }

  if (!data) return null

  return <b>{data.name}</b>
}

export default ContainReference
