import React, { ChangeEvent, FC, useCallback, useState, useMemo } from 'react'
import { Box, Button, FormControl, Icon, Input, InputAdornment, Popover } from '@material-ui/core'
import { iconsList } from './utils'
import useStyle from './icon-picker.style'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { Search } from '@material-ui/icons'

const IconPicker: FC<any> = () => {
  const classes = useStyle()
  const icons = iconsList
  const { t } = useTranslation()
  const [{ value }, , { setValue }] = useField('options.icon')
  const [searchValue, setSearchValue] = useState('')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const openModal = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setSearchValue('')
  }, [])

  const selectIcon = useCallback(
    (icon: string) => () => {
      setValue(icon)
      setAnchorEl(null)
      setSearchValue('')
    },
    [setValue]
  )

  const changeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }, [])

  const newData = useMemo(() => {
    return icons.filter((item: string) => item.includes(searchValue))
  }, [icons, searchValue])

  return (
    <>
      <Box className={classes.chooser}>
        {value && (
          <Box className={classes.icon} onClick={openModal}>
            <Icon className={value} />
          </Box>
        )}
        <Button variant="contained" onClick={openModal}>
          {t('labels.select-icon')}
        </Button>
      </Box>

      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <FormControl className={classes.search}>
          <Input
            value={searchValue}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            onChange={changeHandler}
          />
        </FormControl>
        <Box className={classes.root}>
          {newData.map((icon: string, index: number) => {
            return (
              <Box className={classes.item} key={`${icon}-${index}`} onClick={selectIcon(icon)}>
                <Icon className={icon} key={icon} />
              </Box>
            )
          })}
        </Box>
      </Popover>
    </>
  )
}

export default IconPicker
