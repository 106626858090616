// Core
import React, { FC, memo } from 'react'
import { LinearProgress } from '@material-ui/core'
import { useGetOne } from 'core/data'
// Utils
import { getIdFromIri } from 'core/utils'
// Styles
import useStyles from './reference-field.styles'

type Props = {
  rowData: any
  field: any
  refParams: { field: string; source: string }
}

const ReferenceField: FC<Props> = (props) => {
  const { rowData, refParams, field } = props
  const classes = useStyles()

  const { data, loading } = useGetOne(refParams.source, getIdFromIri(rowData[field]))

  return (
    <div className={classes.title}>
      {loading && <LinearProgress color="primary" />}
      {!loading && data && <span className={classes.title}>{data[refParams.field]}</span>}
      {!loading && !data && <span>Error</span>}
    </div>
  )
}

export default memo(ReferenceField)
