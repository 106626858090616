// Core
import React, { FC, useState, useCallback, useMemo } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Typography, Dialog, DialogContent, IconButton, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
// Components
import { SubmitButton, TextField } from 'core/form'
// Types
import { Login2FAPayload, MfaData } from 'modules/auth/types'
// Styles
import useStyles from './mfa-modal.styles'
import { isAxiosError } from 'axios'

enum ErrorTypeEnum {
  WRONG_TOKEN = 'wrongToken',
  WRONG_CODE = 'wrongCode',
  EXCEEDED_LOGIN_ATTEMPTS = 'exceededLoginAttempts',
}

type FormValues = Pick<Login2FAPayload, 'authCode'>

const ValidationSchema = Yup.object<FormValues>({
  authCode: Yup.string().length(6).required(),
})

type Props = {
  isOpen: boolean
  onClose: () => void
  mfaData: MfaData
  onSubmit: (authCode: string) => void
}

const MfaModal: FC<Props> = ({ isOpen, onClose, mfaData, onSubmit }) => {
  const initialValues: FormValues = {
    authCode: '',
  }

  const [error, setError] = useState<{
    errorType: ErrorTypeEnum | null
    errorMessage: string | null
  }>({
    errorType: null,
    errorMessage: null,
  })

  const classes = useStyles()
  const { t } = useTranslation()
  const { firstLoginAfterReset } = mfaData

  const description = useMemo(
    () => (firstLoginAfterReset ? t('login.mfa-desc-init') : t('login.mfa-desc')),
    [firstLoginAfterReset, t]
  )

  const submitHandler = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      try {
        await onSubmit(values.authCode)
      } catch (e) {
        if (isAxiosError(e)) {
          setError({
            errorType: e.response?.data.errorType,
            errorMessage: e.response?.data.message,
          })
          formikHelpers.setFieldError('authCode', e.response?.data?.message || t('login.mfa-error'))
        }
      }
    },
    [onSubmit, t]
  )

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: (field: string, value: string) => void
    ) => {
      const { value } = e.target
      const numericValue = value?.replace(/\D/, '').slice(0, 6)
      setFieldValue('authCode', numericValue)
    },
    []
  )

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <Typography component="h4" variant="h6" className={classes.title}>
          {t('login.mfa-title')}
        </Typography>
        {error.errorType === ErrorTypeEnum.WRONG_TOKEN ? (
          <Box className={classes.sessionError}>
            <Typography className={classes.sessionErrorMessage}>{error.errorMessage}</Typography>
          </Box>
        ) : (
          <>
            <Typography className={classes.description}>{description}</Typography>
            <Formik
              onSubmit={submitHandler}
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              enableReinitialize
            >
              {({ setFieldValue }) => (
                <Form className={classes.form}>
                  <TextField
                    name="authCode"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, setFieldValue)
                    }
                    FormHelperTextProps={{ className: classes.error }}
                    autoComplete="off"
                    disabled={error.errorType === ErrorTypeEnum.EXCEEDED_LOGIN_ATTEMPTS}
                    autoFocus
                  />
                  <SubmitButton
                    className={classes.submit}
                    title={t('login.mfa-verify')}
                    disabled={error.errorType === ErrorTypeEnum.EXCEEDED_LOGIN_ATTEMPTS}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
      </DialogContent>
      <IconButton color="inherit" aria-label="close" className={classes.closeBtn} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Dialog>
  )
}

export default MfaModal
