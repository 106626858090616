import { RawEditorSettings } from 'tinymce'

type Settings = {
  selector?: undefined
  target?: undefined
} & RawEditorSettings

export const toolbarMainConfig: Settings = {
  height: 500,
  valid_elements: '*[*]',
  paste_as_text: true,
  media_live_embeds: false,
  plugins:
    'wordcount lists advlist anchor link image imagetools media paste table insertdatetime help code code codesample',
  menubar: 'file edit insert view format table tools help',
  toolbar: [
    `undo redo | formatselect | table media image mediaLibrary | numlist | bullist | outdent indent | alignleft aligncenter alignright  alignjustify alignnone| 
      bold italic underline blockquote removeformat | color forecolor backcolor | insertdatetime | 
      pastetext | codesample | template | help | custom-link | unlink | portraitSnippet | snippetSettings snippetAdd | code`,
  ],
  fontsize_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 36px',
  content_style: 'img { margin: 5px 8px;} a { text-decoration: underline; color: blue; }',
  imagetools_toolbar: 'imagealignment | imageoptions',
  image_caption: true,
  image_title: true,
  a11y_advanced_options: true,
  link_context_toolbar: false,
  quickbars_insert_toolbar: true,
  format_empty_lines: true,
  indent_use_margin: true,
  contextmenu: 'image table unlink',
  content_css: '/snippets.css',
}
