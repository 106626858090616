import React, { FC, useEffect, useMemo } from 'react'
import { useField } from 'formik'
import { FormControl, InputLabel, MenuItem, Select as SelectInput } from '@material-ui/core'
import { WordBreakWrapper } from 'ui'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: any[]
  name: string
  defaultValue?: any
}

const Select: FC<TProps> = (props) => {
  const { data = [], name, defaultValue } = props
  const { t } = useTranslation()
  const labelForSelect = useMemo(() => {
    const splited = name.split('.')
    return t(`options.${splited[splited.length - 1]}`)
  }, [name, t])

  const [{ value }, , { setValue }] = useField(name)

  useEffect(() => {
    if (!value) {
      setValue(defaultValue)
    }
    //eslint-disable-next-line
  }, [value])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value)
  }

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>{labelForSelect}</InputLabel>
      <SelectInput name={name} value={value || ''} onChange={handleChange} label={labelForSelect}>
        {data &&
          data.map((item: any) => (
            <MenuItem key={Math.random()} value={item.value}>
              <WordBreakWrapper children={item.text} />
            </MenuItem>
          ))}
      </SelectInput>
    </FormControl>
  )
}

export default Select
