import { useMemo } from 'react'
import { AttributeData } from 'modules/new-entity/types'

function hasRequired(attrData: AttributeData) {
  if (attrData.validationRules.required) {
    return true
  }

  if (attrData.setAttributes) {
    for (const nestedAttr of attrData.setAttributes) {
      if (hasRequired(nestedAttr.attribute)) {
        return true
      }
    }
  }

  return false
}

export default function useIsRequired(attrData: AttributeData) {
  return useMemo(() => {
    return hasRequired(attrData)
  }, [attrData])
}
