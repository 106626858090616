import React, { FC } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Grid } from '@material-ui/core'
import { SelectedAttributeItem } from '../index'
import { SortedAttribute } from 'core/types/attribute'

type TAttributeList = {
  id: string
  data: SortedAttribute[]
  dragEndHandler?: (result: any) => void
  removeAttributeHandler?: (index: number) => () => void
  removeAttributeOptionsHandler?: (attrId: string) => () => void
  removeAttributeDependenciesHandler?: (attrId: string) => () => void
  disabled?: boolean
  isNested?: boolean
  disableOptions?: boolean
  pathToAttribute?: string
}

const SelectedAttributesList: FC<TAttributeList> = (props) => {
  const {
    data,
    isNested,
    disableOptions,
    dragEndHandler,
    id,
    removeAttributeHandler = () => {},
    removeAttributeOptionsHandler = () => {},
    removeAttributeDependenciesHandler = () => {},
    disabled = false,
    pathToAttribute,
  } = props

  switch (disabled) {
    case false: {
      return (
        <div style={{ paddingBottom: 50 }}>
          <DragDropContext onDragEnd={dragEndHandler || (() => {})}>
            <Droppable droppableId={id}>
              {(provided, snapshot) => (
                <Grid {...provided.droppableProps} ref={provided.innerRef} container>
                  {data &&
                    data.map((item, index) => (
                      <Draggable key={item.attribute} draggableId={item.attribute} index={index}>
                        {(providedItem, snapshotItem) => (
                          <Grid
                            item
                            xs={12}
                            ref={providedItem.innerRef}
                            {...providedItem.draggableProps}
                            {...providedItem.dragHandleProps}
                          >
                            <SelectedAttributeItem
                              data={item}
                              index={index}
                              removeAttributeHandler={removeAttributeHandler(index)}
                              removeAttributeOptionsHandler={removeAttributeOptionsHandler(
                                item.attribute
                              )}
                              /* eslint-disable-next-line max-len */
                              removeAttributeDependenciesHandler={removeAttributeDependenciesHandler(
                                item.attribute
                              )}
                              isNested={isNested}
                              pathToAttribute={item.attribute}
                              disableOptions={disableOptions}
                            />
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )
    }
    case true: {
      return (
        <div style={{ paddingBottom: 20 }}>
          {data &&
            data.map((item, index) => (
              <SelectedAttributeItem
                disabled={disabled}
                key={item.attribute}
                data={item}
                parentData={data}
                pathToAttribute={`${pathToAttribute}.${item.attribute}`}
                index={item.sortOrder}
                isNested={isNested}
                removeAttributeHandler={removeAttributeHandler(index)}
                removeAttributeOptionsHandler={removeAttributeOptionsHandler(item.attribute)}
                removeAttributeDependenciesHandler={removeAttributeDependenciesHandler(
                  item.attribute
                )}
              />
            ))}
        </div>
      )
    }
    default:
      return null
  }
}

export default SelectedAttributesList
