// Core
import React, { FC } from 'react'
import { DialogTitle as MuiDialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import clsx, { ClassValue } from 'clsx'
// Styles
import useStyles from './dialog-title.styles'

type Props = {
  onClose?: any
  className?: ClassValue
}

const DialogTitle: FC<Props> = (props) => {
  const classes = useStyles()
  const { children, onClose, className } = props

  return (
    <MuiDialogTitle
      disableTypography
      className={clsx(classes.root, className, { [classes.hasCloseButton]: onClose })}
    >
      <Typography component="div" variant="h6" style={{ width: '100%' }}>
        {children}
      </Typography>
      {onClose && (
        <IconButton onClick={() => onClose()} size="small" className={classes.closeButton}>
          <Close />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}

export default DialogTitle
