import { TextField } from 'core/form'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

type TProps = {
  value?: string
}

const DefaultConditionField: FC<TProps> = (props) => {
  const { t } = useTranslation()
  return (
    <div style={{ width: '100%' }}>
      <TextField name="conditionValue" label={t('labels.set-val')} />
    </div>
  )
}

export default DefaultConditionField
