// Core
import React, { FC, useCallback } from 'react'
import { Draggable, DraggableProps } from 'react-beautiful-dnd'
import { Box, Grid } from '@material-ui/core'
// Components
import { WidgetItem, ButtonInsert } from 'modules/new-entity/components/page-builder/components'
// Hooks
import { useTranslation } from 'react-i18next'

type TProps = {
  data: any
  disableMoveUp: boolean
  disableMoveDown: boolean
  containerId: string
  onInsertWidget: (containerId: string, widgetIndex: number) => void
  isDraggingOver: boolean
  submittedOnce?: boolean
  disabled?: boolean
} & Omit<DraggableProps, 'children'>

const DraggableItem: FC<TProps> = (props) => {
  const {
    data,
    disableMoveDown,
    disableMoveUp,
    index,
    containerId,
    onInsertWidget,
    isDraggingOver,
    submittedOnce,
    disabled,
    ...rest
  } = props

  const { t } = useTranslation()

  const handleInsertWidget = useCallback(() => {
    onInsertWidget(containerId, index)
  }, [containerId, onInsertWidget, index])

  return (
    <Draggable isDragDisabled={disabled} index={index} {...rest}>
      {(providedDrag, snapshotDrag) => (
        <Grid innerRef={providedDrag.innerRef} {...providedDrag.draggableProps} item xs={12}>
          <Box position="relative">
            <ButtonInsert
              type="widget"
              title={t('page-builder.widget.add')}
              onClick={handleInsertWidget}
              disabled={disabled || isDraggingOver}
            />
            <WidgetItem
              disableMoveUp={disableMoveUp}
              disableMoveDown={disableMoveDown}
              data={data}
              isDraggingOver={isDraggingOver}
              dnd={providedDrag.dragHandleProps}
              submittedOnce={submittedOnce}
              disabled={disabled}
              isDragging={snapshotDrag.isDragging}
            />
          </Box>
        </Grid>
      )}
    </Draggable>
  )
}

export default DraggableItem
