// Core
import { useCallback, useEffect, useReducer } from 'react'
// Hooks
import { useGetData } from '../use-get-data'
import { useGetInitialValues } from '../use-get-initial-values'
// Utils
import { reducer } from './reducer'
// Types
import { SelectedValue, SmartSelectProps } from '../../types'
import { SmartSelectState } from './types'

type Methods = {
  setSearch: (value: string) => void
  setSelected: (itemData: SelectedValue) => void
  setPage: (value: number) => void
  setAnchor: (value: any) => void
  deleteSelected: (value: SelectedValue) => void
  clearValue: () => void
  refetch: any
}

type ReturnType = {
  data: any
  isLoading: boolean
  methods: Methods
} & SmartSelectState

type UseSmartSelect = (props: SmartSelectProps) => ReturnType

export const useSmartSelect: UseSmartSelect = (props) => {
  const { multiple, value, valueField = 'id', source, reqParams = {}, onChange, nullable } = props

  const [state, dispatch] = useReducer<typeof reducer>(reducer, {
    page: 1,
    total: 0,
    value: value || multiple ? [] : '',
    search: '',
    selected: null,
    anchor: null,
  })

  const { isLoading, data, refetch } = useGetData(
    source,
    state.page,
    state.search,
    reqParams,
    value,
    (resData: any) => {
      const total = resData.data['hydra:totalItems']
      dispatch({ type: 'set-total', payload: { value: total } })
    }
  )

  useGetInitialValues(props, (value) => dispatch({ type: 'set-selected', payload: { value } }))

  const setSearch = useCallback((value: string) => {
    dispatch({ type: 'set-search', payload: { value } })
  }, [])

  const setPage = useCallback((value: number) => {
    dispatch({ type: 'set-page', payload: { value } })
  }, [])

  const setAnchor = useCallback(
    (anchor: any) => {
      dispatch({ type: 'set-anchor', payload: { value: anchor } })
      if (!anchor) {
        setTimeout(() => {
          setPage(1)
          setSearch('')
        }, 0)
      }
    },
    [setPage, setSearch]
  )

  const changeHandler = useCallback(
    (selectedValue: SelectedValue | SelectedValue[]) => {
      if (!selectedValue) return
      const generateValue = multiple
        ? (selectedValue as SelectedValue[]).map((item) => item?.[valueField])
        : (selectedValue as SelectedValue)[valueField]

      if (onChange) onChange(generateValue)
    },
    [multiple, onChange, valueField]
  )

  const setSelected = useCallback(
    (selectedValue: SelectedValue) => {
      let newValue: SelectedValue | SelectedValue[]

      if (multiple) {
        const prevValue = [...((state.selected as SelectedValue[]) || [])]
        const hasValue =
          prevValue.findIndex((item) => item?.[valueField] === selectedValue?.[valueField]) >= 0
        newValue = hasValue
          ? prevValue.filter((item) => item?.[valueField] !== selectedValue?.[valueField])
          : [...prevValue, selectedValue]
      } else {
        newValue = selectedValue
      }

      changeHandler(newValue)

      dispatch({ type: 'set-selected', payload: { value: newValue } })
      dispatch({ type: 'set-anchor', payload: { value: null } })
    },
    [multiple, state.selected, valueField, changeHandler]
  )

  const deleteSelected = useCallback(
    (value: SelectedValue) => {
      const prevValues = [...(state.selected as SelectedValue[])]
      const newValue = prevValues.filter((item) => item?.[valueField] !== value?.[valueField])

      changeHandler(newValue)

      dispatch({ type: 'set-selected', payload: { value: newValue } })
    },
    [state.selected, valueField, changeHandler]
  )

  const clearValue = useCallback(() => {
    const newValue = multiple ? [] : nullable ? null : ''

    // @ts-ignore
    onChange(newValue)
    // @ts-ignore
    dispatch({ type: 'set-selected', payload: { value: newValue } })
  }, [multiple, nullable, onChange])

  useEffect(() => {
    if (typeof value === 'undefined' || !value) {
      // @ts-ignore
      dispatch({ type: 'set-selected', payload: { value: null } })
    }
  }, [value])

  return {
    ...state,
    data: data?.data['hydra:member'] || [],
    isLoading,
    methods: {
      setSearch,
      setSelected,
      setPage,
      setAnchor,
      deleteSelected,
      clearValue,
      refetch,
    },
  }
}
