/* eslint-disable no-unsafe-finally */
/* eslint-disable no-empty */
import axios, { AxiosError } from 'axios'
//
import storage from '../../storage'
import httpServiceInstance from '../instance'
import { addVersion } from './check-version'

const simpleHttpService = axios.create({
  baseURL: httpServiceInstance.getBasePath(),
})

simpleHttpService.interceptors.request.use(addVersion)

export const refreshToken = async (e: AxiosError) => {
  const { config, response } = e

  if (response && response.status === 401 && response.data.message === 'Expired JWT Token') {
    const refreshTokenValue = storage.getRefreshToken()

    if (refreshTokenValue) {
      const refreshRes = await simpleHttpService
        .post('/token/refresh', {
          refresh_token: refreshTokenValue,
        })
        .catch((e) => e)
      if (refreshRes.response?.status === 401) {
        if (httpServiceInstance.onTokenExpired) httpServiceInstance.onTokenExpired()
        return Promise.reject(e)
      }
      const { data } = refreshRes

      storage.login(data.token, data.refresh_token)

      /**
       * MUST HAVE
       * parse JSON data for next request call
       * after refresh token
       */
      try {
        if (config.data) config.data = JSON.parse(config.data)
      } catch (e) {
      } finally {
        return httpServiceInstance.client(config)
      }
    }
  } else if (response?.data.type === 'cache-revalidation') {
    const {
      data: { message, type },
    } = response
    const error = new Error(message)
    // @ts-ignore
    error.type = type
    return Promise.reject(error)
  } else if (response && response.status === 401) {
    if (httpServiceInstance.onTokenExpired) {
      httpServiceInstance.onTokenExpired()
    }
  }

  return Promise.reject(e)
}
