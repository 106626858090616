// Core
import React, { FC } from 'react'

type TProps = {
  children?: any
}

const WordBreakWrapper: FC<TProps> = (props) => {
  const { children } = props
  return (
    <span
      style={{
        whiteSpace: 'normal',
        wordBreak: 'break-all',
      }}
    >
      {children}
    </span>
  )
}

export default WordBreakWrapper
