import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const attributeSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},

    createArea: {
      borderRadius: '5px',
      padding: theme.spacing(2, 0),
      border: '1px dashed #B0BEC5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: '#f7f7f7',
      },
    },

    enumsArea: {
      borderRadius: '5px',
      margin: theme.spacing(0, 0, 3),
      background: '#f7f7f7',
      padding: theme.spacing(2, 0),
    },
  })
)

export default attributeSelectStyles
