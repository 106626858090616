// Core
import React, { FC, useMemo, useState } from 'react'
import { Box, Button, CircularProgress, Menu, MenuItem } from '@material-ui/core'
import { Add } from '@material-ui/icons'
// Types
import { FilterItemData, Filters, SelectedFilter } from './types'

type Props = {
  data: FilterItemData[]
  selectedFilters: SelectedFilter[]
  onAddFilter: Filters['addFilter']
  isLoading: boolean
}

type FilterItem = FilterItemData & { disabled: boolean }

const AddFilter: FC<Props> = (props) => {
  const { data, selectedFilters, onAddFilter, isLoading } = props

  const preparedData = useMemo<FilterItem[]>(() => {
    const selectedSlugs = selectedFilters.reduce((slugs, item) => {
      slugs[item.slug] = true
      return slugs
    }, {} as Record<string, boolean>)

    return data.map((item) => ({ ...item, disabled: selectedSlugs[item.attrData.slug] }))
  }, [data, selectedFilters])

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const addHandler = (filterItem: FilterItem) => {
    onAddFilter(
      filterItem.attrData.slug,
      filterItem.attrData.name,
      filterItem.attrData.attributeType.type
    )
    setAnchor(null)
  }

  return (
    <Box>
      <Button
        onClick={(e) => setAnchor(e.currentTarget)}
        startIcon={<Add />}
        variant="contained"
        color="primary"
        size="small"
      >
        Add Filter
      </Button>
      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={() => setAnchor(null)}>
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center" minWidth={200}>
            <CircularProgress />
          </Box>
        )}
        {preparedData.map((filterItem) => (
          <MenuItem
            key={filterItem.attrData.slug}
            onClick={() => addHandler(filterItem)}
            disabled={filterItem.disabled}
          >
            {filterItem.attrData.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default AddFilter
