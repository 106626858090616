/**
 * TODO: its copy of /src/modules/new-entity/utils/api-errors.ts
 * need to refactor and make one in all places
 * *
 * formErrors[error.propertyPath] = error.message - is difference
 * need to make dynamic
 */
import { AxiosError, isAxiosError } from 'axios'
import { FormikErrors, FormikHelpers, setNestedObjectValues } from 'formik'

type ApiError = {
  propertyPath: string
  message: string
}

type ApiErrorRes = {
  violations: ApiError[]
}

type FormErrors = FormikErrors<any>

type ApiValidationError = AxiosError<ApiErrorRes>

export class ApiErrorsService {
  formErrors: FormErrors = {}

  constructor(private formInstance: FormikHelpers<any>, private error: ApiValidationError) {}

  public static isApiError(error: unknown) {
    if (!isAxiosError(error)) return false
    const { response } = error
    if (!response?.data) return false
    const apiErrors = response.data as ApiErrorRes
    if (!apiErrors.violations) return false
    return true
  }

  private transformApiErrors(apiErrors: ApiError[]) {
    const formErrors: FormErrors = {}
    const widgetsErrors: Record<string, FormErrors> = {}

    apiErrors.forEach((error) => {
      const type = error.propertyPath.startsWith('entityWidgets') ? 'widgetError' : 'entityError'
      if (type === 'widgetError') {
        const match = error.propertyPath.match(/\[(\d+)\]/)
        const index = match ? match[1] : 0
        const attrPath = error.propertyPath.split('.')[1]
        widgetsErrors[index] = { ...(widgetsErrors[index] || {}), [attrPath]: error.message }
      } else {
        formErrors[error.propertyPath] = error.message
      }
    })

    return {
      formErrors,
      widgetsErrors,
    }
  }

  public setFormErrors() {
    if (!this.error.response?.data) return
    const { violations } = this.error.response.data
    const errors = this.transformApiErrors(violations)
    this.formErrors = errors
    this.formInstance.setTouched(setNestedObjectValues(errors.formErrors, true), false)
    this.formInstance.setErrors(errors.formErrors)
  }
}
