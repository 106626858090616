// Core
import React, { FC, Fragment } from 'react'
// Components
import Login from './pages/login'
import { Route } from 'core/router'
import ResetPassword from './pages/reset-password'
import AuthLayout from './components/auth-layout'

const Routes: FC = () => {
  return (
    <>
      <Route
        guard={{ auth: 'guest' }}
        exact
        path="/login"
        layout={AuthLayout}
        componentTo={<Login />}
      />
      <Route
        exact
        path="/reset-password/:token"
        layout={Fragment}
        componentTo={<ResetPassword />}
      />
    </>
  )
}

export default Routes
