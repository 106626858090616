// Utils
import { validationRulesMap } from './rules-map'
// Types
import { RuleValues } from '../types'
import { AttributeTypesList } from 'common/types'

/**
 * Map of attribute types to validation values
 */
export const typeRulesMap: { [name: string]: RuleValues[] } = {
  attachment: [validationRulesMap.required],
  image: [validationRulesMap.required],
  attachment_collection: [validationRulesMap.required],
  image_collection: [validationRulesMap.required],
  date_time: [validationRulesMap.required],
  decimal: [
    validationRulesMap.required,
    validationRulesMap.unique,
    validationRulesMap.global_unique,
    validationRulesMap.range,
  ],
  integer: [
    validationRulesMap.required,
    validationRulesMap.unique,
    validationRulesMap.global_unique,
    validationRulesMap.range,
  ],
  boolean: [validationRulesMap.required],
  select: [
    validationRulesMap.required,
    validationRulesMap.unique,
    validationRulesMap.global_unique,
  ],
  multi_select: [validationRulesMap.required],
  text: [validationRulesMap.required, validationRulesMap.length, validationRulesMap.regex],
  text_l10n: [validationRulesMap.required, validationRulesMap.length, validationRulesMap.regex],
  [AttributeTypesList.text_editor]: [validationRulesMap.required],
  string: [
    validationRulesMap.required,
    validationRulesMap.unique,
    validationRulesMap.global_unique,
    validationRulesMap.length,
    validationRulesMap.regex,
  ],
  string_l10n: [
    validationRulesMap.required,
    validationRulesMap.unique,
    validationRulesMap.length,
    validationRulesMap.regex,
  ],
  slug: [validationRulesMap.required, validationRulesMap.global_unique],
  repeater: [validationRulesMap.required, validationRulesMap.repeats],
  reference_one_to_one: [validationRulesMap.required, validationRulesMap.global_unique],
  reference_many_to_one: [validationRulesMap.required],
  reference_many_to_many: [validationRulesMap.required],
  reference_one_to_one_reversed: [validationRulesMap.required],
  reference_one_to_many: [validationRulesMap.required],
  reference_many_to_one_multiple_types: [validationRulesMap.required],
  reference_many_to_many_reversed: [validationRulesMap.required],
  [AttributeTypesList.reference_many_to_one_hierarchical]: [validationRulesMap.required],
}
