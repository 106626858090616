// Core
import { UseQueryResult, useQuery } from 'react-query'
// Services
import { httpService } from 'core/data'

type UseGetSelectedAttributes = (attributes: { attribute: string }[]) => UseQueryResult<any>

export const useGetSelectedAttributes: UseGetSelectedAttributes = (attributes) => {
  const attributeIds = attributes.map((item: { attribute: string }) => item.attribute)

  return useQuery(
    'selected-attributes',
    async () => {
      const res = await httpService.get<any>('/attributes', {
        params: { id: attributeIds },
      })

      return res.data['hydra:member']
    },
    {
      refetchOnWindowFocus: false,
      enabled: attributeIds.length > 0,
    }
  )
}
