// Core
import React, { FC, useEffect, useState } from 'react'
import { IconButton } from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'
// Components
import RelationsSelect from 'ui/relations-select'
import Sidebar from '../sidebar'
// Styles
import useStyles from './preview.styles'

type Props = {
  url: string
  onClose: () => void
  previewVersion: number
  segment: string | null
  onSegmentChange: (id: string | null) => void
  disabledSegment: boolean
}

const Preview: FC<Props> = (props) => {
  const { url, onClose, previewVersion, segment, onSegmentChange, disabledSegment } = props
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
  }, [previewVersion])

  const iframeUrl = new URL(url)
  iframeUrl.searchParams.set('asFrame', 'true')

  return (
    <Sidebar
      isLoading={isLoading}
      zIndex={100}
      header={
        <RelationsSelect
          source="segments"
          size="small"
          label="View as"
          value={segment}
          // @ts-ignore
          onChange={onSegmentChange}
          disabled={disabledSegment}
          reqParams={{ active: true }}
          className={classes.select}
        />
      }
      actions={
        <a href={url} target="_blank" rel="noreferrer">
          <IconButton>
            <OpenInNew />
          </IconButton>
        </a>
      }
      onClose={onClose}
    >
      <iframe
        src={iframeUrl.toString()}
        key={previewVersion}
        onLoad={() => setIsLoading(false)}
        title="Preview"
        width="100%"
        height="100%"
        style={{ display: 'block' }}
      />
    </Sidebar>
  )
}

export default Preview
