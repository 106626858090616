// Core
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// Components
import Preview from './preview'
// Hooks
import { useGetActiveSite } from 'modules/sites'
// Utils
import { getIdFromIri } from 'core/utils'
// Types
import { IEntity, AsideComponent } from 'modules/new-entity/types'

export default function usePreview(
  entity: IEntity,
  asideComponents: AsideComponent[],
  toggleAsideComponents: (component: AsideComponent, isShowAction?: boolean) => void
) {
  const activeSite = useGetActiveSite()
  const { id: entityId, originalLabel } = entity || {}

  const [isOpen, setIsOpen] = useState(false)
  const [previewVersion, setPreviewVersion] = useState(0)
  const [activeSegmentId, setActiveSegmentId] = useState<string | null>(null)

  useEffect(() => {
    if (!entity || !entity.entityType) return
    if (entity.originalLabel === 'personalized') {
      const segmentId = getIdFromIri(entity.originalSegments[0])
      setActiveSegmentId(segmentId)
    } else {
      setActiveSegmentId(null)
    }
  }, [entity])

  const previewUrl = useMemo(() => {
    const url = new URL(`${activeSite?.url}/api/preview/`)
    url.searchParams.set('entityId', entityId?.toString())
    url.searchParams.set('token', activeSite?.token!)
    if (activeSegmentId) {
      url.searchParams.set('segment', activeSegmentId.toString())
    }
    return url
  }, [activeSegmentId, activeSite, entityId])

  const disabledSegment = originalLabel === 'personalized'

  const closePreview = useCallback(() => {
    toggleAsideComponents('preview')
    setIsOpen(false)
  }, [toggleAsideComponents])

  const showPreview = useCallback(() => {
    const isActive = isOpen && asideComponents.at(-1) === 'preview'

    if (isActive) {
      closePreview()
    } else {
      toggleAsideComponents('preview', true)
      setIsOpen(true)
    }
  }, [asideComponents, closePreview, isOpen, toggleAsideComponents])

  const updatePreview = useCallback(() => {
    if (!isOpen) return
    setPreviewVersion((prevVersion) => prevVersion + 1)
  }, [isOpen])

  return {
    isOpen,
    showPreview,
    previewUrl,
    updatePreview,
    Preview: isOpen ? (
      <Preview
        url={previewUrl.toString()}
        onClose={closePreview}
        previewVersion={previewVersion}
        segment={activeSegmentId}
        onSegmentChange={setActiveSegmentId}
        disabledSegment={disabledSegment}
      />
    ) : null,
  }
}
