// Core
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, CircularProgress, Grid } from '@material-ui/core'
import { History, OpenInNew, Save, Telegram, VisibilitySharp, FileCopy } from '@material-ui/icons'
// Components
import ActionsPopover from 'common/components/actions-popover'
// Hooks
import {
  useChangeEntityStatus,
  useDeleteEntity,
  useDuplicateEntity,
  useTypePermissions,
} from 'modules/new-entity/hooks'
import { useConfirmation } from 'core/confirmation'
import { useGetActiveSite } from 'modules/sites'
// Types
import {
  EntityStatus,
  EntityUrl,
  EntityVersionLabel,
  SaveEntityActions,
} from 'modules/new-entity/types'
import { EntityType } from 'core/types'
import { Actions } from 'core/data/types/actions'

type Props = {
  originalId: number
  currentId: number
  isEdit: boolean
  isTemplate: boolean
  isUrlable: boolean
  status: EntityStatus
  isLoading: boolean
  entityType: EntityType
  saveEntityAction: (actionType: SaveEntityActions) => void
  saveAsTemplateAction: () => void
  insertTemplateAction: (value: boolean) => void
  checkUnsavedChanges: () => boolean
  previewAction: () => void
  historyAction: () => void
  setSavedChanges: () => void
  restoreAction: () => void
  cloneAction: () => void
  entityLabel: EntityVersionLabel
  url: EntityUrl | undefined
  isGlobalType: boolean
  children?: never
}

const getMainActionText = (
  isEdit: boolean,
  isUrlable: boolean,
  isTemplate: boolean,
  isRevision: boolean
) => {
  if (isRevision) return 'Restore'
  if (!isUrlable && isEdit) return 'Save'
  if (isUrlable && isEdit) {
    if (isTemplate) return 'Save'
    return 'Publish'
  }
  return 'Create'
}

const getMainPath = (entityType: EntityType, isTemplate: boolean) => {
  if (entityType['@type'] === 'EntityType') {
    if (isTemplate) return 'templates'
    return 'entities'
  }

  return 'widgets'
}

const FormActions: FC<Props> = (props) => {
  const {
    originalId,
    currentId,
    isEdit,
    isTemplate,
    isUrlable,
    status,
    isLoading,
    saveEntityAction,
    entityType,
    checkUnsavedChanges,
    setSavedChanges,
    insertTemplateAction,
    saveAsTemplateAction,
    previewAction,
    historyAction,
    entityLabel,
    restoreAction,
    cloneAction,
    isGlobalType,
    url,
  } = props

  const history = useHistory()
  const { setConfirmation } = useConfirmation()
  const permissions = useTypePermissions(entityType)
  const activeSite = useGetActiveSite()
  const entityUrl = `${activeSite?.url}/${url?.url}/`

  const hasUrlableActions = isEdit && isUrlable
  const isRevision = entityLabel === 'revision'
  const showVisit = hasUrlableActions && !isRevision && !isTemplate && status === 'publish'
  const isSingle = entityType.single
  const needTemplates = isUrlable && !isRevision && !isSingle && entityType.builder && !isTemplate
  const mainAction: SaveEntityActions = isEdit && isUrlable && !isTemplate ? 'publish' : 'default'
  const mainActionText = getMainActionText(isEdit, isUrlable, isTemplate, isRevision)
  const mainPath = getMainPath(entityType, isTemplate)
  const isWidgets = mainPath === 'widgets'

  const actionsWrapperRef = useRef<HTMLDivElement | null>(null)
  const [showActions, setShowActions] = useState(false)

  const { deleteEntity, deleteEntityM } = useDeleteEntity(originalId, {
    onSuccess: () => history.push(`/${mainPath}/${entityType.id}`),
    isWidgetType: isWidgets,
  })

  const { changeEntityStatus, changeStatusM } = useChangeEntityStatus(originalId, status, {
    onSuccess: () => history.push(`/${mainPath}/${entityType.id}`),
  })

  const { duplicateEntity, duplicateEntityM } = useDuplicateEntity(currentId, {
    onSuccess: (createdId) => {
      history.push(`/${mainPath}/${entityType.id}/edit/${createdId}`)
    },
    isWidgetType: isWidgets,
  })

  const mainButtonHandler = () => {
    isRevision ? restoreAction() : saveEntityAction(mainAction)
  }

  const actions = useMemo<Actions>(() => {
    const actionWrapper = (action: () => void) => () => {
      action()
      setShowActions(false)
    }

    const createHandler = () => {
      history.push(`/${mainPath}/${entityType.id}/create`)
    }

    const duplicateHandler = () => {
      const hasUnsavedChanges = checkUnsavedChanges()

      if (hasUnsavedChanges) {
        setConfirmation({
          open: true,
          title: 'Confirm duplication',
          content: 'You have unsaved changes. Would you like to continue to duplicate?',
          onSuccess: () => {
            setSavedChanges()
            duplicateEntity()
          },
        })
      } else {
        duplicateEntity()
      }
    }

    const deleteHandler = () => {
      setSavedChanges()
      deleteEntity()
    }

    const archiveHandler = () => {
      changeEntityStatus('archive')
    }

    const isPersonalized = entityLabel === 'personalized'

    return [
      {
        name: 'unpublish',
        label: 'Unpublish',
        action: actionWrapper(() => saveEntityAction('unpublish')),
        condition:
          status === 'publish' && !isRevision && permissions.canEdit && isUrlable && !isTemplate,
        icon: 'icon-eye-off',
      },
      {
        name: 'create_new',
        label: 'Create new',
        action: actionWrapper(createHandler),
        condition: isEdit && !isRevision && !isSingle && permissions.canCreate && !isPersonalized,
        icon: 'icon-add-default',
      },
      {
        name: 'duplicate',
        label: 'Duplicate',
        action: actionWrapper(duplicateHandler),
        condition:
          isEdit &&
          !isSingle &&
          permissions.canCreate &&
          entityType.slug !== 'affiliate_link' &&
          !isPersonalized,
        icon: 'icon-copy',
      },
      {
        name: 'clone',
        label: 'Clone to',
        action: actionWrapper(cloneAction),
        condition: isEdit && !isWidgets && !isSingle && !isGlobalType,
        icon: <FileCopy />,
      },
      {
        name: 'insert_template',
        label: 'Insert template',
        action: actionWrapper(() => insertTemplateAction(true)),
        condition: needTemplates,
        icon: 'icon-add-above',
      },
      {
        name: 'save_as_template',
        label: 'Save as template',
        action: actionWrapper(() => saveAsTemplateAction()),
        condition: needTemplates && isEdit,
        icon: 'icon-add-below',
      },
      {
        name: 'history',
        label: 'View history',
        action: actionWrapper(historyAction),
        condition: isEdit && !isTemplate,
        icon: <History />,
      },
      {
        name: 'delete',
        label: 'Delete',
        action: actionWrapper(deleteHandler),
        condition: isEdit && !isRevision && !isSingle && permissions.canDelete,
        icon: 'icon-trash-2',
      },
      {
        name: 'archive',
        label: 'Archive',
        action: actionWrapper(archiveHandler),
        condition:
          status !== 'archive' &&
          isUrlable &&
          !isRevision &&
          isEdit &&
          !isSingle &&
          permissions.canEdit &&
          !isTemplate &&
          !isWidgets &&
          !isPersonalized,
        icon: 'icon-archive',
      },
    ]
  }, [
    entityLabel,
    status,
    isRevision,
    permissions,
    isUrlable,
    isTemplate,
    isEdit,
    isSingle,
    entityType,
    cloneAction,
    needTemplates,
    historyAction,
    isWidgets,
    history,
    mainPath,
    checkUnsavedChanges,
    setConfirmation,
    setSavedChanges,
    duplicateEntity,
    deleteEntity,
    changeEntityStatus,
    saveEntityAction,
    insertTemplateAction,
    saveAsTemplateAction,
    isGlobalType,
  ])

  const onShowActions = useCallback((value: boolean) => {
    setShowActions(value)
  }, [])

  const hasActions = useMemo(() => actions.some((action) => action.condition), [actions])

  const loading =
    isLoading || deleteEntityM.isLoading || duplicateEntityM.isLoading || changeStatusM.isLoading

  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      wrap="nowrap"
      style={{ flex: 1, columnGap: 10 }}
    >
      {hasUrlableActions && (
        <>
          {showVisit && (
            <Button
              disabled={loading}
              data-aqa-action="visit-page"
              variant="outlined"
              color="primary"
              startIcon={<OpenInNew />}
              component="a"
              target="_blank"
              href={entityUrl}
              title={entityUrl}
            >
              Visit page
            </Button>
          )}
          <Button
            disabled={loading}
            onClick={previewAction}
            data-aqa-action="preview"
            variant="outlined"
            color="primary"
            startIcon={<VisibilitySharp />}
          >
            Preview
          </Button>
          {!isTemplate && permissions.canEdit && !isRevision && (
            <Button
              disabled={loading}
              onClick={() => saveEntityAction('draft')}
              data-aqa-action="save"
              variant="outlined"
              color="primary"
              startIcon={<Save />}
            >
              Save
            </Button>
          )}
        </>
      )}
      <Box
        // @ts-ignore
        ref={actionsWrapperRef}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {permissions.canEdit && (
          <Button
            onClick={mainButtonHandler}
            disabled={loading}
            variant="contained"
            color="primary"
            data-aqa-action={mainActionText.toLowerCase()}
            startIcon={mainAction === 'publish' ? <Telegram /> : <Save />}
          >
            {loading && <CircularProgress size={20} />} {mainActionText}
          </Button>
        )}
        {hasActions && (
          <ActionsPopover
            actions={actions}
            anchorEl={actionsWrapperRef.current}
            showActions={showActions}
            onShowActions={onShowActions}
            loading={loading}
            aqaAction="open_actions"
          />
        )}
      </Box>
    </Grid>
  )
}

export default FormActions
