import { Backgrounds, SectionBackground } from 'core/types/layouts'
// types
import { THEME_TWO_COLOR_SCHEME } from 'modules/new-entity/types'

type Colors = {
  [key: string]: {
    container_bg: {
      name: SectionBackground
      value: string
    }[]
  }
}

const containerBackgrounds = [
  { name: Backgrounds.LIGHT, value: '#F9F9F9' },
  { name: Backgrounds.DARK, value: '#000000' },
]

export const schemes = THEME_TWO_COLOR_SCHEME.reduce((acc, key) => {
  acc[key] = { container_bg: containerBackgrounds }
  return acc
}, {} as Colors)
