// Core
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// Components
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { DialogTitle } from 'ui'
import AttributesForm from 'modules/new-entity/components/attributes-form'
// Utils
import { getIdFromIri } from 'core/utils'
import { useNotify } from 'core/hooks'
import { httpService } from 'core/data'
import { EntityService } from 'modules/new-entity/utils/entity-service'
import { ApiErrorsService } from 'modules/new-entity/utils/api-errors'
// Hooks
import { useEntityController } from 'modules/new-entity/hooks'
import { useRevalidate } from 'core/hooks/use-revalidate'
// Types
import { EavResourceType, MutationType } from 'modules/new-entity/types'

type TProps = {
  entityTypeIri: string | undefined
  setIsOpen: (val: boolean) => void
  isOpen: boolean
  entityId?: number
  isEdit?: boolean
  onSuccess?: (data?: unknown) => void
}

const QuickCreate: FC<TProps> = (props) => {
  const { entityTypeIri, isOpen, setIsOpen, entityId, isEdit, onSuccess } = props
  const { t } = useTranslation()
  const entityTypeId = entityTypeIri && getIdFromIri(entityTypeIri)

  const notify = useNotify()

  const {
    mainAttributes,
    initialValues,
    validationSchema,
    entityType,
    formRef,
    isLoading,
    mutation,
    attributes,
  } = useEntityController(entityId, entityTypeId, EavResourceType.ENTITY, {
    queryKey: 'quick-create',
  })

  const { getEntityUrls, revalidateEntity } = useRevalidate()

  const submitHandler = useCallback(async () => {
    try {
      await EntityService.validateForm(formRef.current)
      let values = { ...formRef.current?.values }

      values = EntityService.generateValues(values, attributes)

      const mutationObject = {
        entityType: entityType['@id'],
        ...values,
      }

      const entityRes: any = await mutation.mutateAsync(mutationObject)
      const entityId = Number(getIdFromIri(entityRes.data['@id']))
      const entData = await httpService.get(`/entities/${entityId}`).then((res) => res.data)

      const entityUrls = await getEntityUrls('entities', entityId, 'entity')

      if (entityUrls) {
        await revalidateEntity(entityId, 'entity', ...entityUrls)
      }

      setIsOpen(false)
      onSuccess && onSuccess(entData)
      notify(t('notify.success'), { type: 'success' })
    } catch (e) {
      if (ApiErrorsService.isApiError(e)) {
        const apiErrorsService = new ApiErrorsService(formRef.current!, e as any)
        apiErrorsService.setFormErrors()
        notify('Validation Error', { type: 'error' })
      } else {
        notify('Creation error', { type: 'error' })
      }
    }
  }, [
    formRef,
    attributes,
    entityType,
    mutation,
    getEntityUrls,
    setIsOpen,
    onSuccess,
    notify,
    t,
    revalidateEntity,
  ])

  const title = isEdit ? `Edit ${entityType?.name}` : `Create ${entityType?.name}`

  return (
    <Dialog
      open={isOpen}
      id={'entity-modal'}
      onClose={() => setIsOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{!isLoading && title}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress size={50} />
        ) : (
          <AttributesForm
            type={MutationType.CREATE}
            mainAttributes={mainAttributes}
            initialValues={initialValues}
            validationSchema={validationSchema}
            resourceType={EavResourceType.ENTITY}
            typeOptions={entityType?.options}
            formRef={formRef}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={mutation.isLoading}
          endIcon={mutation.isLoading && <CircularProgress size={15} />}
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          {isEdit ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default QuickCreate
