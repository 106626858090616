// Core
import React from 'react'
import { createContext, FC, useContext } from 'react'
// Types
import { SelectHandler } from './types'

export type RelationSelectContextProps = {
  search: string
  value: string[]
  selectHandler: SelectHandler
}

const RelationsSelectContext = createContext<RelationSelectContextProps>(null!)

export const useRelationsSelectContext = () => {
  return useContext(RelationsSelectContext)
}

export const RelationsSelectProvider: FC<{ value: RelationSelectContextProps }> = ({
  children,
  value,
}) => {
  return <RelationsSelectContext.Provider value={value}>{children}</RelationsSelectContext.Provider>
}
