import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const previewFieldsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 2),
    },
  })
)

export default previewFieldsStyles
