import { createContext } from 'react'
import { ConfirmationTypes } from '../types'

export const ConfirmationContext = createContext<ConfirmationTypes>({
  open: false,
  title: '',
  content: '',
  note: '',
  onSuccess: () => null,
  // @ts-ignore
  setConfirmation: undefined,
})

ConfirmationContext.displayName = 'ConfirmationContext'
