// Core
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Close, Check } from '@material-ui/icons'
// Utils
import { getActionsColumns, getTableColumn } from 'core/data/components/data-view'
// Types
import { DataView, ResourceProps } from 'core/data'

const SegmentsList: FC<ResourceProps> = (props) => {
  const { t } = useTranslation()

  return (
    <DataView
      resourceProps={props}
      columns={[
        getActionsColumns(
          props.name,
          t,
          undefined,
          'Any personalized version created for this segment will be removed would you like to continue?'
        ),
        getTableColumn({ title: t('table.name'), field: 'name' }),
        getTableColumn({ title: 'Priority', field: 'priority' }),
        getTableColumn({
          title: 'Activated',
          field: 'active',
          render: (rowData: any) => (rowData.active ? <Check /> : <Close />),
        }),
      ]}
    />
  )
}

export default SegmentsList
