// Core
import React, { FC } from 'react'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useDelayValueChange } from 'core/hooks'

type TProps = {
  title?: string
} & ButtonProps

const SubmitButton: FC<TProps> = (props) => {
  const { title = '', ...rest } = props
  const { isSubmitting, isValid } = useFormikContext()
  const [isLoading] = useDelayValueChange(isSubmitting)

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      disabled={isLoading || !isValid}
      {...rest}
      endIcon={isLoading ? <CircularProgress size={18} /> : rest.endIcon}
    >
      <span>{title}</span>
    </Button>
  )
}

export default SubmitButton
