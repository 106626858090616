import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { httpService, useListContext } from 'core/data'
import { useConfirmation } from 'core/confirmation'
// hooks
import { useRevalidate } from 'core/hooks/use-revalidate'

type Params = {
  forceMsg?: string
}

type UseDeleteOne = (
  resource: string,
  params?: Params
) => UseMutationResult<unknown, unknown, number>

export const useDelete: UseDeleteOne = (resource, params = {}) => {
  const { forceMsg } = params
  const props = useListContext()
  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()
  const queryClient = useQueryClient()
  const { getEntityUrls, revalidateEntity } = useRevalidate()

  return useMutation(
    (id: any) => {
      return httpService.delete(`/${resource}/${id}`)
    },
    {
      onError: (error: any, variables) => {
        if (error.response.status === 409) {
          setConfirmation({
            open: true,
            title: t('global.delete'),
            content: forceMsg || t('notify.delete'),
            onSuccess: () => {
              httpService.delete(`/${resource}/${variables}?force=true`).then(() => {
                queryClient.invalidateQueries(resource)
              })
            },
          })
        }
      },
      onSuccess: async ({ data: mediaData }) => {
        queryClient.invalidateQueries(resource)
        const {
          data,
          listParams: {
            params: {
              pagination: { page },
            },
            methods,
          },
        } = props

        if (data?.length === 1) {
          methods.setPage(page - 1 || 1)
        }

        const id = mediaData['@id'].split('/').at(-1)

        const entityUrls = await getEntityUrls(resource, id, 'media')

        if (entityUrls) {
          await revalidateEntity(id, 'media', ...entityUrls)
        }
      },
    }
  )
}
