import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const appBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },

    appBarShift: {},

    hide: {
      display: 'none',
    },

    logo: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',

      fontSize: '2.27rem',
    },
  })
)

export default appBarStyles
