// Core
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { TNavigation } from '../types'
import { getIdFromIri } from 'core/utils'
import { toast } from 'react-toastify'

type TReturnProps = {
  isNavTypeResolver: boolean
  navigationType: any
  isEdit: boolean
  navigationId: string
  navigationData: TNavigation | undefined
  navigationLinkIds: any
}

type TUseMutationRoteHooks = () => TReturnProps

export const useMutationRoteHooks: TUseMutationRoteHooks = () => {
  const { id: navigationId } = useParams<{ id: string }>()
  const isEdit = useMemo(() => Boolean(navigationId), [navigationId])

  /**
   *
   * Resolve type
   * & Links to ENTITY and CUSTOM link et IDs
   * & checker navigationType existing
   */
  const { data: navigationType } = useQuery(
    ['entity-type', 'navigation'],
    async () => {
      const { data } = await httpService.get<any>(`/entity_types?slug=navigation`)
      const [navigation] = data['hydra:member']
      return navigation
    },
    {
      onError: (e: any) => {
        if (e.response.status) {
          toast.error(`${e.response.status}-${e.message}`)
        } else {
          console.error(e)
        }
      },
    }
  )

  const { data: navigationData } = useQuery<TNavigation>(
    ['navigation', navigationId],
    async () => {
      const valuesRes = await httpService.get<TNavigation>(`/navigations/${navigationId}`)
      return valuesRes.data
    },
    {
      enabled: Boolean(navigationId),
      onError: (e: any) => {
        if (e.response.status) {
          toast.error(`${e.response.status}-${e.message}`)
        } else {
          console.error(e)
        }
      },
    }
  )

  const navigationLinkIds = useMemo(() => {
    if (!navigationType) return
    const [entity, custom] = navigationType.children.map(getIdFromIri)
    return { entity, custom }
  }, [navigationType])

  const isNavTypeResolver = useMemo(() => Boolean(navigationType), [navigationType])

  return {
    isNavTypeResolver,
    navigationType,
    isEdit,
    navigationId,
    navigationLinkIds,
    navigationData,
  }
}
