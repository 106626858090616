// interceptors
import {
  requestToken,
  transformLocalizations,
  refreshToken,
  addVersion,
  handlerVersionError,
} from './interceptors'
import httpServiceInstance from './instance'

httpServiceInstance.addInterceptor('request', requestToken)
httpServiceInstance.addInterceptor('request', transformLocalizations)
httpServiceInstance.addInterceptor('response', null, refreshToken)
httpServiceInstance.addInterceptor('request', addVersion)
httpServiceInstance.addInterceptor('response', null, handlerVersionError)

export * from './types'
export default httpServiceInstance
