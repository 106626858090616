// Core
import React, { FC, useCallback } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from '@material-ui/core'
import { Form, Formik, FormikHelpers } from 'formik'
import { Alert } from '@material-ui/lab'
import * as yup from 'yup'
// Components
import { DialogTitle } from 'ui'
import { SubmitButton, TextField } from 'core/form'
import ForgotPasswordLink from '../forgot-password-link'
// Utils/Services
import { confirmPasswordValidationRule, passwordValidationRule } from 'modules/auth/utils'
import { ApiErrorsService } from 'core/form/utils/api-errors'
// Types
import { ChangePasswordPayload } from 'modules/auth/types'

type FormValues = ChangePasswordPayload

const initialValues: FormValues = {
  currentPassword: '',
  newPassword: '',
  repeatedNewPassword: '',
}

const validationSchema = yup.object<FormValues>({
  currentPassword: passwordValidationRule(),
  newPassword: passwordValidationRule(),
  repeatedNewPassword: confirmPasswordValidationRule('newPassword'),
})

type Props = {
  onSubmit: (data: FormValues) => void
  onResetClick: () => void
  expired?: boolean
  resetLoading?: boolean
} & DialogProps

const ChangePasswordModal: FC<Props> = (props) => {
  const { onSubmit, onResetClick, expired, resetLoading, ...dialogProps } = props

  const submitHandler = useCallback(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      try {
        await onSubmit(values)
      } catch (error) {
        if (ApiErrorsService.isApiError(error)) {
          const apiErrorsService = new ApiErrorsService(helpers, error as any)
          apiErrorsService.setFormErrors()
        }
      }
    },
    [onSubmit]
  )

  return (
    <Dialog {...dialogProps} maxWidth="xs" fullWidth>
      <DialogTitle onClose={dialogProps.onClose as any}>Change password</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        <Form>
          <DialogContent>
            {expired && (
              <Box mb={2}>
                <Alert severity="info">Your password has expired, please change it.</Alert>
              </Box>
            )}
            <Typography component="p" variant="caption" color="textSecondary">
              New Password must be a combination of 8 to 20 letters and numbers
            </Typography>
            <Box mt={2}>
              <TextField name="currentPassword" label="Current password" type="password" required />
            </Box>
            <Box mt={2}>
              <TextField name="newPassword" label="New password" type="password" required />
            </Box>
            <Box my={2}>
              <TextField
                name="repeatedNewPassword"
                label="Confirm password"
                type="password"
                required
              />
            </Box>
            <ForgotPasswordLink onResetClick={() => onResetClick()} isLoading={resetLoading} />
          </DialogContent>
          <DialogActions>
            <SubmitButton title="Save" />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default ChangePasswordModal
