import produce from 'immer'
import { IModalState, ActionTypes } from './types'
import { ModalActionTypes } from './actions'

const initialState: IModalState = {
  open: false,
  text: '',
  type: 'confirm',
}

const reducer = (state: IModalState = initialState, action: ModalActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.OPEN_MODAL:
        return action.payload
    }
  })

export default reducer
