import { Action } from 'redux'

export interface IThemeState {
  defaultTheme: boolean
}

export enum ActionTypes {
  SWITCH_THEME = '@@theme/SWITCH_THEME',
}

export interface ISwitchThemeAction extends Action<ActionTypes.SWITCH_THEME> {
  payload: {
    val: boolean
  }
}
