import React, { FC } from 'react'
import { Create } from 'core/data'
import { ResourceProps } from 'core/data'
import CrudFormLayout from '../crud-form'
import SidebarLayout from '../sidebar'

const CreateLayout: FC<ResourceProps> = (props) => {
  const initialProps = {
    slug: '',
    name: '',
    options: {
      footer: true,
      header: true,
      sections: [],
    },
    localizations: [
      {
        locale: '/api/localizations/en_US',
        name: '',
      },
    ],
  }

  return (
    <Create
      initialData={initialProps}
      {...props}
      crudForm={CrudFormLayout}
      sidebar={SidebarLayout}
    />
  )
}

export default CreateLayout
