import React, { FC } from 'react'
import { Form, Formik } from 'formik'
import { LanguageProvider } from 'core/data'
import { TextField } from 'core/form'
import { Media } from '../../types'

type Props = {
  fileData: Media
  formId: string
  onSubmit: (data: Media) => void
}

export const FileForm: FC<Props> = ({ fileData, formId, onSubmit }) => {
  return (
    <div>
      <LanguageProvider>
        <Formik initialValues={fileData} onSubmit={onSubmit} enableReinitialize>
          <Form id={formId}>
            <TextField name="name" label="Internal name" required />
            <TextField name="title" label="Meta title" margin="normal" />
            <TextField name="alt" label="Alt tag" margin="normal" />
          </Form>
        </Formik>
      </LanguageProvider>
    </div>
  )
}
