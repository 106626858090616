import { useCallback, useReducer, useState } from 'react'
import { MediaListItem } from '../types'

type State = {
  selectedRows: number[]
  selectedMedia?: number | null
  selectedFolder?: number | null
  folderPanelOpen?: boolean
  folderFilter?: number | null
  uploadFileOpen: boolean
  bulkPanelOpen: boolean
}

type SelectMediaItemAction = {
  type: 'select_media_item'
  payload: { selected: number[]; rowData?: MediaListItem }
}

type ResetAction = { type: 'reset' }

type SetFolderAction = { type: 'set_folder_filter'; payload: number | null }

type OpenFolderPanelAction = { type: 'open_folder_panel'; payload: { id?: number } }

type OpenUploadAction = { type: 'open_upload' }

type ToggleBulkPanelAction = { type: 'toggle_bulk_panel'; payload: boolean }

type Actions =
  | SelectMediaItemAction
  | ResetAction
  | SetFolderAction
  | OpenFolderPanelAction
  | OpenUploadAction
  | ToggleBulkPanelAction

function stateReducer(state: State, action: Actions): State {
  const { type } = action

  const newState: State = {
    uploadFileOpen: false,
    folderFilter: state.folderFilter,
    selectedRows: state.selectedRows,
    bulkPanelOpen: false,
  }

  if (type === 'select_media_item') {
    const { payload } = action
    const { selected, rowData } = payload

    newState.selectedRows = selected

    const needSelectItem = state.selectedRows.length === 0 && selected.length === 1

    if (rowData && needSelectItem) {
      const stateField = rowData.type === 'folder' ? 'selectedFolder' : 'selectedMedia'
      const isToggle = [state.selectedFolder, state.selectedMedia].includes(rowData.id)

      if (rowData.type === 'folder') {
        newState.folderPanelOpen = !isToggle
      }

      newState[stateField] = isToggle ? null : rowData.id
    } else {
      newState.bulkPanelOpen = true
    }
  }

  if (type === 'set_folder_filter') {
    return {
      ...state,
      folderFilter: action.payload,
      selectedRows: [],
    }
  }

  if (type === 'open_folder_panel') {
    newState.folderPanelOpen = true
    if (action.payload.id) {
      newState.selectedFolder = action.payload.id
    }
    return newState
  }

  if (type === 'open_upload') {
    newState.uploadFileOpen = true
    return newState
  }

  if (type === 'toggle_bulk_panel') {
    return {
      ...state,
      bulkPanelOpen: action.payload,
    }
  }

  if (type === 'reset') {
    return {
      ...newState,
      selectedRows: [],
    }
  }

  return newState
}

type Props = {
  defaultFolder?: number | null
  defaultMedia?: number | null
  onRowSelectionChange?: (params: {
    selected: number[]
    rowData?: MediaListItem
    rows?: MediaListItem[]
  }) => void
}

export const useMediaManagerController = (props: Props = {}) => {
  const { defaultFolder, defaultMedia, onRowSelectionChange } = props
  const [search, setSearch] = useState('')

  const [state, dispatch] = useReducer(stateReducer, {
    selectedRows: [],
    uploadFileOpen: false,
    selectedMedia: defaultMedia,
    folderFilter: defaultFolder,
    bulkPanelOpen: false,
  })

  const selectMediaHandler = useCallback((mediaItem: MediaListItem) => {
    dispatch({ type: 'select_media_item', payload: mediaItem })
  }, [])

  const resetState = useCallback(() => dispatch({ type: 'reset' }), [])

  const setFolderFilter = useCallback(
    (payload: number | null) => dispatch({ type: 'set_folder_filter', payload }),
    []
  )

  const openFolderPanel = useCallback(
    (id?: number) => dispatch({ type: 'open_folder_panel', payload: { id } }),
    []
  )

  const openUpload = useCallback(() => {
    dispatch({ type: 'open_upload' })
  }, [])

  const doubleClickHandler = useCallback(
    (data: MediaListItem) => {
      if (data.type !== 'folder') return
      setFolderFilter(data.id)
    },
    [setFolderFilter]
  )

  const rowsSelectionChangeHandler = useCallback(
    (selected: number[], selectionMeta: { rowData?: MediaListItem; rows?: MediaListItem[] }) => {
      dispatch({ type: 'select_media_item', payload: { selected, rowData: selectionMeta.rowData } })
      onRowSelectionChange?.({ selected, rowData: selectionMeta.rowData, rows: selectionMeta.rows })
    },
    [onRowSelectionChange]
  )

  const toggleBulkPanel = useCallback((val: boolean) => {
    dispatch({ type: 'toggle_bulk_panel', payload: val })
  }, [])

  const isBulkSelection = state.selectedRows.length > 1

  return {
    ...state,
    isBulkSelection,
    search,
    resetState,
    setFolderFilter,
    selectMediaHandler,
    openFolderPanel,
    openUpload,
    setSearch,
    doubleClickHandler,
    rowsSelectionChangeHandler,
    toggleBulkPanel,
  }
}
