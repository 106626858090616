import { MutationOptions, useMutation, useQueryClient } from 'react-query'
import { useNotify } from 'core/hooks'
import { httpService } from 'core/data/services'

type Params = {} & MutationOptions

type Resource = {
  id?: number
} & Record<string, unknown>

export const useMutateOne = (resource: string, params: Params = {}) => {
  const notify = useNotify()
  const queryClient = useQueryClient()

  return useMutation(
    (value: Resource) => {
      const hasId = Boolean(value.id)
      const method = hasId ? 'put' : 'post'
      return httpService[method](`/${resource}${hasId ? `/${value.id}` : ''}`, value)
    },
    {
      ...params,
      onSuccess: (...p) => {
        params.onSuccess?.(...p)
        const { id } = p[1]
        const hasId = Boolean(id)
        const action = hasId ? 'update' : 'creation'
        notify(`Successful ${action} of the resource '${resource}' item`, { type: 'success' })
        queryClient.invalidateQueries([resource, id])
      },
      onError: () => {
        notify('Something went wrong', { type: 'error' })
      },
    }
  )
}
