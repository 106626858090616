import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const nestedFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: '56px',
      left: 0,
      width: '100%',
      minHeight: 'calc(100% - 56px)',
      transform: 'translateX(-100%)',
      backgroundColor: theme.palette.common.white,
      transition: 'transform .3s ease',
      zIndex: 50,
    },
    open: {
      transform: 'none',
      transition: 'transform .3s ease',
    },

    header: {
      backgroundColor: theme.palette.common.white,
      position: 'sticky',
      top: '56px',

      padding: theme.spacing(3, 5, 2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 5,
    },

    title: {
      color: theme.palette.text.primary,
      fontSize: '1rem',
      fontWeight: 500,
    },

    fieldsWrapper: {
      padding: theme.spacing(0, 5, 3),
    },

    footer: {
      position: 'sticky',
      backgroundColor: theme.palette.common.white,
      bottom: 0,
      padding: theme.spacing(2, 5),
      zIndex: 1,
    },
  })
)

export default nestedFormStyles
