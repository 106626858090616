import React, { FC } from 'react'
import logoStyles from './logo.styles'
import { Icon } from '@material-ui/core'

import pack from '../../../../../../../package.json'
import { Link } from 'react-router-dom'

type TProps = {
  data?: object
}

const Logo: FC<TProps> = (props) => {
  const classes = logoStyles()
  const { version } = pack

  return (
    <Link to="/" className={classes.root}>
      <Icon className={`icon-access_logo ${classes.logo}`} />

      <div className={classes.text}>Vulcan {version}</div>
    </Link>
  )
}

export default Logo
