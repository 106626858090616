// Core
import React, { FC } from 'react'
import { useField } from 'formik'
import { FormControlLabel, FormControlLabelProps, Checkbox } from '@material-ui/core'

type TProps = {
  name: string
} & Omit<FormControlLabelProps, 'control'>

const CheckboxField: FC<TProps> = (props) => {
  const { name, ...rest } = props
  const [field] = useField({
    name,
    type: 'checkbox',
  })

  return <FormControlLabel control={<Checkbox color="primary" {...field} />} {...rest} />
}

export default CheckboxField
