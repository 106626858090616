import React, { FC } from 'react'
import useStyles from './modal-footer.styles'

type TProps = {
  data?: object
}

const ModalFooter: FC<TProps> = (props) => {
  const classes = useStyles()
  const { children } = props
  return <div className={classes.root}>{children}</div>
}

export default ModalFooter
