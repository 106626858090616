import { SmartSelectState } from './types'

type Actions =
  | { type: 'set-search'; payload: { value: string } }
  | { type: 'set-selected'; payload: { value: any } }
  | { type: 'set-total'; payload: { value: number } }
  | { type: 'set-page'; payload: { value: number } }
  | { type: 'set-anchor'; payload: { value: any } }

export const reducer = (state: SmartSelectState, action: Actions): SmartSelectState => {
  switch (action.type) {
    case 'set-search': {
      return {
        ...state,
        search: action.payload.value,
        page: 1,
      }
    }
    case 'set-selected': {
      const { value } = action.payload

      return {
        ...state,
        selected: value,
      }
    }
    case 'set-total': {
      return {
        ...state,
        total: action.payload.value,
      }
    }
    case 'set-page': {
      return {
        ...state,
        page: action.payload.value,
      }
    }
    case 'set-anchor': {
      return {
        ...state,
        anchor: action.payload.value,
      }
    }
  }
  return state
}
