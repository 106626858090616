import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const modalFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      display: 'flex',
      alignItems: 'center',
      bottom: 0,
      padding: theme.spacing(2, 3),
      borderTop: '1px solid #EEEEEE',
      backgroundColor: '#FFF',
      zIndex: 5,
    },
  })
)

export default modalFooterStyles
