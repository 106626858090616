// Core
import React, { createElement, ElementType, FC, useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
// Components
import CrudFormController from '../crud-form-controller'
import FormPageSkeleton from 'modules/layout/components/form-page-skeleton'
// Hooks
import { useNotify, useUnsavedChangesWarning } from 'core/hooks'
import { LanguageProvider, useUpdate } from 'core/data'
// Types
import { ResourceProps } from 'core/data'
import { ActionsData } from 'core/data/types/actions'
// Utils
import { transformResponseValidationData } from 'core/utils'
import { httpService } from 'core/data'

type TProps = {
  crudForm: ElementType
  sidebar?: any
  validationSchema?: any
  onSuccess?: () => void
  initialData?: any
  actionsData?: ActionsData
} & ResourceProps

const Edit: FC<TProps> = (props) => {
  const {
    crudForm,
    name,
    sidebar,
    validationSchema,
    onSuccess,
    initialData = {},
    actionsData,
  } = props

  const {
    prompt,
    methods: { setRef, savedChanges },
  } = useUnsavedChangesWarning()
  const history = useHistory()
  const { t } = useTranslation()
  const { id } = useParams()
  const queryClient = useQueryClient()

  const notify = useNotify()

  const createSuccessHandler = useCallback(() => {
    notify(t('notify.updated', { name }), {
      type: 'success',
    })

    queryClient.invalidateQueries(name)
    if (onSuccess) onSuccess()
  }, [name, notify, queryClient, t, onSuccess])

  const { data, isLoading, isFetching } = useQuery(
    [name, id],
    async () => {
      const response = await httpService.get<any>(`/${name}/${id}`)
      return response.data
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
      onError() {
        history.replace(`/not-found`)
      },
    }
  )

  const { error, mutateAsync: update, isLoading: mutationIsLoading } = useUpdate(name)

  const onSubmitHandler = useCallback(
    (values) => {
      savedChanges()

      return update(
        { id, values },
        {
          onSuccess: createSuccessHandler,
        }
      )
    },
    [createSuccessHandler, id, savedChanges, update]
  )

  const formErrors = useMemo(
    // @ts-ignore
    () => transformResponseValidationData(error ? error.response?.data?.violations : null),
    [error]
  )

  if (isLoading) {
    return <FormPageSkeleton hasSidebar={Boolean(props.sidebar)} />
  }

  console.log(data)

  return (
    <LanguageProvider>
      <CrudFormController
        type="edit"
        onSubmit={onSubmitHandler}
        initialProps={{ ...data, ...initialData }}
        errors={formErrors}
        sidebar={sidebar}
        setRef={setRef}
        validationSchema={validationSchema}
        loading={isFetching || mutationIsLoading}
        actionsData={actionsData}
        resourceName={name}
      >
        {crudForm ? createElement(crudForm, { ...props }) : <div />}
      </CrudFormController>
      {prompt}
    </LanguageProvider>
  )
}
export default Edit
