import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
import { filterSupportedTypes } from 'modules/eav/entity/filters/utils'
import { HydraResponse } from 'core/types'
import { Attribute, AttributeType } from 'modules/eav/types'

type Params = {
  entityType: string
}

type AttrsRes = HydraResponse<Attribute>
type AttrTypesRes = HydraResponse<AttributeType>

const fetchEntityTypeAttrs = (entityType: string) => {
  return httpService
    .get<AttrsRes>(`/entity_types/${entityType}/attributes`)
    .then((res) => res.data['hydra:member'])
}

const fetchRelevantAttributes = async () => {
  const attrTypes = await httpService
    .get<AttrTypesRes>('/attribute_types', {
      params: { pagination: false },
    })
    .then((res) => res.data)

  const filteredByRelevantTypes = attrTypes['hydra:member'].filter((attrType) =>
    filterSupportedTypes.includes(attrType.type)
  )

  const typesIris = filteredByRelevantTypes.map((attrType) => attrType['@id'])

  const attrs = await httpService
    .get<AttrsRes>('/attributes', { params: { pagination: false, attributeType: typesIris } })
    .then((res) => res.data)

  return attrs['hydra:member']
}

export default function useGetFilterData(params: Params) {
  const { entityType } = params

  const dataQ = useQuery(
    ['global-search-filters-data', entityType],
    async () => {
      return entityType ? fetchEntityTypeAttrs(getIdFromIri(entityType)) : fetchRelevantAttributes()
    },
    {
      select: (data) => {
        let filtersData: Attribute[] = []

        data.forEach((attrItem) => {
          filtersData.push(attrItem)
        })

        filtersData = filtersData.filter(
          (elem, index, arr) => arr.findIndex((e) => e.slug === elem.slug) === index
        )

        filtersData = filtersData.filter((elem) =>
          filterSupportedTypes.includes(elem.attributeType.type)
        )

        return filtersData
      },
    }
  )

  return {
    data: dataQ.data,
    isLoading: dataQ.isLoading,
  }
}
