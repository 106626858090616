//Core
import React, { FC, useCallback } from 'react'
// Icons
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
// Style
import useStyles from './widget-item.styles'

type TProps = {
  data?: any
  onSelect: (widgetType: any) => void
}

const WidgetItem: FC<TProps> = (props) => {
  const { data, onSelect } = props
  const classes = useStyles()

  const clickHandler = useCallback(() => {
    onSelect(data)
  }, [data, onSelect])

  return (
    <button type="button" className={classes.root} onClick={clickHandler}>
      <ChromeReaderModeIcon className={classes.widgetIcon} />
      <div className={classes.text}>{data.name}</div>
    </button>
  )
}

export { WidgetItem }
