// Core
import React, { FC, useCallback, useMemo } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import clsx from 'clsx'
// Components
import { Box, Chip, Collapse, Icon, IconButton, Tooltip } from '@material-ui/core'
import { WidgetContent, PersonalizedIndicator, WidgetWidth } from './components'
import { Actions } from 'modules/new-entity/components/page-builder/components'
import GroupError from 'modules/new-entity/components/attributes-form/components/group-error'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
// Hooks
import { useTranslation } from 'react-i18next'
import { useConfirmation } from 'core/confirmation'
import { usePageBuilderContext } from 'modules/new-entity/context'
import { useValidationErrorsContext } from 'modules/new-entity/context/validation-errors-context'
// Utils
import { getErrorsCount } from 'modules/new-entity/components/attributes-form/utils'
// Types
import { IEntityWidget } from 'modules/new-entity/types'
import { Actions as ActionsType } from 'modules/new-entity/components/page-builder/types'
// Styles
import useStyles from './widget-item.styles'

type TProps = {
  data: IEntityWidget
  disableMoveUp: boolean
  disableMoveDown: boolean
  isDraggingOver: boolean
  previewMode?: boolean
  dnd?: DraggableProvidedDragHandleProps
  submittedOnce?: boolean
  disabled?: boolean
  isDragging?: boolean
}

const WidgetItem: FC<TProps> = (props) => {
  const {
    data,
    disableMoveUp,
    disableMoveDown,
    isDraggingOver,
    previewMode,
    dnd = {},
    submittedOnce,
    disabled,
    isDragging,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()

  const removeWithConfirmation = useCallback(
    (onSuccess: () => void) => {
      setConfirmation({
        open: true,
        title: t('global.delete'),
        content: t('notify.delete-widget'),
        onSuccess,
      })
    },
    [setConfirmation, t]
  )

  const {
    widgetsDataRef,
    actions: {
      removeWidget,
      moveWidgetUp,
      moveWidgetDown,
      changeWidgetWidth,
      changeWidgetVisibility,
      isWidgetExpanded,
      toggleWidget,
      setWidgetRefData,
      setGlobalWidget,
      duplicateWidget,
    },
  } = usePageBuilderContext()

  const { widgetsErrors = {} } = useValidationErrorsContext()
  const apiErrors = widgetsErrors[`${data.realIndex}`] || {}
  const errorsCount = getErrorsCount(apiErrors)

  const isOpen = isWidgetExpanded(data.options.container, data.id)

  const prevValues =
    widgetsDataRef.current && widgetsDataRef.current[data.id]
      ? widgetsDataRef.current[data.id].formRef?.values
      : null

  const aqaTestDataAttr = useMemo(() => {
    return {
      'data-desktop-width': data.options.desktop_width,
      'data-container': data['container-slug'] || '',
      'data-widget-id': `${data.widgetType.slug}-${data.id}`,
    }
  }, [data])

  const isWidgetHidden = data.options.isHidden

  const isGlobalWidget = Boolean(data.widget)

  const disabledOrPreview = Boolean(disabled || previewMode)

  const widgetActions = useMemo(
    (): ActionsType => ({
      moveUpAction: {
        action: () => moveWidgetUp(data.id),
        condition: !disableMoveUp,
      },
      moveDownAction: {
        action: () => moveWidgetDown(data.id),
        condition: !disableMoveDown,
      },
      duplicateAction: {
        action: () => duplicateWidget(data.id),
        condition: true,
      },
      changeVisibilityAction: isWidgetHidden
        ? {
            name: 'show',
            label: 'Show',
            action: () => changeWidgetVisibility(data.id, !isWidgetHidden),
            condition: true,
            icon: 'icon-eye',
          }
        : {
            name: 'hide',
            label: 'Hide',
            action: () => changeWidgetVisibility(data.id, !isWidgetHidden),
            condition: true,
            icon: 'icon-eye-off',
          },
      deleteAction: {
        action: () => removeWithConfirmation(() => removeWidget(data.id)),
        condition: true,
      },
    }),
    [
      changeWidgetVisibility,
      data.id,
      disableMoveDown,
      disableMoveUp,
      duplicateWidget,
      isWidgetHidden,
      moveWidgetDown,
      moveWidgetUp,
      removeWidget,
      removeWithConfirmation,
    ]
  )

  const toggle = useCallback(
    () => toggleWidget(data.options.container, data.id),
    [data.id, data.options.container, toggleWidget]
  )

  return (
    <Box
      className={clsx(classes.root, {
        [classes.isPreview]: previewMode,
        [classes.isGlobal]: isGlobalWidget,
        [classes.isDraggingOver]: isDraggingOver,
        error: errorsCount > 0,
      })}
      data-error-id={data.id}
      {...aqaTestDataAttr}
    >
      <div className={classes.header}>
        <span onClick={toggle} className={classes.toggleWidget} />

        <Tooltip
          placement="top"
          title={`${t('page-builder.widget.drag')}`}
          classes={isDragging ? { popper: classes.hidden } : {}}
        >
          <div
            className={clsx(classes.dndBtn, { [classes.dndBtnDisabled]: disabledOrPreview })}
            {...dnd}
          >
            <Icon className="icon-dragindrop" />
          </div>
        </Tooltip>
        <div className={classes.content}>
          <IconButton size="small" onClick={toggle} className={classes.toggleBtn} disableRipple>
            <Tooltip
              placement="top"
              title={`${
                !isOpen ? t('page-builder.widget.expand') : t('page-builder.widget.collapse')
              }`}
            >
              <Icon className={`icon-chevron-${!isOpen ? 'right' : 'down'}`} />
            </Tooltip>
          </IconButton>
          <p className={classes.info}>
            <ChromeReaderModeIcon className={classes.widgetIcon} />
            {data?.isCreated && <strong>(Not saved) -</strong>}
            <span>{data.widgetType ? data.widgetType?.name : ''}</span>
            {data?.name && (
              <>
                <span>/</span>
                <strong>{data?.name}</strong>
              </>
            )}
            <span id={`widget-title-error-${data.id}`} />
            <GroupError count={errorsCount} />
            {isGlobalWidget && (
              <Chip
                component="span"
                label={t('chips.global')}
                size="small"
                className={classes.chip}
              />
            )}
            {isWidgetHidden && (
              <Chip
                component="span"
                label={t('chips.hidden')}
                size="small"
                className={classes.chip}
              />
            )}
            <PersonalizedIndicator widgetIri={data.widget} />
          </p>
          <div className={classes.settings}>
            <WidgetWidth
              widgetId={data.id}
              widgetWidth={data.options.desktop_width}
              changeWidgetWidth={changeWidgetWidth}
              disabled={disabledOrPreview}
            />
            <Actions actions={widgetActions} disabled={disabledOrPreview} />
          </div>
        </div>
      </div>
      <Collapse
        className={clsx(classes.expandPanel, { [classes.expandPanelOpen]: isOpen })}
        in={isOpen}
        timeout={300}
      >
        <WidgetContent
          data={data}
          setWidgetRefData={setWidgetRefData}
          setGlobalWidget={setGlobalWidget}
          disabled={disabledOrPreview}
          prevValues={prevValues}
          needRenderForm={isOpen}
          submittedOnce={submittedOnce}
        />
      </Collapse>
    </Box>
  )
}

export default WidgetItem
