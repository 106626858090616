import { Identifier, Entity } from 'core/types'
import { useQuery } from 'react-query'
import { httpService } from 'core/data'

type ReturnType = {
  loading: boolean
  data: Entity
}

// TODO: Think about getting cached data from store
export const useGetOne = (resource: string, id: Identifier, selector?: any): ReturnType => {
  const { data, isLoading } = useQuery([resource, id, selector], async () => {
    if (!id) return false

    return httpService.get(`/${resource}/${id}`).then((res: any) => res.data)
  })

  return {
    data,
    loading: isLoading,
  }
}
