// Core
import React, { FC, useCallback } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
// Components
import Actions from 'ui/relations-select/actions'
import { RelationsSelectItem } from 'ui/relations-select/relations-select-item'
// Hooks
import useEntitiesList from 'modules/eav/hooks/use-entities-list'
// Utils
import { getIdFromIri } from 'core/utils'
// Types
import { EntityListItem } from 'modules/eav/types'

type Props = {
  siteId: number
  onSelect: (value: string) => void
  value: string[]
  search: string
}

export const SiteItem: FC<Props> = ({ siteId, onSelect, value, search }) => {
  const { isLoading, data, page, totalCount, setPage, site } = useEntitiesList({
    siteId,
    requestParams: {
      original: true,
    },
    filters: {
      _name: {
        contain: search,
      },
      status: {
        equal: 'publish',
      },
    },
  })

  const getViewPath = useCallback(
    (listItem: EntityListItem) => {
      const entityTypeId = getIdFromIri(listItem.entityType)
      return `/${siteId}/entities/${entityTypeId}/edit/${listItem.id}`
    },
    [siteId]
  )

  return (
    <Box>
      {isLoading && (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {data?.map((item) => {
        const defaultLocalization = site && getIdFromIri(site.defaultLocale)
        const localization = item.localization || defaultLocalization
        const localizationString = localization ? ` (${localization})` : ''
        const title = `${item.name}${localizationString}`

        return (
          <RelationsSelectItem
            key={item['@id']}
            urls={item.entityUrls}
            label={title}
            button
            onClick={() => onSelect(item['@id'])}
            selected={value.includes(item['@id'])}
            actionsHolder={<Actions valueData={item} viewAction={() => getViewPath(item)} />}
          />
        )
      })}
      {!isLoading && (
        <Pagination
          page={page}
          count={Math.round(totalCount / 30)}
          onChange={(e, page) => setPage(page)}
        />
      )}
    </Box>
  )
}
