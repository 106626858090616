import React, { FC } from 'react'
import { booleanValues } from 'common/components/options-modal/utils'
import { SelectField } from 'core/components'

type TProps = {
  value?: string
}

const BooleanConditionField: FC<TProps> = (props) => {
  const { value } = props

  return <SelectField data={booleanValues} name="conditionValue" value={value} />
}

export default BooleanConditionField
