// Core
import React, { FC } from 'react'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
// Components
import { EntitySettingItem, EntityTypeSettingItem } from './components'
import { useTranslation } from 'react-i18next'
// Hooks
import { useSitemapSettingsContext } from '../../hooks'
import { Permissions, useAppContext } from 'core/app'
// Types
import { SettingsType } from '../../types'
// Styles
import useStyles from './settings-table.styles'

type Props = {
  type: SettingsType
}

const SettingsTable: FC<Props> = (props) => {
  const { type } = props
  const classes = useStyles()
  const { openSettingsModal, settingsData } = useSitemapSettingsContext()
  const { t } = useTranslation()
  const {
    actions: { userCan },
  } = useAppContext()
  const permissionType = type === SettingsType.entity ? 'sitemap_urls' : 'sitemap_indices'

  if (settingsData.isLoading) return <div>{t('global.loading')}</div>

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {type === SettingsType.entity ? t('sitemap.individual') : t('sitemap.general')}
      </div>
      <TableContainer style={{ maxHeight: '400px' }} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {type === SettingsType.entityType ? (
                <>
                  <TableCell size="small">{t('sitemap.entity-type')}</TableCell>
                  <TableCell size="small">{t('sitemap.priority')}</TableCell>
                  <TableCell size="small">{t('sitemap.frequency')}</TableCell>
                  <TableCell size="small">{t('sitemap.date')}</TableCell>
                  <TableCell size="small">{t('sitemap.divide-by')}</TableCell>
                </>
              ) : (
                <>
                  <TableCell size="small">{t('sitemap.entity')}</TableCell>
                  <TableCell size="small">{t('sitemap.link')}</TableCell>
                  <TableCell size="small">{t('sitemap.priority')}</TableCell>
                  <TableCell size="small">{t('sitemap.frequency')}</TableCell>
                </>
              )}
              <TableCell align="right">{t('sitemap.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {type === SettingsType.entityType &&
              settingsData.data?.entityTypeSettings.map((item) => (
                <EntityTypeSettingItem key={item.id} data={item} />
              ))}
            {type === SettingsType.entity &&
              settingsData.data?.entitySettings.map((item) => (
                <EntitySettingItem key={item.id} data={item} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {userCan(permissionType, Permissions.CREATE, 'system') && (
        <div className={classes.footer}>
          <Button color="primary" variant="contained" onClick={() => openSettingsModal(type)}>
            {t('global.add')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default SettingsTable
