// Core
import React, { FC, useCallback } from 'react'
import { DateTimePicker as MaterialDateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import ClearIcon from '@material-ui/icons/Clear'
import { IconButton } from '@material-ui/core'
import useFieldController from 'core/form/hooks/use-field-controller'

type TProps = {
  name: string
  label: string
  readonly?: boolean
  system?: boolean
  slug?: string
  disabled?: boolean
  required?: boolean
}

const DateTimePicker: FC<TProps> = (props) => {
  const { name, label, readonly, system, disabled, required } = props
  const [field, { hasError, errorMsg }, { setValue }] = useFieldController(name)

  const handleChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date) return null
      setValue(date.toISOString())
    },
    [setValue]
  )

  const handleClr = useCallback(
    (e: any) => {
      e.stopPropagation()
      setValue(null)
    },
    [setValue]
  )

  return (
    <MaterialDateTimePicker
      ampm={false}
      format="DD/MM/yyyy HH:mm"
      label={label}
      fullWidth
      variant="inline"
      inputVariant="outlined"
      disabled={disabled}
      {...field}
      onChange={handleChange}
      error={hasError}
      helperText={errorMsg}
      InputProps={
        !system
          ? {
              endAdornment: (
                <IconButton size={'small'} onClick={(e) => handleClr(e)}>
                  <ClearIcon fontSize={'small'} />
                </IconButton>
              ),
            }
          : {}
      }
      readOnly={readonly}
      required={required}
    />
  )
}

export default DateTimePicker
