// Core
import React, { FC } from 'react'
import { Dialog } from '@material-ui/core'
// Components
import { DialogTitle } from 'ui'
import { EntityTypeSettings, EntitySettings } from './components'
// Hooks
import { useSitemapSettingsContext } from '../../hooks'
// Types
import { SettingsType } from '../../types'

type Props = {}

const CreateSettingsModal: FC<Props> = () => {
  const {
    //
    settingsModalState,
    closeSettingsModal,
    createEntityTypeSetting,
    createEntitySetting,
  } = useSitemapSettingsContext()

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={Boolean(settingsModalState)}
      onClose={() => closeSettingsModal()}
    >
      <DialogTitle>Add settings</DialogTitle>
      {settingsModalState?.type === SettingsType.entityType ? (
        <EntityTypeSettings onCancel={closeSettingsModal} onSubmit={createEntityTypeSetting} />
      ) : (
        <EntitySettings onCancel={closeSettingsModal} onSubmit={createEntitySetting} />
      )}
    </Dialog>
  )
}

export default CreateSettingsModal
