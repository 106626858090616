import { MenuItem } from '@material-ui/core'
import { useGetAttributeData } from 'common/hooks'
import React, { forwardRef } from 'react'
import { WordBreakWrapper } from 'ui'
import { AttributeTypesList } from 'common/types'
import { useTranslation } from 'react-i18next'

const VALID_ATTR_TYPES = [
  AttributeTypesList.select,
  AttributeTypesList.string,
  AttributeTypesList.text,
  AttributeTypesList.boolean,
]

// TODO: to typify the component
const SelectItem = forwardRef<any, any>((props, ref) => {
  const { attrIri, ...rest } = props
  const { data: attrData, isLoading, error } = useGetAttributeData(attrIri)
  const { t } = useTranslation()

  if (!VALID_ATTR_TYPES.includes(attrData.attributeType?.type)) {
    return null
  }

  if (isLoading) return <div>{t('global.loading')}</div>
  if (error) return <div>{t('global.error')}</div>

  return (
    <MenuItem ref={ref} {...rest}>
      <WordBreakWrapper children={attrData.name} />
    </MenuItem>
  )
})

export default SelectItem
