import React, { FC } from 'react'
import { ResourceProps } from 'core/data'
import { DataView } from 'core/data'
import { getActionsColumns, getTableColumn } from 'core/data/components/data-view'
import CheckIcon from '@material-ui/icons/Check'
import { useTypeRosourceContext } from '../../hooks'
import { useTranslation } from 'react-i18next'

const WidgetTypeList: FC<ResourceProps> = (props) => {
  const {
    options: { resourceName },
  } = useTypeRosourceContext()

  const { t } = useTranslation()

  return (
    <DataView
      resourceProps={props}
      columns={[
        /** */
        getActionsColumns(props.name, t),
        getTableColumn({ title: t('table.name'), field: 'name' }),
        getTableColumn({ title: t('table.slug'), field: 'slug' }),
        ['entity_types'].includes(resourceName) &&
          getTableColumn({
            title: t('table.urlable'),
            render: (rowData: any) => (rowData.urlable ? <CheckIcon /> : null),
          }),
        // ['entity_types'].includes(resourceName) &&
        //   getTableColumn({
        //     title: t('table.system'),
        //     render: (rowData: any) => (rowData.type === 'system' ? <CheckIcon /> : null),
        //   }),
      ]}
    />
  )
}

export default WidgetTypeList
