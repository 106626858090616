// Core
import React, { FC, useState } from 'react'
import { TextField, Box, Button } from '@material-ui/core'
// Components
import ChangePasswordModal from 'modules/auth/components/change-password-modal'
// Hooks
import { useNotify } from 'core/hooks'
import { useAppContext } from 'core/app'
// Utils/Services
import AuthService from 'modules/auth/services/auth-service'
import { storageService } from 'core/data'
// Types
import { ChangePasswordPayload } from 'modules/auth/types'

const ChangePassword: FC = () => {
  const { user } = useAppContext()
  const notify = useNotify()
  const [changeModalOpen, setChangeModalOpen] = useState(false)
  const [resetLoading, setResetLoading] = useState(false)

  const changePasswordHandler = async (data: ChangePasswordPayload) => {
    await AuthService.changePassword(user.id, data)
    const res = await AuthService.loginByEmail({ email: user.email, password: data.newPassword })
    storageService.login(res.data.token, res.data.refresh_token)
    setChangeModalOpen(false)
    notify('Password changed successfully', { type: 'success' })
  }

  const resetPasswordHandler = async () => {
    try {
      setResetLoading(true)
      await AuthService.forgotPassword({ email: user.email })
      setChangeModalOpen(false)
      notify('Email sent successfully', { type: 'success' })
    } catch (error) {
      notify('Something went wrong', { type: 'error' })
    } finally {
      setResetLoading(false)
    }
  }

  return (
    <Box display="flex" style={{ gap: 16 }} justifyContent="space-between">
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        value="12345678"
        disabled
        style={{ width: '50%' }}
      />
      <Button color="primary" onClick={() => setChangeModalOpen(true)}>
        Change password
      </Button>
      {changeModalOpen && (
        <ChangePasswordModal
          open={changeModalOpen}
          onClose={() => setChangeModalOpen(false)}
          onSubmit={changePasswordHandler}
          onResetClick={resetPasswordHandler}
          resetLoading={resetLoading}
        />
      )}
    </Box>
  )
}

export default ChangePassword
