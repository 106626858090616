import { TUseGetData } from 'common/types'
import { useFormikContext } from 'formik'
import { useMemo } from 'react'

export const useGetData: TUseGetData = (activeAttr, parentData, isNested) => {
  const {
    values: { setAttributes, entityTypeAttributes, widgetTypeAttributes, options },
  } = useFormikContext<any>()

  const keepData = useMemo(() => {
    const isParentData = parentData || setAttributes
    const attributes = entityTypeAttributes || widgetTypeAttributes
    const newData = isNested ? isParentData : attributes
    const filteredData = newData.filter((item: any) => {
      let isRelationNotExist: boolean = true
      const dependsItem =
        options &&
        options.attributeOptions &&
        options.attributeOptions[item.attribute]?.condition.dependsOn
      if (activeAttr['@id'] === dependsItem) {
        isRelationNotExist = false
      }
      return isRelationNotExist && item.attribute !== activeAttr['@id']
    })
    return {
      newData,
      filteredData,
    }
  }, [
    activeAttr,
    entityTypeAttributes,
    isNested,
    options,
    parentData,
    setAttributes,
    widgetTypeAttributes,
  ])

  return keepData
}
