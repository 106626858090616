import cloneDeep from 'lodash.clonedeep'

export const filterParams = (listParams: any, removedFilter: string) => {
  const {
    params: { filters },
  } = listParams
  if (filters.search && filters[removedFilter]) {
    const clonedFilters = cloneDeep(filters)
    delete clonedFilters[removedFilter]

    return {
      ...listParams,
      params: {
        ...listParams.params,
        filters: {
          ...clonedFilters,
        },
      },
    }
  }
  return listParams
}

export const transformParams = (query: any, defaultFiltersInput: any) => {
  const defaultFilters = defaultFiltersInput || {}

  const preparedFilters = {
    page: query.params?.pagination?.page,
    itemsPerPage: query.params?.pagination?.perPage,
    ...defaultFilters,
    ...query.params?.filters,
    ...query.params?.sort,
  }

  // Quick fix for media, empty folder sensitive for API
  if (preparedFilters.folder === '') {
    delete preparedFilters.folder
  }

  return preparedFilters
}
