// Core
import React, { FC, useCallback } from 'react'
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Form, Formik, FormikHelpers } from 'formik'
import { Alert } from '@material-ui/lab'
import * as yup from 'yup'
import { isAxiosError } from 'axios'
// Components
import { SubmitButton, TextField } from 'core/form'
import MfaModal from '../mfa-modal'
// Hooks
import useLoginController from 'modules/auth/hooks/use-login-controller'
// Types
import { LoginPayload } from 'modules/auth/types'

type Props = {
  onSuccessLogin: (token: string, refreshToken: string) => void
}

const initialValues: LoginPayload = {
  email: '',
  password: '',
}

const validationSchema = yup.object<LoginPayload>({
  email: yup.string().email().required(),
  password: yup.string().required().min(3),
})

const ExpiredSessionModal: FC<Props> = (props) => {
  const { onSuccessLogin } = props
  const { t } = useTranslation()

  const { mfaOpen, mfaState, closeMfa, login2FA, login } = useLoginController({ onSuccessLogin })

  const submitHandler = useCallback(
    async (values: LoginPayload, formHelpers: FormikHelpers<LoginPayload>) => {
      try {
        await login(values)
      } catch (e) {
        if (isAxiosError(e)) {
          formHelpers.setFieldError('email', e.response?.data?.message || 'Something went wrong')
        }
      }
    },
    [login]
  )

  if (mfaOpen && mfaState) {
    return <MfaModal isOpen={mfaOpen} onClose={closeMfa} mfaData={mfaState} onSubmit={login2FA} />
  }

  return (
    <Dialog open fullWidth maxWidth="sm">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        <Form>
          <DialogContent>
            <Box mb={1}>
              <Typography variant="h6">Session Expired</Typography>
            </Box>
            <Alert severity="info">Your session has expired due to inactivity</Alert>
            <Box mt={2}>
              <TextField
                name="email"
                placeholder={t('login.email-field')}
                label={t('login.email-field')}
              />
            </Box>
            <Box mt={2}>
              <TextField
                name="password"
                type="password"
                placeholder={t('login.pass-field')}
                label={t('login.pass-field')}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <SubmitButton title="Sign In" />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default ExpiredSessionModal
