// Core
import React, { FC } from 'react'
import { Box, Button, Divider } from '@material-ui/core'

type Props = {
  text: string
  spacing?: number
}

const OperatorDivider: FC<Props> = ({ text, spacing = 1 }) => (
  <Box mt={spacing} position="relative" display="flex" alignItems="center">
    <Divider style={{ position: 'absolute', width: '100%' }} />
    <Button
      style={{
        position: 'relative',
        zIndex: 10,
        background: '#fff',
        paddingLeft: 0,
        paddingRight: 16,
        minWidth: 'auto',
        pointerEvents: 'none',
      }}
    >
      {text}
    </Button>
  </Box>
)

export default OperatorDivider
