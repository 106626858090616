import { TsetPreviewValues } from 'common/types'

export const setPreviewValues: TsetPreviewValues = (attrIri, optionsAttr, attrData: any) => {
  const optionsArray: any = optionsAttr
  const isAttrIri: any = attrIri
  let initOptionsArray: any = {}
  const currentAttr = attrData?.find((attr: any) => attr.attribute === attrIri)

  const entityTypeAttrValues = {
    nameable: currentAttr?.nameable || false,
    showAsColumn: currentAttr?.showAsColumn || false,
    columnOrder: currentAttr?.columnOrder || 0,
  }

  if (optionsArray && optionsArray.attributeOptions && optionsArray.attributeOptions[isAttrIri]) {
    const {
      condition: { dependsOn, conditionValue },
      fieldWidth,
      hidden,
    } = optionsArray.attributeOptions[isAttrIri]

    initOptionsArray = {
      dependsOn,
      conditionValue,
      fieldWidth,
      hidden,
      ...entityTypeAttrValues,
    }
  }

  return initOptionsArray
}
