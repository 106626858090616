// Core
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
// Context
import { SitemapSettingsContext } from './sitemap-settings-context'
// Services
import { httpService } from 'core/data'
// Types
import {
  SettingsData,
  SettingsModalState,
  SettingsType,
  SitemapIndex,
  SitemapPage,
  SitemapSettingsContextProps,
  Entity,
} from '../types'
import { HydraResponse } from 'core/types'

type Props = {}

const SitemapSettingsProvider: FC<Props> = ({ children }) => {
  const [settingsModalState, setSettingsModalState] = useState<SettingsModalState | null>(null)
  const queryClient = useQueryClient()

  const data = useQuery<SettingsData>('sitemap-settings-data', async () => {
    const indexRes = await httpService.get<HydraResponse<SitemapIndex>>('/sitemap_indices')
    const pagesRes = await httpService.get<HydraResponse<SitemapPage>>('/sitemap_urls')

    return {
      entityTypeSettings: indexRes.data['hydra:member'],
      entitySettings: pagesRes.data['hydra:member'],
    }
  })

  const entityTypeSettingMutation = useMutation((data: SitemapIndex) => {
    return httpService.post('/sitemap_indices', data)
  })

  const entityMutation = useMutation((data: SitemapPage) => {
    return httpService.post('/sitemap_urls', data)
  })

  const createEntityTypeSetting = useCallback(
    async (entityType: string) => {
      const data: SitemapIndex = {
        entityType,
        frequency: 'daily',
        priority: 1,
        lastModifiedEnabled: false,
        dividedByPeriod: false,
      }

      await entityTypeSettingMutation.mutateAsync(data)
      await queryClient.invalidateQueries('sitemap-settings-data')
      setSettingsModalState(null)
    },
    [entityTypeSettingMutation, queryClient]
  )

  const createEntitySetting = useCallback(
    async (entityType: string, entity: string) => {
      const entityTypeSettings = data.data?.entityTypeSettings.find(
        (item) => item.entityType === entityType
      )

      const { data: entityData } = await httpService.get<Entity>(`/entities/${entity}`)

      const settingsData: SitemapPage = {
        entityUrl: entityData.entityUrls[0].id,
        frequency: entityTypeSettings?.frequency || 'daily',
        priority: entityTypeSettings?.priority || 1,
      }

      await entityMutation.mutateAsync(settingsData)
      await queryClient.invalidateQueries('sitemap-settings-data')
      setSettingsModalState(null)
    },
    [data.data?.entityTypeSettings, entityMutation, queryClient]
  )

  const contextProps = useMemo<SitemapSettingsContextProps>(
    () => ({
      settingsModalState,
      openSettingsModal: (type: SettingsType) => setSettingsModalState({ type }),
      closeSettingsModal: () => setSettingsModalState(null),
      createEntityTypeSetting,
      createEntitySetting,
      settingsData: data,
    }),
    [createEntitySetting, createEntityTypeSetting, data, settingsModalState]
  )

  return (
    <SitemapSettingsContext.Provider value={contextProps}>
      {children}
    </SitemapSettingsContext.Provider>
  )
}

export default SitemapSettingsProvider
