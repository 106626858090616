// Core
import { Box } from '@material-ui/core'
import React, { FC } from 'react'
// Components
import EntityFilters from 'modules/eav/entity/filters'
// Types
import { FiltersProps } from 'modules/eav/entity/filters/types'

type Props = {
  data: any[]
  isLoading: boolean
} & FiltersProps

const Filters: FC<Props> = (props) => {
  const {
    data,
    isLoading,
    addFilter,
    removeFilter,
    changeFilterCondition,
    changeFilterValue,
    clearAllFilters,
    selectedFilters,
  } = props

  return (
    <Box mt={1} display="flex" flexWrap="wrap" alignItems="center">
      <EntityFilters
        isLoading={isLoading}
        data={data}
        addFilter={addFilter}
        removeFilter={removeFilter}
        changeFilterCondition={changeFilterCondition}
        changeFilterValue={changeFilterValue}
        clearAllFilters={clearAllFilters}
        selectedFilters={selectedFilters}
        loadingSkeleton={false}
      />
    </Box>
  )
}

export default Filters
