// Core
import React, { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { useModalContainer } from '../../hooks'
import { useTranslation } from 'react-i18next'

const Modal: FC = () => {
  const { open, text, closeHandler, confirmHandler } = useModalContainer()
  const { t } = useTranslation()

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={closeHandler}>
      <DialogTitle>{t('global.confirm')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} color="primary">
          {t('notify.disagree')}
        </Button>
        <Button onClick={confirmHandler} color="primary" autoFocus>
          {t('notify.agree')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Modal
