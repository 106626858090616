import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const actionButtonsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // maxWidth: theme.spacing(10),
      '& > span': {
        color: theme.palette.text.secondary,
      },
    },
    iconOpen: {
      transform: 'scale(-1)',
    },
  })
)

export default actionButtonsStyles
