// Core
import React, { FC, useCallback } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
// Hooks
import { useTimer } from './use-timer'
// Types
import { AskControlData } from './types'

type Props = {
  data: AskControlData
  onClose: () => void
  onApproveControl: (withSave?: boolean) => void
  saveLoading: boolean
}

const ControlRequestScreen: FC<Props> = (props) => {
  const { data, onClose, onApproveControl, saveLoading } = props
  const { counter } = useTimer(true)

  const closeHandler = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog open onClose={closeHandler}>
      <DialogContent>
        <b>{data.userName}</b> is asking to take editing control ({counter})
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler} color="secondary">
          Deny
        </Button>
        <Button
          onClick={() => onApproveControl(true)}
          disabled={saveLoading}
          variant="contained"
          color="primary"
        >
          Save and Approve
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ControlRequestScreen
