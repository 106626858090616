// Core
import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
// Components
import { SelectedAttributeItem } from '../../../index'
// Types
import { Attribute } from 'core/types/attribute'
import { ReferenceTitle } from './components'
import { useTranslation } from 'react-i18next'

type TProps = {
  attrData: Attribute
}

const ReferencesPreview: FC<TProps> = (props) => {
  const { attrData } = props
  const { t } = useTranslation()

  return (
    <>
      {attrData.entityType && (
        <Typography component={'div'} variant={'caption'}>
          <strong>{t('labels.ref-to')} </strong>
          <ReferenceTitle referenceIri={attrData.entityType} />
        </Typography>
      )}

      {attrData.entityTypes.length !== 0 && (
        <Typography component={'div'} variant={'caption'}>
          <strong>{t('labels.ref-to')} </strong>
          {attrData.entityTypes.map((item: string, index: number) => {
            return (
              <span key={item}>
                <ReferenceTitle referenceIri={item} />
                {attrData.entityTypes.length - 1 !== index && ', '}
              </span>
            )
          })}
        </Typography>
      )}

      {attrData.referenceOwner && (
        <>
          <Typography variant={'caption'}>
            <strong>{t('labels.ref-owner')}:</strong>{' '}
          </Typography>
          <SelectedAttributeItem
            disabled
            data={{ attribute: attrData.referenceOwner }}
            disableOptions
          />
        </>
      )}
    </>
  )
}

export default ReferencesPreview
