import React, { FC } from 'react'
import globalAttributeItemStyles from './global-attribute-item.styles'
import { CardActionArea, Grid, Icon } from '@material-ui/core'
import clsx from 'clsx'
import { Attribute } from 'core/types/attribute'
import { useTranslation } from 'react-i18next'

type TProps = {
  item: Attribute
  isActive: boolean
  clickHandler: any
  isSystem: boolean
}

const GlobalAttributeItem: FC<TProps> = (props) => {
  const classes = globalAttributeItemStyles()
  const { t } = useTranslation()
  const { item, isActive, clickHandler, isSystem } = props

  return (
    <Grid
      item
      xs={6}
      md={2}
      className={clsx(classes.root, {
        system: isSystem,
      })}
    >
      <CardActionArea onClick={clickHandler} className={clsx(classes.actionArea)}>
        <div
          className={clsx(classes.iconWrap, {
            [classes.active]: isActive,
          })}
        >
          <Icon className={clsx('icon-content', classes.icon)} />
        </div>
        <p className={`${classes.line} ${classes.name}`}>
          {t('labels.name')}: {item.name}
        </p>
        <p className={classes.line}>
          {t('labels.slug')}: {item.slug}
        </p>
        <p className={`${classes.line} ${classes.type}`}>
          {t('labels.type')}: {t(`attr-types.${item.attributeType.type}`)}
        </p>
      </CardActionArea>
    </Grid>
  )
}

export default GlobalAttributeItem
