import React, { useCallback, useState } from 'react'
import { isAxiosError } from 'axios'
import { useMutation } from 'react-query'
import { CloneEntity } from './clone-entity'
import { httpService } from 'core/data'
import { useNotify } from 'core/hooks'

type ApiViolation = {
  propertyPath: string
  message: string
}

export const useCloneEntity = (entityId: number) => {
  const [modalOpen, toggleModal] = useState(false)
  const notify = useNotify()

  const cloneM = useMutation(
    (siteIris: string[]) => httpService.post(`/entities/${entityId}/cloning`, { sites: siteIris }),
    {
      onSuccess: () => {
        toggleModal(false)
        notify('The entity has been successfully cloned to the selected sites', { type: 'success' })
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          const apiErrors: ApiViolation[] = error.response?.data?.violations

          if (apiErrors) {
            const message = apiErrors.reduce((result, item, index) => {
              return `${result}${index !== 0 ? '. ' : ''}${item.message}`
            }, '')

            notify(message, { type: 'error' })
          } else {
            notify('Something went wrong', { type: 'error' })
          }
        } else {
          notify('Something went wrong', { type: 'error' })
        }
      },
    }
  )

  const openModal = useCallback(() => {
    toggleModal(true)
  }, [])

  return {
    openModal,
    Modal: modalOpen ? (
      <CloneEntity
        onClone={(sites) => cloneM.mutate(sites)}
        cloneLoading={cloneM.isLoading}
        open={modalOpen}
        onClose={() => toggleModal(false)}
      />
    ) : null,
  }
}
