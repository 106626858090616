// Core
import React, { FC, memo, useMemo } from 'react'
import { useFormikContext } from 'formik'
// Components
import { SwitcherField, TextField } from 'core/form/components'
import { RadioButtonBoolean } from './components'
// Types
import { AttributeTypesList } from 'common/types'
import { useTranslation } from 'react-i18next'

type Props = {
  children?: never
}

type FormValues = {
  type: AttributeTypesList
}

const strDefaultValues = [
  AttributeTypesList.string,
  AttributeTypesList.string_l10n,
  AttributeTypesList.text,
  AttributeTypesList.text_l10n,
]

const numDefaultValues = [AttributeTypesList.decimal, AttributeTypesList.integer]

const booleanDefaultValues = [AttributeTypesList.boolean]

const dateTimeDefaultValues = [AttributeTypesList.date_time]

const options = [
  {
    label: 'True',
    value: 'true',
  },
  {
    label: 'False',
    value: 'false',
  },
]

const DefaultValue: FC<Props> = () => {
  /**
   * Get 'type' via formik context
   * to know which fields will be render
   */
  const {
    values: { type },
  } = useFormikContext<FormValues>()
  const { t } = useTranslation()

  const showDefaultValue = useMemo(() => {
    const existStr = strDefaultValues.indexOf(type) >= 0
    const existNum = numDefaultValues.indexOf(type) >= 0
    const existBool = booleanDefaultValues.indexOf(type) >= 0
    const existDateTime = dateTimeDefaultValues.indexOf(type) >= 0

    const typeDefaultValue = () => {
      if (existBool) return 'boolean'
      if (existNum) return 'number'
      if (existDateTime) return 'dateTime'
      return 'text'
    }

    return {
      isExist: existStr || existNum || existBool || existDateTime,
      type: typeDefaultValue(),
    }
  }, [type])

  return (
    <>
      {showDefaultValue.isExist && (
        <>
          {(showDefaultValue.type === 'text' || showDefaultValue.type === 'number') && (
            <TextField
              label={t('labels.def-value')}
              name="defaultValues.value"
              type={showDefaultValue.type}
            />
          )}
          {showDefaultValue.type === 'dateTime' && (
            <SwitcherField name="defaultValue" label={t('labels.def-value-date-time')} />
          )}
          {showDefaultValue.type === 'boolean' && (
            <RadioButtonBoolean
              options={options}
              name="defaultValues.value"
              label={t('labels.def-value')}
            />
          )}
        </>
      )}
    </>
  )
}

export default memo(DefaultValue)
