// Core
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { TextField, IconButton } from '@material-ui/core'
import { Search as SearchIcon, Close } from '@material-ui/icons'
import debounce from 'lodash.debounce'
// Hooks
import { useListContext } from 'core/data'
// Styles
import useStyles from './search.styles'
import { useTranslation } from 'react-i18next'

type Props = {
  children?: never
}

const Search: FC<Props> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const {
    listParams: {
      params: { filters },
      methods,
    },
  } = useListContext()

  useEffect(() => {
    if (filters && filters.search) {
      setValue(filters.search)
    } else {
      setValue('')
    }
  }, [filters])

  const searchHandler = useCallback(
    (e: any) => {
      e.preventDefault()

      methods.setFilter('search', value)
    },
    [methods, value]
  )

  const debounceSearch = useMemo(() => debounce(methods.setFilter, 500), [methods.setFilter])

  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
      debounceSearch('search', e.target.value)
    },
    [debounceSearch]
  )

  const clearHandler = useCallback(() => {
    setValue('')
    methods.removeFilter('search')
  }, [methods])

  return (
    <div>
      <form onSubmit={searchHandler}>
        <TextField
          value={value}
          onChange={changeHandler}
          variant="outlined"
          size="small"
          placeholder={t('list.search')}
          InputProps={{
            startAdornment: <SearchIcon />,
            endAdornment: (
              <IconButton
                size="small"
                onClick={clearHandler}
                className={classes.clearButton}
                style={{ opacity: value ? 1 : 0 }}
              >
                <Close />
              </IconButton>
            ),
          }}
        />
      </form>
    </div>
  )
}

export default Search
