import { ActionTypes, IEntityLayoutState } from './types'
import { EntityLayoutsActionTypes } from './actions'
import produce from 'immer'
import { v4 as uuidv4 } from 'uuid'
import { Section, SectionContainer } from 'core/types/layouts'

const initialState: IEntityLayoutState = {
  options: {
    header: true,
    footer: true,
    sections: [],
  },
  activeElement: null,
}

const reducer = (state: IEntityLayoutState = initialState, action: EntityLayoutsActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.TOGGLE_HEADER: {
        if (draft.options.header === true) {
          draft.options.header = false
        } else {
          draft.options.header = true
        }
        break
      }
      case ActionTypes.TOGGLE_FOOTER: {
        if (draft.options.footer === true) {
          draft.options.footer = false
        } else {
          draft.options.footer = true
        }
        break
      }

      case ActionTypes.ADD_SECTION: {
        const emptySection: Section = {
          // TODO to class
          id: uuidv4(),
          containers: [],
          options: {
            hasSidebar: false,
            sidebarWidth: 25,
            sidebarPosition: 'right',
          },
        }

        const { insertPlace } = action.payload
        const activeElem = draft.activeElement
        const sectionIndex =
          draft.options.sections.findIndex((item) => item.id === activeElem?.sectionId) +
          insertPlace

        draft.options.sections.splice(sectionIndex, 0, emptySection)
        break
      }
      case ActionTypes.REMOVE_SECTION: {
        const { id } = action.payload
        const deleteIndex = draft.options.sections.findIndex((item) => item.id === id)
        draft.options.sections.splice(deleteIndex, 1)
        break
      }
      case ActionTypes.TOGGLE_SIDEBAR: {
        const activeElem = draft.activeElement
        const sectionIndex = draft.options.sections.findIndex(
          (item) => item.id === activeElem?.sectionId
        )

        const { sidebarIsAdded } = action.payload
        draft.options.sections[sectionIndex].options.hasSidebar = sidebarIsAdded

        // add sidebar as typical container
        const activeSection = draft.options.sections[sectionIndex]
        if (sidebarIsAdded === true) {
          activeSection.containers.push({
            // TODO to class
            id: uuidv4(),
            sidebar: true,
            options: {
              width: 100,
              columns: 1,
            },
            name: '',
            slug: '',
          })
        } else {
          const findSidebarIndex = activeSection.containers.findIndex(
            (container: SectionContainer) => container.sidebar === true
          )
          if (findSidebarIndex !== -1) {
            draft.options.sections[sectionIndex].containers.splice(findSidebarIndex, 1)
          }
        }

        break
      }
      case ActionTypes.SET_SIDEBAR_WIDTH: {
        const activeElem = draft.activeElement
        const sectionIndex = draft.options.sections.findIndex(
          (item) => item.id === activeElem?.sectionId
        )

        const { sidebarWidth } = action.payload
        draft.options.sections[sectionIndex].options.sidebarWidth = sidebarWidth

        break
      }
      case ActionTypes.SET_SIDEBAR_POSITION: {
        const activeElem = draft.activeElement
        const sectionIndex = draft.options.sections.findIndex(
          (item) => item.id === activeElem?.sectionId
        )

        const { sidebarPosition } = action.payload
        draft.options.sections[sectionIndex].options.sidebarPosition = sidebarPosition

        break
      }
      case ActionTypes.SORT_SECTION: {
        const { source, destination } = action.payload.result
        draft.options.sections.splice(source.index, 1)
        const copyStateSections = {
          ...state.options.sections[source.index],
        }
        draft.options.sections.splice(destination.index, 0, copyStateSections)

        break
      }

      case ActionTypes.ADD_CONTAINER: {
        const activeElem = draft.activeElement
        const sectionIndex = draft.options.sections.findIndex(
          (section) => section.id === activeElem?.sectionId
        )
        const activeSection = draft.options.sections[sectionIndex]

        // if sidebar added
        const sidebarIndex = draft.options.sections[sectionIndex].containers.findIndex(
          (item) => item.sidebar === true
        )

        if (sidebarIndex !== -1) {
          activeSection.containers.splice(sidebarIndex, 0, {
            // TODO to class
            id: uuidv4(),
            sidebar: false,
            options: {
              width: 100,
              columns: 12,
            },
            name: '',
            slug: '',
          })
        } else {
          activeSection.containers.push({
            // TODO to class
            id: uuidv4(),
            sidebar: false,
            options: {
              width: 100,
              columns: 12,
            },
            name: '',
            slug: '',
          })
        }

        break
      }
      case ActionTypes.REMOVE_CONTAINER: {
        const activeElem = draft.activeElement
        const sectionIndex = draft.options.sections.findIndex(
          (item) => item.id === activeElem?.sectionId
        )

        const { id } = action.payload
        const deleteIndex = draft.options.sections[sectionIndex].containers.findIndex(
          (item: SectionContainer) => item.id === id
        )
        draft.options.sections[sectionIndex].containers.splice(deleteIndex, 1)

        break
      }
      case ActionTypes.SET_CONTAINER_WIDTH: {
        const activeElem = draft.activeElement
        const sectionIndex = draft.options.sections.findIndex(
          (item) => item.id === activeElem?.sectionId
        )
        const containerIndex = draft.options.sections[sectionIndex].containers.findIndex(
          (item: SectionContainer) => item.id === activeElem?.containerId
        )

        const { containerWidth } = action.payload
        draft.options.sections[sectionIndex].containers[containerIndex].options.width =
          containerWidth

        break
      }
      case ActionTypes.SET_CONTAINER_COLUMNS: {
        const activeElem = draft.activeElement
        const sectionIndex = draft.options.sections.findIndex(
          (item) => item.id === activeElem?.sectionId
        )
        const containerIndex = draft.options.sections[sectionIndex].containers.findIndex(
          (item: SectionContainer) => item.id === activeElem?.containerId
        )

        const { containerColumns } = action.payload
        draft.options.sections[sectionIndex].containers[containerIndex].options.columns =
          containerColumns

        break
      }
      case ActionTypes.SET_CONTAINER_NAME: {
        const activeElem = draft.activeElement
        const sectionIndex = draft.options.sections.findIndex(
          (item) => item.id === activeElem?.sectionId
        )
        const containerIndex = draft.options.sections[sectionIndex].containers.findIndex(
          (item: SectionContainer) => item.id === activeElem?.containerId
        )

        const { containerName } = action.payload
        draft.options.sections[sectionIndex].containers[containerIndex].name = containerName

        break
      }
      case ActionTypes.SET_CONTAINER_SLUG: {
        const activeElem = draft.activeElement
        const sectionIndex = draft.options.sections.findIndex(
          (item) => item.id === activeElem?.sectionId
        )
        const containerIndex = draft.options.sections[sectionIndex].containers.findIndex(
          (item: SectionContainer) => item.id === activeElem?.containerId
        )

        const { containerSlug } = action.payload
        draft.options.sections[sectionIndex].containers[containerIndex].slug = containerSlug

        break
      }

      case ActionTypes.SELECT_ELEMENT: {
        const { sectionId, containerId } = action.payload
        draft.activeElement = { sectionId, containerId }

        break
      }
      case ActionTypes.UNSELECT_ELEMENT: {
        draft.activeElement = null
        break
      }

      case ActionTypes.SET_INITIAL_OPTIONS: {
        const options = action.payload.data
        draft.options = options
        break
      }
      case ActionTypes.CLEAR_LAYOUT_STORE: {
        draft.options = {
          header: true,
          footer: true,
          sections: [],
        }
        draft.activeElement = null
        break
      }
    }
  })

export default reducer
