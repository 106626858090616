import { useMemo } from 'react'
import { getErrorsCount } from '../utils'
import useFieldController from 'core/form/hooks/use-field-controller'

export default function useGroupErrors(groupPath: string) {
  const [, meta] = useFieldController(groupPath)
  const attrsErrors = meta.error

  const errorsCount = useMemo(() => {
    if (!meta.hasError) return 0
    return getErrorsCount(attrsErrors)
  }, [attrsErrors, meta.hasError])

  const hasError = errorsCount > 0

  return { errorsCount, hasError }
}
