import { useCallback, useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { EntitySettings, IEntity, SetEntityOptionFunc, SortedAttribute } from '../types'

const defaultSettings: EntitySettings = {
  dynamicLayout: false,
  dynamicLayoutSections: [],
}

const useEntitySettings = (
  attributes: SortedAttribute[],
  formRef: FormikProps<any>,
  entity: IEntity,
  isLoading: boolean
) => {
  const [options, setOptions] = useState<EntitySettings>(defaultSettings)
  const optionsAttrIri = attributes?.find((attr) => attr.attribute.slug === 'ui_options')
    ?.attribute['@id']

  useEffect(() => {
    if (isLoading || !entity || !optionsAttrIri) return
    const value = entity.values.find((val) => val.attribute === optionsAttrIri)
    if (!value) return
    setOptions(() => ({ ...JSON.parse(value.value) }))
  }, [isLoading, attributes, optionsAttrIri, entity])

  const setEntityOption = useCallback<SetEntityOptionFunc>(
    (field: keyof EntitySettings, value: unknown) => {
      if (!optionsAttrIri) return

      setOptions((prev) => {
        const newValue = {
          ...prev,
          [field]: value,
        }

        const valueId = formRef.values[optionsAttrIri]?.id || null

        formRef.setFieldValue(optionsAttrIri, { id: valueId, value: JSON.stringify(newValue) })

        return newValue
      })
    },
    [formRef, optionsAttrIri]
  )

  return {
    options,
    setEntityOption,
  }
}

export default useEntitySettings
