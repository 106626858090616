// Core
import React, { FC } from 'react'
import { Link, TableCell, TableRow } from '@material-ui/core'
import clsx from 'clsx'
// Components
import { ActionsCell, FrequencyCell, PriorityCell } from '../common'
// Hooks
import { useSettingItemController } from 'modules/sitemap-settings/hooks'
import { useSitesContext } from 'modules/sites'
// Types
import { SitemapPage } from 'modules/sitemap-settings/types'
// Styles
import useStyles from './setting-item.styles'

type Props = {
  data: SitemapPage
}

const EntitySettingItem: FC<Props> = (props) => {
  const { data } = props
  const classes = useStyles()
  const {
    //
    isEdit,
    form,
    editHandler,
    deleteHandler,
    mutation,
  } = useSettingItemController<SitemapPage>(data)

  const { activeSite } = useSitesContext()
  const url = `${activeSite?.url}/${data.url}`

  return (
    <TableRow className={clsx({ [classes.loading]: mutation.isLoading })}>
      <TableCell size="small" width="15%">
        {data.name}
      </TableCell>
      <TableCell size="small" width="15%">
        <Link href={url} target="_blank">
          {url}
        </Link>
      </TableCell>
      <PriorityCell name="priority" isEdit={isEdit} data={data} form={form} />
      <FrequencyCell name="frequency" isEdit={isEdit} data={data} form={form} />
      <ActionsCell
        permissionType="sitemap_urls"
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        isEdit={isEdit}
      />
    </TableRow>
  )
}

export default EntitySettingItem
