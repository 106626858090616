import React, { FC, useCallback, useEffect, useState } from 'react'
import attributeFilterStyles from './attribute-filter.styles'
import { Chip } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import { httpService } from 'core/data'
import { useListContext } from 'core/data'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: object
}

const AttributeFilter: FC<TProps> = (props) => {
  const classes = attributeFilterStyles()
  const { t } = useTranslation()
  const {
    listParams: { methods },
  } = useListContext()

  const initialValues: [] = []

  const [checkedItems, setCheckedItems] = useState<any>(initialValues)
  const [response, setResponse] = useState<any>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    httpService
      .get<any>('/attribute_groups')
      .then((resp) => {
        setResponse(resp.data['hydra:member'])
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleClick = useCallback(
    (id) => (e: any) => {
      if (checkedItems.includes(id)) {
        setCheckedItems(checkedItems.filter((item: any) => item !== id))
      } else {
        setCheckedItems((items: any) => [...items, id])
      }
      methods.setFilter('attributeGroups', checkedItems)
    },
    [checkedItems, methods]
  )

  const clearState = () => {
    setCheckedItems([...initialValues])
    methods.setFilter('attributeGroups', [])
  }

  useEffect(() => {
    methods.setFilter('attributeGroups', checkedItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems])

  return (
    <div className={classes.root}>
      <div className={classes.items}>
        {loading
          ? t('attrs.list.group-loading')
          : response.map((item: any) => (
              <Chip
                key={item.id}
                label={item.name}
                clickable
                color="primary"
                onClick={handleClick(item['@id'])}
                variant={checkedItems.includes(item['@id']) ? 'default' : 'outlined'}
                icon={checkedItems.includes(item['@id']) ? <DoneIcon /> : <i />}
                id="1"
              />
            ))}
      </div>
      {response.length > 0 && !loading && checkedItems.length !== 0 && (
        <span className={classes.reset} onClick={clearState}>
          {t('attrs.list.clear')}
        </span>
      )}
    </div>
  )
}

export default AttributeFilter
