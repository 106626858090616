import React, { FC } from 'react'
import { Box, LinearProgress } from '@material-ui/core'
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
import { useQuery } from 'react-query'

export const DisplayRelation: FC<{ id: string | null }> = ({ id }) => {
  const { isError, isLoading, data } = useQuery(
    ['user', id],
    () => {
      if (!id) throw new Error('Undefined ID')
      return httpService.get<any>(`/users/${getIdFromIri(id)}`).then((res) => res.data)
    },
    {
      enabled: Boolean(id),
    }
  )

  if (isError) {
    return <Box>Fetch error</Box>
  }

  if (isLoading) {
    return <LinearProgress style={{ width: 100, display: 'inline-flex' }} />
  }

  if (!data) return null

  return (
    <span>
      {data.firstName} {data.lastName}
    </span>
  )
}
