import React, { useCallback } from 'react'
import { useField } from 'formik'
import { slugifyValue } from 'modules/new-entity/utils'
import { withDotValue } from 'core/utils'

type UseSetEntityTitle = (
  slug: string | undefined
) => (event: React.ChangeEvent<string | any>) => void

export const useSetEntityTitle: UseSetEntityTitle = (slug) => {
  const [field, , helpers] = useField(withDotValue(slug || 'slug'))

  return useCallback(
    (event: React.ChangeEvent<string | any>) => {
      if (!slug) return
      if (field.value) return
      helpers.setValue(slugifyValue(event.target.value))
    },
    [slug, helpers, field.value]
  )
}
