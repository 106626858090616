/** Core */
import { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useGetNavigationContext } from './use-get-navigation-context'
/** Types */
import { FullTree, NodeData, OnMovePreviousAndNextLocation } from 'react-sortable-tree'
import { EnumNavigationMethod, EnumNavLinkTypes, TNavigationItemTransformed } from '../types'
/** Utils */
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import debounce from 'lodash.debounce'

type TReturnProps = {
  state: TNavigationItemTransformed[]
  actions: {
    onChangeHandler: (treeData: TNavigationItemTransformed[]) => void
    dragEndHandler: (movedProps: NodeData & FullTree & OnMovePreviousAndNextLocation) => void
    removeHandler: (entity: TNavigationItemTransformed) => void
  }
}

type TTransformedDataForApi = {
  type: number
  navItemId: number
  position: number
  parentIri: string | null
}

type TUseNavTreeHooks = () => TReturnProps

const sortItems = (items: TNavigationItemTransformed[]) => {
  return items.sort((a, b) => {
    //@ts-ignore
    return a.position > b.position ? 1 : -1
  })
}

const setParent = (array: TNavigationItemTransformed[]) => {
  // eslint-disable-next-line
  let c: TNavigationItemTransformed[] = cloneDeep(array)

  const eachData = (data: TNavigationItemTransformed[]) => {
    data.forEach((item: TNavigationItemTransformed) => {
      item.parent = null
      if (item.children.length) {
        eachData(item.children)
        item.children.forEach((children: any) => {
          children.parent = item?.entity
        })
      }
    })

    return data
  }
  return eachData(c)
}

export const useNavTreeHooks: TUseNavTreeHooks = () => {
  const { navigationId, changeParentNavigationItem } = useGetNavigationContext()
  const [state, setState] = useState<TNavigationItemTransformed[]>([])
  const [expandedState, setExpandedState] = useState<{ [id: number]: boolean }>({})

  useQuery<{ items: TNavigationItemTransformed[] }>(
    ['navigation', navigationId],
    async () => {
      const valuesRes = await httpService.get<{ items: TNavigationItemTransformed[] }>(
        `/navigations/${navigationId}`
      )
      return valuesRes.data
    },
    {
      enabled: Boolean(navigationId),
      select: (data): { items: TNavigationItemTransformed[] } => {
        const items = cloneDeep(data.items)

        items.forEach((item, index) => {
          items[index].children = []
          items[index].expanded = true

          if (Object.keys(expandedState).length !== 0) {
            items[index].expanded =
              expandedState[item.id] !== undefined ? expandedState[item.id] : true
          }
        })

        const createDataTree = (
          dataset: TNavigationItemTransformed[]
        ): TNavigationItemTransformed[] => {
          const hashTable = Object.create(null)
          const dataTree: TNavigationItemTransformed[] = []

          dataset.forEach((navItem: any) => {
            hashTable[navItem.id] = { ...navItem }
          })

          dataset.forEach((navItem: TNavigationItemTransformed) => {
            const parentId = +getIdFromIri(String(navItem?.parent))

            if (navItem.parent) {
              if (!hashTable[parentId]) return

              hashTable[parentId].children.push(hashTable[navItem.id])
            } else {
              dataTree.push(hashTable[navItem.id])
            }
          })

          dataset.forEach((navItem: TNavigationItemTransformed) => {
            const parentId = +getIdFromIri(String(navItem?.parent))

            if (!hashTable[parentId]) return

            if (navItem.parent) {
              hashTable[parentId].children = [...sortItems(hashTable[parentId].children)]
            }
          })

          return sortItems(dataTree)
        }

        return { items: createDataTree(items) }
      },
      onSuccess: (_data) => {
        setState(_data.items)
      },
    }
  )

  const setExpanded = useCallback((itemsData: TNavigationItemTransformed[]) => {
    const cloneData = cloneDeep(itemsData)
    const expandedObj: { [id: number]: boolean } = {}
    const arrayOfIds = (data: TNavigationItemTransformed[]) => {
      data.forEach((item) => {
        expandedObj[item.id] = item.expanded
        if (item.children.length) {
          arrayOfIds(item.children)
        } else {
          expandedObj[item.id] = Boolean(item.expanded)
        }
      })

      return expandedObj
    }
    setExpandedState(arrayOfIds(cloneData))
  }, [])

  const onChangeHandler = useCallback(
    (treeData) => {
      setExpanded(treeData)
      setState(treeData)
    },
    [setExpanded]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(
    debounce((dataParams: any) => {
      changeParentNavigationItem(dataParams, EnumNavigationMethod.MOVE)
    }, 2000),
    []
  )

  const dragEndHandler = useCallback(
    async (movedProps) => {
      const { treeData, path, prevPath } = movedProps

      if (isEqual(path, prevPath)) return
      const parentData = setParent(treeData)
      const transformedData: TTransformedDataForApi[] = []

      const setData = (_data: TNavigationItemTransformed[]) => {
        for (let i = 0; i < Number(_data?.length); i++) {
          const item = _data[i]
          if (item.children.length !== 0) {
            setData(item.children)
          }

          transformedData.push({
            type: item.targetEntity
              ? EnumNavLinkTypes.NAVIGATION_LINK
              : EnumNavLinkTypes.NAVIGATION_CUSTOM,
            navItemId: item.id,
            position: i,
            parentIri: item?.parent || null,
          })
        }

        return transformedData
      }
      const dataForApi = setData(parentData)

      debounced(dataForApi)
    },
    [debounced]
  )

  const removeHandler = useCallback((entity: TNavigationItemTransformed) => {
    const findAndRemove = (data: TNavigationItemTransformed[]) => {
      data.forEach((item, index) => {
        if (item.id === entity.id) {
          data.splice(index, 1)
        } else if (item.children.length > 0 && item.id !== entity.id) {
          findAndRemove(item.children)
        }
      })

      return data
    }
    setState((prevState) => {
      return [...findAndRemove(prevState)]
    })
  }, [])

  const actions = useMemo(
    () => ({
      onChangeHandler,
      dragEndHandler,
      removeHandler,
    }),
    [onChangeHandler, dragEndHandler, removeHandler]
  )

  return {
    state,
    actions,
  }
}
