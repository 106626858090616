/**
 * Helper hook for ./use-simultaneously-edit
 * This hook is responsible for socket events listeners
 */
import { useEffect } from 'react'
import { useEvents } from 'core/events'
import {
  ASK_CONTROL_EVENT,
  CANCEL_ASK_CONTROL,
  DENY_CONTROL_EVENT,
  LOCK_DELETED_EVENT,
  LOCK_UPDATED_EVENT,
  parseSocketEvent,
} from './utils'
import { useNotify } from 'core/hooks'
import { AskControlData, LockData, LockedUser } from './types'

type Params = {
  lockKey: string
  canEdit: boolean
  enabled: boolean
  lockedUser: LockedUser
  onLockDeleted?: () => void
  onLockUpdated?: (data: LockData) => void
  onAskControlRequest?: (data: AskControlData) => void
  onDenyControl?: () => void
  onCancelRequestControl?: () => void
}

export function useMessages(params: Params) {
  const {
    lockKey,
    canEdit,
    enabled,
    lockedUser,
    onLockDeleted,
    onLockUpdated,
    onAskControlRequest,
    onDenyControl,
    onCancelRequestControl,
  } = params
  const notify = useNotify()
  const { subscribe } = useEvents()
  const MESSAGES_KEY = `/api/locks/${lockKey}`

  useEffect(() => {
    if (!enabled) return

    const subscription = subscribe(MESSAGES_KEY)

    const listener = (e: any) => {
      const eventData = parseSocketEvent(e)
      const { event, data } = eventData

      if (event === ASK_CONTROL_EVENT && canEdit) {
        onAskControlRequest && onAskControlRequest(data)
      }
      if (event === DENY_CONTROL_EVENT) {
        onDenyControl && onDenyControl()
        if (!canEdit) {
          notify(`Edit access has been denied by ${lockedUser.userName}`, { type: 'error' })
        }
      }
      if (event === LOCK_DELETED_EVENT) {
        onLockDeleted && onLockDeleted()
        if (!canEdit) {
          notify(`${lockedUser.userName} quit editing`, { type: 'success' })
        }
      }
      if (event === LOCK_UPDATED_EVENT) {
        onLockUpdated && onLockUpdated(data)
      }
      if (event === CANCEL_ASK_CONTROL) {
        onCancelRequestControl && onCancelRequestControl()
      }
    }

    subscription.addEventListener('message', listener)

    return () => {
      subscription.removeEventListener('message', listener)
      subscription.close()
    }
  }, [
    MESSAGES_KEY,
    canEdit,
    enabled,
    lockedUser,
    notify,
    onAskControlRequest,
    onCancelRequestControl,
    onDenyControl,
    onLockDeleted,
    onLockUpdated,
    subscribe,
  ])
}
