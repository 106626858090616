import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { useDebounce } from 'core/hooks'
import { HydraResponse } from 'core/types'
import { SearchResult } from './types'
import useGetFilterData from './use-get-filter-data'
import useFilters from 'modules/eav/entity/filters/use-filters'
import { useAppContext } from 'core/app'

type ResultsRes = HydraResponse<SearchResult>

type ReqParams = {
  search: string
  entityType?: string
  filter?: any
}

export default function useSearchController() {
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 300)
  const { user } = useAppContext()

  const [entityType, setEntityType] = useState('')

  const [replaceOpen, setReplaceOpen] = useState(false)

  const filters = useFilters()
  const { filterParams } = filters

  const resultsQ = useQuery(
    ['global-search', debouncedValue, entityType, filterParams],
    () => {
      const params: ReqParams = {
        search: debouncedValue,
      }

      if (entityType) {
        params.entityType = entityType
      }

      if (filterParams) {
        params.filter = filterParams
      }

      return httpService
        .get<ResultsRes>('/entity_counts', {
          params,
        })
        .then((res) => res.data['hydra:member'])
    },
    {
      enabled: Boolean(debouncedValue) || filters.hasSelectedParams,
    }
  )

  const filtersDataProps = useGetFilterData({
    entityType,
  })

  const totalCount = useMemo(() => {
    return (resultsQ.data || []).reduce((acc, item) => {
      return acc + item.count
    }, 0)
  }, [resultsQ.data])

  const canReplace = ['architect', 'content_editor', 'admin'].includes(user.activeRole.slug)

  return {
    value,
    setValue,
    searchTerm: debouncedValue,
    entityType,
    setEntityType,
    data: resultsQ.data,
    totalCount,
    isLoading: resultsQ.isLoading || resultsQ.isFetching,
    filtersData: filtersDataProps.data,
    filtersLoading: filtersDataProps.isLoading,
    filters,
    replaceOpen,
    setReplaceOpen,
    replaceEnabled: canReplace,
  }
}
