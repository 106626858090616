import React, { FC } from 'react'
import { Create } from 'core/data'
import { ResourceProps } from 'core/data'
import CrudForm from '../crud-form'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  alias: Yup.string().required(),
})

const CreateUser: FC<ResourceProps> = (props) => {
  const initialProps = {
    roles: [],
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    alias: '',
    entities: [],
    siteRoles: [],
  }

  return (
    <Create
      validationSchema={validationSchema}
      initialData={initialProps}
      {...props}
      crudForm={CrudForm}
    />
  )
}

export default CreateUser
