// Core
import React, { FC } from 'react'
// Context
import { SitemapSettingsProvider } from '../../context'
// Components
import { SettingsTable, CreateSettingsModal, Regenerate } from '../../components'
import PageHeader from 'modules/layout/components/page-header'
// Types
import { SettingsType } from '../../types'

type Props = {
  children?: never
}

const SitemapSettingsMap: FC<Props> = () => {
  return (
    <SitemapSettingsProvider>
      <PageHeader titleTransKey="xml_settings" actions={<Regenerate />} />
      <SettingsTable type={SettingsType.entityType} />
      <SettingsTable type={SettingsType.entity} />
      <CreateSettingsModal />
    </SitemapSettingsProvider>
  )
}

export default SitemapSettingsMap
