import { ChangeEvent, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setContainerName } from '../store'

type UseContainerName = () => any

export const useContainerName: UseContainerName = () => {
  const dispatch = useDispatch()

  return useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setContainerName(event.target.value))
    },
    [dispatch]
  )
}
