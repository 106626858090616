import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setInitialOptions } from '../store'

type UseSetInitialOptions = (values: any, type: 'edit' | 'create') => void

export const useSetInitialOptions: UseSetInitialOptions = (values, type) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (type === 'edit') {
      dispatch(setInitialOptions(values.options))
    }

    // TODO DEPS
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
