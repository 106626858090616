// Core
import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { Chip } from '@material-ui/core'
// Services/Utils
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
// Types
import { IEntity } from 'modules/new-entity/types'

type Props = {
  widgetIri: string | null
}

const PersonalizedIndicator: FC<Props> = (props) => {
  const { widgetIri } = props

  const widgetQuery = useQuery(
    [`global-widget-${widgetIri}`],
    () => {
      const widgetId = getIdFromIri(widgetIri!)
      return httpService.get<IEntity>(`/widgets/${widgetId}`).then((res) => res.data)
    },
    {
      enabled: Boolean(widgetIri),
      select: (data) => {
        return data.versions.some((version) => version.originalLabel === 'personalized')
      },
    }
  )

  if (!widgetQuery.data) return null

  return (
    <Chip
      component="span"
      label="Personalized"
      size="small"
      style={{ background: '#e5e8f7', marginLeft: 14 }}
    />
  )
}

export default PersonalizedIndicator
