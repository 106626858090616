import { useCallback } from 'react'
import { useSitesContext } from 'modules/sites'

export const useInitialLocalization = (keys: any, notSystem = false): any => {
  const { locales } = useSitesContext()
  const localizations = locales[notSystem ? 'site' : 'system']

  return useCallback(
    (data: any) => {
      data = data || {}
      if (!localizations) return

      const translationValue = {}

      keys.forEach((key: any) => {
        // @ts-ignore
        translationValue[key] = ''
      })
      const translations = {}
      localizations.forEach((item) => {
        // @ts-ignore
        translations[item.code] = translationValue
      })

      data.translations = translations
    },
    [keys, localizations]
  )
}
