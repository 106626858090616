// Core
import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
// Components
import { DataControllerProps } from 'core/data'
import { TextField } from 'core/form/components'
import RulesField from './rules-field'

const CrudForm: FC<DataControllerProps> = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField name="name" label="Name" />
      </Grid>
      <Grid item xs={12}>
        <TextField name="priority" label="Priority" type="number" />
      </Grid>
      <Grid item xs={12}>
        <RulesField />
      </Grid>
    </Grid>
  )
}

export default CrudForm
