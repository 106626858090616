// Core
import { useContext } from 'react'
// Context
import { SitesContext } from '../context'
// Types
import { SitesContextProps } from '../types'

type UseSitesContext = () => SitesContextProps

export const useSitesContext: UseSitesContext = () => {
  const context = useContext<SitesContextProps>(SitesContext)

  return context
}
