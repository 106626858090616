import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setContainerWidth } from '../store'

type UseContainerWidth = () => any

export const useContainerWidth: UseContainerWidth = () => {
  const dispatch = useDispatch()

  return useCallback(
    (event: any, value: any) => {
      dispatch(setContainerWidth(value))
    },
    [dispatch]
  )
}
