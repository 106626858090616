import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const { contentBg } = defaultThemeColors

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: contentBg,
      width: '100%',
      '&>div': {
        padding: '10px',
        '&:hover': {
          '&:not(.Mui-disabled):before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
          },
        },
        '&:before': {
          borderBottom: '1px solid rgb(196 196 196)',
        },
      },
    },
  })
)

export default styles
