import { Action } from 'redux'

export interface IModalState {
  open: boolean
  type: 'confirm'
  text: string
}

export enum ActionTypes {
  OPEN_MODAL = '@@modal/OPEN_MODAL',

  CONFIRM = '@@modal/CONFIRM',
  CANCEL = '@@modal/CANCEL',
}

export interface ConfirmDialogAction extends Action<ActionTypes.OPEN_MODAL> {
  readonly payload: IModalState
}

export interface ConfirmAction extends Action<ActionTypes.CONFIRM> {}

export interface CancelAction extends Action<ActionTypes.CANCEL> {}
