// Core
import React, { FC } from 'react'
import { ResourceProps } from 'core/data'
import { DataView } from 'core/data'
import { getActionsColumns, getTableColumn } from 'core/data/components/data-view'
import { useTranslation } from 'react-i18next'

const MediaSizesList: FC<ResourceProps> = (props) => {
  const { t } = useTranslation()

  return (
    <DataView
      resourceProps={props}
      columns={[
        getActionsColumns(props.name, t),
        getTableColumn({ title: 'name', field: 'name' }),
        getTableColumn({ title: 'slug', field: 'slug' }),
      ]}
    />
  )
}

export default MediaSizesList
