import React, { FC, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  List,
} from '@material-ui/core'
import { isAxiosError } from 'axios'
import { useMutation } from 'react-query'
import { useDropzone } from 'react-dropzone'
import { Delete, Description } from '@material-ui/icons'
import { DialogTitle } from 'ui'
import DropArea from 'common/components/drop-area'
import { useNotify } from 'core/hooks'
import { useRedirectsContext } from 'modules/redirects/hooks'
import { httpService } from 'core/data'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const MAX_FILE_SIZE = 25_000_000

export const UploadFiles: FC<Props> = ({ isOpen, onClose }) => {
  const notify = useNotify()
  const { invalidate } = useRedirectsContext()

  const [file, setFile] = useState<File | null>(null)

  const dropzoneProps = useDropzone({
    accept: ['text/csv'],
    maxSize: MAX_FILE_SIZE,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0])
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((rejectedFile) => {
        rejectedFile.errors.forEach((error) => {
          notify(error.message, { type: 'error' })
        })
      })
    },
  })

  const importM = useMutation(
    async () => {
      if (!file) {
        throw new Error('File not selected')
      }

      const fileData = await file.arrayBuffer()

      return httpService.patch('/redirects', fileData, {
        headers: { accept: '*', 'Content-Type': file.type },
      })
    },
    {
      onSuccess: () => {
        invalidate()
        onClose()
        notify('Redirects successfully imported.', { type: 'success' })
      },
      onError: (error) => {
        if (isAxiosError(error) && error.response?.data.detail) {
          notify(error.response?.data.detail, { type: 'error' })
          return
        }
        notify('Something went wrong', { type: 'error' })
      },
    }
  )

  const hasFile = !!file

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Import CSV</DialogTitle>
      <DialogContent>
        <DropArea
          dropzoneProps={dropzoneProps}
          placeholder="Make sure its a CSV file, up to 25MB"
        />
        {hasFile && (
          <List>
            <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText>{file.name}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton edge="end" size="small" onClick={() => setFile(null)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!hasFile || importM.isLoading}
          onClick={() => importM.mutate()}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  )
}
