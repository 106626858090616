// Core
import React, { FC, Ref } from 'react'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { AddCircleOutlined, Search, Close } from '@material-ui/icons'

type Props = {
  children?: never
  searchValue: string
  onSearchChange: (value: string) => void
  onSearchSubmit: (e: any) => void
  onSearchClear: () => void
  hasCreateButton: boolean
  buttonProps?: any
  buttonText: string
  isLoading: boolean
  actionArea?: JSX.Element
  wrapperRef?: Ref<HTMLDivElement>
}

const InputIcon = (
  <InputAdornment position="start">
    <Search />
  </InputAdornment>
)

const useStyles = makeStyles((theme) => ({
  clearButton: {
    transition: 'opacity .3s ease',
  },
}))

const DataGridHeader: FC<Props> = (props) => {
  const {
    searchValue,
    onSearchSubmit,
    onSearchChange,
    onSearchClear,
    hasCreateButton,
    buttonProps = {},
    buttonText,
    isLoading,
    actionArea = null,
    wrapperRef,
  } = props

  const classes = useStyles()

  return (
    <Box
      display="flex"
      alignItems="center"
      position="relative"
      px={1}
      py={1}
      borderBottom={1}
      style={{ borderColor: '#e0e0e0' }}
      component="form"
      onSubmit={onSearchSubmit}
      // @ts-ignore
      ref={wrapperRef}
    >
      <TextField
        InputProps={{
          startAdornment: InputIcon,
          endAdornment: (
            <IconButton
              size="small"
              onClick={onSearchClear}
              className={classes.clearButton}
              style={{ opacity: searchValue ? 1 : 0 }}
            >
              <Close />
            </IconButton>
          ),
        }}
        variant="outlined"
        size="small"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => onSearchChange(e.currentTarget.value)}
      />
      <Box ml="auto" display="flex" alignItems="center">
        {hasCreateButton && (
          <Button
            // component={NavLink}
            // to={createPath}
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlined />}
            {...buttonProps}
          >
            {buttonText}
          </Button>
        )}
        {actionArea}
      </Box>
      {isLoading && (
        <Box width="100%" zIndex={1100} position="absolute" top="100%" left={0} right={0}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  )
}

export default DataGridHeader
