import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const navigationMutationRouteStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      minHeight: 'calc(100vh - 92px)',
    },
    urlableList: {
      display: 'flex',
    },
    form: {
      flexBasis: 'auto',
    },
    content: {
      flex: 1,
      backgroundColor: '#fff',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
    },

    heightFull: {
      height: '100%',
      // padding: theme.spacing(2),
    },
    spinner: {
      display: 'flex',
      'justify-content': 'center',
      width: '100%',
      padding: theme.spacing(10),
    },
  })
)

export default navigationMutationRouteStyles
