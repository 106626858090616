/** Core */
import React, { FC, useCallback } from 'react'
/** Material */
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
/** Hooks */
import { useGetNavigationContext } from 'modules/navigation/hooks'
import { useTranslation } from 'react-i18next'
import { useConfirmation } from 'core/confirmation'
/** Utils */
import { getIdFromIri } from 'core/utils'
/** Styles */
import useStyles from './action-area.styles'
import { TNavigationItemTransformed } from '../../../../types'

type TProps = {
  node: any
  removeHandler: (entity: TNavigationItemTransformed) => void
}

const ActionArea: FC<TProps> = (props) => {
  const { node, removeHandler } = props
  const classes = useStyles()

  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()

  const removeWithConfirmation = useCallback(
    (onSuccess: () => void, entity: TNavigationItemTransformed, children) => {
      setConfirmation({
        open: true,
        title: t('global.delete'),
        content: t('notify.delete'),
        note: children.length > 0 && t('notify.note-children'),
        onSuccess: () => {
          onSuccess()
          removeHandler(entity)
        },
      })
    },
    [removeHandler, setConfirmation, t]
  )

  const {
    actions: { editNavigationItem, deleteNavigationItem },
  } = useGetNavigationContext()

  return (
    <div className={classes.root}>
      <IconButton
        onClick={() =>
          editNavigationItem(+getIdFromIri(node.entity), +getIdFromIri(node.entityType), node.type)
        }
        size={'small'}
        aria-label="edit"
      >
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={() =>
          removeWithConfirmation(() => deleteNavigationItem(node.entity), node, node.children)
        }
        size={'small'}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    </div>
  )
}

export default ActionArea
