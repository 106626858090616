import { Section } from 'core/types/layouts'

export const reorderContainers = (sections: Section[]) => {
  sections.forEach((section, index) => {
    const container = section.containers[0]

    container.name = `Container ${index + 1}`
    container.slug = `Container-${index + 1}`

    return section
  })
}
