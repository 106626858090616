import { createSelector, Selector } from 'reselect'
import { IRootState } from 'core/init/types'
import { IEntityLayoutState } from './types'
import { SectionContainer } from 'core/types/layouts'

export const rootSelector: Selector<IRootState, IEntityLayoutState> = (state) => state.entityLayout

export const headerIsAddedSelector = createSelector(
  rootSelector,
  (state) => state.options.header || false
)
export const footerIsAddedSelector = createSelector(
  rootSelector,
  (state) => state.options.footer || false
)

export const sectionsSelector = createSelector(rootSelector, (state) => state.options.sections)

export const activeSectionIdSelector = createSelector(
  rootSelector,
  (state) => state.activeElement?.sectionId || ''
)
export const activeContainerIdSelector = createSelector(
  rootSelector,
  (state) => state.activeElement?.containerId || ''
)

export const getActiveSectionSelector = (section: string | number) =>
  createSelector(sectionsSelector, (state) => {
    const activeSection = state.find((_section) => _section.id === section)
    return {
      activeSection,
    }
  })

export const getActiveContainerSelector = (section: string | number, container: string | number) =>
  createSelector(sectionsSelector, (state) => {
    const activeSection = state.find((_section) => _section.id === section)
    const activeContainer = activeSection?.containers.find(
      (_container: SectionContainer) => _container.id === container
    )
    return {
      activeContainer,
    }
  })
