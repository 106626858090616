// Core
import React, { FC, useCallback } from 'react'
// Components
import ActionBase from '../action-base'
import { Icon } from '@material-ui/core'
// Hooks
import { useListContext } from 'core/data'
import { useDelete } from 'core/data'
import { useConfirmation } from 'core/confirmation'
import { useTranslation } from 'react-i18next'

type Props = {
  rowData: any
}

const DeleteAction: FC<Props> = (props) => {
  const { rowData } = props
  const {
    resource: { name },
  } = useListContext()

  const { mutate: remove } = useDelete(name)

  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()

  const removeWithConfirmation = useCallback(
    (onSuccess: () => void) => {
      setConfirmation({
        open: true,
        title: t('global.delete'),
        content: t('notify.delete'),
        onSuccess,
      })
    },
    [setConfirmation, t]
  )

  return (
    <ActionBase
      rowData={rowData}
      onClick={() => removeWithConfirmation(() => remove(rowData.id))}
      icon={<Icon className="icon-delete" />}
    />
  )
}

export default DeleteAction
