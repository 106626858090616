import React, { FC, useMemo } from 'react'
import {
  Breadcrumbs as BreadcrumbsMui,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { MediaFolder } from 'modules/media/types'
import { MoreHoriz, NavigateNext } from '@material-ui/icons'
import { generateBreadcrumbs } from 'modules/media/utils'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& .MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap',
      overflow: 'hidden',
      width: '100%',
    },
    '& li': {
      flexShrink: 1,
      minWidth: 0,
      '& button': {
        display: 'inline-block',
        maxWidth: '100%',
      },
    },
  },
}))

type Props = {
  folder: number
  foldersData: MediaFolder[]
  onSelect: (id: number) => void
}

export const Breadcrumbs: FC<Props> = ({ folder, foldersData, onSelect }) => {
  const classes = useStyles()

  const breadcrumbsData = useMemo(() => {
    const breadcrumbs = generateBreadcrumbs(foldersData, folder)
    const hiddenItemsCount = breadcrumbs.length > 3 ? breadcrumbs.length - 3 : 0
    const hiddenItems = breadcrumbs.slice(0, hiddenItemsCount)
    const displayedItems = breadcrumbs.slice(-3)
    return { hiddenItems, displayedItems }
  }, [folder, foldersData])

  const { hiddenItems, displayedItems } = breadcrumbsData

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  return (
    <BreadcrumbsMui aria-label="breadcrumb" className={classes.root} separator={<NavigateNext />}>
      {hiddenItems.length > 0 && (
        <div>
          <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreHoriz />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {hiddenItems.map((item) => (
              <MenuItem
                key={item.id}
                button
                onClick={() => {
                  setAnchorEl(null)
                  onSelect(item.id)
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}

      {displayedItems.map((item, index) => {
        const isLast = index === displayedItems.length - 1
        const clickHandler = !isLast ? () => onSelect(item.id) : undefined

        return (
          <Link
            key={item.id}
            component="button"
            color={isLast ? 'textPrimary' : 'textSecondary'}
            onClick={clickHandler}
            disabled={isLast}
            underline={isLast ? 'none' : 'hover'}
            style={{ cursor: isLast ? 'default' : 'pointer' }}
            noWrap
            title={item.name}
          >
            {item.name}
          </Link>
        )
      })}
    </BreadcrumbsMui>
  )
}
