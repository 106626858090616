// Core
import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
// Images
import loginLogo from 'assets/img/login-logo.svg'
import bgImage from 'assets/img/login.svg'
// Styles
import useStyles from './auth-layout.styles'

type Props = {}

const AuthLayout: FC<Props> = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.loginContainer}>
      <Box className={classes.loginBackground}>
        <img src={bgImage} alt="login-background" />
        <Typography variant="h5" className={classes.loginBgText}>
          VULCAN CMS
        </Typography>
      </Box>
      <Box className={classes.loginForm}>
        <div className={classes.logo}>
          <img src={loginLogo} alt="login-logo" />
        </div>
        {children}
      </Box>
    </div>
  )
}

export default AuthLayout
